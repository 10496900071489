import axios from 'axios';

export const instance = axios.create({
    baseURL: process.env.REACT_APP_LQLIVE_ADMIN_BACKEND_SERVER,
    timeout: process.env.REQUEST_TIMEOUT || 50000,
});
export function setAuthorizationToken(user) {
    if (user) {
        instance.defaults.headers.common.user_id = `${user.user_id}`;
        instance.defaults.headers.common['session-token'] = `${user.session_token}`;
    } else {
        delete instance.defaults.headers.common.user_id;
        delete instance.defaults.headers.common['session-token'];
    }
}



export const makeHttpRequest = config => axios
        .request({
            method: config.method || 'GET',
            baseURL: config.baseURL ? config.baseURL : process.env.REACT_APP_LQLIVE_ADMIN_BACKEND_SERVER,
            url: config.url,
            data: config.data,
            headers: config.headers ? config.headers : {},
            params: config.params,
            timeout: process.env.REQUEST_TIMEOUT || 50000,
            responseType: config.responseType ? config.responseType : 'json',
        })
        .then(res => res)
        .catch((err) => {
            throw new Error(err);
        });

export default instance;
