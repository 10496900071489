import React from 'react';
import Styles from './styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
//import {Add, Delete} from '@material-ui/icons';
import InputBox from '../../components/InputBox';
import InputLabel from '@material-ui/core/InputLabel';
import axiosInstance from '../../config/axios';
import { withSnackbar } from 'notistack';
import MomentUtils from '@date-io/moment';
import axios from 'axios'
import { connect } from "react-redux";

class StudentSchedule extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            scheduled_time: new Date(),
            scheduled_day: (new Date()).getDay(),
            start_date: new Date(),
            schedule_list: [],
            teacher_list: null,
            selected_teacher: "",
            class_id:this.props.location && this.props.location.query && this.props.location.query.grade,
            number_of_class:null,
            group_type:null,
        }
    }

    componentDidMount(){
        const user_id=this.props && this.props.user.user_id
        const groupType = this.props.location && this.props.location.query && this.props.location.query.groupType
        this.setState({group_type : groupType})
        if(groupType=="FREE"){
            this.setState({number_of_class : 1})
        }
        axios.get(`v1/admin/${user_id}/teacher/`)
        .then((res)=>{
            if(res.status==200){
                console.log(res.data)
                this.setState({
                    teacher_list: res.data.data
                })
            }
        })
        .catch((err)=>{
            // console.log(err.response.data)
        })
    }

    handleDateChange = (scheduled_time, index) => {
        if(index>=0){
            let schedule_list = this.state.schedule_list
            schedule_list[index].time = scheduled_time
            this.setState({
                schedule_list
            })
        }
        else{
        this.setState({scheduled_time})
        }
    }

    handleDayChange = (e, index) => {
        if(index>=0){
            let schedule_list = this.state.schedule_list
            schedule_list[index].day = e.target.value
            this.setState({
                schedule_list
            })
        }
        else{
        this.setState({
            scheduled_day: e.target.value
        })
    }
    }

    addSchedule = () => {
        let schedule_list = this.state.schedule_list
        schedule_list.push({
            day: this.state.scheduled_day,
            time: this.state.scheduled_time
        })
        this.setState({
            schedule_list
        })
    }

    deleteSchedule = (index) => {
        let schedule_list = this.state.schedule_list
        schedule_list.splice(index, 1)
        this.setState({
            schedule_list
        })
    }

    submitSchedule = () => {
        if(this.state.selected_teacher && this.state.class_id && this.state.number_of_class){
        let days = [
            "Sunday", 
            "Monday", 
            "Tuesday", 
            "Wednesday", 
            "Thursday",
            "Friday", 
            "Saturday"
        ] 
        let data = this.state.schedule_list.map((ele)=>{
            return {
                day: days[ele.day],
                time: `${new Date(ele.time).getHours()}:${new Date(ele.time).getMinutes()}:${new Date(ele.time).getSeconds()}`
            }
        })
        
        data.push({
            day: days[this.state.scheduled_day],
            time: `${new Date(this.state.scheduled_time).getHours()}:${new Date(this.state.scheduled_time).getMinutes()}:00`
        })
        console.log("schedulee", new Date(this.state.scheduled_time).toISOString(), new Date(this.state.start_date).toISOString(),
        "2021-04-23T21:00:00+05:30",
        `${new Date(this.state.start_date).toISOString().split("T",1)}T${new Date(this.state.scheduled_time).getHours()-5<10?'0':''}${new Date(this.state.scheduled_time).getHours()-5}:${new Date(this.state.scheduled_time).getMinutes()-30<10?'0':''}${new Date(this.state.scheduled_time).getMinutes()-30}:00+05:30`
        )
        console.log(data)

        if(window.location.href.indexOf("group") && this.props.location.query.groupId){
            const user_id=this.props && this.props.user.user_id
            const PaidData = {
                schedule: data,
                group_id: this.props.location.query.groupId,
                start_date: `${new Date(this.state.start_date).getFullYear()}-${new Date(this.state.start_date).getMonth()+1}-${new Date(this.state.start_date).getDate()}`,
                teacher_id: this.state.selected_teacher,
                // class_id: this.state.class_id,
                no_of_classes: this.state.number_of_class,
                
            }
            const FreeData = {
               
                group_id: this.props.location.query.groupId,
                
                teacher_id: this.state.selected_teacher,
                // class_id: this.state.class_id,
                no_of_classes: this.state.number_of_class,
                scheduled_at: `${new Date(this.state.start_date).toISOString().split("T",1)}T${new Date(this.state.scheduled_time).getHours()<10?'0':''}${new Date(this.state.scheduled_time).getHours()}:${new Date(this.state.scheduled_time).getMinutes()<10?'0':''}${new Date(this.state.scheduled_time).getMinutes()}:00+05:30`,
            }
            axios.post( this.state.group_type=="PAID" ? `v1/admin/${user_id}/group/mark-schedule/` : `v1/admin/${user_id}/group/free-trial-schedule/`, 
            this.state.group_type=="PAID" ? PaidData : FreeData
         )
            .then((res)=>{
                console.log(res)
                if(res.status==200){
                    this.props.enqueueSnackbar(res && res.data && res.data.data && res.data.data.message || 'success', {variant: 'success'})
                }
            })
            .catch((err)=>{
                err.response && err.response.data && err.response.data.data && err.response.data.data.error &&
                err.response.data.data.error.map((error)=>
                this.props.enqueueSnackbar(error || 'error', {variant: 'warning'})
                )
                
                
            })
        }
        // else{
        // axiosInstance.post('/api/schedule-class', {
        //     schedule: data,
        //     user_id: this.props.location.query.userId,
        //     start_date: `${this.state.start_date.getFullYear()}-${this.state.start_date.getMonth()+1}-${this.state.start_date.getDate()}`,
        //     teacher_id: this.state.selected_teacher
        // })
        // .then((res)=>{
        //     console.log(res)
        //     if(res.status==200){
        //         this.props.enqueueSnackbar('success', 'Updated Successfully')
        //     }
        // })
        // .catch((err)=>{
        //     this.props.enqueueSnackbar('error', err.response.data.error || 'Could not update')
        // })}
    }
    else{
        this.setState({
            teacher_error: true, class_error:true, number_of_class_error:true
        })
    }
    }

    handleStartDateChange = (start_date) => {
        this.setState({
            start_date
        })
    }

    handleTeacherChange = (e) => {
        this.setState({
            selected_teacher: e.target.value,
            teacher_error: false
        })
    }

    handleClassChange = (e) => {
        this.setState({
            class_id: e.target.value,
            class_error: false
        })
    }

    render(){
        let days = [
            {id: 0, name: "Sunday"}, 
            {id: 1, name: "Monday"}, 
            {id: 2, name: "Tuesday"}, 
            {id: 3, name: "Wednesday"}, 
            {id: 4, name: "Thursday"},
            {id: 5, name: "Friday"}, 
            {id: 6, name: "Saturday"}
        ];
        let classes = [
            {id: 14, name: "Sr KG"}, 
            {id: 1, name: "1st Grade"}, 
            {id: 2, name: "2nd Grade"}, 
            {id: 3, name: "3rd Grade"}, 
            {id: 4, name: "4th Grade"},
            {id: 5, name: "5th Grade"}, 
            {id: 6, name: "6th Grade"},
            {id: 7, name: "7th Grade"},
            {id: 8, name: "8th Grade"}, 
            {id: 9, name: "9th Grade"}
        ];
        return (
            <Styles>
                <div className="scheduling-container">
                    <div style={{display: "flex"}}>
                    <div style={{marginBottom: 50, marginRight: 20}}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Select a start date"
          format="DD/MM/yyyy"
          value={this.state.start_date}
          onChange={this.handleStartDateChange}
        />
        </MuiPickersUtilsProvider>
        </div>
        <div style={{marginBottom: 50, minWidth:100}} className="teacher-box">
        
          <InputBox 
            type="select"
            label="Teacher"
            options={this.state.teacher_list}
            name="selected_teacher"
            placeholder="Select Teacher"
            value={this.state.selected_teacher}
            onChange={this.handleTeacherChange}
            error={this.state.teacher_error && !this.state.selected_teacher && "Select a teacher"}
        />
        </div>
        </div>
                {
                    this.state.schedule_list && this.state.schedule_list.length>0 && this.state.schedule_list.map((ele, index)=>{
                        return (
                            <div className="schedule-row" key={index}>
                                 <div style={{margin: "0px 15px 20px 0px", minWidth:100}}>
                    <InputBox type="select"
                     onChange={(e)=>{this.handleDayChange(e, index)}} 
                     options={days}
                     value={ele.day}
                     label="Day"
                     name="Selected Day"
                     placeholder="Select Day"
                    />
                    </div>
                   
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Pick a time"
                ampm={false}
                value={ele.time}
                onChange={(date)=>{this.handleDateChange(date, index)}}
                />
                </MuiPickersUtilsProvider>
                <Tooltip title="Add">
                    <IconButton aria-label="add" onClick={()=>{this.deleteSchedule(index)}}>
                        <DeleteIcon />
                    </IconButton>  
                </Tooltip>
                </div>
                        )
                    })
                }
                <div className="schedule-row">
                {this.state.group_type == "PAID" ? 
                <div style={{margin: "0px 15px 20px 0px", minWidth:100}}>
                    <InputBox type="select"
                     onChange={this.handleDayChange} 
                     options={days}
                     value={this.state.scheduled_day}
                     label="Day"
                     name="Selected Day"
                     placeholder="Select Day"
                    />
                    </div>
                    : null}
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Pick a time"
                ampm={false}
                value={this.state.scheduled_time}
                onChange={this.handleDateChange}
                />
                </MuiPickersUtilsProvider>
                {this.state.group_type == "PAID" ? 
                <Tooltip title="Add">
                <Fab color="primary" className="fab">
                    <IconButton aria-label="add" onClick={this.addSchedule}>
                        <AddIcon />
                    </IconButton>
                    </Fab>
                </Tooltip>
                : null}
                </div>
                <div style={{display:"flex", gap:20}} >
                    <div className="teacher-box">
                      <InputBox type="input" 
                     label="Grade"
                     value={this.state.class_id}
                     disabled={true}
                     error={this.state.class_error && !this.state.class_id && "Select Class"}
                    />
                    </div>
                    <div className="teacher-box">
                    <InputBox type="input" 
                     onChange={(e)=>{this.setState({number_of_class:this.state.group_type=="PAID" ? e.target.value : 1, number_of_class_error:false})}} 
                     label="Number of Classes"
                     value={this.state.number_of_class}
                     disabled={this.state.group_type=="PAID" ? false : true}
                     error={this.state.number_of_class_error && !this.state.number_of_class && "Enter Number of Classes"}
                    />
                    </div>
        </div>
                <div className="submit-schedules" onClick={this.submitSchedule}>
                    Update Schedule
                </div>
                </div>
            </Styles>
        )
    }
}

function mapStateToProps(state) {
    return {
       
        user: state.user,
       
    };
}

export default withSnackbar(connect(mapStateToProps)(StudentSchedule));