import {
    SET_CLASS_LIST,
    SET_PACKAGE_LIST
} from "../actions/types";

const INITIAL_STATE = {
    classList: [],
    packageList: []
};

export default function(state = INITIAL_STATE, action) {    
    switch (action.type) {
        case SET_CLASS_LIST:
            return { ...state, classList: action.payload };     
        case SET_PACKAGE_LIST:
                return { ...state, packageList: action.payload };        
        default:
            return { ...state};
    }
}
