import React from 'react';
import { useDrag } from 'react-dnd';

import './style.scss';
import { MEDIA_TYPE } from '../../draggableItemTypes';
import Col from '../../../../components/Elements/Flex/col';

const MediaBox = (props) => {
  const { icon, type, title, subtitle, addComponent } = props;

  const [{}, drag] = useDrag(() => ({
    type: MEDIA_TYPE,
    item: { type },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        switch (type) {
          case 'image':
            addComponent({
              type,
              imageUrl: '',
            });
            break;
          case 'video':
            addComponent({
              type,
              videoUrl: '',
            });
            break;
          case 'gif':
            addComponent({
              type,
              gifUrl: '',
            });
            break;
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <Col boxRef={drag} className='media-box col-flex'>
      <img src={icon} alt='media-icon' />
      <p>{title}</p>
      {subtitle ? <p className='subtitle'>{subtitle}</p> : null}
    </Col>
  );
};

export default MediaBox;
