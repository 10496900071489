import React, { useState } from 'react';
import { Link } from 'react-router';
import {
  makeStyles,
  Button,
  Divider,
  Select,
  MenuItem,
  FormControl,
  TextField,
  InputLabel,
  Icon,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Table from '../../components/LQDataTables/demo';
import EditSessionDetails from '../../components/EditSessionDetails';
import StudentSessionStatus from '../../components/StudentSessionStatus';
import axios from 'axios';
import { connect } from 'react-redux';
import ClassScheduleModal from '../../components/ClassScheduleModal';
import SaveChangesModal from '../../components/SaveChangesModal';
import moment from 'moment';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },

  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});

let tableRef = null;

function SessionTable(props) {
  const classes = userStyles();
  const [showSessionModal, setShowSessionModal] = useState('');
  const [showSessionDetailModal, setShowSessionDetailModal] = useState('');
  const [schedule, setSchedule] = useState(false);
  const [showPTMDetailModal, setShowPTMDetailModal] = useState(false);
  const [showUpdateTopicModal, setShowUpdateTopicModal] = useState(false);
  const [switchVideoSdk, setSwitchVideoSdk] = useState(false);
  const sdkList = { zoom: 'agora', agora: 'zoom' };
  function handleCallBack() {
    tableRef && tableRef.refreshTable();
  }

  function scheduleDetailsModal() {
    return (
      <ClassScheduleModal
        schedule={schedule}
        group_id={schedule.groupId}
        teacher={schedule.teacher}
        closeModal={() => {
          setSchedule(null);
        }}
        showTeacherField={true}
        extraClass={true}
        handleCallBack={handleCallBack}
      />
    );
  }

  function ptmDetailsModals() {
    return showPTMDetailModal?.data?.map((ptmDetails) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          border: '2px solid gray',
          margin: '0.25vw',
          padding: '1vw',
          borderRadius: 7,
          gap: '0.4vw',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Link
              to={{
                pathname: `/user`,
                search: `?id=${ptmDetails.student_id}`,
              }}
              target='_blank'
              style={{ textDecoration: 'none' }}
            >
              <div
                style={{
                  fontSize: '1.25vw',
                  fontWeight: 600,
                  color: '#3f51b5',
                }}
              >
                {ptmDetails.name}
              </div>
            </Link>
            <div>{ptmDetails.phone}</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div>
              {moment(ptmDetails.scheduled_at).format('hh:mm A')} -{' '}
              {moment(ptmDetails.scheduled_at)
                .add(showPTMDetailModal.duration, 'minutes')
                .format('hh:mm A')}
            </div>
            <div>{ptmDetails.is_present ? 'Present' : 'Absent'}</div>
          </div>
        </div>
        {ptmDetails.remark ? <Divider /> : null}
        {ptmDetails.remark ? <div>Remarks: {ptmDetails.remark}</div> : null}
      </div>
    ));
  }

  function getUpdateTopicData(id) {
    axios
      .get(`/v1/admin/${props.adminId}/session/${id}/update-topic/`)
      .then((response) => {
        if (response?.status == 200 && response?.data?.data) {
          setShowUpdateTopicModal({
            sessionData: response.data.data,
            id: id,
            sessionId: '',
            method: { text: false, select: false },
          });
        }
      })
      .catch((error) => {
        const errorText = error?.response?.data?.data?.error;
        props.enqueueSnackbar(errorText || 'Something Went Wrong', {
          variant: 'error',
        });
      });
  }

  function setUpdateTopicData() {
    const { id, sessionId } = showUpdateTopicModal;
    axios
      .post(`/v1/admin/${props.adminId}/session/${id}/update-topic/`, {
        session_id: sessionId,
      })
      .then((response) => {
        if (response?.status == 200 && response?.data) {
          setShowUpdateTopicModal(false);
          handleCallBack();
          props.enqueueSnackbar(
            response?.data?.data?.message ||
              'Session Topic Updated Successfully',
            { variant: 'success' }
          );
        }
      })
      .catch((error) => {
        const errorText = error?.response?.data?.data?.error;
        props.enqueueSnackbar(errorText || 'Something Went Wrong', {
          variant: 'error',
        });
      });
  }

  function updateVideoSdk() {
    const { sessionId } = switchVideoSdk;
    axios
      .post(`/v1/admin/${props.adminId}/session/${sessionId}/update-video-sdk/`)
      .then((response) => {
        if (response?.status == 200 && response?.data) {
          setSwitchVideoSdk(false);
          handleCallBack();
          props.enqueueSnackbar(
            response?.data?.data?.message || 'Session SDK Updated Successfully',
            { variant: 'success' }
          );
        }
      })
      .catch((error) => {
        const errorText = error?.response?.data?.data?.error;
        props.enqueueSnackbar(errorText || 'Something Went Wrong', {
          variant: 'error',
        });
      });
  }

  function updateTopicModal() {
    const { sessionData, sessionId, method } = showUpdateTopicModal;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2vw',
          textAlign: 'center',
        }}
      >
        <FormControl>
          <InputLabel>Select Session</InputLabel>
          <Select
            value={method?.select ? sessionId : ''}
            onChange={(e) => {
              setShowUpdateTopicModal({
                ...showUpdateTopicModal,
                sessionId: e.target.value,
                method: { select: e.target.value ? true : false },
              });
            }}
            disabled={method?.text}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {sessionData?.map((sessionDetails) => (
              <MenuItem value={sessionDetails.id} key={sessionDetails.id}>
                {sessionDetails.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ fontSize: '1vw', fontWeight: 600 }}>OR</div>
        <TextField
          label={'Session Id'}
          placeholder={'Please Enter Session Id'}
          value={method?.text ? sessionId : ''}
          disabled={method?.select}
          onChange={(event) =>
            setShowUpdateTopicModal({
              ...showUpdateTopicModal,
              sessionId: event.target.value,
              method: { text: event.target.value ? true : false },
            })
          }
          variant='outlined'
        />
      </div>
    );
  }

  function formatTable(tableData) {
    const newData =
      tableData &&
      tableData.map((entry, index) => {
        const {
          id,
          scheduled_at,
          started_at,
          completed_at,
          class_type,
          recording_link,
          reschedule,
          group_id,
          status,
          order,
          teacher,
          ptm,
          student_details,
          video_sdk,
          is_mentor,
        } = entry;

        const newEntry = { ...entry };
        newEntry.recording_link = recording_link && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Link
              to={{
                pathname: recording_link,
              }}
              target='_blank'
              style={{ textDecoration: 'none' }}
            >
              <Button
                color='primary'
                variant='contained'
                size='small'
                style={{ fontSize: 12 }}
              >
                View
              </Button>
            </Link>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(recording_link);
                props.enqueueSnackbar('Recording Link Copied to Clipboard', {
                  variant: 'info',
                });
              }}
            >
              <Tooltip title='Copy Link'>
                <Icon className='iconButton'>content_copy_icon</Icon>
              </Tooltip>
            </IconButton>
          </div>
        );
        newEntry.group_id = (
          <Link
            to={{
              pathname: `/group-class/${
                class_type.toLowerCase().includes('paid') ? 'paid' : 'free'
              }`,
              search: `?id=${newEntry.group_id}`,
            }}
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            {newEntry.group_id}
          </Link>
        );
        newEntry.scheduled_at =
          scheduled_at &&
          new Date(scheduled_at).toLocaleString('en-IN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          });
        newEntry.started_at =
          started_at &&
          new Date(started_at).toLocaleString('en-IN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          });
        newEntry.completed_at =
          completed_at &&
          new Date(completed_at).toLocaleString('en-IN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          });

        newEntry.student_details = (
          <div>
            {student_details?.map((studentDetails, index) => (
              <Link
                to={{
                  pathname: '/student-session-details',
                  search: `?student_id=${studentDetails.student_id}`,
                }}
                target='_blank'
                style={{ textDecoration: 'none' }}
              >
                <div>
                  {studentDetails.student_name}
                  {studentDetails.browser && `(${studentDetails.browser})`}
                </div>
              </Link>
            ))}
          </div>
        );
        newEntry.join_class = is_mentor ? (
          <Link
            to={{
              pathname: `${
                process.env.REACT_APP_LQLIVE_TEACHER_FRONTEND_SERVER ||
                'https://lq-live-teacher.logiqids.com/'
              }classroom?sessionScheduleId=${id}&sdk=${
                video_sdk == 'zoom' ? 'zm' : 'ag'
              }`,
            }}
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            <Button
              color='primary'
              variant='contained'
              size='small'
              style={{ fontSize: 12 }}
            >
              Join
            </Button>
          </Link>
        ) : null;
        const updatedEntry = {
          edit: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <IconButton
                onClick={() => setShowSessionModal({ id, status, teacher })}
              >
                <Tooltip title='Edit'>
                  <Icon className='iconButton'>edit</Icon>
                </Tooltip>
              </IconButton>
              {new Date(scheduled_at).getFullYear() ==
                new Date().getFullYear() &&
                new Date(scheduled_at).getMonth() == new Date().getMonth() &&
                new Date(scheduled_at).getDay() == new Date().getDay() && (
                  <IconButton onClick={() => setShowSessionDetailModal(id)}>
                    <Tooltip title='Status'>
                      <Icon className='iconButton'>info</Icon>
                    </Tooltip>
                  </IconButton>
                )}
              {reschedule && (
                <IconButton
                  onClick={() => {
                    setSchedule({
                      groupId: group_id,
                      schedule: [
                        {
                          completed_at: completed_at,
                          name: '',
                          order: order,
                          scheduled_at: scheduled_at,
                          session_schedule_id: id,
                          started_at: started_at,
                          status: status,
                        },
                      ],
                      teacher: teacher,
                    });
                  }}
                >
                  <Tooltip title='Reschedule Extra Class'>
                    <Icon className='iconButton'>reorder</Icon>
                  </Tooltip>
                </IconButton>
              )}
              {status.toLowerCase() == 'upcoming' &&
              entry.package.toLowerCase() != 'free trial' ? (
                <IconButton onClick={() => getUpdateTopicData(id)}>
                  <Tooltip title='Update Topic'>
                    <Icon className='iconButton'>topic_icon</Icon>
                  </Tooltip>
                </IconButton>
              ) : null}
              {ptm ? (
                <Button
                  color='primary'
                  variant='contained'
                  style={{ fontSize: 12 }}
                  onClick={() => setShowPTMDetailModal(ptm)}
                >
                  PTM
                </Button>
              ) : null}
              {status.toLowerCase() == 'upcoming' &&
              entry.package.toLowerCase() != 'free trial' &&
              false ? (
                <IconButton
                  onClick={() =>
                    setSwitchVideoSdk({ sessionId: id, sdk: video_sdk })
                  }
                >
                  <Tooltip title='Switch Video SDK'>
                    <Icon className='iconButton'>video_settings_icon</Icon>
                  </Tooltip>
                </IconButton>
              ) : null}
            </div>
          ),
          ...newEntry,
        };
        return Object.keys(updatedEntry).map((subEntry) =>
          updatedEntry[subEntry]
            ? '' || updatedEntry[subEntry]
            : updatedEntry[subEntry] == 0
            ? '0'
            : '-'
        );
      });
    return newData;
  }

  return (
    <div>
      <Table
        onRef={(ref) => {
          tableRef = ref;
        }}
        firstCol=''
        headerFilterList={HEADER_FILTER_LIST}
        formatTable={formatTable}
        sortList={SORT_LIST}
        sortDate={[]}
        search={props.location && props.location.query}
        tableName='Session Table'
        title='Session Table'
        scrollType='stacked'
        dateFilter={'date'}
        hideColumn={HIDE_COLUMN}
        newColumns={['join_class']}
        apiUrl={'session/list/'}
      />
      {showSessionModal ? (
        <EditSessionDetails
          id={showSessionModal.id}
          closeModal={() => setShowSessionModal('')}
          showTeacherReassign={
            showSessionModal?.status?.toLowerCase() == 'upcoming'
          }
          selectedTeacher={showSessionModal.teacher}
          handleCallBack={handleCallBack}
        />
      ) : null}

      {showSessionDetailModal ? (
        <StudentSessionStatus
          id={showSessionDetailModal}
          closeModal={() => setShowSessionDetailModal('')}
        />
      ) : null}
      {schedule ? scheduleDetailsModal() : null}
      <SaveChangesModal
        openModal={showPTMDetailModal}
        closeModal={() => setShowPTMDetailModal(false)}
        text={'PTM Details'}
        displayData={ptmDetailsModals()}
        style={{
          minHeight: '30vh',
          maxHeight: '80vh',
          overflowY: 'scroll',
          gap: '1vw',
        }}
      />
      <SaveChangesModal
        openModal={showUpdateTopicModal ? true : false}
        closeModal={() => setShowUpdateTopicModal(false)}
        text={'Update Topics Data'}
        displayData={updateTopicModal()}
        disableConfirm={!showUpdateTopicModal?.sessionId}
        onSave={() => setUpdateTopicData()}
      />
      <SaveChangesModal
        openModal={switchVideoSdk}
        closeModal={() => setSwitchVideoSdk(false)}
        text={`Do you want to switch video SDK from ${switchVideoSdk.sdk} to ${
          sdkList[switchVideoSdk.sdk]
        }?`}
        onSave={() => updateVideoSdk()}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    adminId: state?.user?.user_id,
    role: state?.user?.role,
  };
};

export default withSnackbar(connect(mapStateToProps)(SessionTable));

const HEADER_FILTER_LIST = [
  {
    filter_label: 'id',
    type: 'text',
  },
  {
    filter_label: 'group_id',
    type: 'text',
  },
  {
    filter_label: 'group_name',
    type: 'text',
  },
  {
    filter_label: 'class_type',
    type: 'text',
  },
  {
    filter_label: 'teacher',
    type: 'text',
  },
  {
    filter_label: 'session',
    type: 'text',
  },
  {
    filter_label: 'grade',
    type: 'text',
  },
];
const SORT_LIST = ['id'];
const HIDE_COLUMN = ['ptm', 'is_mentor'];
