import React from 'react';
import { TextareaAutosize } from '@material-ui/core'

import './style.scss';
import closeIcon from '../../../../assets/close-icon.png';
import Row from '../../../../components/Elements/Flex/row';
import CssModal from '../../PopUps/Text/TextCssPopup';

const Text = (props) => {
  const {
    index,
    type,
    style,
    text,
    updateState,
    updateElementData,
    selectedElementIndex,
    deleteComponent,
    validated,
  } = props;
  const isSelected = selectedElementIndex === index;

  const updateElementStyle = (key, value) => {
    let updatedStyle = JSON.parse(JSON.stringify(style));
    updatedStyle[key] = value;

    updateElementData(index, {
      key: 'style',
      value: updatedStyle,
    });
  };

  const checkCallStyling = (key, value) => {
    updateElementStyle(key, value);
  };

  return (
    <div
      className={`text-parent-div ${isSelected ? 'selected' : ''} ${
        !validated ? 'error' : ''
      }`}
    >
      <TextareaAutosize
        value={text}
        style={style}
        className={`text-box ${type}`}
        type='text'
        placeholder={type !== 'text' ? 'HEADLINE' : 'Text comes here'}
        onClick={() => updateState({ selectedElementIndex: index })}
        onChange={(event) => {
          updateElementData(index, {
            key: 'text',
            value: event.target.value,
          });
        }}
      />
      {isSelected && (
        <Row
          className='close-icon-div'
          onClick={() => {
            console.log(deleteComponent);
            deleteComponent(index, type);
          }}
        >
          <img src={closeIcon} className='close-icon' />
        </Row>
      )}
      {isSelected && (
        <CssModal style={style} checkCallStyling={checkCallStyling} />
      )}
    </div>
  );
};

export default Text;
