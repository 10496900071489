import React from 'react'
import { DataGrid } from '@material-ui/data-grid';
import axios from 'axios'
import { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PendingSaveChangesModal from '../PendingSaveChangesModal';

const useStyles = makeStyles({
  root: {
    '& .slotCapacity': {
      backgroundColor: '#cee1ff91',
    },
    '& .recentChanges': {
      backgroundColor: '#fff70082',
    },
  },
});


function TeacherSlot(props) {
  useEffect(() => { getClassData(), getSlotData(1) }, [])
  const classes = useStyles();
  const [classId, setClassId] = useState(1)
  const [classList, setClassList] = useState(null)
  const [rows, setRows] = useState(null)
  const [addTimeModal, setAddTimeModal] = useState(false)
  const [timeList, setTimeList] = useState([])
  const [time, setTime] = useState(null)
  const [country, setCountry] = useState('All')
  const [pendingChanges, setPendingChanges] = useState(null)
  const [unEditedData, setUnEditedData] = useState(null)
  const countryList = ['All', 'US']
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  const columns = [{
    field: 'Sunday',
    headerName: 'Sunday',
    flex: 1,
    type: 'number',
    editable: classId ? true : false,
  },
  {
    field: 'Monday',
    headerName: 'Monday',
    flex: 1,
    type: 'number',
    editable: classId ? true : false,

  },
  {
    field: 'Tuesday',
    headerName: 'Tuesday',
    flex: 1,
    type: 'number',
    editable: classId ? true : false,

  },
  {
    field: 'Wednesday',
    headerName: 'Wednesday',
    flex: 1,
    type: 'number',
    editable: classId ? true : false,

  },
  {
    field: 'Thursday',
    headerName: 'Thursday',
    flex: 1,
    type: 'number',
    editable: classId ? true : false,

  },
  {
    field: 'Friday',
    headerName: 'Friday',
    flex: 1,
    type: 'number',
    editable: classId ? true : false,

  },
  {
    field: 'Saturday',
    headerName: 'Saturday',
    flex: 1,
    type: 'number',
    editable: classId ? true : false,

  },]

  function generateTimeList(row) {
    let slotTimeList = []
    row && row.map((rowData) => slotTimeList.push(rowData.id))
    let timeSlotDetail = []
    Array(48).fill(1).map((el, i) => {
      const time = i % 2 == 0 ? (i < 20 ? '0' + i / 2 + ':00:00' : i / 2 + ':00:00') : (i < 20 ? '0' + (i - 1) / 2 + ':30:00' : (i - 1) / 2 + ':30:00')
      slotTimeList && !slotTimeList.includes(time) && timeSlotDetail.push(time)

    }
    )
    setTimeList(timeSlotDetail)
  }
  function renderPendingChangesModal() {
    return <PendingSaveChangesModal
      openModal={pendingChanges && pendingChanges.showModal}
      closeModal={() => setPendingChanges({ ...pendingChanges, showModal: false })}
      saveAndContinue={
        () => {
          postSlotData(rows, false)
          performPendingOperation()
          setPendingChanges(false)
        }
      }
      continueFunction={() => {
        setPendingChanges(false)
        performPendingOperation()
      }}
    />
  }
  function getSlotData(class_id, country) {
    const admin_id = props && props.user && props.user.user_id
    const url = class_id == 0 ? `v1/admin/${admin_id}/free-trial/${class_id}/slots/?all_classes=${true}` :
      `v1/admin/${admin_id}/free-trial/${class_id}/slots/`
    axios.get(url, country == 'US' && {
      headers: {
        'Country-Phone': '+1'
      }
    })
      .then((response) => {
        if (response && response.status == 200) {
          const data = response.data && response.data.data
          const slot_times  = data.slot_times.filter(function(item, index) {
            if (data.slot_times.indexOf(item) == index)
              return item;
          });
          // slot_times.filter((v,i) => slot_times.indexOf(v) == i)
          var row = slot_times
          row && row.map((slot_times, index) =>
            row[index] = {
              id: slot_times,
              Sunday: data.slots && data.slots.Sunday && data.slots.Sunday.findIndex((datas) => datas.slot_time === slot_times) > -1 && data.slots.Sunday[data.slots.Sunday.findIndex((datas) => datas.slot_time === slot_times)].capacity || 0,
              Monday: data.slots && data.slots.Monday && data.slots.Monday.findIndex((datas) => datas.slot_time === slot_times) > -1 && data.slots.Monday[data.slots.Monday.findIndex((datas) => datas.slot_time === slot_times)].capacity || 0,
              Tuesday: data.slots && data.slots.Tuesday && data.slots.Tuesday.findIndex((datas) => datas.slot_time === slot_times) > -1 && data.slots.Tuesday[data.slots.Tuesday.findIndex((datas) => datas.slot_time === slot_times)].capacity || 0,
              Wednesday: data.slots && data.slots.Wednesday && data.slots.Wednesday.findIndex((datas) => datas.slot_time === slot_times) > -1 && data.slots.Wednesday[data.slots.Wednesday.findIndex((datas) => datas.slot_time === slot_times)].capacity || 0,
              Thursday: data.slots && data.slots.Thursday && data.slots.Thursday.findIndex((datas) => datas.slot_time === slot_times) > -1 && data.slots.Thursday[data.slots.Thursday.findIndex((datas) => datas.slot_time === slot_times)].capacity || 0,
              Friday: data.slots && data.slots.Friday && data.slots.Friday.findIndex((datas) => datas.slot_time === slot_times) > -1 && data.slots.Friday[data.slots.Friday.findIndex((datas) => datas.slot_time === slot_times)].capacity || 0,
              Saturday: data.slots && data.slots.Saturday && data.slots.Saturday.findIndex((datas) => datas.slot_time === slot_times) > -1 && data.slots.Saturday[data.slots.Saturday.findIndex((datas) => datas.slot_time === slot_times)].capacity || 0,
            }

          )
          setRows(row)
          setUnEditedData(JSON.parse(JSON.stringify(row)))
          generateTimeList(row)
        }
      })
  }
  const postSlotData = (rowdetail, fetchData) => {

    const admin_id = props && props.user && props.user.user_id
    let slotDayDetails = days.reduce((day, curr) => (day[curr] = [], day), {})
    rows && rows.map((rowData, index) => {
      days.map((day) => {
        const slot_time = rowData && rowData.id
        const capacity = eval('rowData.' + day)
        capacity && eval('slotDayDetails.' + day + '.push({slot_time,capacity})')
      }
      )
    }
    )
    const slotData = {
      slots: slotDayDetails
    }
    console.log("rows", rows, rowdetail, slotData)
    axios.post(`v1/admin/${admin_id}/free-trial/${classId}/slots/`, slotData,
      country == 'US' && {
        headers: {
          'Country-Phone': '+1'
        }
      })
      .then((response) => {
        if (response && response.status == 200) {
          const message = response && response.data && response.data.data && response.data.data.message
          props.enqueueSnackbar(message || 'Successfully updated', { variant: 'success' })
          setPendingChanges(false)
          fetchData && getSlotData(classId, country)
        }
      })
  }
  function getClassData() {
    axios.get('v1/classes/')
      .then((response) => {
        if (response && response.status == 200) {
          const data = response.data && response.data.data
          data.push({ id: 0, name: "All Classes" })
          setClassList(data)
        }
      }
      )
  }

  const performPendingOperation = () => {
    switch (pendingChanges.operation) {
      case 'add':
        setRows(JSON.parse(JSON.stringify(unEditedData)))
        setAddTimeModal(true)
        break;
      case 'country_change':
        setCountry(pendingChanges.value)
        getSlotData(classId, pendingChanges.value)
        break;
      case 'class_change':
        // setRows(JSON.parse(JSON.stringify(unEditedData)))
        setClassId(pendingChanges.value)
        getSlotData(pendingChanges.value, country)
        break;
    }
  }

  const handleCellEditChange = React.useCallback((params, event, rows) => {
    setPendingChanges({ madeChanges: true })
    var row = rows
    const index = rows && rows.findIndex((rowData) => rowData.id === params.id)
    console.log("event", event, params)
    const name = params.field
    if (params && params.props) {
      if (params.props.value)
        eval('row[' + index + '].' + params.field + '=' + params.props.value + ' || 0')
      else
        eval('row[' + index + '].' + params.field + '=0')
    }


  }, [])
  function handleRowEditChange(model) {
    setPendingChanges({ madeChanges: true })
    console.log("model", model, Object.keys(model).length)
    if (Object.keys(model).length > 0) {
      var row = rows
      const index = model && Object.keys(model) && Object.keys(model)[0] && rows && rows.findIndex((rowData) => rowData.id === Object.keys(model)[0])
      const name = model && index > -1 && Object.keys(Object.values(model)[0])[0]
      const value = model && index > -1 && name && Object.values(Object.values(Object.values(model)[0])[0])[0]

      model && Object.keys(model).length > 0 && index > -1 && name && value > -1 && eval('row[' + index + '].' + name + '=' + value + ' || 0') || eval('row[' + index + '].' + name + '=0')

      setRows(row)
      // setUnEditedData(JSON.parse(JSON.stringify(row)))
      console.log("model", model, model && index, model && name, model && value, row, rows)
    }
  }
  function handleAddTime() {
    var rowData = JSON.parse(JSON.stringify(rows))
    rowData.push({
      id: time,
      Sunday: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
    })
    rowData.sort((a, b) => a.id.localeCompare(b.id));
    setRows(rowData)
    setUnEditedData(JSON.parse(JSON.stringify(rowData)))
    setTime(null)
    generateTimeList(rowData)
  }
  return (
    rows &&
    <div style={{ height: '80vh', width: '100%' }} className={classes.root}>
      {pendingChanges && pendingChanges.showModal && renderPendingChangesModal()}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
        <Button variant='outlined' color="primary" onClick={() => {
          if (pendingChanges && pendingChanges.madeChanges)
            setPendingChanges({ madeChanges: true, showModal: true, operation: 'add' })
          else
            setAddTimeModal(true)
        }
        }
          disabled={!classId}
        >Add Time</Button>
        <Dialog open={addTimeModal} onClose={() => setAddTimeModal(false)}>
          <DialogTitle>Select time you want to add</DialogTitle>
          <DialogContent>
            <FormControl style={{ minWidth: 200 }}>
              <InputLabel>Select Time</InputLabel>
              <Select
                value={time}
                onChange={(e) => setTime(e.target.value)}
              >
                {timeList && timeList.map((timeData) => <MenuItem value={timeData}>{timeData}</MenuItem>)}
              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddTimeModal(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={(e) => { handleAddTime(), setAddTimeModal(false) }} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <FormControl style={{ minWidth: 150 }}>
          <InputLabel>Select Country</InputLabel>
          <Select
            value={country}
            onChange={(e) => {
              if (pendingChanges && pendingChanges.madeChanges)
                setPendingChanges({ madeChanges: true, showModal: true, operation: 'country_change', value: e.target.value })
              else {
                setCountry(e.target.value), getSlotData(classId, e.target.value)
              }
            }}
          >
            {countryList.map((timeData) => <MenuItem value={timeData}>{timeData}</MenuItem>)}
          </Select>
        </FormControl>
        <Button onClick={() => postSlotData(rows, true)} variant='outlined' color="primary" disabled={!classId}>Update</Button>
      </div>
      <DataGrid
        rows={rows}
        columns={[{
          field: 'id', headerName:
            <Select value={classId} onChange={(e) => {
              if (pendingChanges && pendingChanges.madeChanges)
                setPendingChanges({ madeChanges: true, showModal: true, operation: 'class_change', value: e.target.value })
              else {
                setRows(null), setClassId(e.target.value), getSlotData(e.target.value, country)
              }
            }}>
              {classList && classList.map((classDetail) => <MenuItem value={classDetail.id}>{classDetail.name}</MenuItem>)}
            </Select>,
          flex: 1.5,
        },
        ...columns
        ]}
        getCellClassName={(params) => {
          const getCellData = params && unEditedData && unEditedData.filter(rowData => rowData?.id == params?.id)
          const valueData = getCellData && getCellData[0] && getCellData[0][params.field]
          return Number(params.value) >= 0 && Number(params.value) != valueData ? 'recentChanges' : Number(params.value) > 0 ? 'slotCapacity' : null;
        }}
        //   pageSize={10}
        disableSelectionOnClick
        autoPageSize
        //   AllowPaging={false}
        disableColumnFilter
        disableColumnMenu
        onEditCellChange={(params, event) => handleCellEditChange(params, event, rows)}
        onEditCellChangeCommitted={(params, event) => handleCellEditChange(params, event, rows)}
        // onCellClick={(event)=>console.log("change",event)}
        // onCellEditCommit={(id, field, value)=>console.log("change222",id, field, value)}
        autoHeight
        onEditRowsModelChange={handleRowEditChange}
      />

    </div>
    || 'loading..'
  )
}

function mapStateToProps(state) {

  return {
    user: state.user,
  };
}

export default withSnackbar(connect(mapStateToProps)(TeacherSlot))