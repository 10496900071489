import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Modal, List, Button, TextField } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Select, MenuItem } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';

const userStyles = {
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  list: {
    display: 'flex',
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },
  Main: {
    width: '95%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 30,
    top: 0,
    alignItems: 'center',
  },
  Modal: {
    background: '#fff',
    borderRadius: 5,
    width: '80vw',
    height: '90vh',
    margin: '5vh auto',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    padding: 20,
    overflowY: 'scroll',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    margin: 'auto 25px',
  },
  button: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },

  root: {
    paddingTop: 10,
    paddingBottom: 20,
    '& > *': {
      width: '70%',
      margin: 'auto',
      display: 'flex',
    },
  },
};

class AvailabilityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classList: null,
      classId: null,
      packageId: 4,
      packageIdList: null,
      slotData: {
        Sunday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
      },
      slotTime: [
        '10:00:00',
        '10:30:00',
        '11:00:00',
        '11:30:00',
        '12:00:00',
        '12:30:00',
        '13:00:00',
        '13:30:00',
        '14:00:00',
        '14:30:00',
        '15:00:00',
        '15:30:00',
        '16:00:00',
        '16:30:00',
        '17:00:00',
        '17:30:00',
        '18:00:00',
        '18:30:00',
        '19:00:00',
        '19:30:00',
        '20:00:00',
        '20:30:00',
      ],
      userSlotCount: null,
      showDetails: false,
      userData: null,
      ac_id: null,
      no_of_classes: null,
      acList: null,
      comments: '',
      noData: false,
      showResult: false,
      selectOnHover: false,
    };
    this.viewData = window.location.pathname == '/availability';
    this.isTeacher =
      this.props.isTeacher ||
      new URLSearchParams(window?.location?.search).get('isTeacher');
    this.teacherUserId =
      new URLSearchParams(window?.location?.search).get('teacherUserId') ||
      false;
  }
  componentDidMount() {
    if (this.isTeacher) {
      if (this.viewData)
        this.setState(
          {
            packageIdList: [
              { value: 10, label: 'All' },
              { value: 4, label: 'LogiMath' },
              { value: 5, label: 'Science' },
            ],
            packageId: 10,
          },
          () => this.getAvailabilityData()
        );
      else this.getAvailabilityData();
    } else {
      this.getPackageData();
      this.getClassDetail();
      this.getRmList();
    }
  }

  getBackgroundColor = (stringInput) => {
    let stringUniqueHash = [...stringInput].reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return `hsl(${stringUniqueHash % 360}, 95%, 80%)`;
  };

  getClassDetail() {
    axios.get('v1/classes/').then((res) => {
      if (res && res.status == 200) {
        let data = res && res.data && res.data.data;
        data.map((ele, index) => {
          data[index] = { label: ele.name, value: ele.id };
          if (ele.name == this.props.grade) {
            this.setState({ classId: ele.id });
            // this.getAvailabilityData(ele.id)
          }
        });
        this.setState({ classList: data });
      }
    });
  }

  getPackageData() {
    const admin_id = this.props && this.props.user && this.props.user.user_id;
    axios.get(`/v1/admin/${admin_id}/assign-package/`).then((res) => {
      if (res && res.status == 200) {
        let temp = [];
        let format = {
          label: '',
          value: '',
        };
        const data = res && res.data && res.data.data;
        data &&
          data.map((packageData) => {
            format = {
              value: packageData.id,
              label: packageData.id + '. ' + packageData.name,
            };
            format == undefined ? null : temp.push(format);
          });
        this.setState({ packageIdList: temp });
      }
    });
  }

  getRmList() {
    const user_id =
      this.props &&
      this.props.user &&
      this.props.user &&
      this.props.user.user_id;
    axios.get(`/v1/admin/${user_id}/ac/`).then((res) => {
      if (res.status == 200 && res.data) {
        let temp = [];
        temp =
          res.data &&
          res.data.data.map &&
          res.data.data.map((a) => {
            return {
              value: a.id,
              label: a.name,
            };
          });
        this.setState({ acList: temp });
      }
    });
  }

  getAvailabilityData(class_id) {
    const admin_id = this.props && this.props.user && this.props.user.user_id;
    if (this.viewData) {
      const params = this.teacherUserId
        ? '?user_id=' + this.teacherUserId + '&details=1'
        : '';
      const url = this.isTeacher
        ? this.state.packageId == 10
          ? `/v1/admin/${admin_id}/teacher-slots/${params}`
          : `/v1/admin/${admin_id}/teacher-slots/?package_id=${this.state.packageId}`
        : `/v1/admin/${admin_id}/student-slots/${class_id}/${this.state.packageId}/`;
      axios.get(url).then((res) => {
        if (res && res.status == 200) {
          const { slots, slot_times, user_data, teacher_data } =
            res.data && res.data.data;
          this.setState({
            slotData: slots,
            slotTime: slot_times,
            userData: user_data || teacher_data,
            showResult: true,
          });
          if (Object.keys(slots).length == 0) {
            this.setState({ noData: true });
          } else {
            this.setState({ noData: false });
            var userTimeData = {};
            slot_times.map(
              (time, i) => (userTimeData = { ...userTimeData, [time]: 0 })
            );

            Object.keys(slots).map((days) => {
              slots[days].map((slotData) => {
                const currentCount = this.isTeacher
                  ? slotData?.teachers?.length
                  : slotData?.students?.paid?.length +
                    slotData?.students?.unpaid?.length;
                const prevCount = userTimeData[slotData.slot_time];
                if (currentCount > prevCount)
                  userTimeData = {
                    ...userTimeData,
                    [slotData.slot_time]: currentCount,
                  };
              });
            });
            this.setState({ userSlotCount: userTimeData });
          }
        }
      });
    } else {
      const url = this.isTeacher
        ? `/v1/admin/${admin_id}/teacher-slots/?user_id=${this.props.user_id}`
        : `/v1/admin/${admin_id}/student-slots/${class_id}/${this.state.packageId}/?student_id=${this.props.user_id}`;
      axios.get(url).then((res) => {
        if (res && res.status == 200) {
          const { slots, ac_id, no_of_classes, comments } =
            res.data && res.data.data;
          const modifiedSlotTime = this.state.slotTime;
          var minTime = '24:00:00';
          var maxTime = '00:00:00';
          const startingTime = this.state.slotTime[0];
          const endTime = this.state.slotTime[this.state.slotTime.length - 1];
          Object.values(slots)
            .flat(1)
            .map((timeDate) => {
              if (
                new moment(timeDate.from_time, 'HH:mm:ss').isBefore(
                  new moment(minTime, 'HH:mm:ss')
                )
              )
                minTime = timeDate.from_time;
              if (
                new moment(timeDate.to_time, 'HH:mm:ss').isAfter(
                  new moment(maxTime, 'HH:mm:ss')
                )
              )
                maxTime = timeDate.to_time;
            });
          maxTime = new moment(maxTime, 'HH:mm:ss').subtract(1, 'minute');
          const beforeGap =
            (new moment(startingTime, 'HH:mm:ss') -
              new moment(minTime, 'HH:mm:ss')) /
            (30 * 60 * 1000);
          const afterGap =
            (new moment(maxTime, 'HH:mm:ss') -
              new moment(endTime, 'HH:mm:ss')) /
            (30 * 60 * 1000);
          for (let i = 0; i < beforeGap; i++)
            modifiedSlotTime.splice(
              0,
              0,
              new moment(modifiedSlotTime[0], 'HH:mm:ss')
                .subtract(30, 'minutes')
                .format('HH:mm:ss')
            );
          for (let i = 0; i < afterGap; i++)
            modifiedSlotTime.splice(
              modifiedSlotTime.length,
              0,
              new moment(
                modifiedSlotTime[modifiedSlotTime.length - 1],
                'HH:mm:ss'
              )
                .add(30, 'minutes')
                .format('HH:mm:ss')
            );
          this.setState({ slotTime: modifiedSlotTime });
          var slotData = this.state.slotData;
          if (Object.keys(slots).length > 0) {
            Object.keys(slotData).map((days) => {
              if (slots[days]) {
                slots[days].map((timeData) => {
                  timeData.to_time =
                    timeData.to_time.slice(0, 4) +
                    '0' +
                    timeData.to_time.slice(5, 8);
                  const dayDetails = slots[days];
                  const from = timeData.from_time.slice(0, 2);
                  const to = timeData.to_time.slice(0, 2);
                  for (let i = from; i <= to; i++) {
                    if (this.isTeacher || true) {
                      // console.log("is teacher", days, timeData, dayDetails, from, to, eval(`slotData.${days}`), eval(`slotData.${days}`).findIndex(({ from_time, to_time }) => from_time == from_time && to_time == to_time))
                      // if(timeData.findIndex(({ from_time, to_time }) => from_time == i+"00:00" && to_time == i+"00:00"))
                      // return;
                      // else
                      if (timeData.from_time == timeData.to_time) {
                        if (
                          slotData[days].findIndex(
                            ({ from_time, to_time }) =>
                              from_time == timeData.from_time &&
                              to_time == timeData.to_time
                          ) < 0
                        )
                          slotData[days].push({
                            from_time: timeData.from_time,
                            to_time: timeData.to_time,
                          });
                      } else if (i == from) {
                        if (timeData.from_time.slice(3, 5) == '00')
                          slotData[days].push({
                            from_time: i + ':00:00',
                            to_time: i + ':00:00',
                          });
                        slotData[days].push({
                          from_time: i + ':30:00',
                          to_time: i + ':30:00',
                        });
                      } else if (i == to) {
                        if (timeData.to_time.slice(3, 5) == '30')
                          slotData[days].push({
                            from_time: i + ':30:00',
                            to_time: i + ':30:00',
                          });
                        slotData[days].push({
                          from_time: i + ':00:00',
                          to_time: i + ':00:00',
                        });
                      } else {
                        slotData[days].push({
                          from_time: i + ':00:00',
                          to_time: i + ':00:00',
                        });
                        slotData[days].push({
                          from_time: i + ':30:00',
                          to_time: i + ':30:00',
                        });
                      }
                    } else
                      slotData[days].push({
                        from_time: i + ':00:00',
                        to_time: i + ':00:00',
                      });
                  }
                });
              }
            });
            this.setState({ slotData: slotData, noData: false });
          }
          this.setState({
            ac_id: ac_id,
            comments: comments,
            showResult: true,
            no_of_classes: no_of_classes,
          });
        }
      });
    }
  }

  postAvailabilityData = async () => {
    var slotDataTemp = {
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    };
    if (!(this.state.ac_id && this.state.no_of_classes) && !this.isTeacher) {
      this.props.enqueueSnackbar(
        !this.state.no_of_classes
          ? 'Please select No of Classes'
          : 'Please select AC',
        {
          variant: 'error',
        }
      );
      return;
    }
    await Object.keys(this.state.slotData).map(async (days) => {
      const slotDataTemporary = this.state.slotData;
      const sortedArray = slotDataTemporary[days]?.sort((a, b) =>
        a.from_time > b.from_time ? 1 : -1
      );
      if (sortedArray && sortedArray.length > 0) {
        slotDataTemp[days].push(sortedArray[0]);
        sortedArray.map((slotDetail, i) => {
          const length = slotDataTemp[days].length;
          if (slotDetail != slotDataTemp[days][length - 1]) {
            const from_time_prev_temp =
              slotDataTemp[days][length - 1].from_time;
            const to_time_temp = slotDataTemp[days][length - 1].to_time;
            const from_time_temp = slotDetail.from_time;
            const to_time_seconds = to_time_temp
              .split(':')
              .reduce((acc, time) => 60 * acc + +time);
            const from_time_seconds = from_time_temp
              .split(':')
              .reduce((acc, time) => 60 * acc + +time);
            const diff = from_time_seconds - to_time_seconds;
            if (this.isTeacher || true ? diff == 1800 : diff == 3600) {
              slotDataTemp[days].pop();
              slotDataTemp[days].push({
                from_time: from_time_prev_temp,
                to_time: slotDetail.to_time,
              });
            } else {
              slotDataTemp[days].push({
                from_time: slotDetail.from_time,
                to_time: slotDetail.to_time,
              });
            }
            // console.log("dataa", slotDetail, slotDataTemp, length, sortedArray,to_time_seconds, from_time_seconds, diff,  eval(`slotDataTemp.${days}`))
          }
        });
      }
    });

    await Object.keys(slotDataTemp).map(async (days) => {
      if (slotDataTemp[days].length) {
        slotDataTemp[days].map((slotDetail, i) => {
          slotDetail.to_time =
            slotDetail.to_time.slice(0, 4) +
            '1' +
            slotDetail.to_time.slice(5, 8);
        });
      }
    });
    const admin_id = this.props && this.props.user && this.props.user.user_id;
    const url = this.isTeacher
      ? `/v1/admin/${admin_id}/teacher-slots/`
      : `/v1/admin/${admin_id}/student-slots/${this.state.classId}/${this.state.packageId}/`;
    const data = this.isTeacher
      ? {
          user_id: this.props.user_id,
          slots: slotDataTemp,
        }
      : {
          student_id: this.props.user_id,
          slots: slotDataTemp,
          ac_id: this.state.ac_id,
          comments: this.state.comments,
          no_of_classes: this.state.no_of_classes,
        };

    await axios
      .post(url, data)
      .then((response) => {
        if (response && response.status == 200) {
          this.props.enqueueSnackbar(
            response?.data?.data?.message ||
              'Availability Data Updated Successfully',
            { variant: 'success' }
          );
          this.props.closeModal();
          this.props.handleCallBack();
        }
      })
      .catch((err) => {
        const data = err?.response?.data?.data?.error;
        this.props.enqueueSnackbar(data || 'Something Went Wrong', {
          variant: 'error',
        });
      });
  };
  renderView = () => {
    return (
      <div style={this.viewData ? null : userStyles.Modal}>
        <div style={userStyles.header}>
          <h3>
            {this.viewData
              ? this.isTeacher
                ? 'Teacher Availability'
                : 'User Availability'
              : `Mark availability for ${
                  this.props.user_name || this.props.user_id
                }`}
          </h3>
          {this.viewData ? null : (
            <IconButton onClick={this.props.closeModal}>
              <Tooltip title='Close Modal'>
                <Icon className='iconButton'>close</Icon>
              </Tooltip>
            </IconButton>
          )}
        </div>

        <List>
          {(this.isTeacher && !this.viewData) || this.teacherUserId ? null : (
            <List item style={{ display: 'flex', gap: 20, fontSize: 10 }}>
              <FormControl style={{ minWidth: 200 }}>
                <InputLabel>Select Package</InputLabel>
                <Select
                  onChange={(event) => {
                    this.setState({
                      packageId: event.target.value,
                      showResult: false,
                    });
                  }}
                  value={this.state.packageId || ''}
                  // selectList={this.state.packageIdList}
                  // style={{ cursor: 'pointer', width: 200 }}
                  // defaultValue={[{ label: 'LogiMath', value: 4 }]}
                  // isDisabled={false}
                >
                  {this.state.packageIdList &&
                    this.state.packageIdList.map((timeData) => (
                      <MenuItem value={timeData.value}>
                        {timeData.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {!this.isTeacher && (
                <FormControl style={{ minWidth: 200 }}>
                  <InputLabel>Select Class</InputLabel>
                  <Select
                    value={this.state.classId || ''}
                    onChange={(e) => {
                      this.setState({
                        classId: e.target.value,
                        showResult: false,
                      });
                    }}
                    defaultValue=''
                    // disabled={!this.state.packageId}
                  >
                    {this.state.classList &&
                      this.state.classList.map((timeData) => (
                        <MenuItem value={timeData.value}>
                          {timeData.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              <Button
                onClick={() => {
                  this.getAvailabilityData(this.state.classId);
                }}
                disabled={
                  !(this.state.packageId && this.state.classId) &&
                  !(this.state.packageId && this.isTeacher)
                }
                color='primary'
                variant='outlined'
              >
                View Result
              </Button>
            </List>
          )}
          {!this.viewData && this.state.classId && this.state.showResult ? (
            <List style={{ display: 'flex', gap: 20 }}>
              <TextField
                id='comments'
                label='comments'
                variant='outlined'
                value={this.state.comments}
                onChange={(event) =>
                  this.setState({ comments: event.target.value })
                }
              />

              <FormControl style={{ minWidth: 200 }}>
                <InputLabel>Select AC</InputLabel>
                <Select
                  value={this.state.ac_id || ''}
                  onChange={(e) => this.setState({ ac_id: e.target.value })}
                >
                  {this.state.acList &&
                    this.state.acList.map((timeData) => (
                      <MenuItem value={timeData.value}>
                        {timeData.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl style={{ minWidth: 150 }}>
                <InputLabel>No. Of Classes</InputLabel>
                <Select
                  value={this.state.no_of_classes || ''}
                  onChange={(e) =>
                    this.setState({ no_of_classes: e.target.value })
                  }
                >
                  <MenuItem value={16}>16</MenuItem>
                  <MenuItem value={24}>24</MenuItem>
                  <MenuItem value={32}>32</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                </Select>
              </FormControl>
            </List>
          ) : null}
          {!this.viewData && this.state.showResult ? (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  const modifiedSlotTime = this.state.slotTime;
                  modifiedSlotTime.splice(
                    0,
                    0,
                    new moment(this.state.slotTime[0], 'HH:mm:ss')
                      .subtract(30, 'minutes')
                      .format('HH:mm:ss')
                  );
                  this.setState({ slotTime: modifiedSlotTime });
                }}
                disabled={this.state.slotTime[0] == '00:00:00'}
              >
                <Icon>add</Icon>
                Add half an hour before {this.state.slotTime[0]}
              </Button>
            </div>
          ) : null}
          {!this.state.showResult ? null : this.state.noData ? (
            'No data to display'
          ) : (
            <List item sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                columns={16}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  verticalAlign: 'center',
                }}
              >
                <Grid item style={{ maxWidth: '10vw' }}>
                  <Grid
                    style={{
                      width: this.viewData ? '10vw' : '5vw',
                      height: 30,
                    }}
                  ></Grid>
                  {this.state.slotTime.map((time) => (
                    <Grid
                      style={{
                        width: this.viewData ? '10vw' : '5vw',
                        minHeight: `calc(${
                          this.teacherUserId ? 1.7 : 1
                        }*100vh / ${
                          this.state.slotTime.length > 10 ||
                          this.state.slotTime.length < 5
                            ? 10
                            : this.state.slotTime.length
                        })`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: this.state.userSlotCount
                          ? this.state.userSlotCount[time] * 21
                          : 'auto',
                      }}
                    >
                      {time}
                    </Grid>
                  ))}
                </Grid>
                {Object.keys(this.state.slotData).map((slot) => (
                  <Grid
                    item
                    style={{
                      padding: 0,
                      width: this.viewData ? '12vw' : '10vw',
                    }}
                  >
                    <Grid
                      style={{
                        height: 30,
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {slot}
                    </Grid>
                    {this.state.slotTime.map((time, i) => {
                      const tempData = this.state.slotData;
                      const dayData = tempData[slot];
                      var index = this.viewData
                        ? dayData.findIndex(
                            ({ slot_time }) => slot_time == time
                          )
                        : dayData.findIndex(
                            ({ from_time, to_time }) =>
                              from_time == time && to_time == time
                          );
                      var isExist = index > -1;
                      return (
                        <Grid
                          style={{
                            width: '100%',
                            minHeight: `calc(${
                              this.teacherUserId ? 1.7 : 1
                            }*100vh / ${
                              this.state.slotTime.length > 10 ||
                              this.state.slotTime.length < 5
                                ? 10
                                : this.state.slotTime.length
                            })`,
                            height: this.state.userSlotCount
                              ? this.state.userSlotCount[time] * 21
                              : 'auto',
                            border: '1px black solid',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background:
                              isExist || this.viewData
                                ? this.teacherUserId && dayData[index]?.group
                                  ? this.getBackgroundColor(
                                      dayData[index]?.group.split(' ')
                                    )
                                  : this.viewData &&
                                    ((this.isTeacher &&
                                      dayData[index]?.teachers?.length > 2) ||
                                      (!this.isTeacher &&
                                        dayData[index]?.students?.paid?.length +
                                          dayData[index]?.students?.unpaid
                                            ?.length >
                                          1))
                                  ? 'lightgreen'
                                  : this.viewData &&
                                    ((this.isTeacher &&
                                      !dayData[index]?.teachers?.length > 0) ||
                                      (!this.isTeacher &&
                                        !(
                                          dayData[index]?.students?.paid
                                            ?.length +
                                          dayData[index]?.students?.unpaid
                                            ?.length
                                        ) > 0))
                                  ? 'white'
                                  : 'lightblue'
                                : 'white',
                            cursor: 'pointer',
                            whiteSpace: 'nowrap',
                            padding: 10,
                          }}
                          onClick={(event) => {
                            if (!this.viewData) {
                              if (!this.state.selectOnHover) {
                                index = dayData.findIndex(
                                  ({ from_time, to_time }) =>
                                    from_time == time && to_time == time
                                );
                                isExist = index > -1;
                                if (index > -1) dayData.splice(index, 1);
                                else
                                  dayData.push({
                                    from_time: time,
                                    to_time: time,
                                  });
                                this.setState({ slotData: tempData });
                              }
                              // console.log("slot", index, dayData, isExist, event, dayData.slice(index, 1))
                              this.setState({
                                selectOnHover: !this.state.selectOnHover,
                              });
                            } else {
                              this.setState({
                                showDetails: !this.state.showDetails,
                              });
                            }
                          }}
                          onMouseOver={(event) => {
                            if (!this.viewData && this.state.selectOnHover) {
                              index = dayData.findIndex(
                                ({ from_time, to_time }) =>
                                  from_time == time && to_time == time
                              );
                              isExist = index > -1;
                              if (index > -1) dayData.splice(index, 1);
                              else
                                dayData.push({
                                  from_time: time,
                                  to_time: time,
                                });
                              this.setState({ slotData: tempData });
                              // console.log("slot", index, dayData, isExist, event, dayData.slice(index, 1))
                            }
                          }}
                        >
                          {this.viewData
                            ? dayData && dayData[index] && isExist
                              ? (dayData[index].students ||
                                  this.teacherUserId ||
                                  dayData[index].teachers) && (
                                  <div style={{ overflowX: 'hidden' }}>
                                    {!this.state.showDetails ? (
                                      this.teacherUserId ? (
                                        <div
                                          style={{ whiteSpace: 'break-spaces' }}
                                        >
                                          {dayData[index].group}
                                        </div>
                                      ) : this.isTeacher ? (
                                        <div>
                                          {dayData[index].teachers.length}
                                        </div>
                                      ) : (
                                        <div>
                                          {dayData[index].students.paid.length +
                                            ' + ' +
                                            dayData[index].students.unpaid
                                              .length}
                                        </div>
                                      )
                                    ) : this.isTeacher ? (
                                      this.teacherUserId ? (
                                        <div
                                          style={{ whiteSpace: 'break-spaces' }}
                                        >
                                          {Object.entries(dayData[index]).map(
                                            ([key, value]) =>
                                              key != 'group' &&
                                              key != 'slot_time' && (
                                                <div style={{ fontSize: 12 }}>
                                                  {key.replaceAll('_', ' ')}:{' '}
                                                  {value}
                                                </div>
                                              )
                                          )}
                                        </div>
                                      ) : (
                                        <div>
                                          <div>
                                            {dayData[index].teachers
                                              ? dayData[index].teachers.map(
                                                  (teacher) => (
                                                    <Tooltip
                                                      title={
                                                        <div>
                                                          <div>
                                                            Name :{' '}
                                                            {teacher.name}
                                                          </div>
                                                          <div>
                                                            Grade :{' '}
                                                            {this.state
                                                              .userData &&
                                                              this.state.userData[
                                                                teacher.user_id
                                                              ].join(', ')}
                                                          </div>
                                                        </div>
                                                      }
                                                    >
                                                      <div>{teacher.name}</div>
                                                    </Tooltip>
                                                  )
                                                )
                                              : '-'}
                                          </div>
                                        </div>
                                      )
                                    ) : (
                                      <div>
                                        <div>
                                          {dayData[index].students.paid
                                            ? dayData[index].students.paid.map(
                                                (student) => (
                                                  <Tooltip
                                                    title={
                                                      <div>
                                                        <div>
                                                          Name : {student.name}
                                                        </div>
                                                        <div>
                                                          AC :{' '}
                                                          {(this.state
                                                            .userData &&
                                                            this.state.userData[
                                                              student.student_id
                                                            ].ac) ||
                                                            '-'}
                                                        </div>
                                                        <div>
                                                          No. of Classes :{' '}
                                                          {this.state
                                                            .userData &&
                                                            this.state.userData[
                                                              student.student_id
                                                            ].no_of_classes}
                                                        </div>
                                                        <div>
                                                          Comment :{' '}
                                                          {(this.state
                                                            .userData &&
                                                            this.state.userData[
                                                              student.student_id
                                                            ].comments) ||
                                                            '-'}
                                                        </div>

                                                        <div>
                                                          {' '}
                                                          Group Name :{' '}
                                                          {this.state?.userData[
                                                            student?.student_id
                                                          ]?.group_data
                                                            ?.group_name || '-'}
                                                        </div>
                                                        {this.state?.userData[
                                                          student?.student_id
                                                        ]?.group_data ? (
                                                          <div>
                                                            {' '}
                                                            Classes Completed :{' '}
                                                            {
                                                              this.state
                                                                ?.userData[
                                                                student
                                                                  ?.student_id
                                                              ]?.group_data
                                                                ?.completed_classes
                                                            }
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    }
                                                  >
                                                    <div>
                                                      {student.name +
                                                        (this.state.userData &&
                                                        this.state.userData[
                                                          student.student_id
                                                        ].group_data
                                                          ? '(' +
                                                            this.state.userData[
                                                              student.student_id
                                                            ].group_data
                                                              .group_name +
                                                            ')'
                                                          : '')}
                                                    </div>
                                                  </Tooltip>
                                                )
                                              )
                                            : '-'}
                                        </div>

                                        <div>
                                          {dayData[index].students.unpaid
                                            ? dayData[
                                                index
                                              ].students.unpaid.map(
                                                (student) => (
                                                  <Tooltip
                                                    title={
                                                      <div>
                                                        <div>
                                                          Name : {student.name}
                                                        </div>
                                                        <div>
                                                          AC :{' '}
                                                          {this.state
                                                            .userData &&
                                                            this.state.userData[
                                                              student.student_id
                                                            ].ac}
                                                        </div>
                                                        <div>
                                                          No. of Classes :{' '}
                                                          {(this.state
                                                            .userData &&
                                                            this.state.userData[
                                                              student.student_id
                                                            ].no_of_classes) ||
                                                            '-'}
                                                        </div>
                                                        <div>
                                                          Comment :{' '}
                                                          {(this.state
                                                            .userData &&
                                                            this.state.userData[
                                                              student.student_id
                                                            ].comments) ||
                                                            '-'}
                                                        </div>
                                                        <div>
                                                          {' '}
                                                          Group Name :{' '}
                                                          {this.state?.userData[
                                                            student?.student_id
                                                          ]?.group_data
                                                            ?.group_name || '-'}
                                                        </div>
                                                        {this.state?.userData[
                                                          student?.student_id
                                                        ]?.group_data ? (
                                                          <div>
                                                            {' '}
                                                            Classes Completed :{' '}
                                                            {
                                                              this.state
                                                                ?.userData[
                                                                student
                                                                  ?.student_id
                                                              ]?.group_data
                                                                ?.completed_classes
                                                            }
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    }
                                                  >
                                                    <div
                                                      style={{
                                                        color: '#4421ad',
                                                      }}
                                                    >
                                                      {student.name +
                                                        (this.state.userData &&
                                                        this.state.userData[
                                                          student.student_id
                                                        ].group_data
                                                          ? '(' +
                                                            this.state.userData[
                                                              student.student_id
                                                            ].group_data
                                                              .group_name +
                                                            ')'
                                                          : '')}
                                                    </div>
                                                  </Tooltip>
                                                )
                                              )
                                            : '-'}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                              : '-'
                            : isExist
                            ? 'Available'
                            : 'Not Available'}
                        </Grid>
                      );
                    })}
                  </Grid>
                ))}
              </Grid>
            </List>
          )}
          {!this.viewData && this.state.showResult ? (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  const modifiedSlotTime = this.state.slotTime;
                  modifiedSlotTime.splice(
                    this.state.slotTime.length,
                    0,
                    new moment(
                      this.state.slotTime[this.state.slotTime.length - 1],
                      'HH:mm:ss'
                    )
                      .add(30, 'minutes')
                      .format('HH:mm:ss')
                  );
                  this.setState({ slotTime: modifiedSlotTime });
                }}
                disabled={
                  this.state.slotTime[this.state.slotTime.length - 1] ===
                  '23:30:00'
                }
              >
                <Icon>add</Icon>
                Add half an hour after{' '}
                {this.state.slotTime[this.state.slotTime.length - 1]}
              </Button>
            </div>
          ) : null}
        </List>
        {!this.viewData && this.state.showResult && (
          <div style={userStyles.button}>
            <Button
              color='primary'
              variant='contained'
              onClick={this.props.closeModal}
            >
              Cancel
            </Button>
            {(this.state.packageId && this.state.classId) || this.isTeacher ? (
              <Button
                color='primary'
                variant='contained'
                onClick={this.postAvailabilityData}
              >
                Update
              </Button>
            ) : null}
          </div>
        )}
      </div>
    );
  };
  render() {
    return (
      <div
        style={{
          minHeight: '100vh',
        }}
      >
        {this.viewData ? (
          this.renderView()
        ) : (
          <Modal
            open={this.props.user_id || true}
            onClose={() => {
              this.props.closeModal;
            }}
          >
            {this.renderView()}
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withSnackbar(connect(mapStateToProps)(AvailabilityModal));
