import React, { useRef, Fragment, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/styles';
import Table from '../../components/LQDataTables/demo';
import { connect } from 'react-redux';
import { browserHistory } from "react-router";
import { withSnackbar } from 'notistack';

const useStyles = makeStyles({
    text: {
        fontSize: 12,
    },
});

function LeadsTable(props) {
    let tableRef = useRef(null);

   

    async function advancedFilterList() {
        const filterList = [
            {
                filter_label: 'name',
                type: 'text',
            },
            {
                filter_label: 'code',
                type: 'text',
            },
            {
                filter_label: 'packages',
                type: 'text',
            },
            {
                filter_label: 'products',
                type: 'text',
            },
            {
                filter_label: 'max_user',
                type: 'text',
            },
            {
                filter_label: 'max_total',
                type: 'text',
            },
            {
                filter_label: 'start_date',
                type: 'date',
                children: [
                    {
                        label: 'From Start Date',
                        state_ref: 'start_date_filter_start',
                    },
                    {
                        label: 'To Start Date',
                        state_ref: 'start_date_filter_end',
                    },
                ],
            },
            {
                filter_label: 'end_date',
                type: 'date',
                children: [
                    {
                        label: 'From End Date',
                        state_ref: 'last_date_filter_start',
                    },
                    {
                        label: 'To End Date',
                        state_ref: 'last_date_filter_end',
                    },
                ],
            },
        ];
        // console.log('New Filter List: ', filterList);
        return filterList;
    }

    const classes = useStyles();



    function renderItems(items) {
        const itemObj = items.map(item => (
            <List key={item}>
                <Typography className={classes.text}>{item}</Typography>
            </List>
        ));
        return itemObj;
    }

  function formatDate(date){
    if (!date) return;
    var date = new Date(date);
    
     return (
        <Typography key={`list_${name}`} className={classes.text}>
          {`${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}  ${date.getHours()>9?date.getHours():`0${date.getHours()}`}:${date.getMinutes()>9?date.getMinutes():`0${date.getMinutes()}`}`}
     
        </Typography>
      );

  }

    function formatTable(tableData) {
        // console.log('Filter', simpleFilterList);
        const newData = tableData
            && tableData.map((entry, index) => {
                const {
                 created_at
                  } = entry;
                // const orderId = entry.order_id;
                const newEntry = { ...entry };

                newEntry.created_at = formatDate(
                    created_at,
                    'created_at'
                  );
           /*      newEntry.packages = newEntry.packages ? renderItems(newEntry.packages) : null;
                newEntry.products = newEntry.products ? renderItems(newEntry.products) : null; */
                const updatedEntry = {
                    edit: (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Tooltip title="Edit">
                                <IconButton>
                                    <Icon>edit</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                    ),
                    ...newEntry,
                };
                // console.log('ENTRY', entry);
                return Object.keys(updatedEntry).map(
                    subEntry => (updatedEntry[subEntry] ? '' || updatedEntry[subEntry] : '-'),
                );
            });
        return newData;
    }

    return (
        <Table
            onRef={(ref) => {
                tableRef = ref;
            }}
            advancedFilterList={advancedFilterList}
            createNew="/create"
            firstCol='edit'
            headerFilterList={HEADER_FILTER_LIST}
            formatTable={formatTable}
            sortList={SORT_LIST}
            tableName="leads"
            title="Leads Table"
            openApiUrl={`leads/`}
        />
    );
}

const mapStateToProps = (state) => {
    return {
      user: state.user
    }
  }  


export default withSnackbar(connect(mapStateToProps)(LeadsTable));

const HEADER_FILTER_LIST = [
    {
        filter_label: 'name',
        type: 'text',
    },
    {
        filter_label: 'code',
        type: 'text',
    },
    {
        filter_label: 'max_user',
        type: 'text',
    },
    {
        filter_label: 'max_total',
        type: 'text',
    },
];

const SORT_LIST = [];
