import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Icon,
  IconButton,
  Tooltip,
  Button,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
} from '@material-ui/core';

import { Link } from 'react-router';
import SessionSelector from './../../components/SessionSelector';
import SaveChangesModal from './../../components/SaveChangesModal';

function EditSessionData(props) {
  const useStyles = makeStyles((theme) =>
    createStyles({
      margin: {
        margin: theme.spacing(1),
      },
      appBar: {
        position: 'relative',
      },
      flex: {
        flex: 1,
      },
      rightIcon: {
        marginLeft: theme.spacing(1),
      },
      formControl: {
        margin: `${theme.spacing(2)}px`,
        width: 450,
      },
      grid: {
        margin: '8px',
        padding: '0px 16px',
      },
      error: {
        color: '#f44336',
        fontSize: '12px',
        margin: `0px ${theme.spacing(2)}px`,
      },
      table: {
        border: null,
      },
      tablecell: {
        border: 'transparent !important',
        borderBottom: '2px solid rgba(224, 224, 224, 1) !important',
        padding: 15,
        // maxWidth:300,
        overflowX: 'auto',
      },
      tablecellSticky: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#fafafa',
        zIndex: 1,
        border: 'transparent !important',
        borderBottom: '2px solid rgba(224, 224, 224, 1) !important',
        padding: 15,
        // maxWidth:300,
        overflowX: 'auto',
      },
    })
  );

  const classes = useStyles();
  // const classId = props && props.location && props.location.query && props.location.query.class_id
  // const package_id = props && props.location && props.location.query && props.location.query.package_id
  const [classId, setClassId] = useState(null);
  const [packageId, setPackageId] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [sessionDetail, setSessionDetail] = useState(null);
  const [name, setname] = useState(null);
  const [ppt_link, setppt_link] = useState(null);
  const [recording_link, setrecording_link] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addTopicModal, setAddTopicModal] = useState(false);

  function getDetails(package_id, class_id) {
    setLoader(true);
    const admin_id = props && props.user && props.user.user_id;
    axios
      .get(
        `/v1/admin/${admin_id}/content/${class_id}/sessions-list/?package_id=${package_id}&details=1`
      )
      .then((res) => {
        if (res.status == 200 && res) {
          setSessionId(null);
          setname(null);
          setppt_link(null);
          setrecording_link(null);
          setClassId(class_id);
          setPackageId(package_id);
          setSessionDetail(res && res.data && res.data.data);
          setLoader(false);
        }
      })
      .catch((err) => {
        props.enqueueSnackbar(
          (err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error[0]) ||
            'Something Went Wrong',
          { variant: 'error' }
        );
      });
  }

  function saveModalChanges() {
    const admin_id = props?.user?.user_id;
    axios
      .post(`/v1/admin/${admin_id}/content/session/${sessionId}/update/`, {
        update_type: openModal.header,
        ...(openModal.header == 'revision_cards'
          ? { module_id: openModal.data?.module_id || null }
          : { [openModal.header]: openModal.data || null }),
      })
      .then((res) => {
        if (res.status == 200 && res) {
          setSessionId(null);
          setOpenModal(null);
          props.enqueueSnackbar(
            res?.data?.data?.message || 'Successfully Updated',
            { variant: 'success' }
          );
          getDetails(packageId, classId);
        }
      })
      .catch((err) => {
        props.enqueueSnackbar(
          (err?.response?.data?.data?.error &&
            err.response.data.data.error[0]) ||
            'Something Went Wrong',
          { variant: 'error' }
        );
      });
  }

  function updateSessionDetails(
    session_id,
    session_name,
    session_pptLink,
    session_recordingLink
  ) {
    const admin_id = props && props.user && props.user.user_id;
    axios
      .post(`/v1/admin/${admin_id}/content/${classId}/sessions-list/`, {
        session_id: session_id,
        name: name != null ? name : session_name,
        ppt_link: ppt_link != null ? ppt_link : session_pptLink,
        recording_link:
          recording_link != null ? recording_link : session_recordingLink,
      })
      .then((res) => {
        if (res.status == 200 && res) {
          setSessionId(null);
          setname(null);
          setppt_link(null);
          setrecording_link(null);
          props.enqueueSnackbar(
            (res && res.data && res.data.data && res.data.data.message) ||
              'Successfully Updated',
            { variant: 'success' }
          );
        }
      })
      .catch((err) => {
        props.enqueueSnackbar(
          (err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error[0]) ||
            'Something Went Wrong',
          { variant: 'error' }
        );
      });
  }

  function markNewTopic(event, session_id) {
    const admin_id = props?.user?.user_id;
    axios
      .post(`/v1/admin/${admin_id}/content/session/${session_id}/update/`, {
        update_type: 'new_topic',
        new_topic: event.target.checked,
      })
      .then((response) => {
        if (response && response.status === 200) {
          props.enqueueSnackbar(
            response?.data?.data?.message || 'Successfully Updated',
            { variant: 'success' }
          );
          getDetails(packageId, classId);
        }
      })
      .catch((err) => {
        props.enqueueSnackbar(
          err?.response?.data?.error || 'Something Went Wrong',
          { variant: 'error' }
        );
      });
  }

  function liveContent(event, session_id) {
    const admin_id = props && props.user && props.user.user_id;
    axios
      .post(`/v1/admin/${admin_id}/session/${session_id}/update/live/`, {
        is_live: event.target.checked,
      })
      .then((response) => {
        if (response && response.status === 200) {
          props.enqueueSnackbar(
            response?.data?.data?.message || 'Successfully Updated',
            { variant: 'success' }
          );
          getDetails(packageId, classId);
        }
      })
      .catch((err) => {
        props.enqueueSnackbar(
          (err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error[0]) ||
            'Something Went Wrong',
          { variant: 'error' }
        );
      });
  }

  function postAddTopic() {
    const admin_id = props?.user?.user_id;
    axios
      .post(`/v1/admin/${admin_id}/content/session/add-topic/`, addTopicModal)
      .then((response) => {
        if (response?.status === 200) {
          setAddTopicModal(false);
          props.enqueueSnackbar(
            response?.data?.data?.message || 'Topic Added Successfully',
            { variant: 'success' }
          );
        }
      })
      .catch((err) => {
        props.enqueueSnackbar(
          err?.response?.data?.data?.error || 'Something Went Wrong',
          { variant: 'error' }
        );
      });
  }

  function modalData() {
    var cellDetail = openModal?.data;
    var revisionCardList = openModal?.revisionCardList;
    if (openModal?.header == 'revision_cards' && !revisionCardList) {
      const admin_id = props && props.user && props.user.user_id;
      axios
        .get(
          `/v1/admin/${admin_id}/flashcard/list/?class_id=${classId}&package_id=${packageId}`
        )
        .then((res) => {
          if (res.status == 200 && res) {
            revisionCardList = res?.data?.data;
            setOpenModal({
              header: openModal?.header,
              data: cellDetail || { module_id: null },
              revisionCardList: revisionCardList,
            });
          }
        });
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          alignItems: 'flex-start',
          marginBottom: 'auto',
        }}
      >
        {openModal?.header == 'description' ? null : (
          <Button
            color='primary'
            variant='outlined'
            style={{ alignSelf: 'end' }}
            onClick={() => {
              if (openModal?.header == 'revision_cards')
                cellDetail = { module_id: null };
              else cellDetail.push('');
              setOpenModal({
                header: openModal?.header,
                data: cellDetail,
                revisionCardList: revisionCardList,
              });
            }}
            disabled={
              openModal?.header == 'revision_cards'
                ? openModal?.data
                : openModal?.data?.length > 0
                ? openModal?.data?.filter((item) => item.trim() === '').length >
                  0
                : false
            }
          >
            Add {openModal?.header?.replaceAll('_', ' ')}
          </Button>
        )}
        {openModal?.header == 'revision_cards' ? (
          cellDetail ? (
            <div style={{ display: 'flex', gap: '1vw' }}>
              <FormControl style={{ minWidth: 200 }}>
                <InputLabel>Select revision module</InputLabel>
                <Select
                  onChange={(event) => {
                    if (cellDetail)
                      cellDetail['module_id'] = event.target.value;
                    else cellDetail = { module_id: event.target.value };
                    setOpenModal({
                      header: openModal.header,
                      data: cellDetail,
                      revisionCardList: revisionCardList,
                    });
                  }}
                  value={openModal?.data?.module_id || ''}
                >
                  {revisionCardList?.map((revisionCard) => (
                    <MenuItem value={revisionCard.module_id}>
                      {revisionCard.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                style={{ padding: 0, alignSelf: 'end' }}
                onClick={() => {
                  cellDetail = null;
                  setOpenModal({
                    header: openModal?.header,
                    data: cellDetail,
                    revisionCardList: revisionCardList,
                  });
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </div>
          ) : null
        ) : openModal?.header == 'description' ? (
          <TextField
            label={openModal?.header}
            value={openModal?.data}
            variant={'outlined'}
            onChange={(event) => {
              cellDetail = event.target.value;
              setOpenModal({ header: openModal.header, data: cellDetail });
            }}
            multiline={true}
            rows={6}
            style={{ width: '100%' }}
            inputProps={{ maxLength: 100 }}
            helperText='Character limit is 100'
          />
        ) : (
          openModal?.data?.map((cellData, index) => (
            <div style={{ width: '100%' }}>
              <TextField
                value={cellData}
                onChange={(event) => {
                  cellDetail[index] = event.target.value;
                  setOpenModal({ header: openModal.header, data: cellDetail });
                }}
                variant='outlined'
                style={{ width: '90%' }}
                rows={openModal?.header == 'experiments' ? 3 : 1}
                multiline
              />
              <IconButton
                onClick={() => {
                  cellDetail.splice(index, 1);
                  setOpenModal({ header: openModal.header, data: cellDetail });
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </div>
          ))
        )}
      </div>
    );
  }

  function addTopicModalData() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2vw' }}>
        <SessionSelector
          filterList={['grade', 'package']}
          packageData={{
            paid: true,
          }}
          onFilterChange={(data) => {
            if (data.package_id)
              setAddTopicModal({
                ...addTopicModal,
                package_id: data.package_id,
              });
            if (data.grade)
              setAddTopicModal({ ...addTopicModal, class_id: data.grade });
          }}
        />
        <TextField
          label={'Name'}
          value={addTopicModal?.name}
          variant={'outlined'}
          onChange={(event) => {
            setAddTopicModal({ ...addTopicModal, name: event.target.value });
          }}
        />
        <TextField
          label={'Order'}
          value={addTopicModal?.order}
          variant={'outlined'}
          onChange={(event) => {
            setAddTopicModal({ ...addTopicModal, order: event.target.value });
          }}
        />
      </div>
    );
  }

  function handleChange(event, header, session_id) {
    if (sessionId && session_id != sessionId) {
      setname(null);
      setppt_link(null);
      setrecording_link(null);
    }
    eval('set' + header + '("' + event.target.value + '")');
    setSessionId(session_id);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2vw',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ fontSize: '1.5vw', fontWeight: 600 }}>Topics</div>
        <Button
          variant='contained'
          color='primary'
          onClick={() =>
            setAddTopicModal({
              package_id: null,
              class_id: null,
              name: '',
              order: '',
            })
          }
        >
          <Icon>add</Icon>Add Topic
        </Button>
      </div>
      <SaveChangesModal
        openModal={addTopicModal ? true : false}
        closeModal={() => setAddTopicModal(false)}
        text={'Add Topic'}
        displayData={addTopicModalData()}
        onSave={() => postAddTopic()}
        disableConfirm={Object.values(addTopicModal).some((data) => !data)}
        style={{ maxHeight: '70vh' }}
      />
      <SessionSelector
        filterList={['grade', 'package']}
        onFilterChange={() => setSessionDetail(null)}
        setFilterData={(package_id, class_id, session_id) =>
          getDetails(package_id, class_id)
        }
      />
      <SaveChangesModal
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        text={
          openModal?.header?.charAt(0).toUpperCase() +
          openModal?.header?.slice(1).replaceAll('_', ' ')
        }
        displayData={modalData()}
        onSave={() => saveModalChanges()}
        style={{
          minHeight: '80vh',
          maxHeight: '80vh',
          overflowY: 'scroll',
          gap: '1vw',
          justifyContent: 'space-between',
        }}
      />
      {
        loader ? (
          <CircularProgress
            style={{
              margin: 'auto',
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
            }}
          />
        ) : sessionDetail && sessionDetail[0] ? (
          <div
            style={{
              width: '100%',
              overflow: 'scroll',
              maxHeight: '85vh',
              height: 'fit-content',
            }}
          >
            <Table
              className={classes.table}
              aria-label='simple table'
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.tablecellSticky}
                    style={{ top: 0, zIndex: 3 }}
                  ></TableCell>
                  {sessionDetail &&
                    sessionDetail[0] &&
                    Object.getOwnPropertyNames(
                      sessionDetail && sessionDetail[0]
                    ).map((header, i) => {
                      const headername = header.replaceAll('_', ' ');
                      return (
                        <TableCell
                          className={
                            headername == 'order'
                              ? classes.tablecellSticky
                              : classes.tablecell
                          }
                          style={
                            headername == 'order'
                              ? { left: 120, top: 0, zIndex: 3 }
                              : null
                          }
                        >
                          {headername.charAt(0).toUpperCase() +
                            headername.slice(1)}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {sessionDetail &&
                  sessionDetail.map((data, index) => (
                    <>
                      <TableRow key={data.session_name}>
                        <TableCell className={classes.tablecellSticky}>
                          <Button
                            color='primary'
                            variant='outlined'
                            onClick={() =>
                              updateSessionDetails(
                                data.id,
                                data.name,
                                data.ppt_link,
                                data.recording_link
                              )
                            }
                            disabled={data.id != sessionId}
                          >
                            Update
                          </Button>
                        </TableCell>
                        {sessionDetail &&
                          sessionDetail[0] &&
                          Object.getOwnPropertyNames(
                            sessionDetail && sessionDetail[0]
                          ).map((header, i) => {
                            const cell = data[header];
                            return (
                              <TableCell
                                className={
                                  header == 'order'
                                    ? classes.tablecellSticky
                                    : classes.tablecell
                                }
                                style={
                                  header == 'name' ||
                                  header == 'ppt_link' ||
                                  header == 'recording_link'
                                    ? { width: '30%', flex: 3 }
                                    : header == 'order'
                                    ? { left: 120 }
                                    : Array.isArray(cell) ||
                                      cell?.module_name ||
                                      (header == 'description' && cell)
                                    ? {
                                        color: 'blue',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }
                                    : null
                                }
                                onClick={() => {
                                  if (
                                    Array.isArray(cell) ||
                                    header == 'revision_cards' ||
                                    header == 'description'
                                  ) {
                                    setOpenModal({
                                      header: header,
                                      data: JSON.parse(JSON.stringify(cell)),
                                    });
                                    setSessionId(data.id);
                                  }
                                  // if(cell.module_id){
                                  //   setOpenModal({header:header, data: JSON.parse(JSON.stringify(cell))})
                                  //   setSessionId(data.id)
                                  // }
                                }}
                              >
                                {header == 'is_live' ||
                                header == 'new_topic' ? (
                                  <Switch
                                    checked={data[header]}
                                    size='small'
                                    color='primary'
                                    onChange={(event) => {
                                      if (header == 'is_live')
                                        liveContent(event, data.id);
                                      else markNewTopic(event, data.id);
                                    }}
                                  />
                                ) : header == 'name' ||
                                  header == 'ppt_link' ||
                                  header == 'recording_link' ? (
                                  <div style={{ display: 'flex' }}>
                                    <TextField
                                      value={
                                        data.id == sessionId &&
                                        (eval(header) || eval(header) == '')
                                          ? eval(header)
                                          : cell
                                          ? cell
                                          : eval(header)
                                          ? ''
                                          : cell
                                      }
                                      onChange={(event) =>
                                        handleChange(event, header, data.id)
                                      }
                                      inputProps={{ 'aria-label': 'naked' }}
                                      // label={header=="name" ? "Edit Session Name" : "Edit PPT Link"}
                                      variant='outlined'
                                      style={{ width: 'max-content' }}
                                    />
                                    {(header == 'ppt_link' ||
                                      header == 'recording_link') &&
                                      (ppt_link || recording_link
                                        ? data.id == sessionId
                                        : cell) && (
                                        <Tooltip title='View PPT'>
                                          <Link
                                            to={{
                                              pathname:
                                                header == 'ppt_link'
                                                  ? ppt_link || cell
                                                  : recording_link || cell,
                                            }}
                                            target='_blank'
                                            style={{ textDecoration: 'none' }}
                                          >
                                            <IconButton style={{ padding: 10 }}>
                                              <Icon>link_icon</Icon>
                                            </IconButton>
                                          </Link>
                                        </Tooltip>
                                      )}
                                  </div>
                                ) : Array.isArray(cell) ? (
                                  cell.length
                                ) : header == 'revision_cards' ||
                                  (header == 'description' && !cell) ? (
                                  cell?.module_name || (
                                    <Button color='primary' variant='outlined'>
                                      Add
                                    </Button>
                                  )
                                ) : typeof cell == 'boolean' ? (
                                  cell ? (
                                    'Yes'
                                  ) : (
                                    'No'
                                  )
                                ) : (
                                  cell
                                )}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </div>
        ) : null
        // <div style={{textAlign:'center'}}>No data to display</div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default withSnackbar(connect(mapStateToProps)(EditSessionData));
