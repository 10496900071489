import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { browserHistory } from 'react-router';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as actions from '../../actions';
// import AppSideBar from '../MenuItems';
import { Authenticated, NotAuthenticated } from '../LoginStatus';
import Navigator from '../Navigator';
import { goToPage } from '../../Utils/utilityFunctions';
import { withSnackbar } from 'notistack';
import { ListItem, makeStyles, Modal, Grid } from '@material-ui/core';
import axios from 'axios';

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflowY: 'hidden',
    overflowX: 'auto',
    position: 'relative',
    display: 'flex',
    height: '100%',
  },
  bar1Determinate: {
    background: 'rgba(26,41,128, 0.8)' /* fallback for old browsers */,
    background:
      '-webkit-linear-gradient(to left, rgba(26,41,128, 0.8), rgba(26,41,128, 0.8))' /* Chrome 10-25, Safari 5.1-6 */,
    background:
      'linear-gradient(to left, rgba(26,41,128, 0.8), rgba(26,41,128, 0.8))' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
  },
  progressBarRoot: {
    height: 4,
  },
  topBar: {
    display: 'flex',
    minHeight: 'unset',
  },
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    color: '#000',
    backgroundColor: '#fff',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  headerText: {
    fontWeight: 'bolder',
    minWidth: 120,
    cursor: 'pointer',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  logoutButton: {
    marginRight: 12,
  },
  changePassword: {
    width: '20%',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'auto',
    height: 'auto',
    backgroundColor: theme.palette.background.default,
    padding: `${theme.spacing(10)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(9),
      padding: 0,
    },
  },
});

class AppDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      completed: 0,
      changePasswordModal: false,
      password: '',
      repassword: '',
      showError: false,
    };
  }

  componentDidUpdate() {
    this.timer = setInterval(this.progress, 200)    
    if(this.props.error){
      this.props.enqueueSnackbar(this.props.error, {variant:'error'})
      this.props.resetErrors()
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  static getDerivedStateFromProps(nextProps) {
    return !nextProps.topLoader
      ? {
          completed: 100,
        }
      : {};
  }

  redirectUser = (path) => {
    if (browserHistory) {
      browserHistory.push({
        pathname: path,
        // search: typeof window !== 'undefined' && window && window.location.search,
      });
    }
    this.handleDrawerClose();
  };

  progress = () => {
    const { completed } = this.state;
    if (completed === 100) {
      clearInterval(this.timer);
    } else {
      const diff = Math.random() * 10;
      let progress = completed + diff;
      if (progress === 100) {
        progress = 90;
        this.setState({ completed: Math.min(90, 100) });
        clearInterval(this.timer);
      }
      this.setState({ completed: Math.min(progress, 100) });
    }
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleLogout = () => {
    const userId = this.props.user && this.props.user.user_id;
    axios
      .post(
        `/v1/user/${userId}/logout/`,
        {},
        {
          headers: {
            'Session-Token': this.props.user && this.props.user.session_token,
          },
        }
      )
      .then(() => {
        this.props.logout(userId);
      });
    // this.props.logout(userId)
  };

  handleLogin = () => {
    if (browserHistory) {
      browserHistory.push({
        pathname: '/',
        search:
          typeof window !== 'undefined' && window && window.location.search,
      });
    }
  };

  render() {
    const userId = this.props.user && this.props.user.user_id;
    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>
        <AppBar
          position='fixed'
          className={classNames(
            classes.appBar,
            this.state.open && classes.appBarShift
          )}
        >
          <Toolbar className={classes.topBar} disableGutters={!this.state.open}>
            {this.props.user ? (
              <IconButton
                color='inherit'
                aria-label='Open drawer'
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.hide
                )}
                disabled={this.props.user === null}
              >
                <MenuIcon />
              </IconButton>
            ) : null}
            <Button
              className={classes.headerText}
              onClick={() => goToPage('/')}
            >
              <Typography variant='h6' color='inherit' noWrap>
                {'LQ Live'}
              </Typography>
            </Button>
            <Navigator 
              display={this.props?.user?.role !== 'woodrock'}
            />
            <Authenticated>
              <Button
                className={classes.changePassword}
                onClick={() => this.setState({ changePasswordModal: true })}
              >
                Change Password
              </Button>

              <Tooltip title='Logout'>
                <Button
                  className={classes.logoutButton}
                  onClick={() => this.handleLogout()}
                >
                  <Icon>power_settings_new</Icon>
                </Button>
              </Tooltip>
            </Authenticated>
            <NotAuthenticated>
              <Tooltip title='Login'>
                <Button
                  className={classes.logoutButton}
                  onClick={() => this.handleLogin()}
                >
                  <Icon>perm_identity</Icon>
                </Button>
              </Tooltip>
            </NotAuthenticated>
          </Toolbar>
          <LinearProgress
            variant='determinate'
            value={this.state.completed}
            classes={{
              bar1Determinate: classes.bar1Determinate,
              root: classes.progressBarRoot,
            }}
          />
        </AppBar>
        <Drawer
          ModalProps={{
            onBackdropClick: () => {
              this.handleDrawerClose();
            },
          }}
          variant='temporary'
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.state.open && classes.drawerPaperClose
            ),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          {/* <List>{AppSideBar(this.props.routes, this.redirectUser)}</List> */}
        </Drawer>
        <main className={classes.content}>
          {/* <div className={classes.toolbar} /> */}
          {this.state.changePasswordModal ? (
            <Modal
              open={this.state.changePasswordModal}
              onClose={() => {
                this.setState({ changePasswordModal: false });
              }}
            >
              <div
                style={{
                  backgroundColor: '#fff',
                  margin: 'auto',
                  marginTop: 100,
                  width: 600,
                  zIndex: 10,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 50,
                    gap: 20,
                  }}
                >
                  <List style={{ fontSize: 20 }}>Change Password</List>
                  <TextField
                    id='password'
                    label='Password'
                    variant='outlined'
                    value={this.state.password}
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                  />
                  <TextField
                    id='re-password'
                    label='Re-Enter Password'
                    variant='outlined'
                    value={this.state.repassword}
                    onChange={(event) =>
                      this.setState({ repassword: event.target.value })
                    }
                  />
                  {this.state.showError ? (
                    <ListItem>
                      Please enter valid and matching passwords
                    </ListItem>
                  ) : null}
                  <div
                    style={{ display: 'flex', justifyContent: 'space-evenly' }}
                  >
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() =>
                        this.setState({ changePasswordModal: false })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => {
                        if (
                          this.state.password &&
                          this.state.repassword &&
                          this.state.password == this.state.repassword
                        ) {
                          this.setState({ showError: false });
                          axios
                            .post(
                              `/v1/user/${userId}/change-password/`,
                              { password: this.state.password },
                              {
                                headers: {
                                  'Session-Token':
                                    this.props.user &&
                                    this.props.user.session_token,
                                },
                              }
                            )
                            .then((res) => {
                              if (res && res.status && res.status == 200) {
                                this.setState({ changePasswordModal: false });
                                this.props.enqueueSnackbar(
                                  (res &&
                                    res.data &&
                                    res.data.data &&
                                    res.data.data.message) ||
                                    'Password Updated Successfully',
                                  { variant: 'success' }
                                );
                                this.setState({ password: '', repassword: '' });
                              }
                            })
                            .catch((error) => {
                              this.props.enqueueSnackbar(
                                error.response &&
                                  error.response.data &&
                                  error.response.data.data.error &&
                                  error.response.data.data.error.password &&
                                  error.response.data.data.error.password[0],
                                { variant: 'error' }
                              );
                            });
                        } else {
                          this.setState({ showError: true });
                        }
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          ) : null}
          {this.props.content ? this.props.content : null}
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    isMobile: state.utils && state.utils.is_mobile,
    topLoader: state.loader && state.loader.topLoader,
    error: state.error
  }
}

export default withSnackbar(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, actions, null)(AppDrawer)
  )
);
