import React from "react";
import Calendar_Scheduler from "./styles";
import { useState } from "react";
import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker";
// import "react-day-picker/lib/style.css";
import { DateUtils } from "react-day-picker";
import moment from "moment";
import axiosInstance from "../../config/axios";
import { browserHistory } from "react-router";
import { Modal } from "@material-ui/core";
import { withSnackbar } from 'notistack';
import axios from "axios"
import { connect } from "react-redux";
import InputBox from '../../components/InputBox';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import { date } from "date-fns/locale/af";
import { data } from "autoprefixer";
import "react-day-picker/lib/style.css";

class CalendarScheduler extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      startDate: new Date(),
      selectedDays: [],
      data: null,
      showConfirm: false,
      timeSlots: Array.from(Array(96).keys()),
      displayTimeSlot: false,
      currentDay: undefined,
      InitialFormat: false,
      schedule: [],
      student_id: null,
      group_id: null,
      updatedSchedule: [],
      no_of_classes: null,
      total_classes: null,
      teacher_id:null,
      teacher_list:null,
      selected_teacher:null,
    };
  }

  componentDidMount() {
    console.log("location", this.props.location);
    if (
      !this.props.location ||
      !this.props.location.query ||
      !this.props.location.query.student_id &&
      !this.props.location.query.group_id
    ) {
      browserHistory.push({
        pathname: "/",
      });
      return;
    }
    const studentId = this.props.location.query.student_id;
    const groupId = this.props.location.query.group_id;
    this.setState({ student_id: studentId });
    this.setState({ group_id: groupId });
    const user_id=this.props && this.props.user.user_id
    axios.get(`v1/admin/${user_id}/teacher/`)
    .then((res)=>{
        if(res.status==200){
            console.log(res.data)
            this.setState({
                teacher_list: res.data.data
            })
        }
    })
    axios
      .get(`/v1/admin/${user_id}/group/${groupId}/calendar-schedule/`)
      .then((res) => {
        console.log("get response", res);
        if (res.status == 200) {
          this.setState({
            schedule: [...res.data.data.classes],
            data: res.data,
            no_of_classes: res.data.data.no_of_classes,
            total_classes: res.data.data.total_classes,
            selected_teacher:  res.data.data.teacher_id,
          });
          if (!this.state.InitialFormat) {
            //console.log('working')
            this.formatAllDays();
          }
          this.props.enqueueSnackbar(
            "Successfully fetched data."
            , { variant: 'success'}
        );
        }
      })
      .catch((err) => {});
  }
  renderDay = (day) => {
    const date = day.getDate();

    const dateStyle = {
      position: "center",
      bottom: 0,
      fontWeight: 800,
      right: 0,
      fontSize: 15,
    };
    const timeStyle = {
      fontSize: "10px",
      fontWeight: 100,
      textAlign: "center",
      color: "#000",
    };
    const cellStyle = {
      height: 40,
      width: 40,
      position: "center",
    };
    return (
      <div style={cellStyle}>
        <div style={dateStyle}>{date}</div>
        {this.state.selectedDays.map((i) =>
          i.getDate() == date &&
          i.getMonth() == day.getMonth() &&
          i.getFullYear() == day.getFullYear() ? (
            <div style={timeStyle}>
              {/* {this.state.selectedDays ? this.state.selectedDays : null} */}
              ({i.getHours()}:{i.getMinutes() == 0 ? "00" : i.getMinutes()})
            </div>
          ) : null
        )}
      </div>
    );
  };

  onSubmitConfim = () => {
    this.setState({
      showConfirm: true,
    });
  };
  handleTeacherChange = (e) => {
    this.setState({
        selected_teacher: e.target.value,
        // teacher_error: false
    })
}

  onSubmit = () => {
    this.setState({ updatedSchedule: [] });
    this.state.selectedDays.map((selectedDays) => {
      //this.setState({updatedSchedule.schedule_at : selectedDay})
      this.state.updatedSchedule.push({
        scheduled_at: moment(selectedDays).format("YYYY-MM-DDTHH:mm:ssZ"),
      });
    });
    // console.log('selected days',this.state.selectedDays);
    // console.log('updated schedule',this.state.updatedSchedule);
    const studentId = this.props.location.query && this.props.location.query.student_id;
    const groupId = this.props.location.query && this.props.location.query.group_id;
    const user_id=this.props && this.props.user.user_id
    groupId ?
    axios.post(`/v1/admin/${user_id}/group/${groupId}/calendar-schedule/`, 
        {
            schedule: this.state.updatedSchedule,
            group_id: groupId,
            teacher_id: this.state.selected_teacher,
          }
          )
        .then((res) => {
          console.log(res);
          if (res && res.status == 200) {
            console.log("successs");
            this.props.enqueueSnackbar('Updated Successfully', { variant: 'success'});
          }
          
        })
        .catch((err) => { 
        this.props.enqueueSnackbar(err.response && err.response.data && err.response.data.data && err.response.data.data.error && err.response.data.data.error[0], { variant: 'error'});
        })
        : null}

  formatAllDays = () => {
    if (this.state.schedule) {
      this.state.schedule.map((schedule) => {
        this.state.selectedDays.push(new Date(schedule.scheduled_at));
      });
    }
    this.setState({ InitialFormat: true });
  };

  handleDayClick(day, { selected }) {
    const { selectedDays } = this.state;
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      day.setHours(0);
      this.setState({ displayTimeSlot: true });
      this.setState({ currentDay: day });
    }
    this.setState({ selectedDays });
  }
  timeSlotsSelection = (timeSlots) => {
    if (timeSlots % 4 == 0) {
      this.state.currentDay.setHours(timeSlots / 4);
    } 
    if(timeSlots % 4 == 2) {
      this.state.currentDay.setHours((timeSlots - 2) / 4);
      this.state.currentDay.setMinutes(30);
    }
    if(timeSlots % 4 == 3) {
      this.state.currentDay.setHours((timeSlots -3) / 4);
      this.state.currentDay.setMinutes(45);
    }
    if(timeSlots % 4 == 1) {
      this.state.currentDay.setHours((timeSlots - 1) / 4);
      this.state.currentDay.setMinutes(15);
    }
    this.state.selectedDays.push(this.state.currentDay);
    console.log(this.state.selectedDays);
    this.setState({ displayTimeSlot: false });
  };

  render() {
    return (
      <Calendar_Scheduler>
        {/* <h1>Scheduler</h1> */}
        <Modal
          open={this.state.showConfirm}
          onClose={() => {
            this.setState({
              showConfirm: false,
            });
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin:"20%"
            }}
          >
            <span style={{ fontSize: 18, marginBottom: 10 }}>
              Do you really want to update the schedule ?
            </span>
            <span style={{ fontSize: 18, marginBottom: 10 }}>
              {`Total classes to be updated ${this.state.schedule.length}`}
            </span>
            <div
              onClick={() => {
                this.setState(
                  {
                    showConfirm: false,
                  },
                  () => {
                    this.onSubmit();
                  }
                );
              }}
              style={{
                backgroundColor: "rgba(26,41,128, 0.8)",
                cursor: "pointer",
                fontWeight: 700,
                padding: "5px 15px",
                color: "#fff",
                fontSize: 18,
                borderRadius: 10,
              }}
            >
              Yes
            </div>
          </div>
        </Modal>
        <div
          style={{
            display: "flex",
            padding: 10,
            flexDirection: "row",
            fontWeight: 600,
            fontSize: 20,
            justifyContent: "space-evenly",
          }}
        >
         <div style={{display:"flex", gap:20}}>
          <span
            style={{
              color: "#000",
             
              marginLeft: 10,
              fontWeight: 300,
            }}
          >
            Teacher:{" "}
          </span>
          {/* <span style={{ backgroundColor: "#ded826", color: "#000" }}>
            {this.state.data && this.state.data.teacher}
          </span> */}
          <div style={{marginTop:-35}}>
          <InputBox 
            type="select"
           
            options={this.state.teacher_list}
            name="selected_teacher"
            value={this.state.selected_teacher}
            onChange={this.handleTeacherChange}
            // error={this.state.teacher_error && !this.state.selected_teacher && "Select a teacher"}
        />
        </div>
        </div>
        <div>
          <span
            style={{
              color: "#000",
              
              marginLeft: 10,
              fontWeight: 300,
            }}
          >
            Classes Remaining:{" "}
          </span>
          <span style={{  color: "#000" }}>
            {this.state && this.state.no_of_classes}
          </span>
          </div>
          <div>
          <span
            style={{
              color: "#000",
              
              marginLeft: 10,
              fontWeight: 300,
            }}
          >
            Total Classes:{" "}
          </span>
          <span style={{  color: "#000" }}>
            {this.state && this.state.total_classes}
          </span>
          </div>
        </div>

        {this.state.schedule.length ? (
          <div className="calendar">
            <DayPicker
              selectedDays={this.state.selectedDays}
              onDayClick={this.handleDayClick}
              numberOfMonths={3}
              className="time"
              renderDay={this.renderDay}
              pagedNavigation
              // renderDay={timelabel}
            />

            <div className={this.state.displayTimeSlot ? "timeslots" : null}>
              {this.state.displayTimeSlot
                ? this.state.timeSlots.map((timeSlot, index) => {
                    console.log(timeSlot);
                    return (
                      <div>
                        <ul>
                          <button
                            onClick={() => this.timeSlotsSelection(timeSlot)}
                          >
                            {timeSlot % 4 == 0
                              ? `${timeSlot / 4}:00`
                              : timeSlot % 4 == 2 ?
                              `${(timeSlot - 2) / 4}:30`
                              : timeSlot % 4 == 3 ?
                              `${(timeSlot - 3) / 4}:45`
                              : `${(timeSlot - 1) / 4}:15`
                              }
                          </button>
                        </ul>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        ) : null}
        {this.state.selectedDays.length != this.state.no_of_classes ? (
          <label className="classWarning">
            Please select {this.state.no_of_classes} number of classes (
            {this.state.selectedDays.length - this.state.no_of_classes > 0
              ? "Unselect"
              : "Select"}{" "}
            {Math.abs(
              this.state.selectedDays.length - this.state.no_of_classes
            )}{" "}
            {this.state.selectedDays.length - this.state.no_of_classes < 0
              ? "more"
              : null}{" "}
            {this.state.selectedDays.length - this.state.no_of_classes == -1 ||
            this.state.selectedDays.length - this.state.no_of_classes == 1
              ? "class"
              : "classes"}
            )
          </label>
        ) : 
        this.state.no_of_classes > 0 ?
        (
          <button className="submitButton effect" onClick={this.onSubmitConfim}>
            <span>Submit</span>
          </button>
        )
      :
      <div>No Class Scheduled</div>
      }
      </Calendar_Scheduler>
    );
  }
}

function mapStateToProps(state) {
  return {
      user: state.user,  
  };
}


export default withSnackbar(connect(mapStateToProps)(CalendarScheduler));
