import React, { useState } from 'react';
import { Link } from 'react-router';
import {
  ListItem,
  Typography,
  makeStyles,
  Modal,
  List,
  Grid,
  Button,
  Icon,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Table from '../../components/LQDataTables/demo';
import LQUserDetails from '../../components/LQUserDetails';
import AvailabilityModal from '../../components/AvailabilityModal';
import axios from 'axios';
import { connect } from 'react-redux';
import { Stars } from '@material-ui/icons';
import DownloadCertificate from '../../components/DownloadCertificate';
import EnrollmentClosedToggle from './enrollmentClosedToggleModal';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },

  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});

let tableRef = null;

const ref = React.createRef();

function UserManagementTable(props) {
  const [resetPassword, setResetPassword] = useState(null);
  const [LQParentId, setLQParentId] = useState(null);
  const [LQUserId, setLQUserId] = useState(null);
  const [availablity, setAvailablity] = useState(null);
  const [downloadCertificate, setDownloadCertificate] = useState(null);

  const classes = userStyles();

  function handleCallBack() {
    tableRef && tableRef.refreshTable();
  }

  const ref = React.createRef();

  function setUserAvailabilityModal() {
    return (
      <AvailabilityModal
        user_id={availablity.id}
        grade={availablity.grade}
        user_name={availablity.name}
        closeModal={() => {
          setAvailablity(null);
        }}
        handleCallBack={handleCallBack}
      />
    );
  }

  function LQUserDetailsModal() {
    return (
      <LQUserDetails
        student_id={LQUserId}
        parent_id={LQParentId}
        closeModal={() => {
          setLQUserId(null), setLQParentId(null);
        }}
        handleCallBack={handleCallBack}
      />
    );
  }

  function resetPasswordModal(id) {
    const user_id = props && props.user.user_id;

    return (
      <Modal
        open={resetPassword}
        onClose={() => {
          setResetPassword(null);
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            margin: 'auto',
            marginTop: 100,
            width: 600,
          }}
        >
          <div>
            <List>
              <ListItem>
                <Typography style={{ fontSize: 20 }}>
                  Do want to reset password of {resetPassword.name}?
                </Typography>
              </ListItem>
              <ListItem>
                <Grid
                  container
                  spacing={8}
                  style={{ justifyContent: 'center' }}
                >
                  <Grid item>
                    <Button
                      color='primary'
                      type='submit'
                      variant='contained'
                      onClick={() => setResetPassword(null)}
                    >
                      {'No'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color='secondary'
                      type='submit'
                      variant='contained'
                      onClick={() => {
                        axios
                          .post(
                            `v1/admin/${user_id}/change-password/${resetPassword.id}/`,
                            {
                              password: 'logic123',
                              role: 'student',
                            }
                          )
                          .then((res) => {
                            console.log(res);
                            if (res.status == 200) {
                              props.enqueueSnackbar(
                                `${resetPassword.name}'s Password Reset Successfully`,
                                { variant: 'success' }
                              );
                              setResetPassword(null);
                              tableRef && tableRef.refreshTable();
                            }
                          })
                          .catch((err) => {
                            err?.response?.data?.data?.error?.map((error) =>
                              props.enqueueSnackbar(error || 'error', {
                                variant: 'warning',
                              })
                            );
                          });
                      }}
                    >
                      {'Yes'}
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </div>
        </div>
      </Modal>
    );
  }

  function formatTable(tableData) {
    const newData =
      tableData &&
      tableData.map((entry, index) => {
        const { id, grade, lq_parent_id, LQ_Child_ID, rm, group_id, name, enrollment_closed } =
          entry;

        const newEntry = { ...entry };

        newEntry.group_id =
          group_id &&
          group_id.map(
            (groupDetails, index) =>
              groupDetails.group_id && (
                <div>
                  <Link
                    to={{
                      pathname:
                        groupDetails.group_type == 'PAID'
                          ? '/group-class/paid'
                          : '/group-class/free',
                      search: `?id=${groupDetails.group_id}`,
                    }}
                    target='_blank'
                    style={{ textDecoration: 'none' }}
                  >
                    {groupDetails.group_id}
                  </Link>
                </div>
              )
          );

        newEntry.name = (
          <Link
            to={{
              pathname: '/student-session-details',
              search: `?student_id=${id}`,
            }}
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            <div>{name}</div>
          </Link>
        );
        const updatedEntry = {
          edit: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Link
                to={{
                  pathname: '/user/create',
                  search: `?userId=${id}`,
                }}
                target='_blank'
                style={{ textDecoration: 'none' }}
              >
                <IconButton>
                  <Tooltip title='Edit'>
                    <Icon className='iconButton'>edit</Icon>
                  </Tooltip>
                </IconButton>
              </Link>

              <IconButton
                onClick={() => {
                  setResetPassword({ id: id, name: name });
                }}
              >
                <Tooltip title='Reset password'>
                  <Icon className='iconButton'>Reset</Icon>
                </Tooltip>
              </IconButton>
              {lq_parent_id ? (
                <IconButton
                  onClick={() => {
                    setLQUserId(entry.LQ_Student_ID),
                      setLQParentId(entry.lq_parent_id);
                  }}
                >
                  <Tooltip title='LogIQids User Data'>
                    <Icon className='iconButton'>list</Icon>
                  </Tooltip>
                </IconButton>
              ) : null}
              <IconButton
                onClick={() => {
                  setAvailablity({ id: id, name: name, grade: grade });
                }}
              >
                <Tooltip title='Set user availability'>
                  <Icon className='iconButton'>event_available_icon</Icon>
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() => setDownloadCertificate({ id: id, name: name })}
              >
                <Tooltip title='Download Certificate'>
                  <Stars />
                </Tooltip>
              </IconButton>
              <EnrollmentClosedToggle userId={id} enrollmentClosed={enrollment_closed} name={name} handleCallBack={handleCallBack}/>
            </div>
          ),

          ...newEntry,
        };
        return Object.keys(updatedEntry).map((subEntry) =>
          updatedEntry[subEntry] ? '' || updatedEntry[subEntry] : '-'
        );
      });
    return newData;
  }

  return (
    <div>
      <Table
        onRef={(ref) => {
          tableRef = ref;
        }}
        firstCol='edit'
        createNew='/user/create'
        headerFilterList={HEADER_FILTER_LIST}
        formatTable={formatTable}
        sortList={SORT_LIST}
        sortDate={[]}
        tableName='user'
        title='User Management'
        scrollType='stacked'
        search={props.location && props.location.query}
        apiUrl={'user/'}
        hideColumn={['enrollment_closed']}
      />
      {resetPassword ? resetPasswordModal() : null}
      {LQParentId ? LQUserDetailsModal() : null}
      {availablity ? setUserAvailabilityModal() : null}
      {downloadCertificate ? (
        <DownloadCertificate
          id={downloadCertificate.id}
          name={downloadCertificate.name}
          onClose={() => setDownloadCertificate(null)}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withSnackbar(connect(mapStateToProps)(UserManagementTable));

const HEADER_FILTER_LIST = [
  {
    filter_label: 'id',
    type: 'text',
  },
  {
    filter_label: 'name',
    type: 'text',
  },
  {
    filter_label: 'grade',
    type: 'text',
  },
  {
    filter_label: 'phone',
    type: 'text',
  },
  {
    filter_label: 'school',
    type: 'text',
  },
  {
    filter_label: 'email',
    type: 'text',
  },
  {
    filter_label: 'user_id',
    type: 'text',
  },
  {
    filter_label: 'lq_parent_id',
    type: 'text',
  },
  {
    filter_label: 'LQ_Child_Id',
    type: 'text',
  },
  {
    filter_label: 'utm',
    type: 'text',
  },
  {
    filter_label: 'Package Assigned',
    type: 'dropdown',
    filter_params_name: 'subscriptions',
  },
];

const SORT_LIST = ['id'];
