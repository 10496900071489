import { orange } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import './style.scss';

import Row from '../../../../components/Elements/Flex/row';
import { COLOR_ARRAY } from '../../FlashCardsUtils';


const OrangeRadio = withStyles({
  root: {
    '&$checked': {
      color: orange[600],
    },
    fontSize: 28,
    fontWeight: 600,
  },
  checked: {},
})((props) => <Radio color='default' {...props} />);

function ButtonCssPopup(props) {
  const COLORS = COLOR_ARRAY;
  let topHeight = document.getElementById('drop-box').offsetHeight;
  const { updateElementData, index, style, importedIn, solution, buttonType } =
    props;

  let updateStyle = (key, value) => {
    let updatedStyle = JSON.parse(JSON.stringify(style));

    if (key === 'buttonType') {
      updateElementData(index, {
        key: 'buttonType',
        value: value,
      });
    } else {
      updatedStyle[key] = value;
      updateElementData(index, {
        key: 'style',
        value: updatedStyle,
      });
    }
  };

  let updateSolutionStyle = (key, value) => {
    let updatedStyle = JSON.parse(JSON.stringify(solution));
    updatedStyle.button.style[key] = value;
    if (key === 'buttonType') {
      updatedStyle.button.buttonType = value;
    } else {
      updatedStyle.button.style[key] = value;
    }

    updateElementData(index, {
      key: 'solution',
      value: updatedStyle,
    });
  };
  return (
    <div className='main-div-button' style={{ top: topHeight / 2 - 65 }}>
      <Row>
        {COLORS.map((data, i) => {
          return (
            <div
              key={i}
              className='color-button-div'
              style={{
                border:
                  style.backgroundColor === data
                    ? '1px solid #FF9F0A'
                    : '1px solid #FFF',
              }}
            >
              <div
                className={`colors ${data == '#FFF' ? 'white' : 'non-white'}`}
                style={{
                  backgroundColor: data,
                }}
                onClick={() => {
                  importedIn === 'singleSelect'
                    ? updateSolutionStyle('backgroundColor', data)
                    : updateStyle('backgroundColor', data);
                }}
              ></div>
            </div>
          );
        })}
      </Row>
      <div className='radio-div'>
        <RadioGroup
          row
          aria-label='position'
          name='position'
          defaultValue='top'
          value={buttonType}
          onChange={(event) =>
            importedIn === 'singleSelect'
              ? updateSolutionStyle('buttonType', event.target.value)
              : updateStyle('buttonType', event.target.value)
          }
        >
          <FormControlLabel
            className='label'
            value='fixed'
            control={<OrangeRadio />}
            label='Fixed'
          />
          <FormControlLabel
            className='label'
            value='scroll'
            control={<OrangeRadio />}
            label='Scroll'
          />
        </RadioGroup>
      </div>
    </div>
  );
}

export default ButtonCssPopup;
