import React, { useState } from 'react';
import { Link } from 'react-router';
import {
  Icon,
  IconButton,
  Tooltip,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  InputAdornment,
  Button,
  Divider,
} from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';
import { withSnackbar } from 'notistack';
import Table from '../../components/LQDataTables/demo';
import axios from 'axios';
import { connect } from 'react-redux';
import SaveChangesModal from '../../components/SaveChangesModal';
import moment from 'moment';
import ReassignMissedSessionStudent from '../../components/ReassignMissedSessionStudent';
import { convertToTitleCase } from '../../Utils/utilityFunctions';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import '../../Utils/util.scss';

let tableRef = null;

function setRowProps(row, index, tabledata) {
  const started_at = tabledata?.originalData[index]?.started_at;
  return started_at
    ? {
        style: {
          backgroundColor: 'rgba(0, 255, 0, 0.2)',
        },
      }
    : null;
}

function DemoCallingPanel(props) {
  const [resetPassword, setResetPassword] = useState(null);
  const [reassignStudent, setReassignStudent] = useState(null);
  const [commentsData, setCommentsData] = useState({
    openModalType: null,
  });
  const [dropDownData, setDropDownData] = useState(null);
  const callLogsColumns = [
    'comments',
    'comments_day_before',
    'comments_hour_before',
  ];

  const roleBasedCommentDropdown = {
    default: {
      woodrock: 'comments_day_before',
      'customer support': 'comments_hour_before',
      admin: 'comments_hour_before',
    },
    dropDownData: {
      woodrock: {
        comments: 'Additional Comments',
        comments_day_before: 'Comments Day Before',
      },
      'customer support': {
        comments: 'Additional Comments',
        comments_hour_before: 'Comments Hour Before',
      },
      admin: {
        comments: 'Additional Comments',
        comments_day_before: 'Comments Day Before',
        comments_hour_before: 'Comments Hour Before',
      },
    },
  };

  function handleCallBack() {
    tableRef && tableRef.refreshTable();
  }

  function onResetPassword() {
    axios
      .post(`v1/admin/${props.adminId}/change-password/${resetPassword.id}/`, {
        password: 'logic123',
        role: 'student',
      })
      .then((res) => {
        if (res.status == 200) {
          props.enqueueSnackbar(
            response?.data?.data?.message ||
              `${resetPassword.name}'s Password Reset Successfully`,
            { variant: 'success' }
          );
          setResetPassword(false);
          handleCallBack();
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.data?.error;
        props.enqueueSnackbar(errorMessage || 'Something Went Wrong', {
          variant: 'error',
        });
      });
  }

  function selectFollowupDate() {
    const { selectedFollowupDate } = commentsData;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateTimePicker
          variant='inline'
          label='Follow Up Date'
          value={selectedFollowupDate || null}
          onChange={(date) =>
            setCommentsData({ ...commentsData, selectedFollowupDate: date })
          }
          dateRangeIcon
          disablePast={true}
          style={{ width: 'fit-content' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Tooltip title='Set Follow Up Date'>
                  <IconButton>
                    <Icon>date_range_icon</Icon>
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
    );
  }

  function renderSwitchUpdateComments() {
    const { openModalType, participantId, callLogs, selectedFollowupDate } = commentsData;
    switch (openModalType) {
      case 'comments':
        return (
          <div className='row-flex space-between align-center gap-1vw'>
            <TextField
              label={convertToTitleCase(openModalType)}
              value={callLogs?.[openModalType] || ''}
              onChange={(event) =>
                setCommentsData({
                  ...commentsData,
                  callLogs: {
                    ...callLogs,
                    [openModalType]: event.target.value,
                  },
                })
              }
              placeholder={`Enter ${convertToTitleCase(openModalType)}`}
              multiline
              rows={4}
              variant='outlined'
              fullWidth
            />
            {callLogs[openModalType] ? (
              <Button
                onClick={() => setReassignStudent({ participantId })}
                variant='outlined'
                color='primary'
                className='flex'
              >
                Reschedule
                <PersonAdd style={{ marginLeft: 5 }} />
              </Button>
            ) : null}
          </div>
        );
      case 'comments_day_before':
      case 'comments_hour_before':
        return (
          <div className='row-flex space-between align-center'>
            <FormControl className='w-50'>
              <InputLabel>{convertToTitleCase(openModalType)}</InputLabel>
              <Select
                value={callLogs?.[openModalType] || ''}
                onChange={(event) =>
                  setCommentsData({
                    ...commentsData,
                    callLogs: {
                      ...callLogs,
                      [openModalType]: event.target.value,
                    },
                  })
                }
              >
                {dropDownData &&
                  Object.entries(dropDownData)?.map(
                    ([dropDownKey, dropDownValue]) => (
                      <MenuItem
                        value={dropDownKey}
                        style={{
                          fontSize: '1vw',
                          marginLeft:
                            dropDownValue && dropDownKey != dropDownValue
                              ? '1vw'
                              : 0,
                        }}
                        disabled={dropDownKey == dropDownValue}
                      >
                        {dropDownKey.replace(` - ${dropDownValue}`, '')}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
            {callLogs[openModalType]
              ?.toLowerCase()
              ?.includes('will not attend') ? (
              <Button
                onClick={() => setReassignStudent({ participantId })}
                variant='outlined'
                color='primary'
                className='flex'
                disabled={selectedFollowupDate}
              >
                Reschedule
                <PersonAdd style={{ marginLeft: 5 }} />
              </Button>
            ) : null}
          </div>
        );
      default:
        <div>{callLogs?.[openModalType] || ''}</div>;
    }
  }

  function renderUpdateComments() {
    const { openModalType, callLogs } = commentsData;
    return (
      <div className='col-flex gap-1vw'>
        {renderCommentHistory()}
        <div className='flex space-between'>
          <FormControl className='w-50'>
            <InputLabel>Comment Type</InputLabel>
            <Select
              value={openModalType || ''}
              onChange={(event) =>
                setCommentsData({
                  ...commentsData,
                  openModalType: event.target.value,
                })
              }
            >
              {Object.entries(
                roleBasedCommentDropdown?.dropDownData?.[props.role]
              )?.map(([key, value]) => (
                <MenuItem value={key}>{value}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {openModalType &&
          callLogs?.[openModalType] &&
          callLogs?.[openModalType] !== 'Will attend'
            ? selectFollowupDate()
            : null}
        </div>
        {renderSwitchUpdateComments()}
      </div>
    );
  }

  function renderCommentHistory() {
    const { commentHistory } = commentsData;
    return (
      commentHistory && (
        <div className='col-flex gap-1vw curved-box'>
          <div className='curved-box-title'>Comment History</div>
          {commentHistory.length ? (
            commentHistory?.map((commentHistoryData, index) => (
              <>
                <div>
                  {Object.entries(commentHistoryData)?.map(
                    ([key, value]) =>
                      key != 'created_at' &&
                      key != 'created_by' &&
                      value && (
                        <div>
                          <b>{convertToTitleCase(key)}</b>:{' '}
                          {key == 'followup_date'
                            ? moment(value).format('DD-MM-YYYY hh:mm A')
                            : value}
                        </div>
                      )
                  )}
                  <div className='flex justify-end'>
                    {commentHistoryData.created_at &&
                      moment(commentHistoryData.created_at).format(
                        'DD-MM-YYYY hh:mm A'
                      )}
                    , {commentHistoryData.created_by}
                  </div>
                </div>
                {commentHistory.length > index + 1 && <Divider />}
              </>
            ))
          ) : (
            <div>There are no comment history</div>
          )}
        </div>
      )
    );
  }

  function fetchCommentsHistory(payload) {
    axios
      .post(`v1/admin/${props.adminId}/free-trial/call-logs/`, {
        action: 'get_comments_history',
        participant_id: payload.participantId,
      })
      .then((response) => {
        if (response.status == 200 && response?.data) {
          const commentHistory = response.data.data;
          setCommentsData({ ...payload, commentHistory });
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.data?.error;
        props.enqueueSnackbar(errorMessage || 'Something Went Wrong', {
          variant: 'error',
        });
      });
  }

  function onUpdateComments(reassignPayload) {
    const { openModalType, participantId, callLogs, selectedFollowupDate } =
      commentsData;
    const updatePayload = {
      action: 'update',
      participant_id: participantId,
      [openModalType]: callLogs?.[openModalType],
      followup_date: selectedFollowupDate || null,
    };
    const apiPayload = reassignPayload
      ? openModalType
        ? {
            ...reassignPayload,
            ...updatePayload,
            action: 'update',
          }
        : reassignPayload
      : updatePayload;
    const callApi =
      reassignPayload ||
      (callLogs[openModalType]?.toLowerCase()?.includes('will not attend')
        ? callLogs[openModalType] && selectedFollowupDate
        : callLogs[openModalType]);
    if (callApi)
      axios
        .post(`v1/admin/${props.adminId}/free-trial/call-logs/`, apiPayload)
        .then((response) => {
          if (response.status == 200) {
            props.enqueueSnackbar(
              response?.data?.data?.message || `Updated Successfully`,
              { variant: 'success' }
            );
            setCommentsData({ ...commentsData, openModalType: null });
            setReassignStudent(null);
            handleCallBack();
          }
        })
        .catch((err) => {
          console.log('err', err);
          const errorMessage = err?.response?.data?.data?.error;
          props.enqueueSnackbar(errorMessage || 'Something Went Wrong', {
            variant: 'error',
          });
        });
    else
      props.enqueueSnackbar(
        `Please enter ${
          callLogs[openModalType]?.toLowerCase()?.includes('will not attend')
            ? 'follow up date or reschedule date'
            : convertToTitleCase(openModalType)
        }`,
        {
          variant: 'error',
        }
      );
  }

  function renderCommentData(header, callLogs, participantId) {
    return (
      <div className='flex w-100 align-center space-between'>
        <div className='col-flex'>{callLogs?.comments}</div>
        <IconButton
          onClick={() => {
            fetchCommentsHistory({
              ...commentsData,
              openModalType: header,
              callLogs: { [header]: '' },
              participantId,
              selectedFollowupDate: null,
            });
          }}
        >
          <Tooltip
            title={`${callLogs?.comments ? 'Edit' : 'Add'} ${convertToTitleCase(
              header
            )}`}
          >
            <Icon>{callLogs?.comments ? 'edit' : 'add'}</Icon>
          </Tooltip>
        </IconButton>
      </div>
    );
  }

  function formatTable(tableData, newParams, admin_roles, overallData) {
    if (!dropDownData && overallData?.comments) {
      const processedDropDownData = [];
      Object.entries(overallData?.comments)?.map(([key, value]) => {
        processedDropDownData.push({ [key]: value ? key : null });
        value?.map((valueData) => {
          if (
            props.role != 'woodrock' ||
            valueData !== 'Forcibly booked a demo'
          )
            processedDropDownData.push({ [valueData + ' - ' + key]: key });
        });
      });
      setDropDownData(Object.assign({}, ...processedDropDownData));
    }
    const newData =
      tableData &&
      tableData.map((entry, index) => {
        const { id, student_id, name, call_logs, scheduled_at } = entry;
        const newEntry = { ...entry };
        newEntry.call_logs = renderCommentData(
          roleBasedCommentDropdown.default[props.role],
          call_logs,
          id
        );
        newEntry.scheduled_at =
          moment(scheduled_at).format('DD-MM-YYYY hh:mm A');

        newEntry.followup_date = call_logs?.followup_date
          ? moment(call_logs?.followup_date).format('DD-MM-YYYY hh:mm A')
          : '-';
        const updatedEntry = {
          edit:
            props.role === 'woodrock' ? null : (
              <div className='flex'>
                <Link
                  to={{
                    pathname: '/user/create',
                    search: `?userId=${student_id}`,
                  }}
                  target='_blank'
                  style={{ textDecoration: 'none' }}
                >
                  <IconButton>
                    <Tooltip title='Edit'>
                      <Icon className='iconButton'>edit</Icon>
                    </Tooltip>
                  </IconButton>
                </Link>

                <IconButton
                  onClick={() => {
                    setResetPassword({ id: student_id, name });
                  }}
                >
                  <Tooltip title='Reset password'>
                    <Icon className='iconButton'>Reset</Icon>
                  </Tooltip>
                </IconButton>
              </div>
            ),
          ...newEntry,
        };
        return Object.keys(updatedEntry).map((subEntry) =>
          updatedEntry[subEntry]
            ? '' || updatedEntry[subEntry]
            : updatedEntry[subEntry] == 0
            ? '0'
            : '-'
        );
      });
    return newData;
  }

  return (
    <div>
      <Table
        onRef={(ref) => {
          tableRef = ref;
        }}
        firstCol=''
        headerFilterList={HEADER_FILTER_LIST}
        formatTable={formatTable}
        sortList={SORT_LIST}
        sortDate={[]}
        search={props.location && props.location.query}
        tableName='Pre Demo Calling Panel'
        title='Pre Demo Calling Panel'
        scrollType='stacked'
        dateFilter={'date'}
        hideColumn={HIDE_COLUMN}
        toolbarFilter={TOOLBAR_FILTER}
        newColumns={['followup_date']}
        apiUrl={'free-trial/call-logs/'}
        defaultParams={{
          date:
            props.role == 'woodrock'
              ? moment(new Date()).add(1, 'd').format('yyyy-MM-DD')
              : moment(new Date()).format('yyyy-MM-DD'),
        }}
        setRowProps={setRowProps}
      />
      <SaveChangesModal
        openModal={resetPassword}
        closeModal={() => setResetPassword(false)}
        text={`Do want to reset password of ${resetPassword?.name}?`}
        onSave={onResetPassword}
      />
      <SaveChangesModal
        openModal={commentsData?.openModalType}
        closeModal={() =>
          setCommentsData({ ...commentsData, openModalType: false })
        }
        text={
          commentsData?.openModalType &&
          convertToTitleCase(commentsData?.openModalType)
        }
        onSave={() => onUpdateComments()}
        displayData={renderUpdateComments()}
        style={{ maxHeight: '80vh', overflow: 'auto' }}
      />
      <ReassignMissedSessionStudent
        participant_id={reassignStudent?.participantId}
        setGroupId={(groupId) =>
          setReassignStudent({ ...reassignStudent, groupId })
        }
        closeModal={() => setReassignStudent(null)}
        handleCallBack={handleCallBack}
        onConfirm={(payload) => onUpdateComments(payload)}
        headerText={'Reschedule Session'}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    adminId: state?.user?.user_id,
    role:
      state?.user?.role === 'admin' || state?.user?.role === 'woodrock'
        ? state?.user?.role
        : 'customer support',
  };
};

export default withSnackbar(connect(mapStateToProps)(DemoCallingPanel));

const HEADER_FILTER_LIST = [
  {
    filter_label: 'student_id',
    type: 'text',
  },
  {
    filter_label: 'name',
    type: 'text',
  },
  {
    filter_label: 'phone',
    type: 'text',
  },
  {
    filter_label: 'email',
    type: 'text',
  },
  {
    filter_label: 'grade',
    type: 'dropdown',
    dropDownList: [
      { id: 0, name: 'All' },
      { id: 'Sr KG', name: 'Sr KG' },
      { id: '1st Grade', name: '1st Grade' },
      { id: '2nd Grade', name: '2nd Grade' },
      { id: '3rd Grade', name: '3rd Grade' },
      { id: '4th Grade', name: '4th Grade' },
      { id: '5th Grade', name: '5th Grade' },
      { id: '6th Grade', name: '6th Grade' },
      { id: '7th Grade', name: '7th Grade' },
      { id: '8th Grade', name: '8th Grade' },
      { id: '9th Grade', name: '9th Grade' },
    ],
  },
  {
    filter_label: 'group_id',
    type: 'text',
  },
  {
    filter_label: 'utm',
    type: 'text',
  },
];
const TOOLBAR_FILTER = {
  key: 'date_type',
  list: {
    all: 'All',
    schedule_date: 'Schedule Date',
    followup_date: 'Followup Date',
  },
  default: 'all',
};
const SORT_LIST = ['scheduled_at'];
const HIDE_COLUMN = ['id', 'is_active', 'started_at'];
