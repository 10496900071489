import { browserHistory } from "react-router";
import {
    RESET_ERRORS,
    SET_IS_MOBILE,
    BACKEND_ERROR,
    USER_LOGGEDIN,
    USER_REGISTERED,
    LOGOUT_USER,
    TOP_LOADER_ENABLE,
    TOP_LOADER_DISABLE,
    SET_CLASS_LIST,
    SET_PACKAGE_LIST,
    USER_PROFILE_FETCHED
} from "./types";
import axios from "axios";


export function hideLoader() {
    return {
        type: SET_IS_MOBILE
    };
}

export function resetErrors() {
    return {
        type: RESET_ERRORS
    };
}
export function displayTopLoader() {
    return {
        type: TOP_LOADER_ENABLE
    };
}

export function hideTopLoader() {
    return {
        type: TOP_LOADER_DISABLE
    };
}

export function setBackendError(error) {
    return {
        type: BACKEND_ERROR,
        payload: error
    };
}

export function setPackageData(value){
    return {
        type: SET_PACKAGE_LIST,
        payload: value
    }
}

export function logout(userId) {
    return (dispatch, getState, { api, setAuthorizationToken }) =>
    {setAuthorizationToken();
                    dispatch({
                        type: LOGOUT_USER
                    });
                    browserHistory.push({
                        pathname: "/"
                        // search: typeof window !== 'undefined' && window && window.location.search,
                    });
                }
}

export function login(userData) {
    const url = "v1/admin/login/";
    return (dispatch, getState, { api, setAuthorizationToken }) =>
        axios
            .post(url, userData)
            .then(response => {
                console.log("login",response.data);
                if (response.status === 200 && response.data) {
                    
                    setAuthorizationToken(response.data.data);
                    dispatch({
                        type: USER_LOGGEDIN,
                        payload: response.data.data
                    });
                    dispatch(resetErrors());
                    if (browserHistory) {
                        browserHistory.push({
                            pathname: response.data?.data?.role == 'woodrock' ? '/demo-calling-panel' : "/group-class/paid",
                            search:
                                typeof window !== "undefined" &&
                                window &&
                                window.location.search
                        });
                    }
                }
            })
            .catch(error => {
                const errorMessage = "Something went wrong.Please try again.";
                const errorObjectWithMessage = {
                    ...error,
                    errorMessage: error?.response?.data?.data?.error
                        ? error.response.data.data.error
                        : [errorMessage]                          
                };
                console.log(errorObjectWithMessage)
                dispatch({
                    type: BACKEND_ERROR,
                    payload: errorObjectWithMessage.errorMessage[0]
                });
                return Promise.reject(error);
            });
}