import FormHelperText from '@material-ui/core/FormHelperText';
import { withSnackbar } from 'notistack';
import React, { Component, useEffect, useState, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import {
  List,
  Grid,
  ListItem,
  InputLabel,
  TextField as MaterailUiTextField,
  Select as MaterailUiSelect,
  FormControl,
  Icon,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField, Select } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';
import * as actions from '../../actions';
import axios from 'axios';
import SaveChangesModal from '../../components/SaveChangesModal';

const initialValue = {
  group_name: '',
  studentId_List: '',
  group_type: '',
  grade: '',
  video_sdk: '',
};

function CreateUserForm(props) {
  const [mode, setMode] = useState(
    props &&
      props.location &&
      props.location.query &&
      props.location.query.groupId
      ? 'update'
      : 'create'
  );
  const [groupId] = useState(props?.location?.query?.groupId);
  const [packageList, setPackageList] = useState([
    { label: 'LQLive Paid Subscription classes', value: 2 },
    { label: 'Free Trial', value: 1 },
  ]);
  const [packageIdList, setPackageIdList] = useState(null);
  const [openPartialGroupModal, setOpenPartialGroupModal] = useState(null);

  const mainUserSchema = Yup.object().shape({
    // studentId_List:
    //   mode == 'create' &&
    //   Yup.string('Please provide comma separated list of Student IDs').required(
    //     'Please provide Student ID'
    //   ),
    group_name: Yup.string('Please provide Group Name').required(
      'Please provide Group Name'
    ),
  });

  const useStyles = makeStyles((theme) =>
    createStyles({
      appBar: {
        position: 'relative',
      },
      flex: {
        flex: 1,
      },
      rightIcon: {
        marginLeft: theme.spacing(1),
      },
      formControl: {
        margin: `${theme.spacing(2)}px 0px`,
        width: 450,
      },
      grid: {
        margin: '8px',
        padding: '0px 16px',
      },
      error: {
        color: 'red',
        fontSize: '16px',
        margin: `${theme.spacing(2)}px 8px`,
      },
    })
  );

  const [ref, setRef] = useState(React.createRef());
  useEffect(() => {
    groupId && getGroupDetails(groupId), getPackageList();
  }, [ref]);

  const classes = styles();

  function getPackageList() {
    const admin_id = props && props.user.user_id;
    axios.get(`/v1/admin/${admin_id}/assign-package/`).then((res) => {
      if (res && res.status == 200) {
        let temp = [];
        let format = {
          label: '',
          value: '',
        };
        const data = res && res.data && res.data.data;
        data &&
          data.map((packageData) => {
            format = {
              value: packageData.id,
              label: packageData.id + '. ' + packageData.name,
            };
            format == undefined ? null : temp.push(format);
          });
        setPackageIdList(temp);
      }
    });
  }

  async function getGroupDetails(groupId) {
    const user_id = props.user && props.user.user_id;
    axios
      .get(`/v1/admin/${user_id}/group/${groupId}/details/`)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          const data = response.data.data;

          let newValues;
          let userlist = [];
          data.student_details.map((details) =>
            userlist.push(details.student_id)
          );

          newValues = {
            ...data,
            group_name: data.name,
            studentId_List: userlist.join(', '),
            packages: data.group_type,
            package_id: data.package,
            grade: data.grade,
          };
          if (ref && ref.current) {
            ref.current.setValues(newValues);
          }
          return Promise.resolve();
        }
      })
      .catch((error) => {
        props.enqueueSnackbar('User Details Could not be fetched', {
          variant: 'warning',
        });
        props.hideTopLoader();
        console.log(error);
      });
  }

  function submitForm(formikProps, formikActions) {
    const user_id = props?.user?.user_id;
    let url = `/v1/admin/${user_id}/group/create/`;
    if (mode == 'update') {
      url = `/v1/admin/${user_id}/group/${groupId}/update/`;
    }
    let api_data = {
      student_ids: formikProps.studentId_List
        ? formikProps.studentId_List.split(',').map(function (item) {
            return parseInt(item, 10);
          })
        : [],
      name: formikProps.group_name,
      group_type: formikProps.packages,
      class_id: formikProps.grade,
      package_id: formikProps.package_id,
      video_sdk: 'agora' || formikProps.video_sdk,
    };
    axios
      .post(url, api_data, {
        headers: { 'Session-Token': props.user && props.user.session_token },
      })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 202) &&
          response.data
        ) {
          if (mode == 'update') {
            props.enqueueSnackbar('User Updated Successfully', {
              variant: 'success',
            });
            formikActions.setSubmitting(false);
          } else {
            props.enqueueSnackbar('User Created Successfully', {
              variant: 'success',
            });
            formikActions.resetForm(initialValue);
            formikActions.setSubmitting(false);
          }
          return Promise.resolve();
        }
        return Promise.reject('Status code not correct');
      })
      .catch((error) => {
        formikActions.setSubmitting(false);

        if (
          error?.response?.data?.data?.error &&
          error.response.data.data.error[0]
        ) {
          const errors = error.response.data.data.error;
          errors.map((errorDisplay) =>
            props.enqueueSnackbar(errorDisplay || 'error', { variant: 'error' })
          );
        } else {
          props.enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
      });
  }
  const renderPartialGroupData = () => {
    let name, studentId, sessionOrder;

    return (
      <List>
        <ListItem>
          <MaterailUiTextField
            value={name}
            onChange={(event) => {
              const partialGroupData = openPartialGroupModal;
              partialGroupData.name = event.target.value;
              name = event.target.value;
              setOpenPartialGroupModal(partialGroupData);
            }}
            label='Group Name'
            variant='outlined'
          />
        </ListItem>
        <ListItem>
          <FormControl style={{ minWidth: 220 }}>
            <InputLabel>Select Student Id</InputLabel>
            <MaterailUiSelect
              onChange={(event) => {
                const partialGroupData = openPartialGroupModal;
                partialGroupData.studentId = event.target.value;
                studentId = event.target.value;
                setOpenPartialGroupModal(partialGroupData);
              }}
              value={studentId}
            >
              {openPartialGroupModal.studentList?.map((data) => (
                <MenuItem value={data}>{data}</MenuItem>
              ))}
            </MaterailUiSelect>
          </FormControl>
        </ListItem>
        <ListItem>
          <MaterailUiTextField
            value={sessionOrder}
            onChange={(event) => {
              let partialGroupData = openPartialGroupModal;
              partialGroupData.sessionOrder = event.target.value;
              sessionOrder = event.target.value;
              setOpenPartialGroupModal(partialGroupData);
            }}
            label='Session Order'
            variant='outlined'
            type='number'
          />
        </ListItem>
      </List>
    );
  };

  function createPartialGroup() {
    const admin_id = props?.user?.user_id;
    axios
      .post(`/v1/admin/${admin_id}/group/create/partial/`, {
        group_id: groupId,
        name: openPartialGroupModal.name,
        student_id: parseInt(openPartialGroupModal.studentId, 10),
        session_order: parseInt(openPartialGroupModal.sessionOrder, 10),
      })
      .then((response) => {
        if (response && response.status == 200) {
          props.enqueueSnackbar(
            response?.data?.data?.message ||
              'Partial Group Created Successfully',
            { variant: 'success' }
          );
          setOpenPartialGroupModal(false);
          getGroupDetails(groupId);
        }
      })
      .catch((err) => {
        const data =
          err?.response?.data?.data?.error &&
          err?.response?.data?.data?.error[0];
        props.enqueueSnackbar(data || 'Something Went Wrong', {
          variant: 'error',
        });
      });
  }

  return (
    <div style={{ minHeight: '70vh' }}>
      {openPartialGroupModal ? (
        <SaveChangesModal
          openModal={openPartialGroupModal}
          closeModal={() => setOpenPartialGroupModal(false)}
          displayData={renderPartialGroupData()}
          onSave={() => {
            createPartialGroup();
          }}
          text={'Create Partial Group'}
          style={{
            minHeight: '30vh',
            maxHeight: '80vh',
            overflowY: 'auto',
            gap: '1vw',
          }}
        />
      ) : null}
      <Formik
        initialValues={initialValue}
        onSubmit={submitForm}
        validationSchema={mainUserSchema}
        innerRef={ref}
        validateOnChange
        render={(formProps) => {
          return (
            <Form className={classes.main}>
              {mode == 'update' ? (
                <Button
                  color='primary'
                  variant='outlined'
                  style={{ alignSelf: 'self-end' }}
                  disabled={
                    formProps.values.studentId_List?.split(', ')?.length < 2
                  }
                  onClick={() =>
                    setOpenPartialGroupModal({
                      name: '',
                      sessionOrder: null,
                      studentId: null,
                      studentList: formProps.values.studentId_List.split(', '),
                    })
                  }
                >
                  <Icon>add</Icon>
                  Create Partial Grpup
                </Button>
              ) : null}

              <List>
                <ListItem>
                  <Typography variant='h4'>
                    {mode == 'update' ? 'Update Group' : 'Create Group'}
                  </Typography>
                </ListItem>
              </List>
              <List>
                <ListItem>
                  <Field
                    name='group_name'
                    className={classes.fullName}
                    type='text'
                    component={TextField}
                    label='Group Name'
                    variant='outlined'
                    placeholder='Group Name'
                  />
                </ListItem>

                <ListItem>
                  <Field
                    name='studentId_List'
                    className={classes.fullName}
                    type='text'
                    component={TextField}
                    label='Student ID'
                    variant='outlined'
                    placeholder='Student ID'
                  />
                </ListItem>
                {mode == 'update' ? (
                  <ListItem>
                    <Field
                      name='packages'
                      className={classes.fullName}
                      type='text'
                      component={TextField}
                      variant='outlined'
                      disabled
                    />
                  </ListItem>
                ) : (
                  <>
                    <ListItem>
                      <InputLabel shrink htmlFor='rm'>
                        Group Type
                      </InputLabel>
                    </ListItem>
                    <ListItem>
                      <Field
                        name='packages'
                        className={classes.formControl}
                        // selectList={packageList}
                        component={Select}
                        // label='Package'
                        variant='outlined'
                        helperText='Package'
                        value={
                          ref.current &&
                          ref.current.values &&
                          ref.current.values.packages
                        }
                        style={{ width: '100%' }}
                      >
                        <MenuItem value={1}>Free Trial</MenuItem>
                        <MenuItem value={2}>Paid</MenuItem>
                      </Field>
                      <ErrorMessage
                        className={classes.error}
                        component='div'
                        name='packages'
                      />
                    </ListItem>
                  </>
                )}
                <ListItem>
                  <InputLabel shrink htmlFor='grade'>
                    Grade
                  </InputLabel>
                </ListItem>
                <ListItem>
                  <Field
                    name='grade'
                    className={classes.formControl}
                    // selectList={packageList}
                    component={mode == 'update' ? TextField : Select}
                    disabled={mode == 'update'}
                    // label='Package'
                    variant='outlined'
                    helperText={mode == 'update' || 'Select Grade'}
                    value={
                      ref.current &&
                      ref.current.values &&
                      ref.current.values.grade
                    }
                    style={{ width: '100%' }}
                  >
                    <MenuItem value={14}>Sr KG</MenuItem>
                    <MenuItem value={1}>1st Grade</MenuItem>
                    <MenuItem value={2}>2nd Grade</MenuItem>
                    <MenuItem value={3}>3rd Grade</MenuItem>
                    <MenuItem value={4}>4th Grade</MenuItem>
                    <MenuItem value={5}>5th Grade</MenuItem>
                    <MenuItem value={6}>6th Grade</MenuItem>
                    <MenuItem value={7}>7th Grade</MenuItem>
                    <MenuItem value={8}>8th Grade</MenuItem>
                    <MenuItem value={9}>9th Grade</MenuItem>
                  </Field>

                  <ErrorMessage
                    className={classes.error}
                    component='div'
                    name='grade'
                  />
                </ListItem>
                <ListItem>
                  <InputLabel shrink htmlFor='package_id'>
                    Package
                  </InputLabel>
                </ListItem>
                <ListItem>
                  <Field
                    name={'package_id'}
                    className={classes.formControl}
                    selectList={packageIdList}
                    component={mode == 'update' ? TextField : Select}
                    variant='outlined'
                    disabled={mode == 'update'}
                    value={
                      ref.current &&
                      ref.current.values &&
                      ref.current.values.package_id
                    }
                    style={{ width: '100%' }}
                  >
                    {packageIdList &&
                      packageIdList.map((packageList, index) => {
                        return ref.current &&
                          ref.current.values &&
                          ref.current.values.packages &&
                          ref.current.values.packages == 1
                          ? packageList.label.includes('Free') && (
                              <MenuItem value={packageList.value}>
                                {packageList.label}
                              </MenuItem>
                            )
                          : ref.current &&
                            ref.current.values &&
                            ref.current.values.packages &&
                            ref.current.values.packages == 2
                          ? !packageList.label.includes('Free') && (
                              <MenuItem value={packageList.value}>
                                {packageList.label}
                              </MenuItem>
                            )
                          : null;
                      })}
                  </Field>
                  <ErrorMessage
                    className={classes.error}
                    component='div'
                    name='packages'
                  />
                </ListItem>
                {mode == 'update' && false ? (
                  <>
                    <ListItem>
                      <InputLabel shrink>Video SDK</InputLabel>
                    </ListItem>
                    <ListItem>
                      <Field
                        name='video_sdk'
                        className={classes.formControl}
                        component={Select}
                        variant='outlined'
                        helperText='Video SDK'
                        style={{ width: '100%' }}
                      >
                        <MenuItem value={'agora'}>Agora</MenuItem>
                        <MenuItem value={'zoom'}>Zoom</MenuItem>
                      </Field>
                    </ListItem>
                  </>
                ) : null}
              </List>
              <Button color='primary' variant='contained' type='submit'>
                {mode == 'update' ? 'Update Group' : 'Create Group'}
              </Button>
            </Form>
          );
        }}
      />
    </div>
  );
}

const styles = makeStyles((theme) => ({
  fullWidth: { width: '100%' },
  lables: {},
  formComponent: {
    width: '100%',
    padding: 15,
  },
  grades: {
    minWidth: 200,
    maxWidth: 300,
  },
  formControl: {
    minWidth: 500,
    maxWidth: 500,

    // marginLeft: theme.spacing.unit * 2
  },
  school: {
    margin: `${theme.spacing(2)}px 0px`,
    width: 500,
  },
  fullName: {
    margin: `${theme.spacing(2)}px 0px`,
    width: 500,
  },
  longText: {
    minWidth: 400,
  },
  twoForms: {
    display: 'flex',
    flexDirection: 'row',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '60vh',
  },
}));

function mapStateToProps(state) {
  const classMap = {};
  state.lists.classList &&
    state.lists.classList.forEach((d) => (classMap[d.value] = d));
  /*   const countryCodeList = Object.keys(state.lists.countryMap).map(
        key => state.lists.countryMap[key],
    ); */
  return {
    classList: state.lists.classList,
    user: state.user,
    classMap,
    lists: state.lists,
  };
}

export default withSnackbar(
  connect(mapStateToProps, actions, null)(CreateUserForm)
);
