import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  List,
  Grid,
  ListItem,
  Box,
  Checkbox,
  Icon,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
  Modal,
  Slider,
  TextField as MaterialUiTextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { TextField } from 'formik-material-ui';
import MySelect from '../../components/FormikSelect';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';
import axios from 'axios';

import S3UploadButton from '../../components/S3UploadButton';
import ExpandedReview from '../../components/Expanded Review/expanded_review';
import LinkQuestion from '../../components/LinkQuestion';
import SingleSelect from '../../components/AutoSuggestSelectBox/SingleSelect';
import Activity from '../../components/QuestionPreview/activityArena';

const initialValue = {
  activity_type: '',
  answer: '',
  id: '',
  marks: 50,
  options: '',
  question: { image: '', text: '', media_type: null, media: '' },
  time: '',
  solution: {
    sol_img: '',
    sol_text: '',
  },
  objectives: '',
};

const mediaTypeList = [
  {
    value: 'image',
    label: 'Image',
  },
  {
    value: 'audio',
    label: 'Audio',
  },
  {
    value: 'video',
    label: 'Video',
  },
];

function EditQuestion(props) {
  const [questionId] = useState(
    props &&
      props.location &&
      props.location.query &&
      props.location.query.questionId
  );
  const [questionImage, handleQuestionImage] = useState(null);
  const [answerCheck, handleAnswerCheck] = useState(null);
  const [mediaType, setMediaType] = useState('image');

  const useStyles = makeStyles((theme) =>
    createStyles({
      appBar: {
        position: 'relative',
      },
      flex: {
        flex: 1,
      },
      rightIcon: {
        marginLeft: theme.spacing(1),
      },
      formControl: {
        margin: `${theme.spacing(2)}px 0px`,
        width: 450,
      },
      grid: {
        margin: '8px',
        padding: '0px 16px',
      },
      error: {
        color: 'red',
        fontSize: '16px',
        margin: `${theme.spacing(2)}px 8px`,
      },
      Modal: {
        background: '#fff',
        borderRadius: 5,
        width: '70vw',
        maxHeight: '70vh',
        // margin: '10vh auto',
        boxShadow:
          '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        padding: 20,

        // overflowY:'scroll'
      },
    })
  );
  const [ref, setRef] = useState(React.createRef());
  const [details, setDetails] = useState(null);
  const [deleteMapping, setDeleteMapping] = React.useState(null);
  const [createMapping, setCreateMapping] = React.useState(null);
  const [questionPreview, setQuestionPreview] = React.useState(false);
  const [previewModalOpened, setPreviewModalOpened] = React.useState(false);
  const [difficulty, setDifficulty] = React.useState(0);
  const [objectives, setObjectives] = React.useState(null);

  useEffect(() => {
    getQuestionDetails(questionId);
  }, [ref]);

  const classes = styles();
  function uploadFile(fileData, dirName, fileName, optionName, formikActions) {
    const file = fileData;
    const key = dirName ? `${dirName}/${fileName}` : `${fileName}`;
    const user_id = props && props.user && props.user.user_id;
    var bodyFormData = new FormData();
    bodyFormData.append('file', file);
    bodyFormData.append('content_type', 'image');
    bodyFormData.append('bucket_name', 'lq-live');
    bodyFormData.append('key', key);
    axios({
      method: 'post',
      url: `v1/admin/${user_id}/s3/upload/`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        if (response.status === 200 && response.data) {
          response.data.data &&
            response.data.data &&
            formikActions.setFieldValue(optionName, response.data.data);
        }
      })
      .catch((error) => {
        console.log('S3 upload', error && error.response);
      });
  }

  function getActivityData(deleteActivityData) {
    axios
      .post(
        `/v1/admin/${props.user.user_id}/content/session/disable-question-mapping/`,
        {
          action: 'get',
          question_id: questionId,
          session_activity_id: deleteActivityData.session_activity_id,
        }
      )
      .then((res) => {
        if (res?.data?.data && res?.status == 200) {
          setDeleteMapping({
            ...deleteActivityData,
            ...res.data.data,
            duration: res.data.data?.duration < 5 ? 5 : res.data.data?.duration,
          });
        }
      });
  }
  function deleteMappingModal() {
    const admin_id = props.user && props.user.user_id;
    return (
      <Modal
        open={deleteMapping}
        onClose={() => {
          setDeleteMapping(null);
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            margin: '100px 25vw',
            width: '50vw',
            zIndex: 10,
            position: 'absolute',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: 50,
              gap: 20,
            }}
          >
            <List style={{ fontSize: 18 }}>
              Do you want to delete this question mapping?
            </List>
            <ListItem>Grade : {deleteMapping.grade}</ListItem>
            <ListItem>Session : {deleteMapping.session}</ListItem>
            <ListItem>Activity Name : {deleteMapping.activity_name}</ListItem>
            <ListItem>
              Question number : {deleteMapping.question_number}
            </ListItem>
            <MaterialUiTextField
              label='Duration'
              variant='outlined'
              type='number'
              disabled={!deleteMapping?.edit_duration}
              value={deleteMapping?.duration}
              onChange={(event) => {
                const activateActivityTempData = JSON.parse(
                  JSON.stringify(deleteMapping)
                );
                activateActivityTempData.duration = parseInt(
                  event.target.value
                );
                setDeleteMapping(activateActivityTempData);
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => setDeleteMapping(null)}
              >
                Cancel
              </Button>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => {
                  axios
                    .post(
                      `/v1/admin/${admin_id}/content/session/disable-question-mapping/`,
                      {
                        action: 'update',
                        question_id: questionId,
                        class_id: deleteMapping.grade,
                        session: deleteMapping.session,
                        activity_name: deleteMapping.activity_name,
                        package_id: deleteMapping.package,
                        session_activity_id: deleteMapping.session_activity_id,
                        duration: deleteMapping.duration,
                      }
                    )
                    .then((res) => {
                      if (res && res.status && res.status == 200) {
                        props.enqueueSnackbar(
                          (res &&
                            res.data &&
                            res.data.data &&
                            res.data.data.message) ||
                            'Question mapping deleted successfully',
                          { variant: 'success' }
                        );
                        setDeleteMapping(null);
                        getQuestionDetails(questionId);
                      }
                    })
                    .catch((error) => {
                      props.enqueueSnackbar(
                        error.response &&
                          error.response.data &&
                          error.response.data.data.error &&
                          error.response.data.data.error &&
                          error.response.data.data.error[0],
                        { variant: 'error' }
                      );
                    });
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  const schema = Yup.object().shape({
    marks: Yup.number('Please enter numeric value').required(
      'Please enter marks'
    ),
    time: Yup.number('Please enter numeric value').required(
      'Please enter time'
    ),
    question: Yup.object({
      text: Yup.string().required(`Please enter question text`),
    }),
    // options: Yup.array().of(Yup.object({
    //   text: optionType == 'Text' ? Yup.string().required("Please enter option text") : Yup.string().nullable(),
    //   image: optionType == 'Image' ? Yup.string().ensure().required("Please upload option image") : Yup.string().nullable()
    // }))
  });

  async function getQuestionDetails(questionId) {
    const user_id = props.user && props.user.user_id;
    axios
      .get(`/v1/admin/${user_id}/question/${questionId}/edit/`)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          const data = response && response.data && response.data.data;
          const newValues = data;
          data.marks = data.marks || 50;
          data.time = data.time || '';
          data.objectives = data.objectives || '';
          // data.objectives = data.objectives
          //   ? { label: data.objectives, value: data.objectives }
          //   : null;
          setDetails(data);
          setDifficulty(data.difficulty);
          setObjectives(data.objectives);
          data &&
            data.answer &&
            handleAnswerCheck(
              data.activity_type == 'Multi Select' ||
                data.activity_type == 'Single Select'
                ? eval(data.answer)
                : data.answer
            );
          if (ref && ref.current) {
            ref.current.setValues(newValues);
            const media_type = newValues.question.media_type || 'image';
            setMediaType(media_type);
          }
          return Promise.resolve();
        }
      })
      .catch((error) => {
        props.enqueueSnackbar('Question Details Could not be fetched', {
          variant: 'warning',
        });
        props.hideTopLoader();
        console.log(error);
      });
  }

  function submitForm(formikProps, formikActions) {
    const user_id = props && props.user && props.user.user_id;
    let url = `/v1/admin/${user_id}/question/${questionId}/edit/`;
    let api_data = {
      question_id: questionId,
      question: {
        media_type: mediaType == 'image' ? null : mediaType,
        media: mediaType == 'image' ? null : formikProps.question.media,
        image: (mediaType == 'image' && formikProps.question.image) || null,
        text: formikProps.question.text,
      },
      options:
        formikProps.options &&
        formikProps.options.map(
          (opt, index) =>
            (formikProps.options[index] = {
              text: opt.text ? opt.text : null,
              image: opt.image ? opt.image : null,
              order: opt.order,
            })
        ),
      answer: formikProps.answer
        ? Array.isArray(formikProps.answer) ||
          (eval(formikProps.answer) && Array.isArray(eval(formikProps.answer)))
          ? formikProps.answer
          : '[' + formikProps.answer + ']'
        : null,
      time: eval(formikProps.time),
      marks: eval(formikProps.marks),
      sol_img: formikProps.solution.sol_img || null,
      sol_text: formikProps.solution.sol_text || null,
      difficulty: difficulty || null,
      objectives: objectives || null,
    };
    axios
      .post(url, api_data)
      .then((response) => {
        if (response.status === 200 && response.data) {
          props.enqueueSnackbar(
            (response.data &&
              response.data.data &&
              response.data.data.message) ||
              'Question updated successfully',
            { variant: 'success' }
          );

          formikActions.setSubmitting(false);
          getQuestionDetails(questionId);
          return Promise.resolve();
        }
        return Promise.reject('Status code not correct');
      })
      .catch((error) => {
        console.log('error', error);
        formikActions.setSubmitting(false);

        if (
          error?.response?.data?.data?.error &&
          error.response.data.data.error[0]
        ) {
          const errors = error.response.data.data.error;
          errors.map((errorDisplay) =>
            props.enqueueSnackbar(errorDisplay || 'error', { variant: 'error' })
          );
        } else {
          props.enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
      });
  }

  return (
    <div style={{ minHeight: '70vh' }}>
      <Formik
        initialValues={initialValue}
        onSubmit={submitForm}
        validationSchema={schema}
        innerRef={ref}
        validateOnChange
        render={(formProps) => {
          const form_values = ref && ref.current && ref.current.values;
          const questionValue = {
            type: details && details.activity_type,
            order: 1,
            text:
              form_values && form_values.question && form_values.question.text,
            image:
              form_values && form_values.question && form_values.question.image,
            user_response: answerCheck,
            answer: answerCheck,
            options: form_values && form_values.options,
            solution_image: form_values && form_values.sol_img,
            solution_text: form_values && form_values.sol_text,
            timed_out: false,
            is_correct: true,
            media:
              form_values && form_values.question && form_values.question.media,
            media_type: mediaType,
          };
          const data = {
            questions: [
              {
                type: 'single_select',
                text:
                  form_values &&
                  form_values.question &&
                  form_values.question.text,
                image:
                  form_values &&
                  form_values.question &&
                  form_values.question.image,
                options: form_values && form_values.options,
                marks: form_values && form_values.marks,
                time: form_values && form_values.time,
                currentQuestion: props.questionListlength
                  ? props.questionListlength + 1
                  : 1,
                totalQuestions: props.questionListlength
                  ? props.questionListlength + 1
                  : 10,
                media:
                  form_values &&
                  form_values.question &&
                  form_values.question.media,
                media_type: mediaType,
              },
            ],
          };
          async function pasteFromClipboard(event, optionName, order) {
            navigator.permissions
              .query({ name: 'clipboard-read' })
              .then(async (result) => {
                // If permission to read the clipboard is granted or if the user will
                // be prompted to allow it, we proceed.

                if (result.state == 'granted' || result.state == 'prompt') {
                  const clipboardData =
                    navigator &&
                    navigator.clipboard &&
                    (await navigator.clipboard.read());

                  let image_type;
                  const item =
                    clipboardData &&
                    clipboardData.find((item) =>
                      item.types.some((type) => {
                        if (type.startsWith('image/')) {
                          image_type = type;
                          return true;
                        }
                      })
                    );
                  var file = item && (await item.getType(image_type));
                  const dirName = 'option_images';
                  const fileName =
                    moment().format('YYYYMMDDHHmmssSSS') +
                    '_O' +
                    order +
                    '_QID_' +
                    questionId +
                    '_AID_' +
                    props.adminId;
                  if (file) {
                    uploadFile(
                      file,
                      dirName,
                      fileName,
                      optionName + '.image',
                      formProps
                    );
                  }
                }
              });
          }
          return (
            <Form className={classes.main}>
              {questionPreview && details && (
                <ExpandedReview
                  question={questionValue}
                  close={() => setQuestionPreview(false)}
                />
              )}
              <Modal
                open={previewModalOpened}
                onClose={() => setPreviewModalOpened(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
              >
                <Box
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#fff',
                    border: '2px solid #000',
                    boxShadow: 24,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                  }}
                >
                  <Activity
                    data={data}
                    onClose={() => setPreviewModalOpened(false)}
                  />
                </Box>
              </Modal>
              <List>
                <ListItem>
                  <Grid container spacing={4}>
                    <Grid item>QuestionId : {details && details.id}</Grid>
                    <Grid item>
                      Activity Type : {details && details.activity_type}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setQuestionPreview(true)}
                      >
                        Preview Summary
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setPreviewModalOpened(true)}
                      >
                        Preview Question
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setCreateMapping(true)}
                      >
                        <Icon style={{ marginRight: 10 }}>add_link_icon</Icon>
                        Link Question
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container spacing={4}>
                    {details && details.created_by && (
                      <Grid item>
                        Created By : {details && details.created_by}
                      </Grid>
                    )}
                    {details && details.created_at && (
                      <Grid item>
                        Created At :{' '}
                        {details &&
                          details.created_at &&
                          new Date(details.created_at).toLocaleString('en-IN', {
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={4}>
                    {details && details.updated_by && (
                      <Grid item>
                        Updated By : {details && details.updated_by}
                      </Grid>
                    )}
                    {details && details.updated_at && (
                      <Grid item>
                        Updated At :{' '}
                        {details &&
                          details.updated_at &&
                          new Date(details.updated_at).toLocaleString('en-IN', {
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {details && details.classes && details.classes[0] && (
                  <ListItem>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          {Object.getOwnPropertyNames(
                            details && details.classes && details.classes[0]
                          ).map((header, i) => {
                            const headername = header.replaceAll('_', ' ');
                            return (
                              <TableCell className={classes.tablecell}>
                                {headername.charAt(0).toUpperCase() +
                                  headername.slice(1)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {details &&
                          details.classes.map((rows, index) => (
                            <TableRow>
                              {Object.getOwnPropertyNames(
                                details && details.classes && details.classes[0]
                              ).map((header, i) => {
                                const cell =
                                  header == 'date' ||
                                  header == 'date_of_payment'
                                    ? eval('rows.' + header) &&
                                      new Date(
                                        eval('rows.' + header)
                                      ).toLocaleString('en-IN', {
                                        year: '2-digit',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      })
                                    : eval('rows.' + header);
                                return (
                                  <TableCell className={classes.tablecell}>
                                    {cell}
                                  </TableCell>
                                );
                              })}

                              <Button
                                variant='outlined'
                                style={{ marginLeft: 10 }}
                                color='primary'
                                onClick={() =>
                                  getActivityData(details.classes[index])
                                }
                              >
                                <Icon style={{ marginRight: 10 }}>
                                  link_off_icon
                                </Icon>
                                Unlink Question
                              </Button>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    {deleteMapping ? deleteMappingModal() : null}
                  </ListItem>
                )}
                {createMapping ? (
                  <LinkQuestion
                    onClose={() => setCreateMapping(null)}
                    questionId={questionId}
                    onSuccess={() => {
                      setCreateMapping(null), getQuestionDetails(questionId);
                    }}
                    createMapping={createMapping}
                  />
                ) : null}
                <ListItem>
                  <Grid style={{ width: '50%' }}>
                    <div
                      style={{
                        width: '80%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div>Difficulty Level</div>
                      <Slider
                        valueLabelDisplay='auto'
                        min={0}
                        max={3}
                        value={difficulty}
                        onChangeCommitted={(event, newValue) =>
                          setDifficulty(newValue)
                        }
                        marks={[
                          {
                            value: 0,
                            label: 'None',
                          },
                          {
                            value: 1,
                            label: 'Easy',
                          },
                          {
                            value: 2,
                            label: 'Medium',
                          },
                          {
                            value: 3,
                            label: 'Difficult',
                          },
                        ]}
                        track={false}
                      />
                    </div>
                  </Grid>
                  <Grid style={{ width: '50%', zIndex: 10 }}>
                    <Autocomplete
                      options={form_values?.objective_suggestions?.map(
                        (option) => option
                      )}
                      freeSolo
                      onChange={(event) => {
                        formProps.setFieldValue(
                          'objectives',
                          event.target.textContent
                        );
                        setObjectives(event.target.textContent);
                      }}
                      value={objectives}
                      renderInput={(params) => (
                        <MaterialUiTextField
                          {...params}
                          label='Objectives'
                          variant={'outlined'}
                          multiline
                          rows={2}
                        />
                      )}
                    />
                    {/* <Field
                      name='objectives'
                      component={MySelect}
                      selectList={form_values?.objective_suggestions?.map(
                        (suggestion) =>
                          (suggestion = {
                            label: suggestion,
                            value: suggestion,
                          })
                      )}
                      label='Objectives'
                      variant='outlined'
                      placeholder='Objectives'
                      fullWidth
                      isClearable={true}
                      style={{ width: '100%' }}
                    /> */}
                  </Grid>
                </ListItem>
                <ListItem style={{ gap: 20 }}>
                  <Field
                    name='marks'
                    className={classes.fullName}
                    type='text'
                    component={TextField}
                    label='Marks'
                    variant='outlined'
                    placeholder='Marks'
                    disabled
                  />
                  <Field
                    name='time'
                    className={classes.fullName}
                    type='text'
                    component={TextField}
                    label='Time'
                    variant='outlined'
                    placeholder='Time'
                  />
                </ListItem>
                <ListItem>
                  <Grid style={{ width: '100%' }}>
                    <Field
                      name='question.text'
                      className={classes.fullName}
                      type='text'
                      component={TextField}
                      label='Question Text'
                      variant='outlined'
                      placeholder='Question Text'
                      fullWidth
                      style={{ width: '100%' }}
                      multiline
                      rows={4}
                    />

                    <Grid
                      container
                      style={{
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                      }}
                    >
                      <SingleSelect
                        name='Media Type'
                        onChange={(event) => {
                          setMediaType(event.value);
                          formProps.setFieldValue('question.media', null);
                          formProps.setFieldValue('question.image', null);
                        }}
                        selectList={mediaTypeList}
                        style={{ cursor: 'pointer' }}
                        value={{
                          label:
                            mediaType.charAt(0).toUpperCase() +
                            mediaType.slice(1),
                          value: mediaType,
                        }}
                      />

                      <div
                        style={{
                          display:
                            form_values &&
                            form_values.question &&
                            (form_values.question.media ||
                              form_values.question.image)
                              ? 'none'
                              : 'flex',
                        }}
                      >
                        <S3UploadButton
                          bucketName='lq-live'
                          fileType={mediaType}
                          title={'Upload Question ' + mediaType}
                          dirName={
                            mediaType == 'image'
                              ? 'question_images'
                              : `question_${mediaType}`
                          }
                          fileName={
                            moment().format('YYYYMMDDHHmmssSSS') +
                            '_QID_' +
                            questionId +
                            '_AID_' +
                            props.adminId
                          }
                          setField={(value) =>
                            formProps.setFieldValue(
                              mediaType == 'image'
                                ? 'question.image'
                                : 'question.media',
                              value
                            )
                          }
                        />
                      </div>
                      {form_values &&
                        form_values.question &&
                        form_values.question.media && (
                          <Button
                            variant='contained'
                            color='primary'
                            style={{ margin: 6 }}
                            onClick={() =>
                              formProps.setFieldValue('question.media', null)
                            }
                          >
                            Remove {mediaType}
                          </Button>
                        )}
                      <div style={{ justifyContent: 'center' }}>
                        {form_values &&
                          form_values.question &&
                          form_values.question.image && (
                            <div style={{ position: 'relative' }}>
                              <img
                                src={form_values.question.image}
                                style={{ maxHeight: 200 }}
                              />
                              <IconButton
                                variant='contained'
                                color='primary'
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                }}
                                onClick={() =>
                                  formProps.setFieldValue(
                                    'question.image',
                                    null
                                  )
                                }
                              >
                                <Icon>close</Icon>
                              </IconButton>
                            </div>
                          )}
                        {form_values &&
                        form_values.question &&
                        form_values.question.media ? (
                          mediaType == 'audio' ? (
                            <audio controls>
                              <source src={form_values.question.media} />
                            </audio>
                          ) : (
                            <video width='auto' height='200px' controls>
                              <source src={form_values.question.media} />
                            </video>
                          )
                        ) : null}
                      </div>

                      <Grid item style={{ display: 'none' }}>
                        <Field
                          name='question.image'
                          className={classes.fullName}
                          type='text'
                          component={TextField}
                          label='Question Image URL'
                          variant='outlined'
                          placeholder='Question Image'
                          // onChange={handleQuestionImage}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                {details && details.options && details.options[0] && (
                  <ListItem>Options</ListItem>
                )}
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {form_values &&
                    form_values.options &&
                    form_values.options.map((option, index) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '50%',
                          margin: '1% auto',
                        }}
                      >
                        <ListItem
                          style={{
                            borderRadius: 5,
                            border: 'none',
                            minHeight: 'none',
                          }}
                        >
                          {details.activity_type == 'Multi Select' ||
                          details.activity_type == 'Single Select' ? (
                            <Checkbox
                              style={{
                                position: 'absolute',
                                top: 15,
                                left: 15,
                                color: 'green',
                              }}
                              checked={
                                answerCheck &&
                                answerCheck.toString().includes(option.order)
                              }
                              value={option.order}
                              onChange={(event) => {
                                let answer = answerCheck;
                                if (details.activity_type == 'Single Select') {
                                  answer[0] = eval(event.target.value);
                                } else {
                                  if (
                                    answerCheck
                                      .toString()
                                      .indexOf(option.order) > -1
                                  ) {
                                    answer.splice(
                                      answer.indexOf(eval(event.target.value)),
                                      1
                                    );
                                  } else {
                                    answer.push(eval(event.target.value));
                                  }
                                }
                                handleAnswerCheck(answer);
                                formProps.setFieldValue(
                                  'answer',
                                  answerCheck.toString()
                                );
                                // formProps.setFieldTouched('options['+index+'].text', true)
                              }}
                            />
                          ) : null}

                          {form_values.options[index].image ? (
                            <div
                              style={{ position: 'relative', width: '100%' }}
                            >
                              <img
                                src={form_values.options[index].image}
                                style={{ maxHeight: 180, marginLeft: 50 }}
                              />
                              <IconButton
                                variant='contained'
                                color='primary'
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                }}
                                onClick={() =>
                                  formProps.setFieldValue(
                                    `options[${index}].image`,
                                    null
                                  )
                                }
                              >
                                <Icon>close</Icon>
                              </IconButton>
                            </div>
                          ) : (
                            <div style={{ width: '100%' }}>
                              <Field
                                name={'options[' + index + '].text'}
                                className={classes.optionStyle}
                                type='text'
                                component={TextField}
                                // label={'Option'+option.order}
                                variant='outlined'
                                inputProps={{
                                  style: {
                                    padding: 15,
                                  },
                                }}
                                onPaste={(event) =>
                                  pasteFromClipboard(
                                    event,
                                    'options[' + index + ']',
                                    option.order
                                  )
                                }
                                style={{
                                  margin: '0px auto',
                                  padding: 0,
                                  width: '100%',
                                }}
                                placeholder={'Write here..'}
                                multiline
                                rows={5}
                              />
                              <Grid
                                item
                                style={{
                                  position: 'absolute',
                                  bottom: 10,
                                  right: 10,
                                }}
                              >
                                <S3UploadButton
                                  bucketName='lq-live'
                                  fileType='img'
                                  title={'Option ' + option.order + ' Image'}
                                  dirName='option_images'
                                  directUpload={true}
                                  fileName={
                                    moment().format('YYYYMMDDHHmmssSSS') +
                                    '_O' +
                                    option.order +
                                    '_QID_' +
                                    questionId +
                                    '_AID_' +
                                    props.adminId
                                  }
                                  setField={(value) =>
                                    formProps.setFieldValue(
                                      'options[' + index + '].image',
                                      value
                                    )
                                  }
                                />
                              </Grid>
                            </div>
                          )}
                        </ListItem>
                      </div>
                    ))}
                </div>
                {details &&
                details.activity_type &&
                (details.activity_type == 'Multi Select' ||
                  details.activity_type == 'Single Select') ? null : (
                  <ListItem>
                    <Field
                      name='answer'
                      className={classes.fullName}
                      type='text'
                      component={TextField}
                      label='Answer'
                      variant='outlined'
                      placeholder='Answer'
                    />
                  </ListItem>
                )}

                <ListItem>
                  <Grid
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Field
                      name='solution.sol_text'
                      className={classes.fullName}
                      type='text'
                      component={TextField}
                      label='Solution Text'
                      variant='outlined'
                      placeholder='Solution Text'
                      fullWidth
                      style={{ minWidth: '50%' }}
                      multiline
                      rows={4}
                    />
                    <Grid
                      container
                      style={{
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        width: 'auto',
                        display:
                          form_values && form_values.sol_img ? 'none' : 'flex',
                      }}
                    >
                      <Grid item style={{}}>
                        {/* <Button variant="outlined" color="secondary">Upload Image</Button> */}
                        <S3UploadButton
                          bucketName='lq-live'
                          fileType='img'
                          title='Upload Solution Image'
                          dirName='solution_images'
                          fileName={
                            moment().format('YYYYMMDDHHmmssSSS') +
                            '_QID_' +
                            questionId +
                            '_S' +
                            '_AID_' +
                            props.adminId
                          }
                          setField={(value) =>
                            formProps.setFieldValue('solution.sol_img', value)
                          }
                        />
                      </Grid>

                      <Grid item style={{ display: 'none' }}>
                        <Field
                          name='sol_img'
                          className={classes.fullName}
                          type='text'
                          component={TextField}
                          label='Solution Image URL'
                          variant='outlined'
                          placeholder='Solution Image'
                          // onChange={handleQuestionImage}
                        />
                      </Grid>
                    </Grid>
                    {form_values && form_values.solution.sol_img && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={form_values.solution.sol_img}
                          style={{ maxHeight: 200 }}
                        />
                        <IconButton
                          variant='contained'
                          color='primary'
                          style={{ position: 'absolute', top: 0, right: 0 }}
                          onClick={() =>
                            formProps.setFieldValue('solution.sol_img', null)
                          }
                        >
                          <Icon>close</Icon>
                        </IconButton>
                      </div>
                    )}
                  </Grid>
                </ListItem>
              </List>
              <Button color='primary' variant='contained' type='submit'>
                Update Question
              </Button>
            </Form>
          );
        }}
      />
    </div>
  );
}

const styles = makeStyles((theme) => ({
  Modal: {
    background: '#fff',
    borderRadius: 5,
    width: '70vw',
    maxHeight: '80vh',
    margin: '10vh auto',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    padding: 10,
    overflowY: 'scroll',

    // overflowY:'scroll'
  },
  fullWidth: { width: '100%' },
  lables: {},
  formComponent: {
    width: '100%',
    padding: 15,
  },
  grades: {
    minWidth: 200,
    maxWidth: 300,
  },
  formControl: {
    minWidth: 500,
    maxWidth: 500,

    // marginLeft: theme.spacing.unit * 2
  },
  school: {
    margin: `${theme.spacing(2)}px 0px`,
    width: 500,
  },
  fullName: {
    margin: `${theme.spacing(2)}px 0px`,
    width: 500,
  },
  longText: {
    minWidth: 400,
  },
  twoForms: {
    display: 'flex',
    flexDirection: 'row',
  },
  main: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    minHeight: '60vh',
  },
}));

function mapStateToProps(state) {
  return {
    user: state.user,
    adminId: state.user && state.user.user_id,
  };
}

export default withSnackbar(connect(mapStateToProps)(EditQuestion));
