import React, { Component, Fragment } from 'react';
import { Icon, Tooltip } from '@material-ui/core';

export const CustomSortTooltip = (props) => {
    const titleIconTuple = setTitleAndIcon(props.sortOrder);
    return (
        <Fragment>
            <Tooltip title={titleIconTuple[0]}>
                <Icon onClick={props.onClick} fontSize="small">
                    {titleIconTuple[1]}
                </Icon>
            </Tooltip>
        </Fragment>
    );
};

function ListItem() {
    this.id = -1;
    this.sortOrder = 0;
}

// In JS object's are passed as reference
export const createDefaultSortListData = (ARRAY_SIZE) => {
    const arr = new Array(ARRAY_SIZE);
    for (let i = 0; i < ARRAY_SIZE; i += 1) {
        arr[i] = new ListItem();
    }
    return arr;
};
export const toggleSort = (prevSortOrder) => {
    let newSortOrder;
    switch (prevSortOrder) {
        case 1:
            newSortOrder = prevSortOrder + 1;
            break;
        case 2:
            newSortOrder = prevSortOrder - 1;
            break;
        default:
            newSortOrder = prevSortOrder + 1;
    }
    return newSortOrder;
};

// Provides Title and Icon for the tooltip on state changes
const setTitleAndIcon = (sortOrder) => {
    const titleIconTuple = [];
    switch (sortOrder) {
        case 1:
            titleIconTuple[0] = 'Ascending';
            titleIconTuple[1] = 'arrow_upward';
            break;
        case 2:
            titleIconTuple[0] = 'Descending';
            titleIconTuple[1] = 'arrow_downward';
            break;
        default:
            titleIconTuple[0] = 'Sort';
            titleIconTuple[1] = 'sort';
    }
    return titleIconTuple;
};

export default CustomSortTooltip;
