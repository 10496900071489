import React, { useState } from 'react';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import { Button, TextField, Checkbox } from '@material-ui/core';
import SaveChangesModal from '../SaveChangesModal';

function UpdateResolution(props) {
  const { remark, isResolved } = props;
  const [updateResolutionData, setUpdateResolutionData] = useState(null);
  const resolutionInputField = () => {
    const { feedbackResolutionText, showError, isMarkResolve } =
      updateResolutionData;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <TextField
          label='Remark'
          variant='outlined'
          onChange={(e) => {
            setUpdateResolutionData({
              ...updateResolutionData,
              feedbackResolutionText: e.target.value,
            });
          }}
          value={feedbackResolutionText}
          placeholder='Resolution remark'
          error={showError}
          helperText={showError ? 'Resolution remark field required' : ''}
          multiline
          rows={4}
        />
        <div>
          <Checkbox
            checked={isMarkResolve}
            onChange={(event) => {
              setUpdateResolutionData({
                ...updateResolutionData,
                isMarkResolve: event.target.checked,
              });
            }}
          />
          Mark Resolved
        </div>
      </div>
    );
  };
  const feedbackResolved = () => {
    const { feedbackResolutionText, isMarkResolve } = updateResolutionData;
    const { apiUrl } = props;
    axios
      .post(apiUrl, {
        resolution_remark: feedbackResolutionText,
        is_resolved: isMarkResolve,
      })
      .then((response) => {
        if (response && response.status === 200) {
          props.enqueueSnackbar(
            response?.data?.data?.message || 'Updated Successfully',
            { variant: 'success' }
          );
          setUpdateResolutionData(null);
          props.onSuccess();
        }
      })
      .catch((err) => {
        props.enqueueSnackbar(
          err?.response?.data?.data?.error || 'Something went wrong',
          { variant: 'error' }
        );
      });
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1vw',
      }}
    >
      <div>{remark}</div>
      {!isResolved ? (
        <Button
          onClick={() => {
            setUpdateResolutionData({
              feedbackResolutionText: remark,
              showError: false,
              isMarkResolve: false,
            });
          }}
          style={{ fontSize: '0.75vw', padding: '0.5vw 0.75vw' }}
          color='primary'
          variant='contained'
        >
          Update Resolution
        </Button>
      ) : null}
      {updateResolutionData ? (
        <SaveChangesModal
          openModal={updateResolutionData}
          closeModal={() => {
            setUpdateResolutionData(null);
          }}
          text={'Do you want to resolve feedback?'}
          onSave={() => {
            if (updateResolutionData.feedbackResolutionText) {
              setUpdateResolutionData({
                ...updateResolutionData,
                showError: false,
              });
              feedbackResolved();
            } else
              setUpdateResolutionData({
                ...updateResolutionData,
                showError: true,
              });
          }}
          style={{ width: '40vw', maxHeight: '70vh' }}
          displayData={resolutionInputField()}
        />
      ) : null}
    </div>
  );
}

export default withSnackbar(UpdateResolution);
