import styled from "styled-components";
import base from "../../styles/base";

const Calendar_Scheduler = styled.div`
  font-family: sans-serif;
  text-align: center;
  .calendar {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: rgba(26,41,128, 0.8);
    opacity: 0.75;
    border-radius: 50%;
    box-shadow: inset -2.5px -2.5px 0px -1px rgba(0, 0, 0, 0.4);
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;

    background-color: rgba(26,41,128, 0.8);
    color: #f0f8ff;
    box-shadow: inset -2.5px -2.5px 0px -1px rgba(0, 0, 0, 0.4);
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;

    background-color: rgba(26,41,128, 0.8);
    color: #f0f8ff;
    box-shadow: inset -2.5px -2.5px 0px -1px rgba(0, 0, 0, 0.4);
  }
  .DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: rgba(26,41,128, 0.8);
    font-size: 0.875em;
    cursor: pointer;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: rgba(26,41,128, 0.8);
  }
  .timeslots {
    display: flex;
    flex-direction: column;
    /* justify-content:center; */
    height: 300px;
    overflow-y: scroll;

    ul {
      margin: 1px;

      button {
        background-color: none;
        border: none;
        color: black;
        padding: 14px 32px;
        text-align: center;
        text-decoration: none;
        font-size: 14px;
      }
      /* Button:hover{
    border-bottom: 3px solid #51A0FA;
    } */
    }
    ul:after {
      display: block;
      content: "";
      border-bottom: solid 3px rgba(26,41,128, 0.8);
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }
    ul:hover:after {
      transform: scaleX(1);
    }
  }
  /* .submitButton{
        background-color: rgba(26,41,128, 0.8);
        border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
    }
    .submitButton:hover{
        background-color: #51A0FA;
    } */
  .submitButton {
    letter-spacing: 0.1em;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 35px;
    max-width: 160px;
    position: relative;
    text-decoration: none;
    width: 100%;
  }
  .submitButton:hover {
    text-decoration: none;
  }

  /*submitButton_background*/
  .effect {
    color: #fff;
    border: 2px solid rgba(26,41,128, 0.8);
    box-shadow: 0px 0px 0px 1px rgba(26,41,128, 0.8) inset;
    background-color: rgba(26,41,128, 0.8);
    overflow: hidden;
    position: relative;
    transition: all 0.5s ease-in-out;
  }
  .effect:hover {
    border: 2px solid rgba(26,41,128, 0.8);
    background-color: transparent;
    box-shadow: 0px 0px 0px 4px #eee inset;
  }

  /*submitButton_text*/
  .effect span {
    transition: all 0.2s ease-out;
    z-index: 2;
  }
  .effect:hover span {
    letter-spacing: 0.13em;
    color: #333;
  }

  /*highlight*/
  .effect:after {
    background: transparent;
    border: 0px solid rgba(26,41,128, 0.8);
    content: "";
    height: 155px;
    left: -75px;
    opacity: 0.8;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    width: 50px;
    transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1); /*easeOutCirc*/
    z-index: 1;
  }
  .effect:hover:after {
    background: transparent;
    border: 20px solid rgba(26,41,128, 0.8);
    /* opacity: 0; */
    left: 120%;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
  }
  .classWarning {
    color: red;
    /* color: white;
  border-radius: 20px; */
    padding: 5px;
    font-size: 14px;
    margin: 20px;
  }
`;
export default Calendar_Scheduler;
