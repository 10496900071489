import React, { useEffect } from "react";
import useLoginStatus from "../hooks/useLoginStatus";

function Authenticated({ children }) {
    const isOnline = useLoginStatus();
    useEffect(() => {});
    return isOnline ? children : null;
}

function NotAuthenticated({ children }) {
    const isOnline = useLoginStatus();
    // useEffect(() => {
    //     console.log('Status', isOnline, children);
    // });
    return !isOnline ? children : null;
}

export { Authenticated, NotAuthenticated };
