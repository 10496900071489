import React from 'react';
import { Button } from '@material-ui/core';

import './style.scss';
import Row from '../Elements/Flex/row';
import Col from '../Elements/Flex/col';

const ConfirmationModal = (props) => {
  const { onClickFunction, text} = props;

  return (
    <Row className='delete-modal-container'>
      <Col className='delete-modal'>
        <p className='delete-modal-title'>{text}</p>
        <Row className='delete-modal-action'>
          <Button className='action-btn' onClick={() => onClickFunction(false)}>
            No
          </Button>
          <Button
            className='action-btn'
            variant='contained'
            onClick={() => onClickFunction(true)}
            color='primary'
          >
            Yes
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default ConfirmationModal;
