import { withSnackbar } from 'notistack';
import React, { Component, useEffect, useState, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import * as actions from '../../actions';
import S3UploadButton from '../../components/S3UploadButton';
import axios from 'axios'
import SingleSelect from '../../components/AutoSuggestSelectBox/SingleSelect';
import { withStyles } from '@material-ui/core/styles';
import {
  ListItem,
  Typography,
  Modal,
  List,
  Grid,
  Button
} from '@material-ui/core';
import { TextField } from '@material-ui/core';

function BulkQuestionMapping(props) {
 
const [ref, setRef] = useState(React.createRef());
const [classList, setClassList] = useState(null)
const [classId, setClassId] = useState('')
const [sessionList, setSessionList] = useState(null)
const [sessionId, setSessionId] = useState('')
const [activityList, setActivityList] = useState(null)
const [activityId, setActivityId] = useState('')
const [classIdCopy, setClassIdCopy] = useState('')
const [sessionIdCopy, setSessionIdCopy] = useState('')
const [activityName, setActivityName] = useState('')
const [activityOrder, setActivityOrder] = useState('')
const [packageIdCopy,setPackageIdCopy]=useState(4)
const [sessionListCopy, setSessionListCopy] = useState(null)
const [packageId,setPackageId]=useState(4)
const [packageIdList,setPackageIdList] = useState(null)
const activityListCopy = [{ label: "Activity", value:  1},{ label: "Homework", value: 0}, { label: "Teacher Training", value: 2}]

useEffect(() => {
 getClassDetail(),
 getPackageData()
}, [ref]);

  const classes = styles();
  function getPackageData(){
    const admin_id=props && props.user.user_id
    axios.get(`/v1/admin/${admin_id}/assign-package/`)
    .then((res)=>
    {
        if(res && res.status==200){
        let temp = []
        let format={
            label:"",
            value:""
        }
        const data = res && res.data && res.data.data
        data && data.map(packageData => {
            format = {
                value: packageData.id,
                label: packageData.id + '. ' + packageData.name
            };
            format == undefined ? null : temp.push(format);
            });
        setPackageIdList(temp);
        }
    }
    )
  }
  function getClassDetail(){
    axios.get('v1/classes/').then((res)=>{
      if(res && res.status==200){
        let data = res && res.data && res.data.data
        data.map((ele,index)=>
        data[index]={label:ele.name, value:ele.id}
        )
        setClassList(data)
        
        console.log(classList, res.data.data, data)
      }
    })
  }
  function getSessionDetail(class_id,mapping){
    const admin_id=props.user && props.user.user_id;
    axios.get(`v1/admin/${admin_id}/content/${class_id}/sessions-list/?package_id=${mapping?packageIdCopy : packageId}`).then((res)=>{
      if(res && res.status==200){
       let data=res && res.data && res.data.data
       data.map((ele,index)=>
       data[index]=
       {
         label:ele.order + '. ' + ele.name,
         value:ele.id
        }
       )
        if(mapping){setSessionListCopy(res.data.data)}
        else{setSessionList(res.data.data)}
        console.log(classList, res.data.data)
      }
    })
  }
  function getActivityDetail(session_id){
    const admin_id=props.user && props.user.user_id;
    axios.get(`v1/admin/${admin_id}/content/session/${session_id}/activities-list/`).then((res)=>{
      if(res && res.status==200){
       let data=res?.data?.data?.activities
       data?.map((ele,index)=>
       data[index]={label:ele.activity_name, value:ele.id}
       )
        setActivityList(res.data.data.activities)        
      }
    })
  }

  function copyPPT(){
    const admin_id=props.user && props.user.user_id;
    axios.post(`v1/admin/${admin_id}/content/session/copy-content/`,
    {
      "from_session_id": sessionId,
      "to_session_id": sessionIdCopy,
    }
    )
    .then((res)=>{
      if(res && res.status==200){
      const message = res && res.data && res.data.data && res.data.data.message
      const error = res && res.data && res.data.data && res.data.data.error
      props.enqueueSnackbar(message || "PPT Link Updated Successfully", {variant: error ? 'error':'success'});
      }
    })
    .catch(error => {
      const errors = error && error.response && error.response.data && error.response.data.data && error.response.data.data.error
      errors && errors[0] && errors.map((err)=>
      props.enqueueSnackbar(err, {variant: 'error'})
      )  
      console.log(error, error.response);
    });
  }

  function Mapping(){
    const admin_id=props.user && props.user.user_id;
    axios.post(`v1/admin/${admin_id}/content/session/copy-activity/`,
    {
      "session_activity_id": activityId,
      "session_id": sessionIdCopy,
      "activity_name": activityName==1 ? "Activity" : activityName==0 ? "Homework" : "Training",
      "activity_order": activityOrder,
      "is_competitive": activityName == 1 ? 1 : 0,
      "status": activityName == 2 ? 2 : 1,
    }
    )
    .then((res)=>{
      if(res && res.status==200){
      const message = res && res.data && res.data.data && res.data.data.message
      const error = res && res.data && res.data.data && res.data.data.error
      props.enqueueSnackbar(message || "Uploaded Successfully", {variant: error ? 'error':'success'});
      }
    })
    .catch(error => {
      const errors = error && error.response && error.response.data && error.response.data.data && error.response.data.data.error
      errors && errors[0] && errors.map((err)=>
      props.enqueueSnackbar(err, {variant: 'error'})
      )  
      console.log(error, error.response);
    });
  }
  return (
   <div style={{minHeight:'80vh', width:'100%'}}>
  <h2>Assign Activity</h2>
  <div style={{width:'90vw',display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
  <div style={{width:'40vw',display:'flex', flexDirection:'column', gap:'4vh'}}>
  <div style={{fontSize:16, fontWeight:600}}>From Activity</div>
  <SingleSelect
    name="Package"
    onChange={(event)=>{ 
      setClassId(null), 
      setSessionId(null), 
      setPackageId(event.value), 
      setActivityId(null), 
      classId &&  getSessionDetail(event.value)
    }}
    selectList={packageIdList}
    style={{cursor:'pointer'}}
    defaultValue={[{ label: "LogiMath", value: 4}]}
    isDisabled={false}
    />

  <SingleSelect
    name="Class"
    onChange={(event)=>{
      setSessionId(null), 
      setActivityId(null), 
      setClassId(event.value), 
      getSessionDetail(event.value)
    }}
    selectList={classList}
    value={classId && classList && classList[classList.includes(classId)]}
    style={{cursor:'pointer'}}
    isDisabled={!packageId}
    />
   
    <SingleSelect
    name="Session"
    onChange={(event)=>{
      setActivityId(null), 
      setSessionId(event.value), 
      getActivityDetail(event.value)
    }}
    selectList={sessionList}
    value={sessionId && sessionList && sessionList[sessionList.includes(sessionId)]}
    style={{cursor:'pointer'}}
    isDisabled={!classId}
    />
     <SingleSelect
    name="Activity"
    onChange={(event)=>{
      setActivityId(event.value)
    }}
    selectList={activityList}
    value={(activityId || activityId==0) && activityList && activityList[activityList.includes(activityId)]}
    style={{cursor:'pointer'}}
    isDisabled={!sessionId}
    />
   </div>
   <div style={{width:'40vw',display:'flex', flexDirection:'column', gap:'4vh'}}>
   <div style={{fontSize:16, fontWeight:600}}>To Activity</div>
   <SingleSelect
    name="Package"
    onChange={(event)=>{ 
      setClassIdCopy(null), 
      setSessionIdCopy(null), 
      setActivityName(null),
      setActivityOrder(null),
      setPackageIdCopy(event.value)
    }}
    selectList={packageIdList}
    style={{cursor:'pointer'}}
    defaultValue={[{ label: "LogiMath", value: 4}]}
    isDisabled={false}
    />

  <SingleSelect
    name="Class"
    onChange={(event)=>{
      setSessionIdCopy(null), 
      setActivityName(null),
      setActivityOrder(null),
      setClassIdCopy(event.value), 
      getSessionDetail(event.value,true)
    }}
    selectList={classList}
    value={classIdCopy && classList && classList[classList.includes(classIdCopy)]}
    style={{cursor:'pointer'}}
    isDisabled={!packageIdCopy}
    />
   
    <SingleSelect
    name="Session"
    onChange={(event)=>{
      setActivityName(null),
      setActivityOrder(null),
      setSessionIdCopy(event.value)
    }}
    selectList={sessionListCopy}
    value={sessionIdCopy && sessionListCopy && sessionListCopy[sessionListCopy.includes(sessionIdCopy)]}
    style={{cursor:'pointer'}}
    isDisabled={!classIdCopy}
    />
     <SingleSelect
    name="Activity Name"
    onChange={(event)=>{
      setActivityName(event.value)
    }}
    selectList={activityListCopy}
    value={(activityName || activityName==0) && activityListCopy && activityListCopy[activityListCopy.includes(activityName)]}
    style={{cursor:'pointer'}}
    isDisabled={!sessionIdCopy}
    />
     <TextField
     label="Activity Order" 
     placeholder="Example: 1" 
     value={activityOrder || ''}
     onChange={(event)=>{
       setActivityOrder(event.target.value)
      }}
     />
     <div style={{display:'flex', justifyContent:'space-evenly'}}>
      <Button
          color="primary"
          variant="contained"
          onClick={copyPPT}
          disabled={!(sessionId && sessionIdCopy)}
      >
        Copy Other Content
      </Button>
      <Button
          color="primary"
          variant="contained"
          onClick={Mapping}
          disabled={!(activityId && sessionIdCopy && (activityName || activityName==0) && activityOrder)}
      >
        Copy Activity
      </Button>
      </div>
   </div>
   </div>
   </div>
   
    );
}

const styles = makeStyles(theme => ({
  select: {
    minWidth: 200,
    maxWidth: 300
  },
  main: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    minHeight:'60vh'
  }
}));

function mapStateToProps(state) {

  return {
    user: state.user,
  };
}

export default withStyles(styles, { withTheme: true })(withSnackbar(
  connect(
    mapStateToProps,
    actions,
    null
  )(BulkQuestionMapping))
);
