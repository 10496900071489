import React from 'react';
import { CheckOutlined } from '@material-ui/icons';

import './style.scss';
import { COLOR_ARRAY } from '../../FlashCardsUtils';
import Row from '../../../../components/Elements/Flex/row';

const BackgroundComponent = (props) => {
  const { setBackgroundColor, selectedColor } = props;

  const COLORS = COLOR_ARRAY;

  return (
    <Row className='background-component row-flex'>
      {COLORS.map((data, i) => {
        return (
          <div
            onClick={() => {
              setBackgroundColor(data);
            }}
            key={i}
            className='color-div'
            style={{ backgroundColor: data }}
          >
            {data === selectedColor ? (
              <CheckOutlined className='check-icon' />
            ) : null}
          </div>
        );
      })}
      {/* <Row className='row'>
        <div className='text-subtitle'>Add Image</div>
        <CloudUploadOutlined className='upload-icon' />
      </Row> */}
    </Row>
  );
};

export default BackgroundComponent;
