import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Modal,
  Button,
  Icon,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,  
  ListItemText,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Table from '../../components/LQDataTables/demo';
import axios from 'axios';
import { connect } from 'react-redux';
import AvailabilityModal from '../../components/AvailabilityModal';
import { Link } from 'react-router';
import SaveChangesModal from '../../components/SaveChangesModal';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },

  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});
let initialuserData = {
  upcoming_class_time: new Date(),
  free_trial_package_class_id: '',
  teacher: '',
};
let tableRef = null;

const ref = React.createRef();

function TeacherTable(props) {
  const [availability, setAvailability] = useState(null);
  const [packageData, setPackageData] = useState(null);
  const [classData, setClassData] = useState(null);
  const [id, setId] = useState(null);
  const packageDataList = [
    { value: 4, label: 'LogiMath' },
    { value: 5, label: 'Science' },
  ];
  const [classList, setClassList] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);

  useEffect(() => {
    getClassDetail();
  }, []);

  function handleCallBack() {
    tableRef && tableRef.refreshTable();
  }
  function getClassDetail() {
    axios.get('v1/classes/').then((res) => {
      if (res && res.status == 200) {
        let data = res && res.data && res.data.data;
        data.map(
          (ele, index) => (data[index] = { label: ele.name, value: ele.id })
        );
        setClassList(data);
      }
    });
  }
  function postDeleteUser() {
    axios
      .post(`/v1/admin/${props.adminId}/teacher/${deleteUser.id}/disable/`)
      .then((res) => {
        if (res?.status == 200) {
          setDeleteUser(false);
          props.enqueueSnackbar(
            res?.data?.data?.message || 'User Deleted Successfully',
            { variant: 'success' }
          );
          handleCallBack();
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.data?.error;
        props.enqueueSnackbar(errorMessage || 'Something went wrong', {
          variant: 'error',
        });
      });
  }

  function setUserAvailabilityModal() {
    return (
      <AvailabilityModal
        user_id={availability.id}
        user_name={availability.name}
        isTeacher={true}
        closeModal={() => {
          setAvailability(null);
        }}
        handleCallBack={handleCallBack}
      />
    );
  }

  function renderSetPackageModal() {
    return (
      <Modal
        open={packageData && classData && id}
        onClose={() => {
          setPackageData(null);
          setClassData(null);
          setId(null);
        }}
      >
        <div
          style={{
            background: '#fff',
            borderRadius: 5,
            width: '70vw',
            height: '50vh',
            margin: 'auto',
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            padding: 20,
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <h3>Select Package</h3>
          <FormControl style={{ width: '100% !important' }}>
            <InputLabel id='demo-multiple-checkbox-label'>
              Select Package
            </InputLabel>
            <Select
              style={{ width: '50vw' }}
              labelId='demo-multiple-checkbox-label'
              id='demo-multiple-checkbox'
              multiple
              value={packageData}
              onChange={(event) => setPackageData(event.target.value)}
              renderValue={(selected) =>
                packageDataList && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      gap: 10,
                      flexWrap: 'wrap',
                    }}
                  >
                    {packageDataList.map(
                      (packageList) =>
                        selected.indexOf(packageList.value) > -1 && (
                          <div>{packageList.label}</div>
                        )
                    )}
                  </div>
                )
              }
            >
              {packageDataList.map((packageList) => (
                <MenuItem key={packageList.value} value={packageList.value}>
                  <Checkbox
                    checked={
                      packageData.toString().indexOf(packageList.value) > -1
                    }
                  />
                  <ListItemText primary={packageList.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <h3>Select Class</h3>
          <FormControl style={{ width: '100% !important' }}>
            <InputLabel id='demo-multiple-checkbox-label'>
              Select Class
            </InputLabel>
            <Select
              style={{ width: '50vw' }}
              labelId='demo-multiple-checkbox-label'
              id='demo-multiple-checkbox'
              multiple
              value={classData}
              onChange={(event) => setClassData(event.target.value)}
              renderValue={(selected) =>
                classList && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      gap: 10,
                      flexWrap: 'wrap',
                    }}
                  >
                    {classList.map(
                      (packageList) =>
                        selected.indexOf(packageList.value) > -1 && (
                          <div>{packageList.label}</div>
                        )
                    )}
                  </div>
                )
              }
            >
              {classList.map((packageList) => (
                <MenuItem key={packageList.value} value={packageList.value}>
                  <Checkbox
                    checked={
                      classData.toString().indexOf(packageList.value) > -1
                    }
                  />
                  <ListItemText primary={packageList.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            onClick={() => {
              if (packageData && classData) {
                axios
                  .post(`/v1/admin/${props.adminId}/teacher/${id}/update/`, {
                    packages: packageData,
                    classes: classData,
                  })
                  .then((res) => {
                    if (res && res.status && res.status == 200) {
                      setPackageData([]);
                      setClassData([]);
                      setId(null);
                      props.enqueueSnackbar(
                        (res &&
                          res.data &&
                          res.data.data &&
                          res.data.data.message) ||
                          'Package Updated Successfully',
                        { variant: 'success' }
                      );
                      handleCallBack();
                    }
                  })
                  .catch((error) => {
                    const errorMessage =
                      error?.response?.data?.data?.error?.teacher_details;
                    props.enqueueSnackbar(
                      errorMessage?.classes ||
                        errorMessage?.packages ||
                        'Something went wrong',
                      { variant: 'error' }
                    );
                  });
              }
            }}
            variant='contained'
            color='primary'
            style={{ marginTop: 10 }}
          >
            Save
          </Button>
        </div>
      </Modal>
    );
  }

  function formatTable(tableData) {
    const newData =
      tableData &&
      tableData.map((entry, index) => {
        const { id, name, email, classes, packages, role } = entry;
        const newEntry = { ...entry };

        const updatedEntry = {
          edit: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <IconButton>
                <Tooltip
                  title='Set teacher availability'
                  onClick={() => {
                    setAvailability({ id: id, name: name });
                  }}
                >
                  <Icon className='iconButton'>event_available_icon</Icon>
                </Tooltip>
              </IconButton>
              {role == 'teacher' ? (
                <>
                  <IconButton>
                    <Tooltip
                      title='Package Assign'
                      onClick={() => {
                        setPackageData(packages);
                        setClassData(classes);
                        setId(id);
                      }}
                    >
                      <Icon className='iconButton'>
                        assignment_turned_in_icon
                      </Icon>
                    </Tooltip>
                  </IconButton>
                  <Link
                    to={{
                      pathname: '/availability',
                      search: `?isTeacher=true&teacherUserId=${id}`,
                    }}
                    target='_blank'
                    style={{ textDecoration: 'none' }}
                  >
                    <IconButton>
                      <Tooltip title='Teacher Schedule Data'>
                        <Icon className='iconButton'>calendar_month_icon</Icon>
                      </Tooltip>
                    </IconButton>
                  </Link>
                </>
              ) : null}
              <Link
                to={{
                  pathname: '/referral',
                  search: `?email=${email}`,
                }}
                target='_blank'
                style={{ textDecoration: 'none' }}
              >
                <IconButton>
                  <Tooltip title='Referral Data'>
                    <Icon className='iconButton'>
                      connect_without_contact_icon
                    </Icon>
                  </Tooltip>
                </IconButton>
              </Link>
              <IconButton>
                <Tooltip
                  title={'Delete ' + role}
                  onClick={() => {
                    setDeleteUser({ id: id, name: name });
                  }}
                >
                  <Icon className='iconButton'>delete</Icon>
                </Tooltip>
              </IconButton>
            </div>
          ),
          ...newEntry,
        };
        return Object.keys(updatedEntry).map((subEntry) =>
          updatedEntry[subEntry] ? '' || updatedEntry[subEntry] : '-'
        );
      });
    return newData;
  }

  return (
    <div>
      <Table
        onRef={(ref) => {
          tableRef = ref;
        }}
        firstCol='edit'
        createNew='/teacher/create'
        headerFilterList={HEADER_FILTER_LIST}
        formatTable={formatTable}
        sortList={[]}
        sortDate={[]}
        tableName='teacher'
        title='Teacher'
        scrollType='stacked'
        search={props.location && props.location.query}
        apiUrl={`teacher/list/`}
      />
      {availability ? setUserAvailabilityModal() : null}
      {id ? renderSetPackageModal() : null}
      <SaveChangesModal
        openModal={deleteUser}
        closeModal={() => setDeleteUser(false)}
        text={`Do you want to delete user ${deleteUser.name}?`}
        onSave={() => postDeleteUser()}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    adminId: state.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(TeacherTable));

const HEADER_FILTER_LIST = [
  {
    filter_label: 'id',
    type: 'text',
  },
  {
    filter_label: 'name',
    type: 'text',
  },
  {
    filter_label: 'phone',
    type: 'text',
  },
  {
    filter_label: 'city_name',
    type: 'text',
  },

  {
    filter_label: 'email',
    type: 'text',
  },
];
