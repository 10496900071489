import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  CircularProgress,
} from '@material-ui/core';

class ReferralContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referralData: null,
      errorText: null
    };
  }
  componentDidMount() {
    this.getReferralData();
  }

  getReferralData = () => {
    axios
      .get(`v1/invite-referral/stats/?email=${this.props?.location?.query?.email}`)
      .then((response) => {
        if (response && response.status == 200 && response.data)
          this.setState({ referralData: response.data.data });
      })
      .catch((error)=> 
      this.setState({errorText: error?.response?.data?.data?.error[0] && error.response.status==400 ? error.response.data.data.error[0] : 'Something went wrong',
      referralData: []
    })
      );
  };

  render() {
    return (this.state.referralData?.length>0 ?
      <div>
        <h3>Teacher Referral Data</h3>
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(this.state.referralData[0])?.map((header) => (
                <TableCell
                  style={{
                    textAlign: 'left',
                    fontSize: '1vw',
                    fontWeight: 600,
                    border: '1px solid #e0e0e0',
                    padding: '6px 24px 6px 16px',
                    height: '10vh',
                    backgroundColor: '#e0e0e0',
                  }}
                >
                  {header.charAt(0).toUpperCase() + header.replaceAll("_", " ").slice(1)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.referralData?.map((referral) => (
              <TableRow>
                {Object.keys(this.state.referralData[0])?.map((header) => (
                  <TableCell
                    style={{
                      textAlign: 'left',
                      fontSize: '1vw',
                      fontWeight: 500,
                      border: '1px solid #e0e0e0',
                      padding: '6px 24px 6px 16px',
                      height: '7vh',
                    }}
                  >
                    {header.includes('date') ? referral[header] ? new Date(referral[header]).toLocaleString() : '-' : referral[header].toString()}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </div>
        : this.state.referralData ? 
        <div>No data</div>
        : this.state.errorText ? 
        this.state.errorText
        : <CircularProgress style={{margin:'auto'}}/>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    adminId: state?.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(ReferralContainer));
