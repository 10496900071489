import React from 'react';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';

import NoSsr from '@material-ui/core/NoSsr';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { titleCase } from 'title-case';
import Components from './Components';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: '100%',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        minWidth: '130px',
    },
    chip: {
        margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
});

const MultiSelectBox = (props) => {
    const { classes, className, name, onChange, selectList, value, theme, onBlur } = props;

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    const def = () => {};

    return (
        <NoSsr>
            <Select
                classes={classes}
                className={className}
                styles={selectStyles}
                textFieldProps={{
                    label: titleCase(name),
                    InputLabelProps: {
                        shrink: true,
                    },
                }}
                options={selectList}
                components={Components}
                value={value}
                onChange={onChange}
                onBlur={onBlur || def}
                isMulti
            />
        </NoSsr>
    );
};

export default withStyles(styles, { withTheme: true })(MultiSelectBox);
