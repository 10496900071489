import React, { useEffect, useState, Fragment, useRef, useCallback } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {
  ListItem,
  Typography,
  makeStyles,
  Modal,
  List,
  Grid,
  Button
} from '@material-ui/core';
import { Link } from 'react-router'
import { withSnackbar } from 'notistack';
import axios from 'axios'
import { connect } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2
  },
  list:{
    display: 'flex',
  },
  iconButton: {
    fontSize: 12
  },
  formControl: {
    width: '53%'
  },
  Main:{
   width:'95%',
    minHeight:'100vh',
    display:'flex',
    justifyContent:'center',
    position: 'absolute',
    zIndex:10,
    top:0,
    alignItems:'center',
    
  },
  Modal:{
    background: '#fff',
    borderRadius:5,
    width: '80vw',
    maxHeight:'60vh',
    margin: '20vh auto',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    padding:20,
    overflowY:'scroll'
  },
  header:{
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'flex-end',
  },

  packageClassDetails: {
    '& a': {
      color: '#000'
    }
  }
});

let tableRef = null;



function StudentSessionStatus(props) {
  const ref = React.createRef();
  const classes = userStyles(); 
  const [sessionStatus, setSessionStatus] = useState(null)

  useEffect(() => {
  getSessionStatus()
  }, []); 

  function getSessionStatus(){
    setSessionStatus(null)
    const admin_id=props && props.user && props.user.user_id
    axios.get(`/v1/admin/${admin_id}/session/${props.id}/status/`)
    .then((response)=>
    {
      if(response && response.status==200){
        const data = response && response.data && response.data.data
      setSessionStatus(response && response.data && response.data.data)
      props.enqueueSnackbar('Successfully fetched the session status', {variant: 'success'});
      }
    }
    )
  }


  return (
    <div className={classes.Main}>
     
    <Modal
     open={props.id}
     onClose={() => {
       props.closeModal
     }}
    >
       
    <div className={classes.Modal}>
     <div className={classes.header}>
       <div style={{margin:"auto 0px", fontSize:20, fontWeight:500}}>Session Status</div>
       <div>
       <Button onClick={getSessionStatus} color="primary" variant="contained">
          Refresh Status
          <Icon className='iconButton'>refresh</Icon>
        </Button>
        <IconButton onClick={props.closeModal}>
          <Tooltip title='Close Modal'>
            <Icon className='iconButton'>close</Icon>
          </Tooltip>
        </IconButton>
        </div>
       </div>
      { sessionStatus ?
      !sessionStatus[0] ?  
        <div>No data to display</div> :
        <Table className={classes.table}>             
          <TableHead>
            <TableRow>
              <TableCell className={classes.tablecell}></TableCell>
                  {sessionStatus && sessionStatus[0] && sessionStatus.map((header, i)=>
                      {  
                          const headername = <div>
                            {header.class_join_time && <Icon style={{color:'green'}}>check_box_icon</Icon>}
                            <div>{header.name +'('+ header.student_id+')'}</div>
                            <div>{header.phone}</div>
                            </div>
                          return(<TableCell className={classes.tablecell}>{headername}</TableCell>)
                      }
                  )}
            </TableRow>
          </TableHead>
          <TableBody>
          {sessionStatus && sessionStatus[0] && Object.getOwnPropertyNames(sessionStatus[0]).map((header, i)=>{
          const headername = header.replaceAll("_", " ")
          
          if(header!='name' && header!='phone' && header!='student_id')
          {
            return <TableRow>
            <TableCell>{headername.charAt(0).toUpperCase() + headername.slice(1)}</TableCell>
            { sessionStatus.map((rows, index)=>{
            const cellData = eval('rows.'+header)
            const cell = header.includes('time') && cellData ? 
            new Date(cellData).toLocaleString("en-IN",{ year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) 
            : header.includes('allowed') && cellData!=null ? cellData ? 'Yes' : 'No'
            : cellData || '-'
            return <TableCell>{cell}</TableCell>}
            )}
            </TableRow>
          }}
          )}        
          </TableBody>
        </Table>
        :
        <div>Loading..</div>
      }
    </div>
    </Modal>
    </div>
      );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default withSnackbar(connect(mapStateToProps)(StudentSessionStatus));
