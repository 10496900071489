import React, { Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Menu from 'material-ui-popup-state/HoverMenu';
import { MenuItem, ListItemText, Button, Container } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { Link } from 'react-router';
import { Authenticated } from '../LoginStatus';

function Navigator(props) {
  return (
    <Container>
      <Authenticated>
        {props.display && NAVIGATION_ROUTES.map((route, index) => (
          <CascadingHoverMenus key={index} data={route} />
        ))}
      </Authenticated>
    </Container>
  );
}

export default Navigator;

const ParentPopupState = React.createContext(null);

function CascadingHoverMenus(props) {
  const popupState = usePopupState({
    popupId: `${props.data.text}-menu`,
    variant: 'popover',
  });
  const { data } = props;

  function renderChildren(children) {
    return children.map((child, index) => {
      switch (child.type) {
        case MENU_ITEM:
          return (
            <MenuItem key={index}>
              <Link
                to={child.link}
                style={{
                  textDecoration: 'none',
                  width: '100%',
                  fontSize: 14,
                  padding: 5,
                }}
                onClick={() => history.push(child.link)}
              >
                {child.text.toUpperCase()}
              </Link>
            </MenuItem>
          );
        case SUB_MENU:
          return (
            <Submenu key={index} popupId={child.text} title={child.text}>
              {renderChildren(child.children)}
            </Submenu>
          );
        default:
          return null;
      }
    });
  }

  return (
    <Fragment>
      {data.children ? (
        <>
          <Button {...bindHover(popupState)}>
            <Link
              to={data.link}
              style={
                data.link != window.location.pathname
                  ? {
                      color: 'black',
                      textDecoration: 'none',
                    }
                  : {
                      textDecoration: 'none',
                      color: 'blue',
                    }
              }
            >
              {data.text}
            </Link>
          </Button>
          <ParentPopupState.Provider value={popupState}>
            <Menu
              {...bindMenu(popupState)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              getContentAnchorEl={null}
            >
              {renderChildren(data.children)}
            </Menu>
          </ParentPopupState.Provider>
        </>
      ) : (
        <Button {...bindHover(popupState)}>
          <Link
            to={data.link}
            style={
              data.link != window.location.pathname
                ? {
                    color: 'black',
                    textDecoration: 'none',
                    width: '100%',
                  }
                : {
                    textDecoration: 'none',
                    width: '100%',
                    color: 'blue',
                  }
            }
          >
            {data.text}
          </Link>
        </Button>
      )}
    </Fragment>
  );
}

// export default CascadingHoverMenus;

const submenuStyles = (theme) => ({
  menu: {
    top: -theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    padding: 5,
  },
  moreArrow: {
    marginRight: -theme.spacing(1),
  },
});

const Submenu = withStyles(submenuStyles)(
  ({ classes, title, popupId, children, ...props }) => {
    const parentPopupState = React.useContext(ParentPopupState);
    const popupState = usePopupState({
      popupId,
      variant: 'popover',
      parentPopupState,
    });
    return (
      <ParentPopupState.Provider value={popupState}>
        <MenuItem {...bindHover(popupState)} selected={popupState.isOpen}>
          <ListItemText className={classes.title}>{title}</ListItemText>
          <ChevronRight className={classes.moreArrow} />
        </MenuItem>
        <Menu
          {...bindMenu(popupState)}
          className={classes.menu}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          {...props}
        >
          {children}
        </Menu>
      </ParentPopupState.Provider>
    );
  }
);

const MAIN_MENU = 0;
const MENU_ITEM = 1;
const SUB_MENU = 2;

const NAVIGATION_ROUTES = [
  {
    type: MAIN_MENU,
    text: 'USER',
    link: '/user',
    children: [
      {
        type: MENU_ITEM,
        text: 'Users',
        link: '/user',
      },
      {
        type: MENU_ITEM,
        text: 'User Availability',
        link: '/availability',
      },
    ],
  },
  {
    type: MAIN_MENU,
    text: 'Group',
    link: '/group-class/paid',
    children: [
      {
        type: MENU_ITEM,
        text: 'paid',
        link: '/group-class/paid',
      },
      {
        type: MENU_ITEM,
        text: 'free trial',
        link: '/group-class/free',
      },
      {
        type: MENU_ITEM,
        text: 'group misfits',
        link: '/group-misfits',
      },
    ],
  },
  {
    type: MAIN_MENU,
    text: 'sessions',
    link: '/sessions',
    children: [
      {
        type: MENU_ITEM,
        text: 'sessions',
        link: '/sessions',
      },
      {
        type: MENU_ITEM,
        text: 'absent',
        link: '/missed-sessions',
      },
      {
        type: MENU_ITEM,
        text: 'doubt sessions',
        link: '/doubt-sessions',
      },
      {
        type: MENU_ITEM,
        text: 'reschedule',
        link: '/reschedule-request',
      },
      {
        type: MENU_ITEM,
        text: 'slot management',
        link: '/teacher-slot',
      },
      {
        type: MENU_ITEM,
        text: 'ptm',
        link: '/ptm',
      },
      {
        type: MENU_ITEM,
        text: 'class feedback',
        link: '/class-feedback',
      },
      {
        type: MENU_ITEM,
        text: 'Demo calling panel',
        link: '/demo-calling-panel',
      },
    ],
  },
  {
    type: MAIN_MENU,
    text: 'Order',
    link: '/orders',
    children: [
      {
        type: MENU_ITEM,
        text: 'orders',
        link: '/orders',
      },
      {
        type: MENU_ITEM,
        text: 'Assign Package',
        link: '/package-assign',
      },
      {
        type: MENU_ITEM,
        text: 'cancelled orders',
        link: '/cancelled-orders',
      },
    ],
  },
  {
    type: MAIN_MENU,
    text: 'content',
    link: '/questions',
    children: [
      {
        type: MENU_ITEM,
        text: 'inventory',
        link: '/questions',
      },
      {
        type: MENU_ITEM,
        text: 'upload questions',
        link: '/upload-question',
      },
      {
        type: MENU_ITEM,
        text: 'bulk upload',
        link: '/bulk-question-upload',
      },
      {
        type: MENU_ITEM,
        text: 'copy activity',
        link: '/bulk-question-mapping',
      },
      {
        type: MENU_ITEM,
        text: 'queries',
        link: '/queries',
      },
      {
        type: MENU_ITEM,
        text: 'topics',
        link: '/edit-session',
      },
      {
        type: MENU_ITEM,
        text: 'Interactive Flash Cards',
        link: '/interactive-flash-cards',
      },
    ],
  },
  {
    type: MAIN_MENU,
    text: 'TEACHER',
    link: '/teacher',
    children: [
      {
        type: MENU_ITEM,
        text: 'Teacher',
        link: '/teacher',
      },
      {
        type: MENU_ITEM,
        text: 'Teacher Availability',
        link: '/availability?isTeacher=true',
      },
      {
        type: MENU_ITEM,
        text: 'Teacher Performance',
        link: '/teacher-performance',
      },
      {
        type: MENU_ITEM,
        text: 'Leave Management',
        link: '/leave-management',
      },
      {
        type: MENU_ITEM,
        text: 'announcements',
        link: '/announcements',
      },
    ],
  },
  {
    type: MAIN_MENU,
    text: 'Admin',
    link: '/admin',
    children: [
      {
        type: MENU_ITEM,
        text: 'Admin',
        link: '/admin',
      },
      {
        type: MENU_ITEM,
        text: 'Roles and Authentication',
        link: '/roles-and-authentication',
      },
    ],
  },
  {
    type: MAIN_MENU,
    text: 'Analytics',
    link: '/teacher-trainer',
    children: [
      {
        type: MENU_ITEM,
        text: 'Teacher Trainer',
        link: '/teacher-trainer',
      },
      {
        type: SUB_MENU,
        text: 'Student Mentor',
        children: [
          {
            type: MENU_ITEM,
            text: 'View 1',
            link: '/student-mentor-view-1',
          },
          {
            type: MENU_ITEM,
            text: 'View 2',
            link: '/student-mentor-view-2',
          },
          {
            type: MENU_ITEM,
            text: 'View 3',
            link: '/student-mentor-view-3',
          },
        ],
      },
      {
        type: MENU_ITEM,
        text: 'Content Head',
        link: '/content-head',
      },
      {
        type: MENU_ITEM,
        text: 'Teacher Performance',
        link: '/teacher-trainer-stats',
      },
      {
        type: MENU_ITEM,
        text: 'monthly feedback',
        link: '/monthly-feedback',
      },
    ],
  },
];
