import React, { Component } from "react";
import { connect } from "react-redux";
// import { Link } from 'react-router';
// import { browserHistory } from 'react-router';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import Divider from '@material-ui/core/Divider';
import { withStyles } from "@material-ui/core/styles";

// import PropTypes from 'prop-types';
import classNames from "classnames";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import { Validations } from "../../Utils";
import * as actions from "../../actions";
import axios from "axios";
import { browserHistory } from "react-router";

class Login extends Component {
  constructor(props) {
    super(props);
    this.validations = new Validations();
    this.state = {
      fields: ["email", "password"],
      email: "",
      password: "",
      errors: this.validations.errors,
      currentRole: "login",
      errorMessage: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    console.log("pro", process, this.props)
    if (this.props.error != null) {
      this.props.resetErrors();
    }

    const { query } = this.props.location;
    if (query && query.email && query.password) {
      this.setState(
        {
          email: query.email,
          password: query.password,
        },
        () => {
          this.props
            .login({ email: query.email, password: query.password })
            .then((res) => res)
            .catch((e) => {
              let error =
                e.response &&
                e.response.data &&
                e.response.data.error &&
                e.response.data.error.non_field_errors[0];
                console.log("e",error)
              if (error) {
                this.setState({ errorMessage: error });
              }
              else{
                // this.props.enqueueSnackbar('success', 'Updated Successfully');
              }
            });
        }
      );
    }
  }

  handleInputChange = (event) => {
    const { target } = event;
    event.preventDefault();
    event.stopPropagation();
    const errors =
      this.state.currentRole === "signUp"
        ? this.validations.validate(target.name, target.value)
        : null;
    this.setState({
      [target.name]: target.value,
      errors,
    });
  };

  handleChange = (event) => {
    const { target } = event;
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      [target.name]: target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.handleLogin();
  };



  handleLogin(event) {
    console.log("this is here");
    const { email, password } = this.state;
    let errors = null;
    this.state.fields.forEach((field) => {
      const fieldValue = this.state[field];
      errors = this.validations.validate(field, fieldValue);
    });
    this.setState({
      errors,
    });
    if (!this.validations.allNullKeyValue(errors)) return false;
    {this.props
      .login({ email, password })
      .then((res) => res)
      .catch((e) => {
        let error = 
          e.response &&
          e.response.data &&
          e.response.data.data &&
          e.response.data.data.error &&
          e.response.data.data.error[0];
          console.log("error",e)
        if (error) {
         
          this.setState({ errorMessage: error });
        }
        else{
          this.setState({ errorMessage: 'Something Went Wrong' });
          this.props.enqueueSnackbar('Something Went Wrong',{variant:'error'});
        }
      });}}

 

  render() {
    const { errors, currentRole } = this.state;
    const errorMessage = this.props.error;
    const { classes, theme } = this.props;
    this.props.user ? 
    setTimeout(
        browserHistory.push({
          pathname:  this.props.user?.role == 'woodrock' ? '/demo-calling-panel' : '/group-class/paid',
          search:
            typeof window !== 'undefined' && window && window.location.search,
        })
        ,2000)
       : null
    const disabledInputs =
      currentRole === "login" ? this.props.user : !this.props.user;
    const disabledText =
      disabledInputs && currentRole === "login"
        ? "you are already logged in"
        : null;
    const disabledText2 =
      disabledInputs && currentRole === "signUp"
        ? "you are not logged in"
        : null;
    return (
      <div className={classes.root}>
        <Paper className={classNames(classes.paper)}>
          <Typography variant="h4" className={classes.typeWriter}>
            LOGIN
          </Typography>
          <form className={classes.loginForm}>
            <FormControl
              className={classes.formControl}
              error={!!(errors && errors.email)}
            >
              <div>
                <InputLabel htmlFor="email-helper">Email</InputLabel>
                <Input
                  disabled={disabledInputs}
                  id="email-helper"
                  value={this.state.email}
                  onChange={this.handleChange}
                  onBlur={(e) => this.handleInputChange(e)}
                  className={classes.input}
                  name="email"
                  type="email"
                />
                <FormHelperText id="password-error-text">
                  {errors && errors.email}
                  {disabledText || disabledText2}
                </FormHelperText>
              </div>
            </FormControl>
            <FormControl
              className={classes.formControl}
              error={!!(errors && errors.password)}
            >
              <div>
                <InputLabel htmlFor="password-error">Password</InputLabel>
                <Input
                  disabled={disabledInputs}
                  id="password-error"
                  value={this.state.password}
                  onChange={this.handleChange}
                  onBlur={(e) => this.handleInputChange(e)}
                  className={classes.input}
                  name="password"
                  type="password"
                />
                <FormHelperText id="password-error-text">
                  {errors && errors.password}
                  {disabledText || disabledText2}
                </FormHelperText>
              </div>
            </FormControl>
            <div className={classes.error}>{this.state.errorMessage}</div>
            <div className={classes.actionButtons}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.applyButton}
                  onClick={this.handleSubmit}
                >
                  {currentRole === "login" ? "Login" : "Submit"}
                </Button>
              </div>
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.error,
    user: state.user,
  };
}

const styles = (theme) => ({
  typeWriter: {
    background: "-webkit-linear-gradient( right,rgba(26,41,128, 0.8), rgba(26,41,128, 0.8))",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    /* 	animation:
			"typewriter 3s steps(10) 0.4s 1 normal both, blinkTextCursor 500ms steps(44) infinite normal",
		overflow: "hidden",
		margin: "0 auto",
		whiteSpace: "nowrap",
		letterSpacing: ".02em" */
  },
  "@keyframes typewriter": {
    from: { width: 0 },
    to: { width: "55%" },
  },
  "@keyframes blinkTextCursor": {
    from: { borderRightColor: "rgba(255,255,255,.75)" },
    to: { borderRightColor: "transparent" },
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    width: "auto",
    minWidth: 500,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      //	width: theme.spacing.unit * 50,
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      //	width: auto,
      padding: theme.spacing(4),
    },
  },
  button: {
    margin: theme.spacing(1),
    width: "36px",
  },
  divider: {
    margin: "10px 0px",
  },
  paragraph: {
    marginTop: "5px",
    marginBottom: "15px",
  },
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  applyButton: {
    background: "rgba(26,41,128, 0.8)" /* fallback for old browsers */,
    minWidth: "120px",
    border: 0,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(26,41,128, .2)",
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  cancelButton: {
    minWidth: "120px",
    border: "1px solid rgba(26,41,128, 0.8)",
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  loginForm: {
    display: "flex",
    flexDirection: "column",
    minWidth: 500,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  input: {
    width: "100%",
  },
  actionButtons: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  error: {
    color: "red",
    textAlign: "center",
  },
});

export default withSnackbar(
  withStyles(styles)(
    connect(
      mapStateToProps,
      actions
    )(Login)
  )
);
