import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import MomentUtils from '@date-io/moment';

import { connect } from 'react-redux';
import {
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Modal,
  List,
  Button,
  Tooltip,
} from '@material-ui/core';
import { Delete, DateRange } from '@material-ui/icons';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import SaveChangesModal from '../SaveChangesModal';

const ref = React.createRef();

function ClassScheduleModal(props) {
  const [selectedDate, handleDateChange] = useState(null);
  const [sessionId, handlesessionId] = useState(null);
  const [teacherList, setTeacherList] = useState(null);
  const [teacherId, setTeacherId] = useState(null);
  const [disableClass, setDisableClass] = useState(null);

  useEffect(() => {
    if (props.showTeacherField) {
      if (props.autoShowTeacher) getAvailableTeacherList();
      else getTeacherList();
    }
  }, []);
  const user_id = props.user.user_id;

  function getTeacherList() {
    const admin_id = props.user && props.user.user_id;
    axios.get(`v1/admin/${admin_id}/teacher/`).then((res) => {
      if (res.status == 200) {
        let data = res && res.data && res.data.data;
        data.map((ele, index) => {
          data[index] = { label: ele.name, value: ele.id };
          if (props.teacher && ele.name == props.teacher) setTeacherId(ele.id);
        });
        setTeacherList(data);
      }
    });
  }
  function getAvailableTeacherList() {
    const admin_id = props.user && props.user.user_id;
    axios
      .post(`v1/admin/${admin_id}/teacher/`, {
        session_schedule_id: props?.schedule?.schedule[0]?.session_schedule_id,
      })
      .then((res) => {
        if (res.status == 200) {
          let data = res && res.data && res.data.data;
          data.map((ele, index) => {
            data[index] = { label: ele.name, value: ele.id };
            if (props.teacher && ele.name == props.teacher)
              setTeacherId(ele.id);
          });
          setTeacherList(data);
        }
      });
  }
  function onDisableClass() {
    axios
      .post(
        `/v1/admin/${user_id}/session/${disableClass}/disable-extra-session/`
      )
      .then((response) => {
        if (response && response.status === 200) {
          props.enqueueSnackbar(
            response?.data?.data?.message || 'Updated Successfully',
            { variant: 'success' }
          );
          setDisableClass(null);
          props.closeModal();
          props.handleCallBack();
        }
      })
      .catch((err) => {
        props.enqueueSnackbar(
          err?.response?.data?.data?.error || 'Something went wrong',
          { variant: 'error' }
        );
      });
  }
  function Reschedule(sessionIdData) {
    axios
      .post(`/v1/admin/${user_id}/group/${props.group_id}/re-schedule/`, {
        group_id: props.group_id,
        scheduled_at: new Date(selectedDate).toISOString(),
        session_schedule_id: sessionId || sessionIdData,
        ...(props.showTeacherField && { teacher_id: teacherId }),
      })
      .then((response) => {
        if (response && response.status === 200) {
          props.enqueueSnackbar('Updated Successfully', { variant: 'success' });
          handleDateChange(null);
          handlesessionId(null);
          props.closeModal();
          props.handleCallBack();
        }
      })
      .catch((err) => {
        const data = err?.response?.data?.data?.error;
        props.enqueueSnackbar(data || 'Something Went Wrong', {
          variant: 'error',
        });
      });
  }

  function EndClass(startedAt, session_ID) {
    const user_id = props.user.user_id;
    axios
      .post(`/v1/admin/${user_id}/group/${props.group_id}/end-class/`, {
        group_id: props.group_id,
        started_at: new Date(startedAt).toISOString(),
        completed_at: new Date().toISOString(),
        session_schedule_id: session_ID,
      })
      .then((response) => {
        if (response && response.status === 200) {
          props.enqueueSnackbar('Updated Successfully', { variant: 'success' });
          handleDateChange(null);
          handlesessionId(null);
          props.closeModal();
          props.handleCallBack();
        }
      })
      .catch((err) => {
        const data = err?.response?.data?.data?.error;
        props.enqueueSnackbar(data || 'Something Went Wrong', {
          variant: 'error',
        });
      });
  }

  function getLabel(status) {
    switch (status) {
      case 'completed':
        return 'Class Completed';
      case 'missed':
        return 'Class Missed';
      case 'not_completed':
        return 'Class Not Completed';
      case 'started':
        return 'Class Started';
      case 'scheduled':
        return 'Class Scheduled At';
      default:
        return 'Class Scheduled At';
    }
  }

  return disableClass ? (
    <SaveChangesModal
      openModal={disableClass}
      closeModal={() => setDisableClass(null)}
      text={'Do you want to disable extra class?'}
      onSave={() => onDisableClass()}
      style={{ width: '40vw' }}
    />
  ) : (
    <Modal open={props.group_id} onClose={props.closeModal}>
      <div
        style={{
          backgroundColor: '#fff',
          margin: 'auto',
          marginTop: 100,
          width: '60vw',
          padding: '15px',
          position: 'relative',
          height: '80vh',
          overflowY: 'scroll',
        }}
      >
        <div
          style={{
            position: 'absolute',
            right: 25,
            top: 20,
            fontSize: 20,
            cursor: 'pointer',
          }}
          onClick={props.closeModal}
        >
          X
        </div>
        <Typography style={{ fontSize: 20, alignSelf: 'center', padding: 5 }}>
          Schedule List
        </Typography>
        <div>
          <List>
            {props.schedule &&
              props.schedule.schedule &&
              props.schedule.schedule.map((schedule) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 10,
                    gap: 10,
                  }}
                >
                  <div style={{ display: 'flex', gap: 10 }}>
                    <label
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      {schedule && schedule.order}.
                    </label>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <div
                        onClick={() => {
                          schedule.status == 'completed'
                            ? null
                            : handlesessionId(schedule.session_schedule_id);
                        }}
                      >
                        <DateTimePicker
                          variant='inline'
                          label={getLabel(schedule.status)}
                          value={
                            selectedDate &&
                            sessionId == schedule.session_schedule_id
                              ? selectedDate
                              : schedule.scheduled_at
                          }
                          onChange={handleDateChange}
                          disabled={
                            schedule.status == 'completed' ||
                            schedule.status == 'started'
                              ? true
                              : false
                          }
                          dateRangeIcon
                          disablePast={
                            schedule.status != 'completed' &&
                            sessionId == schedule.session_schedule_id
                              ? true
                              : false
                          }
                          InputProps={
                            schedule.status == 'completed' ||
                            schedule.status == 'started'
                              ? null
                              : {
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <Tooltip title='Reschedule'>
                                        <IconButton>
                                          <DateRange />
                                        </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  ),
                                }
                          }
                        />
                      </div>
                    </MuiPickersUtilsProvider>
                  </div>
                  {(sessionId == schedule.session_schedule_id &&
                    selectedDate) ||
                  props.autoShowTeacher ? (
                    <>
                      {props.showTeacherField ? (
                        <FormControl style={{ minWidth: 120 }}>
                          <InputLabel>Select Teacher</InputLabel>
                          <Select
                            onChange={(event) => {
                              setTeacherId(event.target.value);
                            }}
                            value={teacherId || ''}
                          >
                            {teacherList?.map((teacherData) => (
                              <MenuItem value={teacherData.value}>
                                {teacherData.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : null}
                      <div style={{ margin: 'auto 10px' }}>
                        <List
                          style={{
                            display: 'flex',
                            gap: 10,
                            paddingTop: 0,
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() =>
                              Reschedule(
                                props.autoShowTeacher &&
                                  props?.schedule?.schedule[0]
                                    ?.session_schedule_id
                              )
                            }
                          >
                            Reschedule
                          </Button>
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                              handleDateChange(null);
                              handlesessionId(null);
                            }}
                          >
                            Reset
                          </Button>
                        </List>
                      </div>
                    </>
                  ) : props.extraClass ? (
                    <Tooltip title='Disable Class'>
                      <IconButton
                        onClick={() =>
                          setDisableClass(schedule.session_schedule_id)
                        }
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {schedule.status == 'not_completed' ? (
                    <div style={{ margin: 'auto 10px' }}>
                      <List style={{ display: 'flex', gap: 10 }}>
                        <Button
                          variant='contained'
                          color='secondary'
                          onClick={() =>
                            EndClass(
                              schedule.started_at,
                              schedule.session_schedule_id
                            )
                          }
                        >
                          End Class
                        </Button>
                      </List>
                    </div>
                  ) : null}
                </div>
              ))}
          </List>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withSnackbar(connect(mapStateToProps)(ClassScheduleModal));
