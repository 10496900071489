import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import axios from 'axios';
import moment from 'moment';
import { Button, CircularProgress } from '@material-ui/core';

import './style.scss';
import closeIcon from '../../../assets/close-icon.png';
import Col from '../../../components/Elements/Flex/col';
import Row from '../../../components/Elements/Flex/row';

const AddModuleModal = (props) => {
  const [moduleData, setModuleData] = useState({
    name: null,
    description: null,
    image: null,
  });
  const [loader, setLoader] = useState(false);
  const [imgLoader, setImgLoader] = useState(false);
  const [apiError, setApiError] = useState('');

  const {
    closeModal,
    existingModuleData,
    editExistingModule,
    adminId,
    packageName,
    className,
    selectedPackageId,
    selectedClassId,
    fetchFlashCards,
  } = props;

  const handleInput = (key, value) => {
    let moduleDataObj = { ...moduleData };
    moduleDataObj[key] = value;
    setModuleData(moduleDataObj);
  };

  const imageHandler = (e) => {
    setImgLoader(true);

    const bodyFormData = new FormData();
    bodyFormData.append('content_type', 'image');
    bodyFormData.append('bucket_name', 'lq-live');
    bodyFormData.append(
      'key',
      `flashcard/${moment().format('YYYYMMDDHHmmssSSS')}_AID_${adminId}`
    );
    bodyFormData.append('file', e.target.files[0]);

    axios({
      method: 'post',
      url: `v1/admin/${adminId}/s3/upload/`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        if (response.status === 200) {
          handleInput('image', response.data.data);
          setImgLoader(false);
        }
      })
      .catch((error) => {
        console.log('error! Something went wrong.', error.message);
        setImgLoader(false);
      });
  };

  const createModule = () => {
    setLoader(true);

    axios({
      method: 'post',
      url:
        editExistingModule !== false
          ? `v1/admin/${adminId}/flashcard/update/${existingModuleData.module_id}/`
          : `v1/admin/${adminId}/flashcard/create/`,
      data: {
        ...moduleData,
        package_id: selectedPackageId,
        class_id: selectedClassId,
      },
    })
      .then((res) => {
        if (res.data.data.error) {
          setApiError(res.data.data.error[0]);
          setLoader(false);
        } else {
          if (editExistingModule !== false) {
            fetchFlashCards();
            closeModal();
          } else {
            browserHistory.push(`/interactive-flash-cards/${res.data.data.id}`);
          }
        }
      })
      .catch((err) => {
        setApiError(err.message);
        setLoader(false);
      });
  };

  const removeUploadedImg = () => {
    setModuleData({ ...moduleData, image: null });
  };

  useEffect(() => {
    if (editExistingModule !== false) {
      const { name, description, image } = existingModuleData;
      setModuleData({
        name,
        description,
        image,
      });
    }
  }, []);

  return (
    <Row className='modal-container'>
      <Col className='modal'>
        <Row className='close-icon-div' onClick={closeModal}>
          <img src={closeIcon} className='close-icon' />
        </Row>
        <Row className='add-module-header'>
          <p className='modal-title'>
            {editExistingModule !== false ? 'Edit Module' : 'Create Module'}
          </p>
        </Row>
        <Row className='grade-package'>
          <Row>
            <Row className='package-name'>{'Package:'}</Row>
            <Row className='package'>{packageName}</Row>
          </Row>
          <Row>
            <Row className='grade-name'>{'Grade:'}</Row>
            <Row className='grade'>{className}</Row>
          </Row>
        </Row>
        <Col className='add-module-form'>
          <Col>
            <p className='add-module-form-title'>Enter Module Name</p>
            <input
              value={moduleData.name}
              className='text-box'
              type='text'
              placeholder='Module Name'
              onChange={(event) => {
                handleInput('name', event.target.value);
              }}
              autoFocus
            />
          </Col>
          <Col>
            <p className='add-module-form-title'>Enter Description</p>
            <input
              value={moduleData.description}
              maxlength='200'
              className='text-box'
              type='text'
              placeholder='Module Discription'
              onChange={(event) => {
                handleInput('description', event.target.value);
              }}
            />
          </Col>
          <Col>
            <p className='add-module-form-title'>Attach Module Image</p>
            {moduleData.image ? (
              <div className='img-uploaded'>
                <Row
                  className='close-icon-div'
                  onClick={() => removeUploadedImg()}
                >
                  <img src={closeIcon} className='close-icon' />
                </Row>
                <img
                  className='module-uploaded-img'
                  src={moduleData.image}
                  alt='loading..'
                />
              </div>
            ) : (
              <button
                className='add-module-button'
                disabled={imgLoader}
                onClick={() => {
                  document.getElementById('add-module-img').click();
                }}
              >
                <input
                  type='file'
                  accept='image/*'
                  id='add-module-img'
                  onChange={(e) => {
                    imageHandler(e);
                  }}
                />
                {imgLoader ? (
                  <CircularProgress size='1vw' style={{ color: 'white' }} />
                ) : (
                  'Add Image'
                )}
              </button>
            )}
          </Col>
          {apiError && <p className='api-error'>{apiError}</p>}
        </Col>
        <Row className='add-module-action'>
          <Button
            disabled={
              loader ||
              !moduleData.name ||
              !moduleData.description ||
              !moduleData.image
            }
            className='action-btn'
            variant='contained'
            onClick={createModule}
          >
            {editExistingModule !== false ? 'Update' : 'Create'}
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({ adminId: state.user.user_id });

export default connect(mapStateToProps)(AddModuleModal);
