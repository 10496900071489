import React from 'react';
import { useDrop } from 'react-dnd';

import './style.scss';
import dropElementLogo from '../../../assets/drop-element-logo.png';
import {
  TEXT_TYPE,
  MEDIA_TYPE,
  SINGLESELECT_TYPE,
  BUTTON_TYPE,
} from '../draggableItemTypes';
import DragDropWrapper from './DragDropWrapper/DragDropWrapper';
import Col from '../../../components/Elements/Flex/col';
import Text from './Text/Text';
import MediaBox from './MediaBox/MediaBox';
import SingleSelect from './SingleSelect/SingleSelect';
import Button from './Button/Button';

const DropZone = (props) => {
  const {
    currentSlideData,
    updateState,
    updateElementData,
    rearrangeElements,
    selectedElementIndex,
    deleteComponent,
    currentSlideValidation,
    elementsAdded,
    currentSlideIndex,
  } = props;

  console.log(currentSlideData);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [TEXT_TYPE, MEDIA_TYPE, SINGLESELECT_TYPE, BUTTON_TYPE],
    drop: () => ({ name: 'dropzone' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  let selectedColor =
    currentSlideData && currentSlideData.backgroundColor
      ? currentSlideData.backgroundColor
      : null;

  return (
    <div
      className='dropzone-container'
      style={selectedColor ? { backgroundColor: selectedColor } : null}
    >
      <Col id='drop-box' boxRef={drop} className='dropzone-box'>
        {currentSlideData.slideData.length !== 0 ? (
          currentSlideData.slideData.map((element, index) =>
            element.type === 'heading-1' ||
            element.type === 'heading-2' ||
            element.type === 'text' ? (
              <DragDropWrapper
                {...{
                  index,
                  acceptType: TEXT_TYPE,
                  rearrangeElements,
                  updateState,
                }}
                key={index}
              >
                {console.log(currentSlideValidation[index])}
                <Text
                  key={element.type + index}
                  {...{
                    ...element,
                    index,
                    updateState,
                    updateElementData,
                    selectedElementIndex,
                    deleteComponent,
                    validated:
                      currentSlideValidation[index] === undefined
                        ? true
                        : currentSlideValidation[index],
                  }}
                />
              </DragDropWrapper>
            ) : element.type === 'image' ||
              element.type === 'video' ||
              element.type === 'gif' ? (
              <DragDropWrapper
                key={index}
                {...{
                  index,
                  acceptType: MEDIA_TYPE,
                  rearrangeElements,
                  updateState,
                }}
              >
                <MediaBox
                  {...{
                    ...element,
                    index,
                    updateState,
                    selectedElementIndex,
                    deleteComponent,
                    updateElementData,
                    currentSlideIndex,
                    validated:
                      currentSlideValidation[index] === undefined
                        ? true
                        : currentSlideValidation[index],
                  }}
                />
              </DragDropWrapper>
            ) : element.type === 'single-select' ? (
              <DragDropWrapper
                key={index}
                {...{
                  index,
                  acceptType: SINGLESELECT_TYPE,
                  rearrangeElements,
                  updateState,
                }}
              >
                <SingleSelect
                  {...{
                    ...element,
                    index,
                    updateState,
                    updateElementData,
                    selectedElementIndex,
                    deleteComponent,
                    validated:
                      currentSlideValidation[index] === undefined
                        ? true
                        : currentSlideValidation[index],
                    elementsAdded,
                    currentSlideIndex,
                  }}
                />
              </DragDropWrapper>
            ) : element.type === 'button' ? (
              <DragDropWrapper
                key={index}
                {...{
                  index,
                  acceptType: BUTTON_TYPE,
                  rearrangeElements,
                  updateState,
                }}
              >
                <Button
                  {...{
                    ...element,
                    index,
                    selectedElementIndex,
                    updateState,
                    updateElementData,
                    deleteComponent,
                    importedIn: 'dropZone',
                    validated:
                      currentSlideValidation[index] === undefined
                        ? true
                        : currentSlideValidation[index],
                    importedIn: 'dropZone',
                    selectOption: null, // passing to minimize error
                  }}
                />
              </DragDropWrapper>
            ) : (
              <></>
            )
          )
        ) : (
          <Col className='no-data-ui'>
            <img src={dropElementLogo} alt='drop-element-logo' />
            <p>Drag and drop elements from the left panel to start</p>
          </Col>
        )}
      </Col>
    </div>
  );
};

export default DropZone;
