import React from 'react';
import _equal from 'lodash.isequal';

function Col(props) {
  let { className, style, onClick, boxRef, id,onMouseEnter } = props;

  return (
    <div
      ref={boxRef}
      className={className ? className + ' col-flex' : 'col-flex'}
      id={id ? id : ''}
      style={style}
      onClick={onClick || null}
      onMouseEnter={onMouseEnter||null}
    >
      {props.children}
    </div>
  );
}

export default Col;
