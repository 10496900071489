import React, { Component, Fragment } from 'react';
import { Icon, Tooltip } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { IconButton, InputAdornment } from '@material-ui/core';
import DateRangePicker from 'react-daterange-picker';
import './dateRangeStyle.css';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export const CustomDateSortTooltip = (props) => {
  const [selectedDate, setSelectedDate] = React.useState(
    props.tableName.includes('Session') || props.tableName.includes('Demo Calling') ? props.date ? new Date(props.date) : new Date() : null
  );  
  const [dateRange, setDateRange] = React.useState(
    moment.range(new Date() - 7 * 24 * 60 * 60 * 1000, new Date())
  );
  const [dateRangeOpen, setDateRangeOpen] = React.useState(false);
  const handleDateChange = (date) => {
    setSelectedDate(date);

    const dateString =
      date &&
      new Date(date).toLocaleString('en-IN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });    
    props.handleClickForDateSort(dateString);
  };

  const handleDateRangeChange = (date) => {
    setDateRange(date);
    setDateRangeOpen(false);
    props.handleClickForDateSort({
      from_date: date.start.format('YYYY-MM-DD'),
      to_date: date.end.format('YYYY-MM-DD'),
    });
  };

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <>
          {dateRangeOpen && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                zIndex: 50,
                background: '#FFF',
              }}
            >
              <DateRangePicker
                value={dateRange}
                onSelect={handleDateRangeChange}
                singleDateRange={true}
                numberOfCalendars={2}
              />
            </div>
          )}
          {props.tableName.includes('Missed') ? (
            <Tooltip title='Date Filter'>
              <TextField
                onClick={() => setDateRangeOpen(!dateRangeOpen)}
                value={
                  dateRange.start.format('DD-MM-YYYY') +
                  ' to ' +
                  dateRange.end.format('DD-MM-YYYY')
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton style={{ padding: 0 }}>
                        <DateRangeIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
          ) : (
            <DatePicker
              // margin='0'
              placeholder='Date Filter'
              format='DD/MM/yyyy'
              value={selectedDate}
              onChange={handleDateChange}
              clearable
              InputProps={
                props.tableName.includes('Session') || props.tableName.includes('Demo Calling')
                  ? {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Tooltip title='Previous Date'>
                            <IconButton
                              style={{ padding: 0 }}
                              onClick={(e) => {                                
                                e.stopPropagation();
                                  handleDateChange(
                                    new Date(selectedDate - 864e5)
                                  );
                              }}
                            >
                              <KeyboardArrowLeftIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Tooltip title='Next Date'>
                            <IconButton
                              style={{ padding: 0 }}
                              onClick={(e) => {
                                e.stopPropagation(),
                                  handleDateChange(
                                    new Date(selectedDate - -864e5)
                                  );
                              }}
                            >
                              <KeyboardArrowRightIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }
                  : {
                      endAdornment: (
                        <InputAdornment position='end'>
                          {!selectedDate ? (
                            <Tooltip title='Date Filter'>
                              <IconButton style={{ padding: 0 }}>
                                <DateRangeIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title='Clear Filter'>
                              <IconButton
                                style={{ padding: 0 }}
                                onClick={(e) => {
                                  e.stopPropagation(), handleDateChange(null);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }
              }
            />
          )}
        </>
      </MuiPickersUtilsProvider>
    </Fragment>
  );
};
