import React from 'react';
import ReactDOM from 'react-dom';
import App from './app.js';

import './index.scss'
import axios from 'axios';
import configureStore from './store/configureStore';

const store = configureStore(window.__REDUX_STATE__);
axios.defaults.baseURL =
  process.env.REACT_APP_LQLIVE_ADMIN_BACKEND_SERVER ||
  'https://lq-live-api.logiqidstest.com';

ReactDOM.render(<App />, document.getElementById('root'));
