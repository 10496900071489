import React, { Component, Fragment } from 'react';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline'; // Can cause issues in React Strict mode
// import globalStyles from '../styles/global-styles';
import AppDrawer from './AppDrawer';
import SweetAlertBox from './SweetAlertBox';

export default class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            title: '',
            text: '',
            type: '',
            // html: '',
            onCancel: null,
            showCancel: null,
            confirmButtonText: null,
            cancelButtonText: null,
            onConfirm: null,
        };
        this.showAlert = this.showAlert.bind(this);
        this.handleAlertClose = this.handleAlertClose.bind(this);
    }

    showAlert(
        title,
        text,
        type,
        confirmButtonText,
        onConfirm,
        showCancel,
        onCancel,
        cancelButtonText,
    ) {
        this.setState({
            showAlert: true,
            title,
            text,
            type,
            onCancel,
            showCancel,
            confirmButtonText,
            cancelButtonText,
            onConfirm,
        });
    }

    handleAlertClose() {
        this.setState({
            showAlert: false,
        });
    }

    renderChildrens() {
        const that = this;
        return React.Children.map(this.props.children, (child) => {
            const clonedProps = {
                ...child.props,
                showAlert: that.showAlert,
                handleAlertClose: that.handleAlertClose,
            };
            return React.cloneElement(child, clonedProps);
        });
    }

    render() {
        // globalStyles();
        const contentToBeLoaded = this.renderChildrens();
        console.log(this.props);
        return (
            <SnackbarProvider maxSnack={5} autoHideDuration={5000}>
                <Fragment>
                    {/* <CssBaseline /> */}
                    {this.state.showAlert ? (
                        <SweetAlertBox
                            title={this.state.title}
                            text={this.state.text.toString()}
                            handleAlertClose={this.handleAlertClose}
                            show={this.state.showAlert}
                            type={this.state.type}
                            showCancel={!!this.state.showCancel}
                            onCancel={this.state.onCancel}
                            onConfirm={this.state.onConfirm}
                            confirmButtonText={this.state.confirmButtonText}
                            cancelButtonText={this.state.cancelButtonText}
                        />
                    ) : null}
                   <AppDrawer routes={this.props.route && this.props.route.childRoutes} content={contentToBeLoaded} path={this.props.location && this.props.location.pathname} />
                </Fragment>
            </SnackbarProvider>
        );
    }
}
