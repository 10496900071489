import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  CircularProgress,
  Chip,
  InputAdornment,
  Icon,
  IconButton,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Divider,
} from '@material-ui/core';
import moment from 'moment';
import UpdateResolution from '../UpdateResolution';

class MonthlyFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(moment(new Date()).subtract(1, 'months')),
      rating: null,
      feedbackData: null,
      ratingValue: null,
      ratingType: '',
      ratingTypeList: ['teaching', 'content', 'platform', 'program'],
      page: 0,
      no_of_rows: 50,
      count: null,
      selectPackageState: false,
      packageData: null,
      packageId: 4,
      feedbackResponse: null,
      ratingTypes: '',
    };
  }

  componentDidMount() {
    this.getPackage();
  }

  fetchRatingData = () => {
    const month = new Date(this.state.date).getMonth() + 1;
    const year = new Date(this.state.date).getFullYear();
    const packageParams = this.state.packageId
      ? `&package_id=${this.state.packageId}`
      : '';
    axios
      .get(
        `/v1/admin/${this.props.adminId}/monthly-feedback/count/?month=${month}&year=${year}&rating_type=${this.state.ratingType}${packageParams}`
      )
      .then((res) => {
        if (res?.status == 200 || (304 && res.data)) {
          const { ratings } = res.data.data;
          this.setState({
            rating: ratings,
            page: 0,
          });
          if (this.state.ratingValue) {
            this.fetchFeedbackData(this.state.ratingValue);
          }
        }
      });
  };
  convertToTitleCase = (string) => {
    return (
      string.charAt(0).toUpperCase() + string.replaceAll('_', ' ').slice(1)
    );
  };
  fetchFeedbackData = (value) => {
    const { packageId, page, no_of_rows, ratingType, date } = this.state;
    const month = new Date(date).getMonth() + 1;
    const year = new Date(date).getFullYear();
    this.setState({ feedbackData: null });
    const packageParams = packageId ? `&package_id=${packageId}` : '';
    axios
      .get(
        `/v1/admin/${
          this.props.adminId
        }/monthly-feedback/list/?month=${month}&year=${year}&rating_type=${ratingType}&rating=${value}${packageParams}&page=${
          page + 1
        }&page_size=${no_of_rows}`
      )
      .then((res) => {
        if (res?.status == 200 && res?.data?.data) {
          const { results, count } = res.data.data;
          this.setState({
            feedbackData: res.data.data?.details,
            feedbackResponse: res.data,
            ratingValue: value,
            count: count,
          });
        }
      });
  };

  getPackage = () => {
    const admin_id = this.props && this.props.adminId;
    axios
      .get(`/v1/admin/${admin_id}/assign-package/?paid=1`)
      .then((res) => {
        if (res && res.data) {
          console.log(res.data.data, 'res');
          this.setState({ packageData: res.data.data });
        }
      })
      .catch((err) => {
        console.log('Something went wrong!');
      });
  };

  render() {
    const {
      rating,
      feedbackData,
      ratingValue,
      ratingTypeList,
      ratingType,
      count,
      page,
      selectPackageState,
      packageData,
      packageId,
      feedbackResponse,
      ratingTypes,
    } = this.state;
    const cellStyle = {
      textAlign: 'center',
      fontSize: '0.85vw',
      fontWeight: 600,
      border: '1px solid #e0e0e0',
      borderRadius: 10,
      padding: '0.5vw 1.5vw',
      height: 'fit-content',
      // zIndex: ratingTypes === 'program' ? 99 : '',
    };
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2vw' }}>
        <div style={{ fontSize: '1.5vw', fontWeight: 600 }}>
          Monthly Feedback
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              gap: '1vw',
            }}
          >
            <div style={{ display: 'flex', gap: '10vw' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  width: '60vw',
                }}
              >
                {ratingTypeList?.map((ratingTypeListData) => (
                  <div
                    onClick={() => {
                      this.setState({ ratingType: ratingTypeListData }, () => {
                        if (
                          ratingTypeListData !== 'teaching' &&
                          ratingTypeListData !== 'content'
                        ) {
                          this.fetchRatingData();
                        }
                        if (
                          packageId &&
                          (ratingTypeListData === 'teaching' ||
                            ratingTypeListData === 'content')
                        ) {
                          this.fetchRatingData();
                        }
                      });
                      if (
                        ratingTypeListData === 'teaching' ||
                        ratingTypeListData === 'content'
                      ) {
                        this.setState({ selectPackageState: true });
                      } else {
                        this.setState({ selectPackageState: false });
                      }
                      if (ratingType != ratingTypeListData)
                        this.setState({
                          ratingTypes: ratingTypeListData,
                          ratingType:
                            ratingTypeListData === 'teaching'
                              ? 'mentor'
                              : ratingTypeListData === 'content'
                              ? 'subject'
                              : ratingTypeListData === 'program'
                              ? 'recommendation'
                              : ratingTypeListData,
                        });
                    }}
                    style={{
                      textAlign: 'center',
                      fontSize: '1vw',
                      fontWeight: 600,
                      border: '1px solid #e0e0e0',
                      borderRadius: 10,
                      padding: '1vw 4vw',
                      backgroundColor:
                        ratingTypes == ratingTypeListData
                          ? '#bf5af2'
                          : 'transparent',
                      height: 'fit-content',
                      color: ratingTypes == ratingTypeListData ? '#fff' : '',
                      cursor: 'pointer',
                    }}
                  >
                    <div>
                      {ratingTypeListData.charAt(0).toUpperCase() +
                        ratingTypeListData.slice(1)}
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ width: '15vw' }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    onChange={(date) => {
                      this.setState({ date: new Date(date) }, () =>
                        this.fetchRatingData()
                      );
                    }}
                    value={this.state.date}
                    views={['year', 'month']}
                    label='Month and Year'
                    format='MM/yyyy'
                    inputVariant='outlined'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Tooltip title='Schedule'>
                            <IconButton>
                              <Icon>date_range_icon</Icon>
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            {selectPackageState ? (
              <div
                style={{
                  width: '20vw',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <FormControl style={{ minWidth: '12vw', marginLeft: '-3vw' }}>
                  <InputLabel>Select Package</InputLabel>
                  <Select
                    onChange={(e) => {
                      this.setState({ packageId: e.target.value }, () => {
                        this.fetchRatingData();
                      });
                    }}
                    value={packageId}
                  >
                    {packageData?.map((value) => {
                      return <MenuItem value={value.id}>{value.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            ) : null}
            {rating ? (
              <div
                style={{
                  display: 'flex',
                  // justifyContent: 'space-evenly',
                  width: '90vw',
                  flexWrap: 'wrap',
                  gap: ratingTypes !== 'program' ? '1vw' : '0.5vw',
                  padding:
                    ratingTypes !== 'program' ? '0.5vw 1.5vw' : '0.5vw 1vw',
                }}
              >
                {rating?.map((ratingData) => (
                  <div
                    onClick={() => {
                      this.setState({ page: 0 }, () =>
                        this.fetchFeedbackData(ratingData.value)
                      );
                    }}
                    style={{
                      ...cellStyle,
                      backgroundColor:
                        ratingValue == ratingData.value
                          ? '#bf5af2'
                          : 'transparent',
                      color: ratingValue == ratingData.value ? '#fff' : '',
                      cursor: 'pointer',
                    }}
                  >
                    <div>{ratingData.name}</div>
                    <div>{ratingData.count}</div>
                  </div>
                ))}
                <div style={{ ...cellStyle, background: 'gainsboro' }}>
                  <div>Total Feedback</div>
                  <div>
                    {rating
                      ?.map((item) => item.count)
                      .reduce((prev, next) => prev + next)}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {feedbackData?.length ? (
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(feedbackData[0]).map((header) => (
                  <TableCell
                    style={{
                      textAlign: 'left',
                      fontSize: '1vw',
                      fontWeight: 600,
                      border: '1px solid #e0e0e0',
                      padding: '6px 24px 6px 16px',
                      height: '10vh',
                      backgroundColor: '#e0e0e0',
                    }}
                  >
                    {this.convertToTitleCase(header)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {feedbackData.map((feedback, index) => (
                <TableRow>
                  {Object.keys(feedbackData[0]).map((header) => (
                    <TableCell
                      style={{
                        textAlign: 'left',
                        fontSize: '1vw',
                        fontWeight: 500,
                        border: '1px solid #e0e0e0',
                        padding: '0.5vw 1vw',
                        height: '7vh',
                        minWidth: '5%',
                        maxWidth: '20%',
                        width:
                          header === 'resolution'
                            ? '20%'
                            : header === 'ratings'
                            ? '15%'
                            : '7%',
                      }}
                    >
                      {feedback[header] ? (
                        header === 'resolution' ? (
                          <UpdateResolution
                            remark={feedback[header].resolution_remark}
                            isResolved={feedback[header].is_resolved}
                            onSuccess={() => this.fetchRatingData()}
                            apiUrl={`/v1/admin/${this.props.adminId}/monthly-feedback/${feedbackData[index].feedback_id}/resolve/`}
                          />
                        ) : header.includes('date') ? (
                          new Date(feedback[header]).toLocaleString()
                        ) : Array.isArray(feedback[header]) ? (
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 5,
                            }}
                          >
                            {feedback[header].map((feedbackArrayData) => (
                              <Chip
                                label={feedbackArrayData}
                                variant='outlined'
                                style={{ height: 'fit-content' }}
                              />
                            ))}
                          </div>
                        ) : typeof feedback[header] === 'object' ? (
                          Object.entries(feedback[header])?.map(
                            ([key, value]) => {
                              return (
                                <div
                                  style={{
                                    fontSize: '0.95vw',
                                    margin: '0.25vw 0',
                                    border: '1px solid grey',
                                    padding: '0.25vw',
                                    borderRadius: 4,
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  {typeof value === 'object' ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.25vw',
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: '0.95vw',
                                          fontWeight: 700,
                                        }}
                                      >
                                        {this.convertToTitleCase(key)}
                                      </div>
                                      <Divider
                                        style={{ backgroundColor: 'gray' }}
                                      />
                                      {Object.entries(value)?.map(
                                        ([packageKey, packageValue]) => (
                                          <div>
                                            {this.convertToTitleCase(
                                              packageKey
                                            )}
                                            : {packageValue}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      {this.convertToTitleCase(key)}: {value}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )
                        ) : (
                          feedback[header]
                        )
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : feedbackData?.length == 0 || feedbackResponse?.data?.length == 0 ? (
          <div style={{ margin: 'auto' }}>No data</div>
        ) : ratingValue ? (
          <CircularProgress style={{ margin: 'auto' }} />
        ) : null}
        {count ? (
          <TablePagination
            className='teacherTrainerPagination'
            rowsPerPageOptions={[50]}
            component='div'
            count={count}
            rowsPerPage={50}
            page={page}
            onChangePage={(event, newPage) => {
              this.setState({ page: newPage }, () =>
                this.fetchFeedbackData(this.state.ratingValue)
              );
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminId: state.user?.user_id,
  };
};

export default connect(mapStateToProps)(MonthlyFeedback);
