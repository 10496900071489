import { TOP_LOADER_DISABLE, TOP_LOADER_ENABLE } from '../actions/types';

const INITIAL_STATE = {
    topLoader: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case TOP_LOADER_ENABLE:
            return Object.assign({}, state, {
                topLoader: true,
            });
        case TOP_LOADER_DISABLE:
            return Object.assign({}, state, {
                topLoader: false,
            });
        default:
            return state;
    }
}
