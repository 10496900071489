import React from 'react';
import { CircularProgress } from '@material-ui/core';

import './style.scss'
import Col from '../Elements/Flex/col';

const LoaderErrorBox = (props) => {
  const { loader, loaderSize, textSize, errorMsg, normalMsg } = props;

  return (
    <Col className='error-loader-box' style={{ fontSize: textSize }}>
      {loader ? (
        <>
          <CircularProgress size={loaderSize} />
          Loading...
        </>
      ) : (
        <p
          className={errorMsg ? 'api-error' : ''}
          style={{ fontSize: textSize }}
        >
          {errorMsg || normalMsg}
        </p>
      )}
    </Col>
  );
};

export default LoaderErrorBox
