import React, { useState } from 'react';
import { Link } from 'react-router';
import { IconButton, Icon, Tooltip, makeStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Table from '../../components/LQDataTables/demo';
import axios from 'axios';
import { connect } from 'react-redux';
import ExpandedReview from '../../components/Expanded Review/expanded_review';
import ContentHistoryModal from '../../components/ContentHistoryModal';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },

  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});

let tableRef = null;

const ref = React.createRef();

function QuestionTable(props) {
  const classes = userStyles();
  function handleCallBack() {
    tableRef && tableRef.refreshTable();
  }
  const [questionPreview, setQuestionPreview] = useState(null);
  const [contentUpdateModal, setContentUpdateModal] = useState(null);
  function questionDetails(questionId) {
    const user_id = props.user && props.user.user_id;
    axios
      .get(`/v1/admin/${user_id}/question/${questionId}/edit/`)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          const details = response && response.data && response.data.data;
          const questionValue = {
            type: details && details.activity_type,
            order: '1',
            text: details && details.question && details.question.text,
            image: details && details.question && details.question.image,
            user_response:
              details &&
              details.answer &&
              details.activity_type &&
              (details.activity_type == 'Multi Select' ||
              details.activity_type == 'Single Select' ||
              details.activity_type == 'Tap And Type' ||
              details.activity_type == 'Tap And Fill'
                ? eval(details.answer)
                : details.answer),
            answer:
              details &&
              details.answer &&
              details.activity_type &&
              (details.activity_type == 'Multi Select' ||
              details.activity_type == 'Single Select' ||
              details.activity_type == 'Tap And Type' ||
              details.activity_type == 'Tap And Fill'
                ? eval(details.answer)
                : details.answer),
            options: details && details.options,
            solution_image:
              details && details.solution && details.solution.sol_img,
            solution_text:
              details && details.solution && details.solution.sol_text,
            timed_out: false,
            is_correct: true,
            media: details && details.question && details.question.media,
            media_type:
              details && details.question && details.question.media_type,
          };
          setQuestionPreview(questionValue);
        }
      })
      .catch((error) => {
        props.enqueueSnackbar('Question Details Could not be fetched', {
          variant: 'warning',
        });
        // props.hideTopLoader();
        console.log(error);
      });
  }

  const ref = React.createRef();
  function formatTable(tableData) {
    const newData =
      tableData &&
      tableData.map((entry, index) => {
        const { id, question, solution, options, activity_type, answer } =
          entry;

        const { classes, ...newEntry } = { ...entry };
        newEntry.options = (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            {options.map((option, index) => (
              <div style={{ display: 'flex', gap: 10 }}>
                {option.order && <div>{option.order})</div>}
                {option.text && <div>{option.text}</div>}
                {option.image && (
                  <img
                    src={option.image}
                    style={{
                      objectFit: 'scale-down',
                      maxHeight: 100,
                      maxWidth: 300,
                    }}
                  />
                )}
                {activity_type == 'Single Select' &&
                  option &&
                  option.order &&
                  answer.slice(1, 2) == option.order && (
                    <div style={{ alignSelf: 'center' }}> &#9989;</div>
                  )}
              </div>
            ))}
          </div>
        );

        newEntry.question = (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {question && (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    question.text &&
                    question.text.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                }}
              />
            )}
            {question.image && (
              <img
                src={question.image}
                style={{
                  objectFit: 'scale-down',
                  maxHeight: 200,
                  maxWidth: 300,
                }}
              />
            )}
            {question.media && question.media_type ? (
              question.media_type == 'audio' ? (
                <audio controls>
                  <source src={question.media} />
                </audio>
              ) : (
                <video width='auto' height='200px' controls>
                  <source src={question.media} />
                </video>
              )
            ) : null}
          </div>
        );
        newEntry.solution = solution &&
          (solution.sol_img || solution.sol_text) && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {solution && (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      solution.sol_text &&
                      solution.sol_text.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                  }}
                />
              )}
              {solution.sol_img && (
                <img
                  src={solution.sol_img}
                  style={{
                    objectFit: 'scale-down',
                    maxHeight: 200,
                    maxWidth: 300,
                  }}
                />
              )}
            </div>
          );
        const updatedEntry = {
          edit: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Link
                to={{
                  pathname: '/questions/edit',
                  search: `?questionId=${id}`,
                }}
                target='_blank'
                style={{ textDecoration: 'none' }}
              >
                <IconButton>
                  <Tooltip title='Edit'>
                    <Icon className='iconButton'>edit</Icon>
                  </Tooltip>
                </IconButton>
              </Link>
              <IconButton onClick={() => questionDetails(id)}>
                <Tooltip title='Show Question Preview'>
                  <Icon className='iconButton'>view_carousel</Icon>
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() =>
                  setContentUpdateModal({
                    adminId: props?.user?.user_id,
                    params: `question_id=${id}`,
                  })
                }
              >
                <Tooltip title='View Content Update History'>
                  <Icon className='iconButton'>manage_search_icon</Icon>
                </Tooltip>
              </IconButton>
            </div>
          ),
          ...newEntry,
        };
        return Object.keys(updatedEntry).map((subEntry) =>
          updatedEntry[subEntry] ? '' || updatedEntry[subEntry] : '-'
        );
      });
    return newData;
  }

  return (
    <div>
      <Table
        onRef={(ref) => {
          tableRef = ref;
        }}
        firstCol='edit'
        createNew='/questions/create'
        headerFilterList={HEADER_FILTER_LIST}
        formatTable={formatTable}
        sortList={SORT_LIST}
        sortDate={SORT_DATE}
        search={props.location && props.location.query}
        tableName='Question Table'
        title='Question Table'
        scrollType='stacked'
        downloadPDFOption={true}
        apiUrl={`question/list/`}
      />
      {questionPreview && (
        <ExpandedReview
          question={questionPreview}
          close={() => setQuestionPreview(null)}
        />
      )}
      {contentUpdateModal ? (
        <ContentHistoryModal
          openModal={contentUpdateModal ? true : false}
          closeModal={() => setContentUpdateModal(null)}
          adminId={contentUpdateModal.adminId}
          params={contentUpdateModal.params}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withSnackbar(connect(mapStateToProps)(QuestionTable));

const HEADER_FILTER_LIST = [
  {
    filter_label: 'id',
    type: 'text',
  },
  {
    filter_label: 'question',
    type: 'text',
  },
  {
    filter_label: 'activity_type',
    type: 'text',
  },
  {
    filter_label: 'class_type',
    type: 'text',
  },
  {
    filter_label: 'grade',
    type: 'text',
  },
  {
    filter_label: 'session',
    type: 'text',
  },
  {
    filter_label: 'activity_name',
    type: 'text',
  },
  {
    filter_label: 'solution',
    type: 'text',
  },
  {
    filter_label: 'package',
    type: 'text',
  },
];
const SORT_LIST = ['id'];
const SORT_DATE = [];
