import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import axios from 'axios';
import moment from 'moment';

import './style.scss';
import closeIcon from '../../../../../assets/close-icon.png';
import Col from '../../../../../components/Elements/Flex/col';
import Row from '../../../../../components/Elements/Flex/row';

class SolutionImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
  }

  imageHandler = (value) => {
    this.setState({ loader: true });

    const { updateElementData, index } = this.props;
    const adminId = this.props?.user?.user_id;
    let dataURl = value;

    const bodyFormData = new FormData();
    bodyFormData.append('content_type', 'image');
    bodyFormData.append('bucket_name', 'lq-live');
    bodyFormData.append(
      'key',
      `flashcard/${moment().format('YYYYMMDDHHmmssSSS')}_AID_${adminId}`
    );
    bodyFormData.append('file', dataURl);

    axios({
      method: 'post',
      url: `v1/admin/${adminId}/s3/upload/`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        if (response.status === 200) {
          const { solution } = this.props;
          let solutionObj = JSON.parse(JSON.stringify(solution));
          solutionObj.image['imageUrl'] = response.data.data;

          updateElementData(index, {
            key: 'solution',
            value: solutionObj,
          });
          this.setState({ loader: false });
        }
      })
      .catch((error) => {
        console.log('error! Something went wrong.', error.message);
        this.setState({ loader: false });
      });
  };

  deleteSingleSelectImage = () => {
    const { updateElementData, index, solution } = this.props;

    const solutionObj = JSON.parse(JSON.stringify(solution));
    delete solutionObj.image;

    updateElementData(index, {
      key: 'solution',
      value: solutionObj,
    });
  };

  pasteFromClipboard = () => {
    console.log('clipboard');
    navigator.permissions
      .query({ name: 'clipboard-read' })
      .then(async (result) => {
        // If permission to read the clipboard is granted or if the user will
        // be prompted to allow it, we proceed.

        if (result.state == 'granted' || result.state == 'prompt') {
          const clipboardData =
            navigator &&
            navigator.clipboard &&
            (await navigator.clipboard.read());

          let image_type;
          const item =
            clipboardData &&
            clipboardData.find((item) =>
              item.types.some((type) => {
                if (type.startsWith('image/')) {
                  image_type = type;
                  return true;
                }
              })
            );
          var file = item && (await item.getType(image_type));
          if (file) {
            this.addImagePropPromise().then(() => {
              this.imageHandler(file);
            });
          }
        }
      });
  };

  addImagePropPromise = () => {
    return new Promise((resolve) => {
      const { solution, updateElementData, index } = this.props;
      const solutionObj = JSON.parse(JSON.stringify(solution)) || {};
      solutionObj.image = { type: 'image', imageUrl: '' };

      updateElementData(index, {
        key: 'solution',
        value: solutionObj,
      });
      return resolve();
    });
  };

  render() {
    const {
      type,
      index,
      imageUrl,
      updateState,
      selectedElementIndex,
      validated,
      updateElementData,
      solution,
      currentSlideIndex,
    } = this.props;
    const { loader } = this.state;

    const isSelected = selectedElementIndex === index ? true : false;

    return (
      <div
        className={`solution-img-parent ${isSelected ? 'selected' : null} ${
          !validated ? 'error' : ''
        }`}
        onClick={() => {
          updateState({ selectedElementIndex: index });
          imageUrl
            ? null
            : document
                .getElementById(`paste-input-sol${index}${currentSlideIndex}`)
                .focus();
        }}
      >
        {/* onPaste image hidden input */}
        <input
          id={`paste-input-sol${index}${currentSlideIndex}`}
          className={`text-box`}
          type='text'
          placeholder={'paste image here'}
          onClick={() =>
            imageUrl ? null : updateState({ selectedElementIndex: index })
          }
          onPaste={() => {
            imageUrl ? null : this.pasteFromClipboard();
          }}
          autoFocus
        />
        {imageUrl ? (
          <Col
            className={`media-box-container-image`}
            onClick={() => {
              updateState({ selectedElementIndex: index });
            }}
          >
            <img className='media-box-image' src={imageUrl} />
            {isSelected && (
              <Row
                className='close-icon-div'
                onClick={(event) => {
                  event.stopPropagation();
                  this.deleteSingleSelectImage();
                }}
              >
                <img src={closeIcon} className='close-icon' />
              </Row>
            )}
          </Col>
        ) : loader ? (
          <CircularProgress size={24} className='loader' />
        ) : (
          <>
            <Row className='input-row'>
              <input
                type='file'
                accept='image/*'
                id='input-image-solution'
                onClick={() => {
                  const solutionObj =
                    JSON.parse(JSON.stringify(solution)) || {};
                  solutionObj.image = { type: 'image', imageUrl: '' };

                  updateElementData(index, {
                    key: 'solution',
                    value: solutionObj,
                  });
                }}
                onChange={(e) => {
                  this.imageHandler(e.target.files[0]);
                }}
              />
              <label
                htmlFor='input-image-solution'
                className='input-image-label'
              >
                Add image
              </label>
              <CloudUploadOutlined className='upload-icon' />
            </Row>
            <div className='or-text'>or</div>
            <div className='text-image-label'>CLICK & PASTE IMAGE HERE</div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(SolutionImage);
