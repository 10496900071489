import React from 'react';
import { useDrag } from 'react-dnd';

import './style.scss';
import { SINGLESELECT_TYPE } from '../../draggableItemTypes';
import SingleSelectImg from '../../../../assets/single-select.png';

const SingleSelectComponent = (props) => {
  const singleSelectType = {
    type: 'single-select',
    answer: null,
    style: { color: '#0D0D0D', fontSize: 14, textAlign: 'left' },
    solution: {},
    optionType: 'text',
    options: [
      { sNo: 1, text: '', imageUrl: null },
      { sNo: 2, text: '', imageUrl: null },
      { sNo: 3, text: '', imageUrl: null },
      { sNo: 4, text: '', imageUrl: null },
    ],
  };

  const { addComponent } = props;

  const [{}, drag] = useDrag(() => ({
    type: SINGLESELECT_TYPE,
    item: singleSelectType,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        addComponent(item);
        console.log(`You dropped !`, item);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div ref={drag} className='single-select-component'>
      <img src={SingleSelectImg} alt='SingleSelectImg' />
      <p className='single-select'>Single Select</p>
    </div>
  );
};

export default SingleSelectComponent;
