import React, { useEffect, useState } from 'react';
import Icon from '@material-ui/core/Icon';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Link } from 'react-router';
import {
  ListItem,
  Typography,
  makeStyles,
  Modal,
  List,
  Grid,
  Button,
  Select,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Table from '../../components/LQDataTables/demo';
import ClassScheduleModal from '../../components/ClassScheduleModal';
import axios from 'axios';
import { connect } from 'react-redux';
import { MenuItem } from '@material-ui/core';
import ScheduleOrderModal from '../../components/ScheduleOrderModal';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    minWidth: 300,
  },

  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});
let initialuserData = {
  upcoming_class_time: new Date(),
  free_trial_package_class_id: '',
  teacher: '',
};
let tableRef = null;

const ref = React.createRef();

function GroupTable(props) {
  function setRowProps(row, index, tableData) {
    const classes_status = row
      ? row[11].toString().includes('Missed') ||
        row[11].toString().includes('Not Completed')
      : false;
    if (classes_status || (row[4] && row[4].props)) {
      return {
        style: {
          backgroundColor: classes_status
            ? 'rgba(255, 0, 0, 0.1)'
            : row[4] && row[4].props
            ? 'rgba(0, 255, 0, 0.1)'
            : 'none',
          border: classes_status ? '1px solid' : 'none',
        },
      };
    }
  }

  const [schedule, setschedule] = useState(null);
  const [type, setType] = useState(
    window.location.pathname.includes('paid') ? '2' : '1'
  );
  const [classStatus, setClassStatus] = useState(null);
  const [teacherList, setTeacherList] = useState(null);
  const [teacherId, setTeacherId] = useState(null);
  const [assigning, setAssigning] = useState(false);
  const [showScheduleOrderModal, setShowScheduleOrderModal] = useState(false);

  useEffect(() => {
    setType(window.location.pathname.includes('paid') ? '2' : '1');
  }, []);
  const classes = userStyles();
  function handleCallBack() {
    tableRef && tableRef.refreshTable();
  }

  function scheduleDetailsModal() {
    return (
      <ClassScheduleModal
        schedule={schedule.scheduleData}
        group_id={schedule.groupId}
        teacher={schedule.teacher}
        closeModal={() => {
          setschedule(null);
        }}
        handleCallBack={handleCallBack}
      />
    );
  }

  function getTeacherList(scheduled_at, session_schedule_id) {
    const admin_id = props && props.user && props.user.user_id;
    axios
      .post(`/v1/admin/${admin_id}/teacher/`, {
        scheduled_at: scheduled_at,
        session_schedule_id: session_schedule_id,
      })
      .then((res) => {
        if (res && res.status == 200) {
          setTeacherList(res && res.data && res.data.data);
        }
      })
      .catch((err) => {
        err?.response?.data?.data?.error?.map((error) =>
          props.enqueueSnackbar(error || 'Something Went Wrong', {
            variant: 'warning',
          })
        );
      });
  }

  function postAssignTeacher() {
    const admin_id = props && props.user && props.user.user_id;
    if (teacherId) {
      setAssigning(true);
      props.enqueueSnackbar('Assigning', { variant: 'info' });
      axios
        .post(
          `v1/admin/${admin_id}/session/${classStatus.session_schedule_id}/assign-teacher/`,
          { teacher_id: teacherId }
        )
        .then((res) => {
          if (res.status == 200 && res) {
            props.enqueueSnackbar(
              res?.data?.data?.message || `Teacher Assigned`,
              { variant: 'success' }
            );
            setClassStatus(null);
            setAssigning(false);
            setTeacherList(null);
            setTeacherId(null);
            tableRef && tableRef.refreshTable();
          }
        })
        .catch((err) => {
          err?.response?.data?.data?.error?.map((error) =>
            props.enqueueSnackbar(error || 'Something went wrong', {
              variant: 'error',
            })
          );
        });
    } else {
      props.enqueueSnackbar('Please select the teacher', { variant: 'error' });
    }
  }
  function renderScheduleOrderModal() {
    return (
      <ScheduleOrderModal
        openModal={showScheduleOrderModal}
        closeModal={() => setShowScheduleOrderModal(false)}
        groupId={showScheduleOrderModal}
        handleCallBack={handleCallBack}
      />
    );
  }
  function AssignTeacher() {
    return (
      <Modal
        open={classStatus}
        onClose={() => {
          setClassStatus(null);
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            margin: '30vh auto',
            alignItems: 'center',
            width: 600,
          }}
        >
          <div>
            <List>
              <ListItem>
                <Typography style={{ fontSize: 20 }}>Assign teacher</Typography>
              </ListItem>
              <ListItem style={{ justifyContent: 'center' }}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Select teacher</InputLabel>
                  <Select
                    value={teacherId}
                    onChange={(event) => setTeacherId(event.target.value)}
                  >
                    {teacherList &&
                      teacherList.map((teacher) => (
                        <MenuItem value={teacher.id}>{teacher.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </ListItem>
              <ListItem>
                <Grid
                  container
                  spacing={8}
                  style={{ justifyContent: 'center' }}
                >
                  <Grid item>
                    <Button
                      color='primary'
                      type='submit'
                      variant='outlined'
                      onClick={() => setClassStatus(null)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color='primary'
                      type='submit'
                      variant='outlined'
                      onClick={() => postAssignTeacher()}
                    >
                      Assign
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </div>
        </div>
      </Modal>
    );
  }

  const ref = React.createRef();
  function formatTable(tableData) {
    const newData =
      tableData &&
      tableData.map((entry, index) => {
        const assign_teacher_pending =
          entry &&
          entry.classes_completed &&
          entry.classes_completed.assign_teacher_pending;
        const {
          id,
          student_id,
          group_type,
          name,
          grade,
          student_name,
          user_id,
          classes_completed,
          teacher,
          class_status,
          phone,
        } = entry;
        const newEntry = { ...entry };
        console.log('entry', entry);
        (newEntry.class_status =
          class_status &&
          new Date(class_status.scheduled_at).toLocaleString('en-IN', {
            year: 'numeric',
            month: '2-digit',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }) +
            ' - ' +
            class_status.status +
            ` Session - ${class_status.order}`),
          (newEntry.student_id = (
            <div>
              {student_id && Array.isArray(student_id)
                ? student_id.map((id, index) => (
                    <Link
                      to={{
                        pathname: '/user',
                        search: `?id=${id}`,
                      }}
                      target='_blank'
                      style={{ textDecoration: 'none' }}
                    >
                      <div>{id}</div>
                    </Link>
                  ))
                : student_id}
            </div>
          )),
          (newEntry.student_name = (
            <div>
              {student_name.map((studentDetails, index) => (
                <Link
                  to={{
                    pathname: '/student-session-details',
                    search: `?student_id=${student_id && student_id[index]}`,
                  }}
                  target='_blank'
                  style={{ textDecoration: 'none' }}
                >
                  <div>
                    {studentDetails.student_name}
                    {studentDetails.browser && `(${studentDetails.browser})`}
                  </div>
                </Link>
              ))}
            </div>
          )),
          (newEntry.phone =
            phone &&
            phone.map((mobile_number, index) => <div>{mobile_number}</div>)),
          (newEntry.classes_completed = classes_completed.classes_completed);
        newEntry.teacher =
          (assign_teacher_pending && (
            <IconButton
              onClick={() => {
                getTeacherList(
                  class_status.scheduled_at,
                  class_status.session_schedule_id
                ),
                  setClassStatus(class_status);
              }}
            >
              <Tooltip title='Assign Teacher'>
                <Icon className='iconButton'>person_add</Icon>
              </Tooltip>
            </IconButton>
          )) ||
          teacher;
        const updatedEntry = {
          edit: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Link
                to={{
                  pathname: '/group-class/edit',
                  search: `?groupId=${id}`,
                }}
                target='_blank'
                style={{ textDecoration: 'none' }}
              >
                <IconButton>
                  <Tooltip title='Edit'>
                    <Icon className='iconButton'>edit</Icon>
                  </Tooltip>
                </IconButton>
              </Link>

              <Link
                to={{
                  pathname: '/group-class/schedule',
                  search: `?groupId=${id}&&groupType=${
                    type == 2 ? 'PAID' : 'FREE'
                  }&&grade=${grade}`,
                }}
                target='_blank'
                style={{ textDecoration: 'none' }}
              >
                <IconButton>
                  <Tooltip title='Schedule'>
                    <Icon className='iconButton'>schedule</Icon>
                  </Tooltip>
                </IconButton>
              </Link>
              <Link
                to={{
                  pathname: '/calendar',
                  search: `?group_id=${id}`,
                }}
                target='_blank'
                style={{ textDecoration: 'none' }}
              >
                <IconButton>
                  <Tooltip title='Calendar Schedule'>
                    <DateRangeIcon />
                  </Tooltip>
                </IconButton>
              </Link>

              <IconButton
                onClick={() => {
                  setschedule({
                    scheduleData: entry.classes_completed,
                    teacher: teacher,
                    groupId: id,
                  });
                }}
              >
                <Tooltip title='Schedule List'>
                  <Icon className='iconButton'>reorder</Icon>
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() => {
                  setShowScheduleOrderModal(id);
                }}
              >
                <Tooltip title='Schedule Order'>
                  <Icon className='iconButton'>low_priority_icon</Icon>
                </Tooltip>
              </IconButton>
            </div>
          ),

          ...newEntry,
        };
        return Object.keys(updatedEntry).map((subEntry) =>
          updatedEntry[subEntry] ? '' || updatedEntry[subEntry] : '-'
        );
      });
    return newData;
  }

  return (
    <div>
      <Table
        onRef={(ref) => {
          tableRef = ref;
        }}
        firstCol='edit'
        createNew='/group-class/create'
        headerFilterList={HEADER_FILTER_LIST}
        formatTable={formatTable}
        sortList={SORT_LIST}
        tableName='group-class'
        title={
          window.location.pathname.includes('paid')
            ? 'Paid Group Class Table'
            : 'Free Trial Group Class Table'
        }
        scrollType='stacked'
        sortDate={[]}
        dateFilter={'next_class'}
        search={props.location && props.location.query}
        setRowProps={setRowProps}
        apiUrl={`group/${type}/list/`}
      />
      {schedule ? scheduleDetailsModal() : null}
      {classStatus && !assigning && teacherList && teacherList.length > 0
        ? AssignTeacher()
        : null}
      {showScheduleOrderModal ? renderScheduleOrderModal() : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withSnackbar(connect(mapStateToProps)(GroupTable));

const HEADER_FILTER_LIST = [
  {
    filter_label: 'id',
    type: 'text',
  },
  {
    filter_label: 'name',
    type: 'text',
  },
  {
    filter_label: 'grade',
    type: 'text',
  },
  {
    filter_label: 'phone',
    type: 'text',
  },
  {
    filter_label: 'city',
    type: 'text',
  },
  {
    filter_label: 'school',
    type: 'text',
  },
  {
    filter_label: 'email',
    type: 'text',
  },
  {
    filter_label: 'teacher',
    type: 'text',
  },
  {
    filter_label: 'student_id',
    type: 'text',
  },
  {
    filter_label: 'student_name',
    type: 'text',
  },
  {
    filter_label: 'group_type',
    type: 'text',
  },
  {
    filter_label: 'package',
    type: 'text',
  },
  {
    filter_label: 'video_sdk',
    type: 'dropdown',
    dropDownList: [
      { id: 0, name: 'All' },
      { id: 'zoom', name: 'Zoom' },
      { id: 'agora', name: 'Agora' },
    ],
  },
];

const SORT_LIST = ['id', 'name'];
const SORT_DATE = ['class_status'];
