import {
  USER_PROFILE_FETCHED
} from "../actions/types";

const INITIAL_STATE = null;

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_PROFILE_FETCHED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
