import styled from 'styled-components'

const StudentScheduleStyles = styled.div`

    .scheduling-container{
        font-size: 30px;

        .teacher-box{
            .input-container{
                width: max-content;
            }
        }

        .schedule-row{
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            div{
            &:first-child{
                // width: max-content;
                // margin-right: 15px !important;
            }
            .input-container{ 
                margin-bottom: 0 !important;
                .error-msg{
                height: 0 !important;
            }
            select{
                width: 200px;
                font-size: 20px;
            }
            }
            }

            .MuiFormControl-marginNormal{
                margin-top: 0 !important;
                margin-bottom: 0 !important;
                margin-right: 20px !important;
            }
            
            .fab{
                background-color: rgba(26,41,128, 0.8);
            /* .MuiFab-primary{
                background-color: rgba(26,41,128, 0.8) !important;
            } */
            .MuiIconButton-label{
                color: #fff;
            }
            }
        }
        .submit-schedules{
            margin-top: 50px;
            width: max-content;
            cursor: pointer;
            color: #fff;
            background-color: rgba(26,41,128, 0.8);
            padding: 15px 35px;
            border-radius: 10px;
            font-size: 20px;

            &:hover{
                background-color: rgba(26,41,128, 1);
            }
        }
    }

`;

export default StudentScheduleStyles