import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import axios from "axios";
import SaveChangesModal from "../../components/SaveChangesModal";
import { Switch, Tooltip } from "@material-ui/core";

class EnrollmentClosedToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showButtonLoader: false,
      showModal: false,
    };
  }

  onToggleStatus = () => {
    const { userId, adminId, enrollmentClosed, handleCallBack } = this.props;
    this.setState({ showButtonLoader: true });
    axios
      .post(`/v1/admin/${adminId}/student/${userId}/enrollment-closed/`, {
        is_eligible: enrollmentClosed || false,
      })
      .then((response) => {
        if (response.status == 200 && response?.data) {
          const { message } = response.data;
          this.setState({ showButtonLoader: false, showModal: false }, ()=>handleCallBack());
          this.props.enqueueSnackbar(message || "Enrollment status updated", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        this.props.enqueueSnackbar(
          err?.response?.data?.data?.error || "Something went wrong",
          { variant: "error" }
        );
        this.setState({ showButtonLoader: false });
      });
  };

  render() {
    const { userId, enrollmentClosed, name } = this.props;
    const { showModal, showButtonLoader } = this.state;
    return (
      <>
        <Tooltip title="Update enrollment closed status">
          <Switch
            checked={enrollmentClosed || false}
            onChange={() => this.setState({ showModal: true })}
            color="primary"
          />
        </Tooltip>
        <SaveChangesModal
          openModal={showModal}
          closeModal={() =>
            this.setState({ showModal: false, showButtonLoader: false })
          }
          text={`Do you want to ${
            enrollmentClosed ? "open" : "close"
          } enrollment for ${name}?`}
          displayData={`Note: Enrollment is ${
            enrollmentClosed ? "closed" : "open"
          } right now for this user.`}
          onSave={this.onToggleStatus}
          loader={showButtonLoader}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminId: state?.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(EnrollmentClosedToggle));
