import React from 'react';

import './style.scss';
import Row from '../../../../components/Elements/Flex/row';
import closeIcon from '../../../../assets/close-icon.png';
import ButtonCssPopup from '../../PopUps/Button/ButtonCssPopup';

const Button = (props) => {
  const {
    type,
    index,
    text,
    style,
    buttonType,
    selectedElementIndex,
    updateState,
    updateElementData,
    deleteComponent,
    importedIn,
    solution,
    selectOption,
    validated,
    selectButton,
  } = props;
  const isSelected = selectedElementIndex === index ? true : false;
  const singleSelectButton = importedIn === 'singleSelect' ? true : false;

  const handleSingleSelectButton = (event) => {
    const solutionObj = JSON.parse(JSON.stringify(solution));
    solutionObj.button['text'] = event.target.value;

    updateElementData(index, {
      key: 'solution',
      value: solutionObj,
    });
  };

  const deleteSingleSelectButton = () => {
    const solutionObj = JSON.parse(JSON.stringify(solution));
    delete solutionObj.button;

    updateElementData(index, { key: 'solution', value: solutionObj });
  };

  return (
    <div
      className={`button-parent-div ${isSelected ? 'selected' : ''} ${
        !validated ? 'error' : ''
      }`}
      style={
        buttonType === 'fixed'
          ? {
              position: 'fixed',
              bottom: '1.5vw',
              left: '1.5vw',
              width: 'calc(100% - 3vw)',
              zIndex: 9,
            }
          : { position: 'relative' }
      }
      onClick={() => {
        singleSelectButton
          ? selectButton()
          : updateState({ selectedElementIndex: index });
      }}
    >
      <div className={`buttonbox-div ${buttonType}`} style={style}>
        <input
          value={text}
          className='buttonbox-button'
          type='text'
          autoFocus={selectedElementIndex === index}
          placeholder='Add Text'
          onChange={(event) => {
            singleSelectButton
              ? handleSingleSelectButton(event)
              : updateElementData(index, {
                  key: 'text',
                  value: event.target.value,
                });
          }}
        />
        {isSelected ? (
          <Row
            className='close-icon-div'
            onClick={() => {
              singleSelectButton
                ? deleteSingleSelectButton()
                : deleteComponent(index, type);
            }}
          >
            <img src={closeIcon} className='close-icon' />
          </Row>
        ) : null}
      </div>
      {isSelected && selectOption === null ? (
        <ButtonCssPopup
          index={index}
          style={importedIn === 'singleSelect' ? solution.button.style : style}
          updateElementData={updateElementData}
          importedIn={importedIn}
          solution={solution}
          buttonType={buttonType}
        />
      ) : null}
    </div>
  );
};

export default Button;
