import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
} from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {
  ListItem,
  Typography,
  makeStyles,
  Modal,
  List,
  Grid,
  Button,
  Checkbox,
} from '@material-ui/core';
import axios from 'axios';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  list: {
    display: 'flex',
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },
  Main: {
    width: '95%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 10,
    top: 0,
    alignItems: 'center',
  },
  Modal: {
    background: '#fff',
    borderRadius: 5,
    width: '70vw',
    maxHeight: '80vh',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    height: 'fit-content',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },

  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});

function ScheduleOrderModal(props) {
  const classes = userStyles();
  const [scheduleData, setScheduleData] = useState();
  const [update, setUpdate] = useState(0);
  const [dragId, setDragId] = useState();
  const [referenceStart, setReferenceStart] = useState(1)

  useEffect(() => {
    getScheduleData();
  }, []);

  function getScheduleData() {
    axios
      .get(`v1/admin/${props.adminId}/group/${props.groupId}/schedule-order/`)
      .then((res) => {
        if (res?.status == 200 && res?.data?.data) {
          const data = res.data.data;
          // data.map((details, index)=>details[index]={...details, order: index+1})
          setScheduleData(data);
          setReferenceStart(data && data[0]?.order)
        }
      });
  }

  function postScheduleData() {
    const scheduleOrderData = scheduleData;
    scheduleOrderData.map(
      (data, index) =>
      (scheduleOrderData[index] = {
        session_schedule_id: data.id,
        ptm: data.ptm,
      })
    );
    axios
      .post(
        `v1/admin/${props.adminId}/group/${props.groupId}/schedule-order/`,
        {
          schedule: scheduleOrderData,
        }
      )
      .then((res) => {
        if (res && res.status == 200 && res.data) {
          // getScheduleData();
          props.closeModal();
          props.enqueueSnackbar(
            (res && res.data && res.data.data && res.data.data.message) ||
            'Schedule order updated successfully',
            { variant: 'success' }
          );
        }
      });
  }

  const getChangedPos = (currentPos, newPos) => {
    const data = scheduleData;
    data.splice(newPos, 0, data[currentPos]);
    data.splice(currentPos, 1);
    setScheduleData(data);
  };

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const newPosition = ev.currentTarget.id;
    const currentPos = dragId;
    const data = scheduleData;
    data.splice(newPosition, 0, data.splice(currentPos, 1)[0]);
    setScheduleData(data);
    setDragId(null);
  };

  return (
    <div className={classes.Main}>
      <Modal
        open={props.openModal}
        onClose={() => {
          props.closeModal();
        }}
      >
        <div className={classes.Modal}>
          <div className={classes.header}>
            <div style={{ margin: 'auto 0px', fontSize: 20, fontWeight: 500 }}>
              Schedule Order
            </div>
            <div>
              <IconButton onClick={props.closeModal}>
                <Tooltip title='Close Modal'>
                  <Icon className='iconButton'>close</Icon>
                </Tooltip>
              </IconButton>
            </div>
          </div>
          {scheduleData?.length>0 ? (
            <div style={{ overflowY: 'scroll', display:'flex', flexDirection:'column', gap:10 }}>
              <div style={{display:'flex', alignItems:'center', gap:10}}>
                  <div style={{ border: '2px solid white', borderRadius: 12, padding:10, width:45, textAlign:'center' }}></div>
                  <div            
                    style={{ borderRadius: 12, width:'-webkit-fill-available', display: 'flex', alignItems:'center', gap:20, padding:10, fontWeight:600, fontSize:15 }}
                  >
                    <div>PTM</div>
                    <div>Topic</div>
                  </div>
                </div>
              {scheduleData.map((data, index) => (
                <div style={{display:'flex', alignItems:'center', gap:10}}>
                  <div style={{ border: '2px solid black', borderRadius: 12, padding:10, width:45, textAlign:'center' }}>{referenceStart + index}</div>
                  <div
                    draggable={true}
                    id={index}
                    onDragOver={(ev) => ev.preventDefault()}
                    onDragStart={handleDrag}
                    onDrop={handleDrop}
                    style={{ border: '2px solid black', borderRadius: 12, width:'-webkit-fill-available', display: 'flex', alignItems:'center', justifyContent:'space-between' }}
                  >
                    <div>
                    <Checkbox
                      key={index}
                      checked={data.ptm}
                      onChange={(event) => {
                        const detail = [...scheduleData];
                        detail[index].ptm = !detail[index].ptm;
                        setScheduleData(detail);
                      }}
                    />
                    {data.order + '. ' + data.name}
                    </div>
                    <Icon>drag_indicator_icon</Icon>
                  </div>
                </div>
              ))}
            </div>
          ) : 'No Data'}
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button
              variant='contained'
              color='primary'
              onClick={props.closeModal}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={postScheduleData}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    adminId: state?.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(ScheduleOrderModal));
