import styled from 'styled-components';

const UploadQuestionStyle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 10px 100px;
  .filters {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
  .tableCell {
    border: none !important;
    border-bottom: solid 1px #7d7d7d9e !important;
    padding: 10px;
  }
  .tableRow {
    margin: 10px;
  }
  .activity-edit-icon {
    color: #3f51b5;
    cursor: pointer;
  }
`;

export default UploadQuestionStyle;
