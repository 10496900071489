// import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const loggedIn = createSelector(state => state.user, user => user !== null);

function useLoginStatus() {
    return useSelector(state => loggedIn(state));
    // return user !== null;
}

export default useLoginStatus;
