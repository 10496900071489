import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';

import './style.scss';
import uploadImage from '../../../../assets/image-logo1.png';
import videoLogo from '../../../../assets/video-logo.png';
import gifLogo from '../../../../assets/gif-logo.png';
import closeIcon from '../../../../assets/close-icon.png';
import Col from '../../../../components/Elements/Flex/col';
import Row from '../../../../components/Elements/Flex/row';

class MediaBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
  }

  fileUploadHandler = (value) => {
    this.setState({ loader: true });
    const { updateElementData, index, type } = this.props;
    const adminId = this.props?.user?.user_id;
    let dataURl = value;

    const bodyFormData = new FormData();
    bodyFormData.append('content_type', 'image');
    bodyFormData.append('bucket_name', 'lq-live');
    bodyFormData.append(
      'key',
      `flashcard/${moment().format('YYYYMMDDHHmmssSSS')}_AID_${adminId}`
    );
    bodyFormData.append('file', dataURl);

    axios({
      method: 'post',
      url: `v1/admin/${adminId}/s3/upload/`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        if (response.status === 200) {
          let keyName;
          if (type === 'image') {
            keyName = 'imageUrl';
          } else if (type === 'video') {
            keyName = 'videoUrl';
          } else if (type === 'gif') {
            keyName = 'gifUrl';
          }
          updateElementData(index, {
            key: keyName,
            value: response.data.data,
          });

          this.setState({ loader: false });
        }
      })
      .catch((error) => {
        console.log('error! Something went wrong.', error.message);
        this.setState({ loader: false });
      });
  };

  deleteSingleSelectImage = () => {
    const { updateElementData, index, solution } = this.props;
    let solutionObj = JSON.parse(JSON.stringify(solution));
    solutionObj.image = null;
    updateElementData(index, {
      key: 'solution',
      value: solutionObj,
    });
  };

  pasteFromClipboard = () => {
    console.log('clipboard');
    navigator.permissions
      .query({ name: 'clipboard-read' })
      .then(async (result) => {
        // If permission to read the clipboard is granted or if the user will
        // be prompted to allow it, we proceed.

        if (result.state == 'granted' || result.state == 'prompt') {
          const clipboardData =
            navigator &&
            navigator.clipboard &&
            (await navigator.clipboard.read());

          let media_type;
          const item =
            clipboardData &&
            clipboardData.find((item) =>
              item.types.some((type) => {
                if (type.startsWith('image/')) {
                  media_type = type;
                  return true;
                }
              })
            );
          var file = item && (await item.getType(media_type));
          if (file) {
            this.fileUploadHandler(file);
          }
        }
      });
  };

  render() {
    const {
      type,
      index,
      imageUrl,
      updateState,
      selectedElementIndex,
      deleteComponent,
      validated,
      currentSlideIndex,
      videoUrl,
      gifUrl,
    } = this.props;
    const { loader } = this.state;

    const isSelected = selectedElementIndex === index ? true : false;

    return (
      <Col
        className={`media-box-container ${isSelected ? 'selected' : ''} ${
          !validated ? 'error' : ''
        }`}
        onClick={() => {
          updateState({ selectedElementIndex: index });
          imageUrl || type === 'video' || type === 'gif'
            ? null
            : document
                .getElementById(`paste-input${index}${currentSlideIndex}`)
                .focus();
        }}
      >
        {/* onPaste image hidden input */}
        <input
          id={`paste-input${index}${currentSlideIndex}`}
          className={`text-box`}
          type='text'
          placeholder={'paste image here'}
          onClick={() =>
            imageUrl || videoUrl || gifUrl
              ? null
              : updateState({ selectedElementIndex: index })
          }
          onPaste={() => {
            imageUrl ? null : this.pasteFromClipboard();
          }}
          autoFocus
        />
        {loader ? (
          <>
            <CircularProgress size={24} className='loader' />
            <p className='loader-subtitle'>Uploading...</p>
          </>
        ) : (
          <>
            {type === 'image' ? (
              imageUrl ? (
                <img className='media-box-image' src={imageUrl} />
              ) : (
                <>
                  <img className='image-logo' src={uploadImage} />
                  <input
                    type='file'
                    accept='image/jpg, image/jpeg'
                    id='input-image'
                    onChange={(e) => {
                      this.fileUploadHandler(e.target.files[0]);
                    }}
                  />
                  <label htmlFor='input-image' className='input-image-label'>
                    UPLOAD IMAGE
                  </label>
                  <div className='or-text'>or</div>
                  <div className='input-image-label'>
                    CLICK & PASTE IMAGE HERE
                  </div>
                </>
              )
            ) : null}
            {type === 'video' ? (
              videoUrl ? (
                <video
                  className='media-box-image'
                  src={videoUrl}
                  controls
                ></video>
              ) : (
                <>
                  <img className='image-logo' src={videoLogo} />
                  <input
                    type='file'
                    accept='video/*'
                    id='input-video'
                    onChange={(e) => {
                      this.fileUploadHandler(e.target.files[0]);
                    }}
                  />
                  <label htmlFor='input-video' className='input-video-label'>
                    UPLOAD VIDEO
                  </label>
                </>
              )
            ) : null}
            {type === 'gif' ? (
              gifUrl ? (
                <img className='media-box-image' src={gifUrl} alt='gif' />
              ) : (
                <>
                  <img className='image-logo' src={gifLogo} />
                  <input
                    type='file'
                    accept='image/gif'
                    id='input-gif'
                    onChange={(e) => {
                      this.fileUploadHandler(e.target.files[0]);
                    }}
                  />
                  <label htmlFor='input-gif' className='input-video-label'>
                    UPLOAD GIF
                  </label>
                </>
              )
            ) : null}
          </>
        )}

        {isSelected ? (
          <Row
            className='close-icon-div'
            onClick={(event) => {
              event.stopPropagation();
              deleteComponent(index, type);
            }}
          >
            <img src={closeIcon} className='close-icon' />
          </Row>
        ) : null}
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(MediaBox);
