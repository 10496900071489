import React, { Component } from 'react'
import axios from 'axios'
import axiosInstance from '../../config/axios'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { DateTime } from '../../components/FormikDateTimePicker'
import SaveIcon from '@material-ui/icons/SaveRounded'
import MySelect from '../../components/FormikSelect'
import { withSnackbar } from 'notistack'
import { connect } from "react-redux";
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import {
  ListItem,
  Typography,
  makeStyles,
  Modal,
  List,
  Grid,
  Button,
} from '@material-ui/core'


const ref = React.createRef()

class LQUserDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      details:null,
    }
  }

   componentDidMount() {
    const user_id=this.props && this.props.user.user_id
    axios.get(`v1/admin/${user_id}/logiqids/${this.props.parent_id}/get-data/`)
    .then((res)=>{
      if(res.status==200 && res.data){
        console.log(res.data)
        this.setState({details: res.data && res.data.data})
      }
    })
    .catch((err)=>{
      console.log(err.response && err.response.data)
    })
  }

  

  render() {
      
    return (
        <Modal
        open={this.props.parent_id}
        onClose={this.props.closeModal}
      >
        <div
          style={{
            backgroundColor: '#fff',
            margin: 'auto',
            marginTop: 100,
            width: 600,
            padding: '15px',
            position: 'relative',
            height:"80vh",
            overflowY:"scroll",
          }}
        >
          <div
            style={{
              position: 'absolute',
              right: 25,
              top: 20,
              fontSize: 20,
              cursor: 'pointer',
            }}
            onClick={this.props.closeModal}
          >
            X
          </div>
          <Typography style={{ fontSize: 20, alignSelf: 'center', padding:5 }}>
           LogIQids Parent Details
          </Typography>
          <div>
           
           {
             this.state.details && this.state.details.map((LqDetail)=>
             
             <List>
              <ListItem style={{fontSize:18}}>{LqDetail.name} {this.props.student_id==LqDetail.user_id ? "(User)" : null}</ListItem>
              <ListItem>User ID : {LqDetail.user_id}</ListItem>
              {LqDetail.class_name ? <ListItem>Class : {LqDetail.class_name}</ListItem>: null}
              {LqDetail.user_created_date ?<ListItem>Created At : {LqDetail.user_created_date}</ListItem>: null}
              {LqDetail.worksheets_count ? <ListItem>Worksheets Count : {LqDetail.worksheets_count}</ListItem>: null}
              {LqDetail.exam_score &&
                LqDetail.exam_score.map((exam)=>
                exam.test_name ? <ListItem>Test : {exam.test_name} {exam.score ? '(Score : '+exam.score+')' : null}</ListItem> : null
                )}
                
             </List>
             )
           }
          </div>
        </div>
      </Modal>
    )
  }
}
function mapStateToProps(state) {
  return {
      user: state.user,
  };
}

export default withSnackbar(connect(mapStateToProps)(LQUserDetails))
