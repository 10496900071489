import React from 'react';
import {
  List,
  ListItem,
  Typography,
  Grid,
  Button,
  TextField as MaterialUiTextField,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SaveChangesModal from '../../components/SaveChangesModal';
import SessionSelector from '../../components/SessionSelector';

const styles = (theme) => ({
  table: {
    margin: 10,
  },
  tablecell: {
    padding: 10,
  },
});

class UpdateOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialOrderData: null,
      selectedDate: null,
      pauseOrderModal: false,
      changePackageModal: false,
      deleteInitialClassesModal: false,
    };
    this.my_ref = React.createRef();
  }

  componentDidMount() {
    this.setState(
      {
        package_subcription_id: this.props.location.query.id,
      },
      () => this.getDetails()
    );
  }

  getDetails = () => {
    const user_id = this.props && this.props.user && this.props.user.user_id;
    axios
      .get(
        `/v1/admin/${user_id}/subscriptions/${this.state.package_subcription_id}/update/`,
        { package_subcription_id: this.state.package_subcription_id }
      )
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            initialOrderData: res.data && res.data.data,
            selectedDate:
              res.data && res.data.data && res.data.data.date_of_payment,
          });
          this.props.enqueueSnackbar('Fetched Successfully', {
            variant: 'success',
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.error)
          this.props.enqueueSnackbar(
            err.response.data.error || 'Could not fetch data',
            { variant: 'error' }
          );
      });
  };

  pauseOrder = () => {
    const user_id = this.props && this.props.user && this.props.user.user_id;
    const data = {
      action: 'pause',
    };
    axios
      .post(
        `/v1/admin/${user_id}/subscriptions/${this.state.package_subcription_id}/change-status/`,
        data
      )
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({ pauseOrderModal: false }, () => this.getDetails());
          this.props.enqueueSnackbar(
            response?.data?.data?.message || 'Order Paused Successfully',
            { variant: 'success' }
          );
        }
      })
      .catch((err) => {
        console.log('error ', err.response);
        this.props.enqueueSnackbar(
          (err?.response?.data?.data?.error &&
            err.response.data.data.error[0]) ||
            'Could not pause the prder',
          { variant: 'error' }
        );
      });
  };

  deleteInitialClasses = () => {
    const user_id = this.props && this.props.user && this.props.user.user_id;
    const data = {
      no_of_classes: parseInt(
        this.state.deleteInitialClassesModal.no_of_classes
      ),
    };
    axios
      .post(
        `/v1/admin/${user_id}/subscriptions/${this.state.package_subcription_id}/delete-classes/`,
        data
      )
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({ deleteInitialClassesModal: false }, () =>
            this.getDetails()
          );
          this.props.enqueueSnackbar(
            response?.data?.data?.message ||
              'Initial Classes Deleted Successfully',
            { variant: 'success' }
          );
        }
      })
      .catch((err) => {
        this.props.enqueueSnackbar(
          (err?.response?.data?.data?.error &&
            err.response.data.data.error[0]) ||
            'Something went wrong',
          { variant: 'error' }
        );
      });
  };

  changePackage = () => {
    const user_id = this.props && this.props.user && this.props.user.user_id;
    const { changePackageModal } = this.state;
    const data = {
      package_id: changePackageModal.package_id,
    };
    axios
      .post(
        `/v1/admin/${user_id}/subscriptions/${this.state.package_subcription_id}/update-package/`,
        data
      )
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({ changePackageModal: false }, () => this.getDetails());
          this.props.enqueueSnackbar(
            response?.data?.data?.message || 'Order Paused Successfully',
            { variant: 'success' }
          );
        }
      })
      .catch((err) => {
        console.log('error ', err.response);
        this.props.enqueueSnackbar(
          (err?.response?.data?.data?.error &&
            err.response.data.data.error[0]) ||
            'Could not pause the order',
          { variant: 'error' }
        );
      });
  };

  updateOrder = (values) => {
    const user_id = this.props && this.props.user && this.props.user.user_id;
    axios
      .post(
        `/v1/admin/${user_id}/subscriptions/${this.state.package_subcription_id}/update/`,
        {
          no_of_classes: parseInt(values.no_of_classes),
          amount: parseInt(values.amount),
          package_subcription_id: this.state.package_subcription_id,
          total_amount: parseInt(values.total_amount),
          comments: values.comments,
          payment_link: values.payment_link,
          classes_paid_for: parseInt(values.classes_paid_for),
          date_of_payment: this.state.selectedDate,
          credits: parseInt(values.credits) || 0,
        }
      )
      .then((res) => {
        if (res.status == 200) {
          this.props.enqueueSnackbar('Updated Successfully', {
            variant: 'success',
          });
          this.getDetails();
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.error)
          this.props.enqueueSnackbar(
            err.response.data.error || 'Could not fetch data',
            { variant: 'error' }
          );
      });
  };

  render() {
    const orderSchema = Yup.object().shape({
      userId: Yup.number(),
      no_of_classes: Yup.number().required('Select number of classes'),
      classes_paid_for: Yup.number().required('Select number of classes'),
      amount: Yup.number().required('Enter amount'),
      completed_classes: Yup.number(),
      createdAt: Yup.string(),
    });
    const { classes } = this.props;
    const { pauseOrderModal, changePackageModal, deleteInitialClassesModal } =
      this.state;
    return (
      <div style={{ display: 'flex' }}>
        <SaveChangesModal
          openModal={pauseOrderModal}
          closeModal={() => this.setState({ pauseOrderModal: false })}
          text={'Do you want to pause the order?'}
          onSave={this.pauseOrder}
        />
        <SaveChangesModal
          openModal={deleteInitialClassesModal}
          closeModal={() => this.setState({ deleteInitialClassesModal: false })}
          text={'Do you want to delete initial classes?'}
          onSave={this.deleteInitialClasses}
          displayData={
            <MaterialUiTextField
              label='No of Classes'
              variant='outlined'
              value={deleteInitialClassesModal.no_of_classes}
              onChange={(event) =>
                this.setState({
                  deleteInitialClassesModal: {
                    no_of_classes: event.target.value,
                  },
                })
              }
            />
          }
        />
        <SaveChangesModal
          openModal={changePackageModal}
          closeModal={() => this.setState({ changePackageModal: false })}
          text={'Change package'}
          onSave={this.changePackage}
          disableConfirm={!changePackageModal?.package_id}
          displayData={
            <SessionSelector
              filterList={['package']}
              packageData={{
                paid: true,
                exclude: this.state?.initialOrderData?.package_id,
              }}
              onFilterChange={({ package_id }) =>
                this.setState({
                  changePackageModal: { package_id: package_id },
                })
              }
            />
          }
        />
        {this.state.initialOrderData && (
          <Formik
            initialValues={this.state.initialOrderData}
            enableReinitialize={true}
            ref={this.my_ref}
            onSubmit={this.updateOrder}
            validationSchema={orderSchema}
            render={(formProps) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Form style={{ flex: 1 }}>
                    <List>
                      <Typography variant='h4' style={{ padding: 8 }}>
                        Update Order
                      </Typography>
                      <ListItem>
                        <Grid container spacing={8}>
                          {/* <Grid item>
                                                    <Field
                                                        name="userId"
                                                        type="number"
                                                        component={TextField}
                                                        label="User ID"
                                                        variant="outlined"
                                                        disabled
                                                    />
                                                </Grid> */}
                          <Grid item>
                            <Field
                              name='name'
                              type='string'
                              component={TextField}
                              label='Name'
                              variant='outlined'
                              disabled
                            />
                          </Grid>
                          <Grid item>
                            <Field
                              name='phone'
                              type='string'
                              component={TextField}
                              label='Phone'
                              variant='outlined'
                              disabled
                            />
                          </Grid>
                          {/* <Grid item>
                                                    <Field
                                                        name="created_at"
                                                        type="string"
                                                        component={TextField}
                                                        label="Created at"
                                                        variant="outlined"
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Field
                                                        name="completed_classes"
                                                        type="string"
                                                        component={TextField}
                                                        label="Completed Classes"
                                                        variant="outlined"
                                                        disabled
                                                    />
                                                </Grid> */}
                        </Grid>
                      </ListItem>
                      <ListItem>
                        <Grid container spacing={8}>
                          <Grid item>
                            <Field
                              name='school'
                              type='string'
                              component={TextField}
                              label='School'
                              variant='outlined'
                              disabled
                            />
                          </Grid>
                          <Grid item>
                            <Field
                              name='grade'
                              type='string'
                              component={TextField}
                              label='Grade'
                              variant='outlined'
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </ListItem>

                      <ListItem>
                        <Grid container spacing={8}>
                          <Grid item>
                            <Field
                              name='no_of_classes'
                              type='text'
                              component={TextField}
                              label='Number of classes'
                              variant='outlined'
                              disabled={false}
                            />
                          </Grid>
                          <Grid item>
                            <Field
                              name='classes_paid_for'
                              type='text'
                              component={TextField}
                              label='Classes paid for'
                              variant='outlined'
                              disabled={false}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem>
                        <Grid container spacing={8}>
                          <Grid item>
                            <Field
                              name='amount'
                              type='text'
                              component={TextField}
                              label='Amount'
                              variant='outlined'
                              disabled={false}
                            />
                          </Grid>
                          <Grid item>
                            <Field
                              name='total_amount'
                              type='text'
                              component={TextField}
                              label='Total Amount'
                              variant='outlined'
                              disabled={false}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>

                      <ListItem>
                        <Grid container spacing={8}>
                          <Grid item>
                            <Field
                              name='payment_link'
                              type='string'
                              component={TextField}
                              label='Payment Link'
                              variant='outlined'
                              disabled={false}
                            />
                          </Grid>
                          <Grid item>
                            <Field
                              name='comments'
                              type='string'
                              component={TextField}
                              label='Comment'
                              variant='outlined'
                              disabled={false}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem>
                        <Grid container spacing={8}>
                          <Grid item>
                            <Field
                              name='credits'
                              type='text'
                              component={TextField}
                              label='Credits'
                              variant='outlined'
                              disabled={false}
                            />
                          </Grid>
                          <Grid item>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <DateTimePicker
                                variant='inline'
                                label='Date of payment'
                                value={this.state.selectedDate}
                                margin='auto'
                                onChange={(event) =>
                                  this.setState({ selectedDate: event })
                                }
                                dateRangeIcon
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem>
                        <Grid container spacing={8}>
                          <Grid item>
                            <Button
                              color='primary'
                              variant='contained'
                              type='submit'
                            >
                              Update Order
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              color='primary'
                              variant='outlined'
                              disabled={
                                !this.state.initialOrderData
                                  .allow_package_update
                              }
                              onClick={() =>
                                this.setState({ changePackageModal: true })
                              }
                            >
                              Change Package
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              color='primary'
                              variant='outlined'
                              onClick={() =>
                                this.setState({ pauseOrderModal: true })
                              }
                            >
                              Pause Order
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              color='primary'
                              variant='outlined'
                              onClick={() =>
                                this.setState({
                                  deleteInitialClassesModal: {
                                    no_of_classes: '',
                                  },
                                })
                              }
                            >
                              Delete Initial Classes
                            </Button>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                  </Form>
                </div>
              );
            }}
          />
        )}
        {this.state.initialOrderData &&
          this.state.initialOrderData.update_history && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Typography variant='h4' style={{ padding: 8 }}>
                Update History
              </Typography>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {Object.getOwnPropertyNames(
                      this.state.initialOrderData.update_history[0]
                    ).map((header, i) => {
                      const headername =
                        header == 'admin_id'
                          ? 'admin'
                          : header.replaceAll('_', ' ');
                      return (
                        <TableCell className={classes.tablecell}>
                          {headername.charAt(0).toUpperCase() +
                            headername.slice(1)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.initialOrderData.update_history.map(
                    (rows, index) => (
                      <TableRow>
                        {Object.getOwnPropertyNames(
                          this.state.initialOrderData.update_history[0]
                        ).map((header, i) => {
                          const cell =
                            header == 'date' || header == 'date_of_payment'
                              ? eval('rows.' + header) &&
                                new Date(eval('rows.' + header)).toLocaleString(
                                  'en-IN',
                                  {
                                    year: '2-digit',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                  }
                                )
                              : eval('rows.' + header);
                          return (
                            <TableCell className={classes.tablecell}>
                              {cell}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </div>
          )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withSnackbar(
  withStyles(styles)(connect(mapStateToProps)(UpdateOrder))
);
