import React, { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {
  ListItem,
  Typography,
  makeStyles,
  Modal,
  List,
  Grid,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  list: {
    display: 'flex',
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },
  Main: {
    width: '95%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 30,
    top: 0,
    alignItems: 'center',
  },
  Modal: {
    background: '#fff',
    borderRadius: 5,
    width: '70vw',
    maxHeight: '80vh',
    minHeight: '50vh',
    height: 'fit-content',
    margin: 'auto',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    padding: 20,
    overflowY: 'auto',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    margin: 'auto 25px',
  },
  button: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  noStyle: {
    border: 'none !important',
  },
  root: {
    paddingTop: 10,
    paddingBottom: 20,
    '& > *': {
      width: '70%',
      margin: 'auto',
      display: 'flex',
    },
  },
});

let tableRef = null;

function ReassignMissedSessionStudent(props) {
  const ref = React.createRef();
  const classes = userStyles();
  const [sessionId, setSessionId] = useState('');
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [suggestionData, setSuggestionData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  function resetData() {
    setSessionId('');
    setMessage(null);
    setErrorMessage(null);
    setSuggestionData(null);
    setSelectedDate(null);
  }
  function getReassignSessionDetails() {
    const admin_id = props && props.user && props.user.user_id;
    axios
      .get(
        `/v1/admin/${admin_id}/session/${sessionId}/missed/${props.participant_id}/re-assign/`
      )
      .then((response) => {
        if (response && response.status == 200) {
          const data = response && response.data && response.data.data;
          setMessage(data && data.message);
          setErrorMessage(data && data.error);
        }
      })
      .catch((err) => {
        const data =
          err.response &&
          err.response.data &&
          err.response.data.data &&
          err.response.data.data.error;
        setErrorMessage(data && data[0]);
      });
  }

  function postReassignSessionDetails() {
    const admin_id = props && props.user && props.user.user_id;
    axios
      .post(
        `/v1/admin/${admin_id}/session/${sessionId}/missed/${props.participant_id}/re-assign/`
      )
      .then((response) => {
        if (response && response.status == 200) {
          props.enqueueSnackbar(
            response?.data?.data?.message || 'Successfully Reassigned',
            { variant: 'success' }
          );
          props.closeModal();
          props.handleCallBack();
        }
      })
      .catch((err) => {
        const data =
          err?.response?.data?.data?.error && err.response.data.data.error[0];
        props.enqueueSnackbar(data || 'Something Went Wrong', {
          variant: 'error',
        });
        data && setErrorMessage(data);
        setMessage(null);
      });
  }

  function viewSuggestions(selectedDate) {
    if (selectedDate) setSelectedDate(selectedDate);
    setSuggestionData('Loading..');
    const admin_id = props && props.user && props.user.user_id;
    axios
      .get(
        `/v1/admin/${admin_id}/missed/${props.participant_id}/suggestions/${
          selectedDate
            ? `?schedule_date=${moment(selectedDate).format('YYYY-MM-DD')}`
            : ''
        }`
      )
      .then((response) => {
        if (response && response.status == 200) {
          const data = response && response.data && response.data.data;
          setSuggestionData(data);
        }
      })
      .catch((err) => {
        const data =
          err?.response?.data?.data?.error && err.response.data.data.error[0];
        props.enqueueSnackbar(data || 'Something Went Wrong', {
          variant: 'error',
        });
        setSuggestionData(null);
      });
  }

  return props.participant_id ? (
    <div className={classes.Main}>
      <Modal
        open={props.participant_id}
        onClose={() => {
          props.closeModal();
          resetData();
        }}
      >
        <div className={classes.Modal}>
          <div className={classes.header}>
            <h3>{props.headerText || 'Reassign Session'}</h3>
            <IconButton
              onClick={() => {
                props.closeModal();
                resetData();
              }}
            >
              <Tooltip title='Close Modal'>
                <Icon className='iconButton'>close</Icon>
              </Tooltip>
            </IconButton>
          </div>
          {props.onConfirm ? (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                label='Select Date to View Suggestions'
                onChange={(date) => viewSuggestions(date)}
                clearable
                format='DD/MM/yyyy'
                value={selectedDate}
                dateRangeIcon
                style={{ width: 300 }}
              />
            </MuiPickersUtilsProvider>
          ) : (
            <>
              <form className={classes.root} noValidate autoComplete='off'>
                <TextField
                  label={
                    `Provide ${
                      props.onConfirm ? 'Group' : 'Session'
                    } ID to reassign participant ID ` + props.participant_id
                  }
                  value={sessionId}
                  onChange={(event) => {
                    setSessionId(event.target.value),
                      setErrorMessage(null),
                      setMessage(null);
                  }}
                  width='100%'
                />
              </form>
              {message && (
                <div
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    padding: 10,
                    justifyContent: 'center',
                  }}
                >
                  {message}
                </div>
              )}
              {errorMessage && (
                <div
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    padding: 10,
                    color: 'red',
                    justifyContent: 'center',
                  }}
                >
                  {errorMessage}
                </div>
              )}
              <div className={classes.button}>
                {!suggestionData && (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => viewSuggestions()}
                  >
                    View Suggestion
                  </Button>
                )}
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    props.closeModal();
                    resetData();
                  }}
                >
                  Cancel
                </Button>
                {sessionId && !message && (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      if (props.onConfirm) {
                        props.onConfirm({
                          action: 'reassign',
                          participant_id: props.participant_id,
                          group_id: sessionId,
                        });
                        resetData();
                      } else getReassignSessionDetails();
                    }}
                  >
                    Reassign
                  </Button>
                )}
                {sessionId && message && !errorMessage && (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      if (props.onConfirm) {
                        props.onConfirm({
                          action: 'reassign',
                          participant_id: props.participant_id,
                          group_id: sessionId,
                        });
                        resetData();
                      } else postReassignSessionDetails();
                    }}
                  >
                    Yes
                  </Button>
                )}
              </div>
            </>
          )}
          {suggestionData && (
            <div>
              <h3>Suggestions</h3>
              {Array.isArray(suggestionData)
                ? (suggestionData.length > 0 && (
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          {Object.getOwnPropertyNames(suggestionData[0]).map(
                            (header, i) => {
                              const headername =
                                header == 'session__class_id_id'
                                  ? 'Grade'
                                  : header == 'student_group_id'
                                  ? 'Group ID'
                                  : header == 'student_group__no_of_students'
                                  ? 'No. of students'
                                  : header == 'id'
                                  ? 'Session ID'
                                  : header == 'student_group__name'
                                  ? 'Group name'
                                  : header.replaceAll('_', ' ');
                              return (
                                <TableCell className={classes.tablecell}>
                                  {headername.charAt(0).toUpperCase() +
                                    headername.slice(1)}
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {suggestionData.map((rows, index) => (
                          <TableRow>
                            {Object.getOwnPropertyNames(suggestionData[0]).map(
                              (header, i) => {
                                const cell =
                                  header == 'scheduled_at'
                                    ? eval('rows.' + header) &&
                                      new Date(
                                        eval('rows.' + header)
                                      ).toLocaleString('en-IN', {
                                        year: '2-digit',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      })
                                    : eval('rows.' + header);
                                return (
                                  <TableCell className={classes.tablecell}>
                                    {cell || cell == '0' ? cell : '-'}
                                  </TableCell>
                                );
                              }
                            )}
                            <TableCell className={classes.noStyle}>
                              <Button
                                color='secondary'
                                variant='outlined'
                                onClick={() => {
                                  if (props.onConfirm) {
                                    props.onConfirm({
                                      action: 'reassign',
                                      participant_id: props.participant_id,
                                      group_id: rows.group_id,
                                    });
                                    resetData();
                                  } else {
                                    setSessionId(rows.id),
                                      setErrorMessage(null),
                                      setMessage(null);
                                  }
                                }}
                              >
                                Assign
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )) ||
                  'No data to display'
                : suggestionData}
            </div>
          )}
        </div>
      </Modal>
    </div>
  ) : null;
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withSnackbar(
  connect(mapStateToProps)(ReassignMissedSessionStudent)
);
