import React, {
  Fragment,
  useCallback,
  useState,
  useEffect,
  useReducer,
} from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  Grid,
  List,
  ListItem,
  Typography,
  Divider,
  Button,
  LinearProgress,
  Checkbox,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/SaveRounded';
import { withSnackbar } from 'notistack';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import MySelect from '../../components/FormikSelect';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { TeacherAuthRedirector } from '../../hocs/requireAuth';
import axios from 'axios';
import MomentUtils from '@date-io/moment';

function reducer(state, action) {
  switch (action.type) {
    case 'setPackage':
      return { ...state, package: action.payload };
    case 'setPackageList':
      return { ...state, packageList: action.payload };
    default:
      return state;
  }
}

function PackageAssign(props) {
  const [state, dispatch] = useReducer(reducer, {
    packageList: [],
  });
  const [packageList, setPackageList] = useState([]);
  const [rmList, setRmList] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [showAllPackage, handleShowAllPackages] = useState(false);

  const initialValues = {
    package: null,
    user: '',
    amount: '',
    total_amount: '',
    no_of_classes: '',
    classes_paid_for: '',
    payment_link: '',
    ac: null,
    // date_of_payment:new Date(),
    comments: '',
    status: { value: 0, label: 'Provisional' },
    /*        price: "",
               error: [],
               comment: "",
               errorName: "" */
  };

  const useStyles = makeStyles((theme) =>
    createStyles({
      appBar: {
        position: 'relative',
      },
      flex: {
        flex: 1,
      },
      rightIcon: {
        marginLeft: theme.spacing(1),
      },
      formControl: {
        margin: `${theme.spacing(2)}px`,
        width: 450,
      },
      grid: {
        margin: '8px',
        padding: '0px 16px',
      },
      error: {
        color: '#f44336',
        fontSize: '12px',
        margin: `0px ${theme.spacing(2)}px`,
      },
    })
  );

  const classes = useStyles();
  async function getPackage() {
    const admin_id = props && props.user && props.user.user_id;
    axios.get(`/v1/admin/${admin_id}/assign-package/`).then((res) => {
      if (res && res.status == 200) {
        let temp = [];
        let format = {
          label: '',
          value: '',
        };
        const data = res && res.data && res.data.data;
        data &&
          data.map((packageData) => {
            format = {
              value: packageData.id,
              label: packageData.name,
            };
            format == undefined ? null : temp.push(format);
          });
        setPackageList(temp);
      }
    });
  }

  function getRmList() {
    const user_id = props && props.user && props.user.user_id;
    axios.get(`/v1/admin/${user_id}/ac/`).then((res) => {
      if (res.status == 200 && res.data) {
        let temp = [];
        temp =
          res.data &&
          res.data.data.map &&
          res.data.data.map((a) => {
            return {
              value: a.id,
              label: a.name,
            };
          });
        setRmList(temp);
      }
    });
  }

  async function saveData(values, formikActions) {
    try {
      const user_id = props && props.user && props.user.user_id;
      formikActions.setSubmitting(true);
      formikActions.setFieldValue('error', []);
      const response = await axios.post(
        `/v1/admin/${user_id}/assign-package/`,
        values.package.value != 1
          ? {
              package_id: values.package.value,
              student_ids: eval('[' + values.user + ']'),
              ac_id: values.ac && values.ac.value,
              no_of_classes: values.no_of_classes,
              amount: values.amount,
              payment_link: values.payment_link,
              total_amount: values.total_amount,
              comments: values.comments,
              date_of_payment: selectedDate,
              classes_paid_for: values.classes_paid_for,
              status: values.status.value,
            }
          : {
              package_id: values.package.value,
              student_ids: eval('[' + values.user + ']'),
              ac_id: values.ac && values.ac.value,
            }
      );
      if (response.status === 200 && response.data) {
        props.enqueueSnackbar('Package Assigned', {
          variant: 'success',
        });
        formikActions.resetForm(initialValues);
      }
    } catch (error) {
      const errors =
        error.response &&
        error.response.data &&
        error.response.data.data &&
        error.response.data.data.error;
      props.enqueueSnackbar(errors || 'error', {
        variant: 'error',
      });
    } finally {
      formikActions.setSubmitting(false);
    }
  }

  const listItemSchema = Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
  });

  const noOfClassesSchema = Yup.object().shape({
    label: Yup.number().required(),
    value: Yup.number().required(),
  });

  const schema = Yup.object().shape({
    user: Yup.string(
      'Please provide comma separated list of User IDs'
    ).required('Give at least 1 user ID'),
    package: listItemSchema.nullable().required('Must have 1 package selected'),
    ac: listItemSchema.nullable().required('Please select AC'),
    amount: Yup.string().when('package', {
      is: (value) => value && value.value != 1,
      then: Yup.string().required('Enter the amount paid'),
      otherwise: Yup.string(),
    }),
    total_amount: Yup.string().when('package', {
      is: (value) => value && value.value != 1,
      then: Yup.string().required('Enter the total amount'),
      otherwise: Yup.string(),
    }),
    payment_link: Yup.string().when('package', {
      is: (value) => value && value.value != 1,
      then: Yup.string().required('Enter the payment link'),
      otherwise: Yup.string(),
    }),
    no_of_classes: Yup.string().when('package', {
      is: (value) => value && value.value != 1,
      then: Yup.string().required('Enter total number of classes'),
      otherwise: Yup.string(),
    }),
    classes_paid_for: Yup.string().when('package', {
      is: (value) => value && value.value != 1,
      then: Yup.string().required('Enter number of classes paid for'),
      otherwise: Yup.string(),
    }),
    comments: Yup.string().when('package', {
      is: (value) => value && value.value != 1,
      then: Yup.string().required('Enter comment'),
      otherwise: Yup.string(),
    }),
  });

  useEffect(() => {
    getPackage();
    getRmList();
  }, []);

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={saveData}
        validationSchema={schema}
        render={(formProps) => {
          console.log(formProps.values);
          console.log('FORMPROPS');
          return (
            <Form>
              <Grid container direction='column'>
                <Grid item className={classes.grid}>
                  <List>
                    <ListItem>
                      <Typography variant='h4'>Assign Package</Typography>
                    </ListItem>
                    <Divider />
                    <ListItem style={{ zIndex: 10 }}>
                      <div>
                        <Field
                          name='package'
                          className={classes.formControl}
                          selectList={packageList.filter(
                            (filteredPackageList) =>
                              showAllPackage ||
                              filteredPackageList.value === 1 ||
                              filteredPackageList.value >= 10
                          )}
                          component={MySelect}
                        />
                        <ErrorMessage
                          className={classes.error}
                          component='div'
                          name='package'
                        />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            onChange={(event) =>
                              handleShowAllPackages(event.target.checked)
                            }
                          />
                          <div>Show All Packages</div>
                        </div>
                      </div>
                      <Field
                        name='user'
                        component={TextField}
                        className={classes.formControl}
                        label={
                          formProps.values &&
                          formProps.values.package &&
                          formProps.values.package.value &&
                          formProps.values.package.value != 1
                            ? 'User ID'
                            : 'User List'
                        }
                        variant='outlined'
                      />
                    </ListItem>
                    {formProps.values &&
                      formProps.values.package &&
                      formProps.values.package.value &&
                      formProps.values.package.value != 1 && (
                        <>
                          <ListItem>
                            <Field
                              name='no_of_classes'
                              className={classes.formControl}
                              //selectList={[{value: 24, label: 24}, {value: 48, label: 48}, {value: 96, label: 96}]}
                              component={TextField}
                              label='Total Number of classes'
                              variant='outlined'
                            />

                            <Field
                              name='classes_paid_for'
                              className={classes.formControl}
                              component={TextField}
                              label='Classes Paid for'
                              variant='outlined'
                            />
                          </ListItem>
                          <ListItem>
                            <Field
                              name='amount'
                              component={TextField}
                              className={classes.formControl}
                              label='Amount'
                              variant='outlined'
                            />

                            <Field
                              name='total_amount'
                              component={TextField}
                              className={classes.formControl}
                              label='Total Amount'
                              variant='outlined'
                            />
                          </ListItem>
                          <ListItem>
                            <Field
                              name='payment_link'
                              component={TextField}
                              className={classes.formControl}
                              label='Payment Link'
                              variant='outlined'
                            />

                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <DateTimePicker
                                variant='inline'
                                label='Date of payment'
                                value={selectedDate}
                                className={classes.formControl}
                                onChange={handleDateChange}
                                dateRangeIcon
                              />
                            </MuiPickersUtilsProvider>
                          </ListItem>
                          <ListItem>
                            <Field
                              name='comments'
                              component={TextField}
                              className={classes.formControl}
                              label='Comment'
                              variant='outlined'
                            />
                            <Field
                              label='Status'
                              className={classes.formControl}
                              selectList={[
                                { value: 1, label: 'Active' },
                                { value: 0, label: 'Provisional' },
                              ]}
                              component={MySelect}
                              name='status'
                            />
                          </ListItem>
                        </>
                      )}
                    <ListItem>
                      <div>
                        <Field
                          label='Academic Counsellor'
                          className={classes.formControl}
                          selectList={rmList}
                          component={MySelect}
                          name='ac'
                        />
                        <ErrorMessage
                          className={classes.error}
                          component='div'
                          name='ac'
                        />
                      </div>
                    </ListItem>
                    <ListItem>
                      {formProps.isSubmitting ? (
                        <LinearProgress style={{ width: '100%' }} />
                      ) : null}
                    </ListItem>
                    <ListItem>
                      <Button
                        type='button'
                        color='primary'
                        variant='outlined'
                        onClick={formProps.handleReset}
                        disabled={!formProps.dirty || formProps.isSubmitting}
                        style={{ marginRight: '8px' }}
                      >
                        {'Reset'}
                      </Button>
                      <Button
                        color='primary'
                        variant='contained'
                        type='submit'
                        disabled={formProps.isSubmitting}
                      >
                        {'ASSIGN PACKAGE'}
                        <SaveIcon className={classes.rightIcon} />
                      </Button>
                    </ListItem>
                    {formProps.values.error &&
                    formProps.values.error.length > 0 ? (
                      <ListItem>
                        <Typography>{formProps.values.errorName}</Typography>
                        <br />
                        <br />
                        <ul>
                          {formProps.values.error.map((item, index) => (
                            <li key={index} className={classes.error}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </ListItem>
                    ) : null}
                  </List>
                </Grid>
              </Grid>
            </Form>
          );
        }}
        validateOnChange
      />
    </Fragment>
  );
}

export default withSnackbar(TeacherAuthRedirector(PackageAssign));
