import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import './style.scss';
import {
  TEXT_TYPE,
  BUTTON_TYPE,
  SINGLESELECT_TYPE,
  MEDIA_TYPE,
} from '../../draggableItemTypes';

let draggableTimeout = null;
// let hoverIdx

const DragDropWrapper = (props) => {
  const [isDraggable, setIsDraggable] = useState(false);

  const { index, rearrangeElements, acceptType } = props;
  const ref = useRef(null);

  //drop
  const [{ handlerId }, drop] = useDrop({
    accept: [TEXT_TYPE, MEDIA_TYPE, SINGLESELECT_TYPE, BUTTON_TYPE],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      rearrangeElements(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
      // hoverIdx = hoverIndex
    },
  });

  //drag
  const [{}, drag] = useDrag({
    type: acceptType,
    item: () => {
      return { index };
    },
    canDrag: isDraggable,
  });

  const onMouseUp = () => {
    setIsDraggable(false);
    clearTimeout(draggableTimeout);
    draggableTimeout = null;
  };

  const onMouseDown = () => {
    draggableTimeout = setTimeout(() => {
      props.updateState({ selectedElementIndex: null }, () => {
        setIsDraggable(true);
      });
      draggableTimeout = null;
    }, 500);
  }

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className='drag-drop'
      data-handler-id={handlerId}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onDragEnd={onMouseUp}
      style={{ cursor: isDraggable ? 'move' : 'unset' }}
    >
      {props.children}
    </div>
  );
};

export default DragDropWrapper;
