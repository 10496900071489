import styled from 'styled-components';

const PageNotFoundContainer = styled.div`
	display: flex;
	flex-direction: row;
    align-items: center;
    height: 80vh;
	width:50vw;
	padding:10vh 5vw;
`;
export { PageNotFoundContainer };
