import React, { useState } from 'react';
import SaveChangesModal from '../SaveChangesModal';
import './style.scss';
import axios from 'axios';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
} from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';

function HomeworkReordering(props) {
  const [homeworkData, setHomeworkData] = useState(props.homeworkData);
  const [update, setUpdate] = useState(0);
  const [dragId, setDragId] = useState();
  const [referenceStart, setReferenceStart] = useState(1);

  function postHomeworkData() {
    const session_activity_ids = homeworkData.map((homework) => homework.id);
    const { sessionId } = props;
    const activityType = homeworkData?.[0]?.type || 'homework';
    axios
      .post(
        `v1/admin/${props.adminId}/content/session/${sessionId}/update-activity-order/`,
        {
          activity_type: activityType,
          session_activity_ids,
        }
      )
      .then((res) => {
        if (res && res.status == 200 && res.data) {
          props.getActivityList();
          props.closeModal();
          props.enqueueSnackbar(
            res?.data?.data?.message || 'Order Updated Successfully',
            { variant: 'success' }
          );
        }
      });
  }

  const getChangedPos = (currentPos, newPos) => {
    const data = homeworkData;
    data.splice(newPos, 0, data[currentPos]);
    data.splice(currentPos, 1);
    setHomeworkData(data);
  };

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const newPosition = ev.currentTarget.id;
    const currentPos = dragId;
    const data = homeworkData;
    data.splice(newPosition, 0, data.splice(currentPos, 1)[0]);
    setHomeworkData(data);
    setDragId(null);
  };

  return (
    <SaveChangesModal
      openModal={props.openModal}
      closeModal={() => {
        props.closeModal();
      }}
      onSave={postHomeworkData}
      text={'Homework Reordering'}
      style={{ overflow: 'auto', maxHeight: '80vh', maxWidth: '70vw' }}
      displayData={
        homeworkData?.length > 0 ? (
          <Table className='homeworkReorderingTable'>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Order</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>No. Of Questions</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {homeworkData.map((homework, index) => (
                <TableRow
                  draggable={true}
                  id={index}
                  onDragOver={(ev) => ev.preventDefault()}
                  onDragStart={handleDrag}
                  onDrop={handleDrop}
                  className='drag-row'
                >
                  <TableCell>
                    <div className='index-box'>{index + 1}</div>
                  </TableCell>
                  <TableCell>{homework.order}</TableCell>
                  <TableCell>{homework.id}</TableCell>
                  <TableCell>{homework.activity_name}</TableCell>
                  <TableCell>{homework.duration}</TableCell>
                  <TableCell>{homework.no_of_questions}</TableCell>
                  <TableCell>
                    {homework.is_active ? 'LIVE' : 'NOT LIVE'}
                  </TableCell>
                  <TableCell>
                    <DragIndicator />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          'No Data'
        )
      }
    />
  );
}
const mapStateToProps = (state) => {
  return {
    adminId: state?.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(HomeworkReordering));
