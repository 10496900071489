import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  CircularProgress,
} from '@material-ui/core';
import SessionSelector from '../../components/SessionSelector';
import axios from 'axios';
import './style.scss';

class StudentMentorView2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentMentorData: null,      
      classId: null,
      isLoading: false
    };
  }
  setSessionData = (packageId, gradeId, sessionId) => {
    this.setState({classId:gradeId},()=>this.fetchStudentMentorData()  )   
  }
  fetchStudentMentorData = () => {
    this.setState({ studentMentorData: null});
    if(this.state.classId){
      this.setState({isLoading: true})
    axios
      .get(
        `v1/admin/${
          this.props.adminId
        }/student/paid/details/?class_id=${this.state.classId}`
      )
      .then((response) => {
        if (response?.data?.data) {
          const data = response.data.data;        
          data.map((studentMentorDetails)=>{
            Object.assign(studentMentorDetails, {accuracy: {}});                            
            Object.keys(studentMentorDetails).map((keyName)=>{                            
              if(keyName.includes('accuracy') && keyName!='accuracy'){
                Object.assign(studentMentorDetails.accuracy, {[keyName]: studentMentorDetails[keyName]});   
                delete studentMentorDetails[keyName];             
              }
            }
            )
          })               
          this.setState({
            studentMentorData: data,
            isLoading: false
          });
        }
      });
    }
  };

  render() {
    const { studentMentorData, isLoading } =
      this.state;
    return (
      <div className='studentMentorContainer'>
        <div className='studentMentorHeaderContainer'>
          <div className='studentMentorHeader'>Student Mentor View 2</div>          
        </div>
        <SessionSelector
          setFilterData={this.setSessionData}
          filterList={['grade']}
          onFilterChange={()=>this.setState({studentMentorData:null})}
        />
        {studentMentorData?.length ? (        
            <div className='tableContainer'>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Object.keys(studentMentorData[0]).map((header) =>
                      header == 'accuracy' ? (
                        <TableCell colSpan={Object.keys(studentMentorData[0][header]).length}>                       
                          {header.charAt(0).toUpperCase() +
                            header.replaceAll('_', ' ').slice(1)}
                        </TableCell>
                      ) : (
                        <TableCell rowSpan={2}>
                          {header.charAt(0).toUpperCase() +
                            header.replaceAll('_', ' ').slice(1)}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                  <TableRow>
                  {Object.keys(studentMentorData[0]).map((header) =>
                      header == 'accuracy' ? (
                  
                    Object.keys(studentMentorData[0]) && Object.keys(studentMentorData[0][header]).map(
                      (subHeader) => (
                        <TableCell>{subHeader.charAt(0).toUpperCase() +
                          subHeader.replaceAll('_', ' ').slice(1)}</TableCell>
                      )
                    )
                  ) : null)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentMentorData.map((studentMentor) => (
                    <TableRow>
                      {Object.keys(studentMentorData[0]).map((header) =>
                        header == 'accuracy' ? (
                          <>
                            {Object.keys(
                              studentMentorData[0][header]
                            ).map((subHeader) => (
                              <TableCell>
                                {studentMentor[header][subHeader]}
                              </TableCell>
                            ))}
                          </>
                        ) : (
                          <TableCell>
                            {header.includes('date') ||
                            header.includes('schedule')
                              ? new Date(
                                  studentMentor[header]
                                ).toLocaleString()
                              : typeof studentMentor[header] == "boolean" ? studentMentor[header] ? 'Yes' : 'No' :                              
                              studentMentor[header] || '-'}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          
        ) : studentMentorData?.length == 0 ? (
          <div className='centerAligned'>No data</div>
        ) : isLoading ? (
          <CircularProgress className='centerAligned' />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  return {
    adminId: states?.user?.user_id,
  };
};

export default connect(mapStateToProps)(StudentMentorView2);
