import MUIDataTable from 'mui-datatables';
import React, { Component, Fragment } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Icon,
  InputBase,
  Typography,
  Tooltip,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { titleCase } from 'title-case';
import moment from 'moment';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import FullPageLoader from '../FullPageLoader';
import * as actions from '../../actions';
import CustomToolbar from '../CustomToolbar';
import {
  CustomSortTooltip as LQDataTablesCustomSortTooltip,
  createDefaultSortListData,
  toggleSort,
} from './CustomSortTooltip';
import CustomFooter from './CustomFooter';
import { DemoContainer } from './style';
// export  LQDataTablesCustomToolbar from './LQDataTablesCustomToolbar';
export { CustomSortTooltip as LQDataTablesCustomSortTooltip } from './CustomSortTooltip';
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

class LQDataTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // right: true || false,
      tableColumnIndex: 0,
      tableData: {
        columns: [],
        count: 0,
        data: [],
        page: 0,
        pageSize: 50,
        search: false,
      },
      dropDownData:
        props?.lists?.packageList?.length > 0
          ? [{ id: 0, name: 'All' }, ...props.lists.packageList]
          : [],
      selectedDropDownValue: 0,
      filterSelectList: [],
      filterList: props.headerFilterList,
      sortList: [],
      sortListData: createDefaultSortListData(props.sortList.length),
      params: {
        /* , sort: Array(SORT_LIST.length).fill('') */
        ...props.appliedFilterList,
        ...props.defaultParams,
      },
      loaded: false,
      tableListsFetched: !props.onMount,
    };
    if (props.onRef) props.onRef(this);
  }

  async componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount().then(() => {
        this.setState({ tableListsFetched: true });
      });
    }
    if (this.state.dropDownData?.length == 0) {
      const admin_id = this.props?.user?.user_id;
      axios.get(`/v1/admin/${admin_id}/assign-package/`).then((response) => {
        if (response?.status === 200 && response?.data) {
          const dropDownData = JSON.parse(JSON.stringify(response.data?.data));
          dropDownData && this.props.setPackageData(response.data.data);
          dropDownData.unshift({ id: 0, name: 'All' });
          this.setState({ dropDownData: dropDownData });
        }
      });
    }
    try {
      const tableState = await this.getData();
      if (tableState && tableState.tableData !== null) {
        this.setState({
          tableData: {
            ...tableState.tableData,
          },
        });
        this.setState({ tableListsFetched: true });
      }
    } catch (error) {
      this.setState({ tableListsFetched: true });
      console.log('CDM Error', error);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) this.props.onRef(undefined);
  }

  clearParams = () => {
    this.setState({ params: {} }, () =>
      this.fetchTableDataAndSetMyState(
        this.state.params,
        this.state.tableData.page
      )
    );
  };

  customFooter = (event) => {
    console.log('footer ', event);
    return event;
  };

  customToolbar = (parentThis) => (props) =>
    (
      <CustomToolbar
        // onButtonClick={parentThis.handleCustomToolbarButtonClick}
        createNew={parentThis.props.createNew}
        onClickForClearSort={parentThis.handleClearForSort}
        onClickForClearParams={parentThis.clearParams}
        refreshTable={parentThis.refreshTable}
        handleClickForDateSort={(e) => this.handleClickForDateSort(e)}
        tableName={this.props.tableName}
        downloadPDFOption={this.props.downloadPDFOption}
        dateFilter={this.props.dateFilter}
        allowDownload={
          parentThis.state &&
          parentThis.state.tableData &&
          parentThis.state.tableData.allow_download
        }
        downloadPDF={parentThis.downloadPDF}
        rescheduleStatus={this.state.params && this.state.params.status}
        showRescheduleStatus={this.props.rescheduleStatus}
        handleChangerescheduleStatus={(value) =>
          this.handleChangerescheduleStatus(value)
        }
        showCancelledOrders={(value) => this.showCancelledOrders(value)}
        showQueryOrderStatus={(value) => this.showQueryOrderStatus(value)}
        showToolbarDropdown={(value)=>this.showToolbarDropdown(value)}
        toolbarFilter={this.props.toolbarFilter}
        params={this.state.params}
      >
        {parentThis.props.toolBarChildren}
        {/* <FilterForm
                filterFormStateData={filterFormStateData}
                getFilterList={parentThis.props.advancedFilterList}
                setParentTableData={parentThis.fetchTableDataAndSetMyState}
                onOpen={parentThis.clearParams}
            /> */}
        {/* <LQDataTablesCustomDateSortTooltip
        handleClickForDateSort={(e)=>this.handleClickForDateSort(e)}
                                           
        /> */}
      </CustomToolbar>
    );

  refreshTable = () => {
    this.fetchTableDataAndSetMyState(
      this.state.params,
      this.state.tableData.page
    );
  };

  downloadPDF = (allowDownload) => {
    const admin_id = this.props && this.props.user && this.props.user.user_id;
    const { session_id } = this.props && this.props.search;
    if (allowDownload) {
      axios
        .get(
          session_id
            ? `/v1/admin/${admin_id}/content/session/download-pdf/?session_id=${session_id}`
            : `/v1/admin/${admin_id}/content/session/download-pdf/?grade=${
                this.state.params && this.state.params.grade
              }&session=${this.state.params && this.state.params.session}`
        )
        .then((res) => {
          this.props.enqueueSnackbar('Downloading..', { variant: 'info' });
          if (res && res.status == 200) {
            const Link = res && res.data && res.data.data && res.data.data.url;
            const grade =
              res && res.data && res.data.data && res.data.data.grade;
            const session =
              res && res.data && res.data.data && res.data.data.session;
            Link &&
              axios
                .get(Link, {
                  headers: {
                    'Content-Type': 'application/octet-stream',
                  },
                  responseType: 'blob',
                })
                .then((response) => {
                  const a = document.createElement('a');
                  const url = window.URL.createObjectURL(response.data);
                  a.href = url;
                  a.download =
                    `Questions_G${
                      (this.state.params && this.state.params.grade) || grade
                    }_S${
                      (this.state.params && this.state.params.session) ||
                      session
                    }` + Link.substring(Link.lastIndexOf('.'));
                  a.click();
                })
                .catch((err) => {
                  console.log('error', err);
                });
          }
        })
        .catch((err) => {
          this.props.enqueueSnackbar('Something went wrong', {
            variant: 'error',
          });
        });
    } else {
      this.props.enqueueSnackbar(
        'Please select grade and session filter to download the question PDF',
        { variant: 'error' }
      );
    }
  };
  // CARE ASYNC NETWORK IO OPERATION: Gets table data for the applied filters
  fetchTableDataAndSetMyState = async (params = {}, pageNo) => {
    if (
      this.props.tableName.includes('Question') &&
      params &&
      ((!params.hasOwnProperty('grade') && params.hasOwnProperty('session')) ||
        ((!params.hasOwnProperty('grade') ||
          !params.hasOwnProperty('session')) &&
          params.hasOwnProperty('activity_name')))
    ) {
      if (
        params.hasOwnProperty('activity_name') ||
        params.hasOwnProperty('session')
      ) {
        if (
          !params.hasOwnProperty('grade') &&
          params.hasOwnProperty('session')
        ) {
          this.props.enqueueSnackbar('Please select Grade', {
            variant: 'error',
          });
        } else if (
          (!params.hasOwnProperty('grade') ||
            !params.hasOwnProperty('session')) &&
          params.hasOwnProperty('activity_name')
        ) {
          this.props.enqueueSnackbar('Please select Grade and Session', {
            variant: 'error',
          });
        }
      }
    } else {
      const tableState = await this.getData(params, pageNo);
      // Set page to zero after every new filter req
      if (tableState && tableState.tableData !== null) {
        this.setState((prevState) => ({
          tableData: {
            ...prevState.tableData,
            ...tableState.tableData,
            page: pageNo,
          },
          params: tableState.params,
        }));
      } else {
        this.setState((prevState) => ({
          tableData: {
            ...prevState.tableData,
            data: [],
          },
        }));
      }
    }
  };

  setDropdownFilterData = (e, paramsName) => {
    const paramsData = { ...this.state.params, [paramsName]: e.target.value };
    if (paramsData[paramsName] == 0) delete paramsData[paramsName];
    this.setState(
      {
        selectedDropDownValue: e.target.value,
        params: paramsData,
      },
      () => {
        this.fetchTableDataAndSetMyState(paramsData, 0);
      }
    );
  };

  setDateFilter = (date, paramsName) => {
    const paramsData = date
      ? { ...this.state.params, [paramsName]: date }
      : { ...this.state.params };
    this.setState({ params: paramsData }, () => {
      this.fetchTableDataAndSetMyState(paramsData, 0);
    });
  };

  // CARE ASYNC NETWORK IO OPERATION: Gets table data
  getData = async (
    parameters = {},
    pageNumber = 0 /* usePrevParam = true */
  ) => {
    // once component is mounted packages are ed using api call using axios
    const { sortList, selectedDropDownValue } = this.state;
    this.setState({ loaded: false });
    const params = { ...this.state.params, ...this.props.search };
    let newParams = {};
    // if (usePrevParam) {
    if (sortList.length > 0) {
      params.sort = sortList.join(',');
    }
    newParams = { ...params };
    // }
    const page = pageNumber + 1;
    newParams = {
      ...(this.props.rescheduleStatus &&
        !newParams.status && { status: this.props.rescheduleStatus }),
      ...newParams,
      ...parameters,
      page_size: this.state.tableData.pageSize,
      page,
      ...this.props.params,
    };
    if (
      this.props.rescheduleStatus &&
      parameters &&
      parameters.status &&
      parameters.status == 'all'
    ) {
      newParams = {};
    }
    const tableDataForApiRouting = {
      tableName: this.props.tableName,
      params: newParams,
    };

    this.props.displayTopLoader();
    const { headerFilterList } = this.props;
    var querystring = require('querystring');
    try {
      const user_id = this.props.user.user_id;
      const api = this.props.apiUrl
        ? `/v1/admin/${user_id}/${this.props.apiUrl}`
        : `/v1/${this.props.openApiUrl}`;
      const response = await axios.get(
        api + `?${querystring.stringify(newParams)}`
      );

      if (response.status === 200 && response.data) {
        this.props.enqueueSnackbar('Successfully fetched table data.', {
          variant: 'success',
        });
        const { title } = this.props;
        const { data } = response.data;
        const { count, previous, next, allow_download } = response.data.data;
        this.setState({ params: newParams });
        var results = data.results;
        const admin_roles = data.admin_roles;
        if (results && results.length === 0) {
          return null;
        }

        const nameMappingDict = {};
        let updatedEntry = {};
        if (this.props.firstCol !== undefined) {
          if (typeof this.props.firstCol === 'object') {
            this.props.firstCol.map((item) => {
              updatedEntry[item] = '';
            });
          } else {
            updatedEntry[this.props.firstCol] = '';
          }
        }
        const newEntry = [];
        if (this.props.newColumns !== undefined) {
          this.props.newColumns.map((item) => {
            newEntry[item] = '';
          });
        }

        if (title.includes('User' || 'user')) {
          var userlist = [];
          results &&
            results.map((result, index) => {
              userlist.push({
                id: result.id,
                name: result.name,
                phone: result.phone,
                email: result.email,
                grade: result.grade,
                school: result.school,
                group_id:
                  result.group_details &&
                  result.group_details[0] &&
                  result.group_details.map(
                    (groupDetails, index) => groupDetails
                  ),
                group_name:
                  result.group_details &&
                  result.group_details[0] &&
                  result.group_details.map((groupDetails, index) => (
                    <div>{groupDetails.group_name || '-'}</div>
                  )),
                'Package Assigned':
                  result.subscriptions &&
                  result.subscriptions.map((subscribedPackage) => (
                    <div>
                      {subscribedPackage.name +
                        [' - '] +
                        subscribedPackage.status +
                        [' ']}
                    </div>
                  )),
                'upcoming class':
                  result.group_details &&
                  result.group_details[0] &&
                  result.group_details.map((groupDetails, index) => (
                    <div>
                      {(groupDetails.upcoming_class_time &&
                        new Date(
                          groupDetails.upcoming_class_time
                        ).toLocaleString('en-IN', {
                          year: 'numeric',
                          month: '2-digit',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })) ||
                        '-'}
                    </div>
                  )),
                ac: result.ac,
                device: result.device,
                lq_parent_id: result.lq_parent_id,
                LQ_Student_ID: result.lq_child_id,
                utm: result.utm,
                enrollment_closed: result.enrollment_closed,
                created_at: result.created_at ?
                      new Date(result.created_at).toLocaleString('en-IN', {
                        year: 'numeric',
                        month: '2-digit',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      }) : '-'
              });
            });
          results = userlist;
        }
        if (title.includes('Group' || 'group')) {
          var grouplist = [];
          results.map((result, index) => {
            var name = [],
              id = [],
              grade = [],
              school = [],
              phone = [],
              groupId = result.id,
              groupName = result.name ? result.name : '-',
              rm = result.rm ? result.rm : '-';
            for (var i = 0; i < result.student_details.length; i++) {
              name.push({
                student_name: result.student_details[i].student_name,
                browser: result.student_details[i].browser,
              }),
                id.push(result.student_details[i].student_id),
                grade.push(result.student_details[i].grade),
                school.push(result.student_details[i].school),
                phone.push(result.student_details[i].phone);
            }
            grouplist.push({
              id: groupId,
              name: groupName,
              grade: result.grade,
              teacher: result.teacher,
              student_id: id.length > 8 && false ? id.length + ' Students' : id,
              student_name: name.length > 8 && false ? '-' : name,
              phone: phone.length > 8 && false ? '-' : phone,
              package: result.package,
              classes_completed: result.schedule_details, //&& result.schedule_details.classes_completed,
              'schedule</br>preference':
                result.schedule_preference &&
                eval(result.schedule_preference).map(
                  (pref) => pref.day + '(' + pref.time + ') '
                ),
              class_status:
                result.schedule_details && result.schedule_details.next_class,
              video_sdk: result.video_sdk,
            });
          });
          results = grouplist;
        }

        if (title.includes('Teacher' || 'teacher')) {
          var teacherlist = [];
          results.map((result, index) => {
            teacherlist.push({
              id: result.id,
              name: result.name,
              phone: result.phone,
              email: result.email,
              city_name: result.city_name,
              classes: result.classes,
              packages: result.packages,
              role: result.role,
            });
          });
          results = teacherlist;
        }
        if (title.includes('Question' || 'question')) {
          var questionlist = [];
          results.map((result, index) => {
            questionlist.push({
              id: result.id,
              question: result.question,
              solution: result.solution,
              options: result.options,
              answer: result.answer,
              activity_type: result.activity_type,
              package:
                result.classes &&
                result.classes.map(
                  (classDetail) =>
                    classDetail.package && <div>{classDetail.package}</div>
                ),
              grade:
                result.classes &&
                result.classes.map(
                  (classDetail) =>
                    classDetail.grade && <div>{classDetail.grade}</div>
                ),
              session:
                result.classes &&
                result.classes.map(
                  (classDetail) =>
                    classDetail.session && <div>{classDetail.session}</div>
                ),
              activity_name:
                result.classes &&
                result.classes.map(
                  (classDetail) =>
                    classDetail.activity_name && (
                      <div>{classDetail.activity_name}</div>
                    )
                ),
              'question</br>number':
                result.classes &&
                result.classes.map(
                  (classDetail) =>
                    classDetail.question_number && (
                      <div>{classDetail.question_number}</div>
                    )
                ),
              time: result.time,
              marks: result.marks,
            });
          });
          results = questionlist;
        }

        // console.log("title", title);
        updatedEntry = { ...updatedEntry, ...results[0], ...newEntry };
        // console.log('Filter', simpleFilterList);
        const dataHeaders = Object.keys(updatedEntry);

        Object.keys(updatedEntry).forEach((subEntry) => {
          nameMappingDict[subEntry] = subEntry;
        });
        //  console.log('Data Response', results, 'Data Headers', dataHeaders);

        const simpleFilterList = headerFilterList?.map(
          (elem) => elem.filter_label
        );

        const { sortListData, selectedDropDownValue, dropDownData } =
          this.state;
        const { classes } = this.props;
        const columns =
          dataHeaders &&
          dataHeaders.map((entry, index) => {
            const displayName = nameMappingDict[entry]
              ? nameMappingDict[entry]
              : entry;
            const shortName = entry;
            const sortListIndex = this.props.sortList.indexOf(entry);
            const sortDateIndex =
              this.props.sortDate && this.props.sortDate.indexOf(entry);
            const hideColumn =
              this.props.hideColumn?.filter((hideColumnData) => {
                if (hideColumnData == displayName) return true;
              }).length > 0;
            const hasFilter =
              headerFilterList?.filter((e) => e.filter_label === entry).length >
              0
                ? headerFilterList.filter((e) => e.filter_label === entry)[0]
                : false;
            return {
              name: titleCase(displayName),
              options: {
                customHeadRender: (value) => {
                  const header = (
                    <th className={classes.th} key={index}>
                      <Typography
                        noWrap
                        className={classes.headers}
                        variant='body2'
                        component='span'
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: value.name.replaceAll('_', ' '),
                          }}
                        ></div>
                        {sortListIndex >= 0 ? (
                          <LQDataTablesCustomSortTooltip
                            key={sortListIndex}
                            sortOrder={
                              this.state.sortListData &&
                              this.state.sortListData[sortListIndex] &&
                              this.state.sortListData[sortListIndex].sortOrder
                            }
                            onClick={(
                              (f, i, n) => () =>
                                f(i, n)
                            )(this.handleClickForSort, sortListIndex, entry)}
                          />
                        ) : null}
                      </Typography>
                      {/* {sortDateIndex >= 0 ? (
                                                
                                                    <LQDataTablesCustomDateSortTooltip
                                                        key={sortDateIndex}
                                                        handleClickForDateSort={(e)=>this.handleClickForDateSort(e)}
                                                        tableName={this.props.tableName}
                                                        
                                                    />
                                             
                                                ) : null} */}
                      {hasFilter ? (
                        hasFilter.type == 'text' ? (
                          <FormControl className={classes.formControl}>
                            <InputBase
                              onChange={this.onChangeTopSearchBox}
                              onKeyPress={this.handleTopSearchBoxEnter}
                              name={shortName}
                              classes={{
                                input: classes.searchbox,
                                focused: classes.searchboxFocused,
                                root: classes.searchboxRoot,
                              }}
                              value={this.state.params[shortName] || ''}
                            />
                          </FormControl>
                        ) : hasFilter.type == 'dropdown' ? (
                          <FormControl
                            fullWidth
                            style={{ margin: '8.25px auto' }}
                          >
                            <Select
                              value={selectedDropDownValue}
                              onChange={(e) =>
                                this.setDropdownFilterData(
                                  e,
                                  hasFilter.filter_params_name ||
                                    hasFilter.filter_label
                                )
                              }
                              variant='outlined'
                              hiddenLabel
                              style={{ height: 30, backgroundColor: '#f9f9f9' }}
                            >
                              {(hasFilter.dropDownList || dropDownData)?.map(
                                (values, index) => {
                                  return (
                                    <MenuItem key={index} value={values.id}>
                                      {values.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          </FormControl>
                        ) : hasFilter.type == 'date' ? (
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              variant='inline'
                              label='Date Filter'
                              clearable
                              format='DD/MM/yyyy'
                              autoOk
                              onChange={(date) =>
                                this.setDateFilter(
                                  moment(date).format('YYYY-MM-DD'),
                                  hasFilter.filter_params_name ||
                                    hasFilter.filter_label
                                )
                              }
                              value={
                                this.state.params[
                                  hasFilter.filter_params_name ||
                                    hasFilter.filter_label
                                ] || null
                              }
                              dateRangeIcon
                              disablePast={true}
                              style={{ width: 'fit-content' }}
                            />
                          </MuiPickersUtilsProvider>
                        ) : null
                      ) : sortDateIndex && sortDateIndex < 0 ? (
                        <FormControl className={classes.formControl}>
                          <InputBase
                            disabled
                            classes={{
                              input: classes.dsearchbox,
                              focused: classes.dsearchboxFocused,
                              root: classes.dsearchboxRoot,
                            }}
                          />
                        </FormControl>
                      ) : null}
                    </th>
                  );
                  return header;
                },
                display:
                  displayName == 'LQ_Student_ID' ||
                  displayName == 'lq_parent_id' ||
                  displayName == 'packages' ||
                  displayName == 'classes' ||                  
                  displayName == 'reschedule' ||
                  hideColumn
                    ? 'false'
                    : 'true',
                // display: displayName !== 'id' ? 'true' : 'excluded',
                filter: false /*
                                sortKey: entry,
                                sort: SORT_LIST.indexOf(entry) >= 0, */,
              },
            };
          });

        return {
          tableData: {
            count,
            columns,
            originalData: results,
            data: this.props.formatTable(results, newParams, admin_roles, data),
            next,
            previous,
            nameMappingDict,
            pageSize: this.state.tableData.pageSize,
            allow_download,
          },
          params: newParams,
        };
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.hideTopLoader();
      this.setState({ loaded: true });
    }

    return null;
  };

  handleClearForSort = async () => {
    // console.log('Clearing Sort List');
    if (this.state.sortList.length > 0) {
      await this.setState((prevState) => {
        const newParams = { ...prevState.params };
        if (newParams.sort) {
          delete newParams.sort;
        }
        return {
          sortList: [],
          sortListData: createDefaultSortListData(this.props.sortList.length),
          params: newParams,
        };
      });
      // console.log('Sort', this.state.sortList);
      this.fetchTableDataAndSetMyState({}, this.state.tableData.page);
    }
  };

  showQueryOrderStatus = async (value) => {
    if (value != 'all')
      this.fetchTableDataAndSetMyState({ query_status: value }, 0);
    else {
      await this.setState({ params: {} });
      this.fetchTableDataAndSetMyState({}, 0);
    }
  };

  showCancelledOrders = async (value) => {
    if (value != 'all') this.fetchTableDataAndSetMyState({ status: value }, 0);
    else {
      await this.setState({ params: {} });
      this.fetchTableDataAndSetMyState({}, 0);
    }
  };

  showToolbarDropdown = async (value) => {    
    if (value != 'all') this.fetchTableDataAndSetMyState({ [this.props.toolbarFilter.key]: value }, 0);
    else {
      const paramsData = this.state.params
      if(paramsData?.[this.props.toolbarFilter.key]) delete paramsData?.[this.props.toolbarFilter.key]
      this.setState({ params: paramsData },()=>this.fetchTableDataAndSetMyState({}, 0));      
    }
  };

  handleClickForDateSort = async (selectedDate) => {
    if (
      this.props.tableName.includes('Missed')
    ) {
      this.setState({ params: {} }, () =>
        this.fetchTableDataAndSetMyState(selectedDate, 0)
      );
    } else {
      if (selectedDate) {
        const dateFormated =
          selectedDate.slice(6) +
          '-' +
          selectedDate.slice(3, 5) +
          '-' +
          selectedDate.slice(0, 2);
        // this.setState({params : {...this.state.params, next_class:"selectedDate"} })
        this.fetchTableDataAndSetMyState(
          { ...this.state.params, [this.props.dateFilter]: dateFormated },
          0
        );
      } else {
        let newParamData = { ...this.state.params };
        delete newParamData[this.props.dateFilter];
        this.setState({ params: newParamData }, () =>
          this.fetchTableDataAndSetMyState({ ...this.state.params }, 0)
        );
      }
    }
  };
  handleChangerescheduleStatus = (statusValue) => {
    if (statusValue) {
      this.fetchTableDataAndSetMyState({ status: statusValue }, 0);
    }
  };

  handleClickForSort = async (dataIndex, prevName) => {
    // console.log('Toggle Sort: ', dataIndex, prevName);
    let called = 1;
    const sortListData = [...this.state.sortListData];
    const sortList = [...this.state.sortList];
    if (sortListData[dataIndex].id < 0) {
      sortListData[dataIndex].id = this.state.sortList.length;
    }
    sortListData[dataIndex].sortOrder = toggleSort(
      sortListData[dataIndex].sortOrder
    );
    const name =
      sortListData[dataIndex].sortOrder === 1 ? prevName : `-${prevName}`;
    sortList[sortListData[dataIndex].id] = name;
    await this.setState((prevState) => {
      // console.log('Times Called: ', called);
      called += 1;
      // console.log('Sort List Data: ', sortListData, sortListData[dataIndex]);
      // console.log('New Sort order', sortListData, sortListData[dataIndex], name);
      return {
        ...prevState,
        sortListData,
        sortList,
      };
    });
    // console.log('Sort', this.state.sortList);
    this.fetchTableDataAndSetMyState({}, 0);
  };

  handleTopSearchBoxEnter = (event) => {
    if (event.key === 'Enter') {
      console.log('params', this.state.params);
      this.fetchTableDataAndSetMyState(this.state.params, 0);
    }
  };

  onChangeTopSearchBox = (event) => {
    const { target } = event;
    event.preventDefault();
    event.stopPropagation();
    const value =
      target.value && target.value.trim() !== '' ? target.value : null;
    const oldData = this.state.params;
    if (value) {
      this.setState({
        params: Object.assign({}, oldData, {
          [target.name]: value,
        }),
      });
    } else if (this.state.params && this.state.params[target.name]) {
      this.setState(
        {
          params: Object.assign({}, oldData, {
            [target.name]: '',
          }),
        },
        () => {
          delete this.state.params[target.name];
        }
      );
    } else {
      console.log('df');
    }
  };

  onChangePage = (page) => {
    console.log('page', page);
    this.fetchTableDataAndSetMyState({}, page);
  };

  onTableChange = async (action, tableState) => {
    // console.log('onServerRequest event fired');
    // const { tableData } = this.state;
    // const { page } = tableData;
    /* const { announceText, activeColumn, columns } = tableState; */
    // let newParams = {};
    // let newTableState;
    // let requestURL;
    switch (action) {
      case 'changePage':
        // requestURL = (page || 0) <= tableState.page ? tableData.next : tableData.previous;
        // newParams = queryString.parse(requestURL ? new URL(requestURL).search : '');
        // console.log('New Params: ', newParams);
        // this.fetchTableDataAndSetMyState(newParams, tableState.page + 1);
        // newTableState = await this.getData(newParams);
        // if (newTableState && newTableState.tableData !== null) {
        //     this.setState({
        //         tableData: {
        //             ...tableData,
        //             ...newTableState.tableData,
        //             page: tableState.page,
        //         },
        //         params: newTableState.params,
        //     });
        // }
        break;
      case 'rowsPerPage':
        // this.setState();
        break;
      case 'propsUpdate':
        // tableState.page = 1;
        break;
      case 'sort':
        /*             this.sortList =
                    announceText.search(/ascending$/) < 0
                        ? `-${columns[activeColumn].sortKey}`
                        : columns[activeColumn].sortKey
                ); */
        break;
      default:
    }
  };

  // Array comparision doesn't work like this, this doesn't help solving re-rendering issue
  // shouldComponentUpdate(nextProps) {
  //     console.log('Should Component Update', nextProps.tableData, this.props.tableData);
  //     return nextProps.tableData !== this.props.tableData;
  // }
  onChangeRowsPerPage = (nRows, parentThis) => {
    parentThis.setState(
      { tableData: { ...parentThis.state.tableData, pageSize: nRows } },
      async () => {
        const data = await parentThis.getData(
          {},
          parentThis.state.tableData.page
        );
        console.log('data afer changing rows ', data);
        this.setState({
          tableData: data && data.tableData,
          params: data && data.params,
        });
      }
    );
  };

  onRowsSelect = (value, allSelected) => {
    // console.log(this.state.tableData.data);
    // console.log("index value ", value[0].index);
    const userIdList = [];
    for (let i = 0; i < allSelected.length; i++) {
      const userId =
        this.state.tableData &&
        this.state.tableData.data &&
        this.state.tableData.data[allSelected[i].index] &&
        this.state.tableData.data[allSelected[i].index][1];
      userIdList.push(userId);
    }
    this.props.onRowsSelect(userIdList);
  };

  render() {
    const { title, topLoader } = this.props;
    const {
      // tableColumnIndex,
      loaded,
      tableData,
      tableListsFetched,
    } = this.state;

    const options = {
      count: tableData?.data?.length ? tableData.count : 0,
      filter: false,
      // expandableRows: true,
      responsive: 'simple',
      selectableRows: this.props.selectableRows || 'none',
      onRowsSelect: this.props.onRowsSelect ? this.onRowsSelect : undefined,
      onRowsDelete: this.props.onRowsDelete
        ? this.props.onRowsDelete
        : undefined,
      // resizableColumns: true,
      download: false,
      print: false,
      serverSide: true, // Up lifting: true,
      page: tableData.page,
      rowsPerPage: tableData.pageSize,
      rowsPerPageOptions: [20, 50, 100, 500, 1000],
      onChangeRowsPerPage: (nRows) => this.onChangeRowsPerPage(nRows, this),
      search: false, // tableData.search && true,
      onTableChange: this.onTableChange,
      onChangePage: this.onChangePage,
      customToolbar: this.customToolbar(this),
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage,
        textLabels
      ) => (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
          rowsPerPageOptions={[20, 50, 100, 500, 1000]}
        />
      ),
      // showResponsive: true,
      customSort: (data, colIndex, order) =>
        console.log('Custom Table Sort: ', data, colIndex, order),
      setRowProps: (row, index) =>
        this.props.setRowProps && this.props.setRowProps(row, index, tableData),
    };

    const myTheme = () =>
      createMuiTheme({
        overrides: {
          MuiTableCell: {
            body: {
              border: '1px solid #000',
            },
          },
          MUIDataTableBodyCell: {
            root: {
              borderWidth: '1px',
              borderColor: '#6f6f6f',
              padding: '6px',
              fontSize: '12px',
            },
          },
          MUIDataTable: {
            responsiveScroll: {
              minHeight: '528px',
            },
          },
          MUIDataTableBodyRow: {
            root: {
              '&:nth-child(odd)': {
                backgroundColor: '#fafafa',
              },
              '&:nth-child(even)': {
                backgroundColor: '#cacaca',
              },
            },
          },
          MuiIcon: {
            root: {
              fontSize: '20px',
            },
          },
          MuiIconButton: {
            root: {
              padding: 6,
            },
          },
          MUIDataTableSelectCell: {
            fixedHeader: {
              top: 0,
              left: 0,
              zIndex: 100,
              position: 'inherit',
            },
            headerCell: {
              position: 'sticky',
            },
          },
        },
      });

    return (
      <Fragment>
        {!loaded ? <FullPageLoader show /> : null}
        <MuiThemeProvider theme={myTheme}>
          <MUIDataTable
            title={title}
            data={tableData.data}
            columns={tableData.columns}
            options={options}
          />
        </MuiThemeProvider>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.error,
    user: state.user,
    lists: state.lists,
    topLoader: state.loader.topLoader,
  };
}

const styles = (theme) => ({
  td: {
    padding: 5,
  },
  th: {
    border: 'solid 1px #7d7d7d !important',
    padding: 5,
    position: 'sticky',
    top: -1,
    backgroundColor: 'white',
    zIndex: 10,
  },
  headers: {
    display: 'flex',
    marginTop: 15,
    justifyContent: 'space-around',
  },
  searchbox: {
    backgroundColor: '#f9f9f9',
    border: 'solid 0.8px #616161',
    fontWeight: 'normal',
    fontSize: 14,
    color: '#000',
    padding: 5,
    borderRadius: 5,
  },
  searchboxFocused: {
    boxShadow: '0 0 0 0.15rem rgba(38, 206, 205,.40)',
    borderRadius: 5,
  },
  searchboxRoot: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    marginTop: 10,
  },
  dsearchbox: {
    backgroundColor: '#fff',
    padding: 5,
  },
  dsearchboxRoot: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    marginTop: 10,
  },
  button: {
    margin: theme.spacing(1),
    width: theme.spacing(4) + 4,
  },
});

export default withSnackbar(
  withStyles(styles)(connect(mapStateToProps, actions)(LQDataTables))
);

function onRenderCallback(
  id, // the "id" prop of the Profiler tree that has just committed
  phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration, // time spent rendering the committed update
  baseDuration, // estimated time to render the entire subtree without memoization
  startTime, // when React began rendering this update
  commitTime, // when React committed this update
  interactions // the Set of interactions belonging to this update
) {
  // Aggregate or log render timings...
  // console.log(
  //     'Rendered -> ',
  //     id,
  //     phase,
  //     actualDuration,
  //     baseDuration,
  //     startTime,
  //     commitTime,
  //     interactions,
  // );
}
