import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateRangePickerModal from '../../DateRangePickerModal';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  CircularProgress,
  Tooltip,
  IconButton,
  Icon,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import { withSnackbar } from 'notistack';
import { DateRange, PersonAdd } from '@material-ui/icons';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router';
import SaveChangesModal from '../../SaveChangesModal';
import './style.scss';

class LeaveClassesImpacted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classesData: null,
      startDate: null,
      endDate: null,
      sessionId: null,
      isLoading: false,
      showTeacherUpdateModal: false,
    };
  }

  componentDidMount() {
    const { requestId } = this.props;
    if (requestId) this.fetchLeaveClassesData();
  }

  fetchLeaveClassesData = (
    startDate = this.state.startDate,
    endDate = this.state.endDate
  ) => {
    this.setState({
      classesData: null,
      startDate: startDate,
      endDate: endDate,
      showTeacherUpdateModal: false,
    });
    const { requestId } = this.props;
    const apiUrlParams = requestId
      ? `request_id=${requestId}`
      : `from_date=${startDate}&to_date=${endDate}`;
    const apiUrl = `v1/admin/${this.props.adminId}/teacher/leave/session-list/?${apiUrlParams}`;
    axios.get(apiUrl).then((response) => {
      if (response?.data?.data?.results) {
        const data = response.data.data;
        this.setState({
          classesData: data.results,
          isLoading: false,
        });
      }
    });
  };

  getAvailableTeacherList = (session_schedule_id) => {
    const admin_id = this.props.adminId;
    axios
      .post(`v1/admin/${admin_id}/teacher/`, {
        session_schedule_id: session_schedule_id,
      })
      .then((res) => {
        if (res.status == 200) {
          let data = res && res.data && res.data.data;
          this.setState({
            showTeacherUpdateModal: {
              ...this.state.showTeacherUpdateModal,
              teacherList: data,
            },
          });
        }
      });
  };

  reassignTeacher = () => {
    const { showTeacherUpdateModal } = this.state;
    axios
      .post(
        `/v1/admin/${this.props.adminId}/group/${showTeacherUpdateModal.group_id}/re-schedule/`,
        {
          group_id: showTeacherUpdateModal.group_id,
          scheduled_at: new Date(
            showTeacherUpdateModal.scheduled_at
          ).toISOString(),
          session_schedule_id: showTeacherUpdateModal.session_schedule_id,
          teacher_id: showTeacherUpdateModal.selectedTeacher,
        }
      )
      .then((response) => {
        if (response && response.status === 200) {
          this.fetchLeaveClassesData();
          this.props.enqueueSnackbar('Updated Successfully', {
            variant: 'success',
          });
        }
      })
      .catch((err) => {
        const data = err?.response?.data?.data?.error;
        this.props.enqueueSnackbar(data || 'Something Went Wrong', {
          variant: 'error',
        });
      });
  };

  render() {
    const { classesData, isLoading, showTeacherUpdateModal } = this.state;
    const { requestId } = this.props;
    return (
      <div className='leaveDetailsContainer'>
        <SaveChangesModal
          openModal={showTeacherUpdateModal}
          closeModal={() => {
            this.setState({ showTeacherUpdateModal: false });
          }}
          text={'Reassign Teacher'}
          displayData={
            <div className='reassignTeacher'>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                  variant='inline'
                  label={'Class Scheduled At'}
                  value={showTeacherUpdateModal?.scheduled_at}
                  disabled={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <DateRange />
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
              <FormControl style={{ minWidth: 200 }}>
                <InputLabel>Reassign Teacher</InputLabel>
                <Select
                  value={showTeacherUpdateModal.selectedTeacher}
                  onChange={(e) => {
                    this.setState({
                      showTeacherUpdateModal: {
                        ...showTeacherUpdateModal,
                        selectedTeacher: e.target.value,
                      },
                    });
                  }}
                >
                  {showTeacherUpdateModal?.teacherList?.map((teacherData) => (
                    <MenuItem value={teacherData.id}>
                      {teacherData.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          }
          onSave={() => this.reassignTeacher()}
          disableConfirm={!showTeacherUpdateModal.selectedTeacher}
        />
        {requestId ? null : (
          <DateRangePickerModal
            fetchData={this.fetchLeaveClassesData}
            defaultStartDate={moment(new Date()).format('YYYY-MM-DD')}
            defaultEndDate={moment(new Date()).add(7, 'd').format('YYYY-MM-DD')}
            defaultDateRangeLabel={'Upcoming 7 days'}
          />
        )}
        {classesData?.length ? (
          <div className='tableContainer'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {Object.keys(classesData[0]).map((header, index) =>
                    header == 'group_details' ? (
                      Object.keys(classesData[0][header])?.map((headerData) => (
                        <TableCell>
                          {headerData.charAt(0).toUpperCase() +
                            headerData.replaceAll('_', ' ').slice(1)}
                        </TableCell>
                      ))
                    ) : (
                      <TableCell>
                        {header.charAt(0).toUpperCase() +
                          header.replaceAll('_', ' ').slice(1)}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {classesData.map((leaveDetails) => (
                  <TableRow>
                    <TableCell>
                      <div style={{ display: 'flex' }}>
                        <Link
                          to={{
                            pathname: '/calendar',
                            search: `?group_id=${leaveDetails.group_id}`,
                          }}
                          target='_blank'
                          style={{ textDecoration: 'none' }}
                        >
                          <IconButton>
                            <Tooltip title='Calendar Schedule'>
                              <DateRange />
                            </Tooltip>
                          </IconButton>
                        </Link>
                        <IconButton
                          onClick={() => {
                            this.setState({
                              showTeacherUpdateModal: {
                                session_schedule_id: leaveDetails.id,
                                scheduled_at: leaveDetails.scheduled_at,
                                group_id: leaveDetails.group_id,
                                teacher: leaveDetails.teacher,
                                selectedTeacher: '',
                              },
                            });
                            this.getAvailableTeacherList(leaveDetails.id);
                          }}
                        >
                          <Tooltip title='Update Teacher'>
                            <PersonAdd />
                          </Tooltip>
                        </IconButton>
                      </div>
                    </TableCell>
                    {Object.keys(classesData[0]).map((header) =>
                      header == 'group_details' ? (
                        Object.keys(leaveDetails[header])?.map((headerData) => (
                          <TableCell>
                            {leaveDetails[header][headerData] || '-'}
                          </TableCell>
                        ))
                      ) : (
                        <TableCell>
                          {header.includes('date') ||
                          header.includes('created_at') ||
                          header.includes('schedule') ||
                          header.includes('time')
                            ? new Date(leaveDetails[header]).toLocaleString()
                            : typeof leaveDetails[header] == 'boolean'
                            ? leaveDetails[header]
                              ? 'Yes'
                              : 'No'
                            : leaveDetails[header] || '-'}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : classesData?.length == 0 ? (
          <div className='centerAligned'>No data</div>
        ) : isLoading ? (
          <CircularProgress className='centerAligned' />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  return {
    adminId: states?.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(LeaveClassesImpacted));
