import React from 'react'
import './style.scss'

const VideoPlaceHolder = (props) => (
  <div
    className="video-placeholder"
    onClick={props.playVideo}
    style={props.style}
  >
    <div className="play-btn">
      <svg viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.8041 11.9513L8.11859 16.9909C7.38149 17.4181 6.43555 16.9008 6.43555 16.0395V5.96033C6.43555 5.10038 7.38013 4.58168 8.11859 5.01029L16.8041 10.0499C16.9718 10.1456 17.1111 10.2839 17.2081 10.4509C17.305 10.6179 17.3561 10.8075 17.3561 11.0006C17.3561 11.1937 17.305 11.3833 17.2081 11.5503C17.1111 11.7172 16.9718 11.8556 16.8041 11.9513Z"
          fill="#BF5AF2"
        />
      </svg>
    </div>
  </div>
)

export default VideoPlaceHolder
