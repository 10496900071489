/* eslint-disable no-console */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

const RequireAuthParent = (ChildComponent) => {
  const Auth = (props) => {
    useEffect(() => {
      if (props.user ? props.user.role !== "parent" : true) {
        // console.log("Redirected Parent: ", props.user, browserHistory);
        browserHistory.push({
          pathname: "/",
          search:
            typeof window !== "undefined" && window && window.location.search,
        });
      }
    }, [props.user]);

    return <ChildComponent {...props} />;
  };

  return connect(mapStateToProps)(Auth);
};

const RequireAuthStudent = (ChildComponent) => {
  const Auth = (props) => {
    useEffect(() => {
      let path = "/";
      if (props.user ? props.user.role !== "student" : true) {
        console.log("Redirected Student: ", props.user, browserHistory);
        if (props.user && props.user.role === "parent") path = "/parent/home";
        browserHistory.push({
          pathname: path,
          search:
            typeof window !== "undefined" && window && window.location.search,
        });
      } else if (
        props.user &&
        props.user.role === "student" &&
        props.user.test_state
      ) {
        path = "/register-buy";
        browserHistory.push({
          pathname: path,
          search:
            typeof window !== "undefined" && window && window.location.search,
        });
      }
    }, [props.user]);

    return <ChildComponent {...props} />;
  };

  return connect(mapStateToProps)(Auth);
};

const TeacherLoginAuthRedirector = (ChildComponent) => {
  const Auth = (props) => {
    useEffect(() => {
      if (props.user) {
        console.log("Redirected: ", props.user, browserHistory);
        let path = "/user";

        browserHistory.push({
          pathname: path,
        });
      }
    }, [props.user]);

    return <ChildComponent {...props} />;
  };

  return connect(mapStateToProps)(Auth);
};

const AuthRedirector = (ChildComponent) => {
  const Auth = (props) => {
    useEffect(() => {
      if (!props.user) {
        browserHistory.push({
          pathname: "/login",
        });
      }
    }, [props.user]);

    return <ChildComponent {...props} />;
  };

  return connect(mapStateToProps)(Auth);
};

const TeacherAuthRedirector = (ChildComponent) => {
  const Auth = (props) => {
    useEffect(() => {
      if (props.user && props.user.role == "teacher") {
        browserHistory.push({
          pathname: "/user",
        });
      }
    }, [props.user]);

    return <ChildComponent {...props} />;
  };

  return connect(mapStateToProps)(Auth);
};

// eslint-disable-next-line no-unused-vars
function mapStateToProps({ user }, ownProps) {
  // console.log("Smit PROPS", ownProps);
  return { user };
}

// eslint-disable-next-line object-curly-newline
export {
  TeacherLoginAuthRedirector,
  RequireAuthParent,
  RequireAuthStudent,
  AuthRedirector,
  TeacherAuthRedirector,
};
