import React, { Component } from 'react';
import { connect } from 'react-redux';
import SaveChangesModal from '../../SaveChangesModal';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import axios from 'axios';
import LeaveClassesImpacted from '../LeaveClassesImpacted';

class TeacherOnLeaveList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onLeaveData: null,
      startDate: null,
      endDate: null,
      isLoading: false,
      changeStatusData: null,
      requestId: null,
    };
    this.statusData = {
      0: 'Raised',
      1: 'Approved',
      2: 'Rejected',
    };
  }
  componentDidMount() {
    this.fetchStudentMentorData();
  }
  fetchStudentMentorData = () => {
    this.setState({ onLeaveData: null });
    axios
      .get(`v1/admin/${this.props.adminId}/teacher/leave/list/`)
      .then((response) => {
        if (response?.data?.data?.results) {
          const data = response.data.data;
          this.setState({
            onLeaveData: data.results,
            isLoading: false,
            changeStatusData: null,
          });
        }
      });
  };

  changeStatus = () => {
    const { leaveId, status } = this.state.changeStatusData;
    axios
      .post(
        `v1/admin/${this.props.adminId}/teacher/leave/${leaveId}/change-status/`,
        { status: status }
      )
      .then((response) => {
        if (response?.data && response.status == 200) {
          this.fetchStudentMentorData();
        }
      });
  };

  render() {
    const { onLeaveData, isLoading, changeStatusData, requestId } = this.state;
    return (
      <div className='studentMentorContainer'>
        <SaveChangesModal
          openModal={changeStatusData}
          closeModal={() => this.setState({ changeStatusData: null })}
          text={`Do you want to change status to ${
            this.statusData[changeStatusData?.status]
          }`}
          onSave={this.changeStatus}
        />
        <SaveChangesModal
          openModal={requestId}
          closeModal={() => this.setState({ requestId: null })}
          text={'Classes Impacted'}
          displayData={<LeaveClassesImpacted requestId={requestId} />}
          style={{ overflow: 'auto', width: '90vw', maxHeight: '90vh' }}
        />
        {onLeaveData?.length ? (
          <div className='tableContainer'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Object.keys(onLeaveData[0]).map((header) => (
                    <TableCell>
                      {header.charAt(0).toUpperCase() +
                        header.replaceAll('_', ' ').slice(1)}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {onLeaveData.map((teacherData) => (
                  <TableRow>
                    {Object.keys(onLeaveData[0]).map((header) => (
                      <TableCell>
                        {header.includes('date') ||
                        header.includes('schedule') ||
                        header.includes('time') ||
                        header.includes('created_at')
                          ? new Date(teacherData[header]).toLocaleString()
                          : typeof teacherData[header] == 'boolean'
                          ? teacherData[header]
                            ? 'Yes'
                            : 'No'
                          : teacherData[header] || '-'}
                      </TableCell>
                    ))}
                    <TableCell>
                      <div style={{ display: 'flex', gap: '0.5vw' }}>
                        {teacherData.status.toLowerCase() == 'raised' ||
                        teacherData.status.toLowerCase() == 'rejected' ? (
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              this.setState({
                                changeStatusData: {
                                  status: 1,
                                  leaveId: teacherData.id,
                                },
                              })
                            }
                          >
                            Approve
                          </Button>
                        ) : null}
                        {teacherData.status.toLowerCase() == 'approved' ||
                        teacherData.status.toLowerCase() == 'raised' ? (
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              this.setState({
                                changeStatusData: {
                                  status: 2,
                                  leaveId: teacherData.id,
                                },
                              })
                            }
                          >
                            Reject
                          </Button>
                        ) : null}
                        <Button
                          color='primary'
                          variant='outlined'
                          onClick={() =>
                            this.setState({ requestId: teacherData.id })
                          }
                          size='small'
                        >
                          Classes Impacted
                          <KeyboardArrowRight />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : onLeaveData?.length == 0 ? (
          <div className='centerAligned'>No data</div>
        ) : isLoading ? (
          <CircularProgress className='centerAligned' />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  return {
    adminId: states?.user?.user_id,
  };
};

export default connect(mapStateToProps)(TeacherOnLeaveList);
