import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Button,
  Icon,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import SaveChangesModal from '../../components/SaveChangesModal';
import './styles.scss';
import { withSnackbar } from 'notistack';

class AdminRolesAndAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rolesData: null,
      authenticationDetail: null,
    };
  }
  componentDidMount() {
    this.fetchRolesData();
  }
  fetchRolesData = () => {
    const admin_id = this.props?.admin_id;
    axios
      .get(`/v1/admin/${admin_id}/admins/roles/`)
      .then((res) => {
        if (res?.status && res?.data?.data) {
          this.setState({
            rolesData: res.data.data,
            authenticationDetail: null,
          });
        }
      })
      .catch((err) => {
        this.props.enqueueSnackbar(
          (err?.response?.data?.data?.error &&
            err.response.data.data.error[0]) ||
            'Something Went Wrong',
          { variant: 'error' }
        );
      });
  };
  onUpdateRole = () => {
    const admin_id = this.props?.admin_id;
    const { authenticationDetail } = this.state;
    if (
      !(
        authenticationDetail?.auths &&
        authenticationDetail?.auths[authenticationDetail.auths.length - 1]
          ?.auth &&
        authenticationDetail?.auths[authenticationDetail.auths.length - 1]
          ?.methods
      ) &&
      authenticationDetail.action == 'update_auth' &&
      authenticationDetail.auths.length > 0
    ) {
      this.props.enqueueSnackbar('Select Authentication Detail', {
        variant: 'error',
      });
      return;
    }
    authenticationDetail.auths?.map(
      (authsDetail) =>
        (authsDetail.methods = JSON.stringify(authsDetail.methods))
    );
    axios
      .post(`/v1/admin/${admin_id}/admins/roles/`, {
        action: authenticationDetail.action,
        role: authenticationDetail.role,
        ...(authenticationDetail.auths && {
          auths: authenticationDetail.auths,
        }),
      })
      .then((res) => {
        if (res && res.status == 200) {
          const message = res?.data?.data?.message;
          this.props.enqueueSnackbar(message || 'Role updated Successfully', {
            variant: 'success',
          });
          this.fetchRolesData();
        }
      })
      .catch((err) => {
        this.props.enqueueSnackbar(
          (err?.response?.data?.data?.error &&
            err.response.data.data.error[0]) ||
            'Something Went Wrong',
          { variant: 'error' }
        );
      });
  };
  adminRolesData = () => {
    const { authenticationDetail } = this.state;
    const { auth_list } = this.state.rolesData;
    return (
      <div className='editRoleContainer'>
        <div>
          <TextField
            value={authenticationDetail.role}
            onChange={(event) => {
              const authenticationData = authenticationDetail;
              authenticationData.role = event.target.value;
              this.setState({ authenticationDetail: authenticationData });
            }}
            label='Role'
            disabled={authenticationDetail.action == 'update_auth'}
          />
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              const authenticationData = authenticationDetail;
              authenticationData.auths.push({ auth: '', methods: '' });
              this.setState({ authenticationDetail: authenticationData });
            }}
            disabled={
              authenticationDetail.action == 'add_role' ||
              !(
                authenticationDetail.auths.length == 0 ||
                (authenticationDetail?.auths[
                  authenticationDetail.auths.length - 1
                ]?.auth &&
                  authenticationDetail?.auths[
                    authenticationDetail.auths.length - 1
                  ]?.methods)
              )
            }
          >
            <Icon>add</Icon>Add Access
          </Button>
        </div>
        {authenticationDetail.auths?.map((authsDetail, index) => (
          <div>
            <FormControl>
              <InputLabel>Select Access</InputLabel>
              <Select
                value={authsDetail.auth}
                onChange={(e) => {
                  const auth = authenticationDetail;
                  auth.auths[
                    (authenticationDetail?.auths).findIndex(
                      (authData) => authData.auth === authsDetail.auth
                    )
                  ].auth = e.target.value;
                  this.setState({
                    authenticationDetail: auth,
                  });
                }}
              >
                {auth_list.map(
                  (authList) =>
                    ((authenticationDetail?.auths).findIndex(
                      (authData) => authData.auth === authList
                    ) < 0 ||
                      authList == authsDetail.auth) && (
                      <MenuItem value={authList}>{authList}</MenuItem>
                    )
                )}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Select Access Type</InputLabel>
              <Select
                value={authsDetail.methods.toString()}
                onChange={(e) => {
                  const auth = authenticationDetail;
                  auth.auths[
                    (authenticationDetail?.auths).findIndex(
                      (authData) => authData.auth === authsDetail.auth
                    )
                  ].methods = e.target.value.split(',');
                  this.setState({
                    authenticationDetail: auth,
                  });
                }}
              >
                <MenuItem value={['GET'].toString()}>GET</MenuItem>
                <MenuItem value={['GET', 'POST'].toString()}>
                  GET and POST
                </MenuItem>
              </Select>
            </FormControl>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                const authenticationData = authenticationDetail;
                authenticationData.auths.splice(
                  (authenticationData?.auths).findIndex(
                    (authData) => authData.auth === authsDetail.auth
                  ),
                  1
                );
                this.setState(
                  { authenticationDetail: authenticationData },
                  () => console.log(this.state)
                );
              }}
            >
              Remove
            </Button>
          </div>
        ))}
      </div>
    );
  };
  render() {
    const adminRoleData = this.state?.rolesData?.data;
    return (
      <div className='adminRoleContainer'>
        <div className='headerContainer'>
          <div className='header'>Admin Roles and Authentication</div>
          <Button
            color='primary'
            variant='contained'
            onClick={() =>
              this.setState({
                authenticationDetail: {
                  action: 'add_role',
                  text: 'Add Role',
                },
              })
            }
          >
            <Icon>add</Icon>Add role
          </Button>
        </div>
        {this.state.rolesData ? (
          <div className='tableContainer'>
            {this.state.authenticationDetail && (
              <SaveChangesModal
                openModal={this.state.authenticationDetail ? true : false}
                closeModal={() => this.setState({ authenticationDetail: null })}
                displayData={this.adminRolesData()}
                text={this.state.authenticationDetail.text}
                onSave={() => this.onUpdateRole()}
                style={{ width: '60vw', overflowY: 'scroll' }}
              />
            )}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Edit</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Access</TableCell>
                  <TableCell>Method</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminRoleData.map((roleData) => (
                  <>
                    <TableRow>
                      <TableCell rowSpan={roleData.auths.length || 1}>
                        <Button
                          color='primary'
                          variant='contained'
                          disabled={roleData.role=='Admin'}
                          onClick={() =>
                            this.setState({
                              authenticationDetail: {
                                ...JSON.parse(JSON.stringify(roleData)),
                                action: 'update_auth',
                                text: 'Update Authentication',
                              },
                            })
                          }
                        >
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell rowSpan={roleData.auths.length || 1}>
                        {roleData.role}
                      </TableCell>
                      <TableCell>
                        {roleData.auths[0] && roleData.auths[0].auth}
                      </TableCell>
                      <TableCell>
                        {roleData.auths[0] &&
                          roleData.auths[0].methods.toString()}
                      </TableCell>
                    </TableRow>
                    {roleData.auths.length > 1
                      ? roleData.auths.slice(1).map((authsData) => (
                          <TableRow>
                            <TableCell>{authsData.auth}</TableCell>
                            <TableCell>
                              {authsData.methods.toString()}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  return {
    admin_id: states?.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(AdminRolesAndAuthentication));
