import { Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React from 'react';
import './style.scss';

import RightAlign from '../../../../assets/menu_alt_02.png';
import LeftAlign from '../../../../assets/menu_alt_03.png';
import CenterAlign from '../../../../assets/menu_alt_04.png';
import Row from '../../../../components/Elements/Flex/row';
import { COLOR_ARRAY, FONT_ARRAY } from '../../FlashCardsUtils';

const alignImages = [
  { image: LeftAlign, value: 'left' },
  { image: CenterAlign, value: 'center' },
  { image: RightAlign, value: 'right' },
];

function TextCssModal(props) {
  const COLORS = COLOR_ARRAY;
  const FONT_SIZES = FONT_ARRAY;

  let topHeight = document.getElementById('drop-box').offsetHeight;

  const { style, checkCallStyling } = props;

  return (
    <div
      className='main-div-text'
      id='text-popup'
      style={{ top: topHeight / 2 - 65 }}
    >
      <Row style={{}}>
        {COLORS.map((data, i) => {
          return (
            <div
              className='color-text-div'
              style={{
                border:
                  style.color === data ? '1px solid #FF9F0A' : '1px solid #FFF',
              }}
            >
              <div
                className={`colors ${data == '#FFF' ? 'white' : 'non-white'}`}
                key={i}
                style={{
                  background: data,
                }}
                onClick={() => {
                  checkCallStyling('color', data);
                }}
              >
                A
              </div>
            </div>
          );
        })}
      </Row>
      <FormControl className='form-control' variant='outlined'>
        <Select
          native
          value={style.fontSize}
          className='select'
          onChange={(event) => {
            checkCallStyling('fontSize', Number(event.target.value));
          }}
        >
          {FONT_SIZES &&
            FONT_SIZES.map((data, i) => (
              <option key={i} value={data}>
                {data}
              </option>
            ))}
        </Select>
      </FormControl>
      <Row className='align-row'>
        {alignImages.map((data, i) => (
          <img
            style={{
              backgroundColor: data.value === style.textAlign ? '#E9ECEF' : '',
            }}
            onClick={() => {
              checkCallStyling('textAlign', data.value);
            }}
            key={i}
            className='align-img'
            src={data.image}
            alt='left'
          />
        ))}
      </Row>
    </div>
  );
}

export default TextCssModal;
