// import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { useState, useEffect } from 'react';
// import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { goToPage } from '../../Utils/utilityFunctions';
import { CustomDateSortTooltip as LQDataTablesCustomDateSortTooltip } from '../../components/LQDataTables/CustomDateSortTooltip';

const defaultToolbarStyles = {
  serverSide: {
    marginLeft: 0,
  },
  iconButton: {
    background: '#27debf' /* fallback for old browsers */,
    border: 0,
    color: 'white',
    boxShadow: '0 3px 5px 2px rgba(26,41,128, .2)',
    marginRight: 8,
    marginLeft: 8,
  },
  createNew: {
    border: 0,
    marginRight: 8,
  },
};

const CustomToolbar = (props) => {
  const {
    classes,
    createNew,
    onClickForClearParams,
    onClickForClearSort,
    refreshTable,
    dateFilter,
    from_date,
    allowDownload,
    downloadPDF,
    tableName,
    downloadPDFOption,
    rescheduleStatus,
    handleChangerescheduleStatus,
    showRescheduleStatus,
    showCancelledOrders,
    showQueryOrderStatus,
    showToolbarDropdown,
    toolbarFilter,
  } = props;

  const [cancelOrderDisplayed, setCancelOrderDisplayed] = useState('all');
  const [toolbarFilterStatus, setToolbarFilterStatus] = useState(
    toolbarFilter?.default || 'all'
  );
  const [queryOrderStatus, setQueryOrderStatus] = useState(1);

  return (
    <React.Fragment>
      {downloadPDFOption && (
        <Tooltip
          title={
            allowDownload
              ? 'Download PDF'
              : 'Plaese enter grade and session filter to download the PDF'
          }
        >
          <IconButton onClick={() => downloadPDF(allowDownload)}>
            <Icon style={{ opacity: allowDownload ? 1 : 0.6 }}>
              get_app_icon
            </Icon>
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title='Clear Sort'>
        <IconButton onClick={onClickForClearSort}>
          <Icon>restore_page</Icon>
        </IconButton>
      </Tooltip>
      <Tooltip title='Clear Params'>
        <IconButton onClick={onClickForClearParams}>
          <Icon>restore</Icon>
        </IconButton>
      </Tooltip>
      {createNew ? (
        <Tooltip title='Create New'>
          <IconButton onClick={() => goToPage(createNew)}>
            <Icon>add</Icon>
          </IconButton>
        </Tooltip>
      ) : null}
      <Tooltip title='Refresh Table'>
        <IconButton onClick={refreshTable}>
          <Icon>refresh</Icon>
        </IconButton>
      </Tooltip>
      {from_date}
      {dateFilter && (
        <Tooltip title='Date Sort'>
          <LQDataTablesCustomDateSortTooltip
            handleClickForDateSort={(e) => props.handleClickForDateSort(e)}
            tableName={props.tableName}
            date={props.params.date}
          />
        </Tooltip>
      )}
      {/* <Tooltip title="Server Side">
                <FormControlLabel
                    className={classes.serverSide}
                    disabled
                    dir="rtl"
                    control={<Switch checked />}
                    label="Server Side"
                />
            </Tooltip> */}
      {showRescheduleStatus && (
        <Select
          value={rescheduleStatus || 'all'}
          onChange={(event) => handleChangerescheduleStatus(event.target.value)}
        >
          <MenuItem value={1}>Active</MenuItem>
          <MenuItem value={2}>Rescheduled</MenuItem>
          <MenuItem value={3}>Cancelled</MenuItem>
          <MenuItem value={'all'}>All</MenuItem>
        </Select>
      )}
      {tableName && tableName.includes('order') && (
        // <Button variant="contained" color={cancelOrderDisplayed ?"primary" : ""}
        // onClick={()=>
        // {
        //     showCancelledOrders(!cancelOrderDisplayed),
        //     setCancelOrderDisplayed(!cancelOrderDisplayed)
        // }
        // }
        // >
        //     {cancelOrderDisplayed ? 'Show Orders' : 'Show Cancelled Orders'}
        // </Button>
        <Select
          value={cancelOrderDisplayed}
          onChange={(event) => {
            showCancelledOrders(event.target.value);
            setCancelOrderDisplayed(event.target.value);
          }}
        >
          <MenuItem value={0}>
            Provisional Orders{' '}
            <div
              style={{
                width: 13,
                height: 13,
                background: 'rgba(0, 255, 0, 0.3)',
                marginLeft: 10,
              }}
            />
          </MenuItem>
          <MenuItem value={1}>
            Active Orders{' '}
            <div
              style={{
                width: 13,
                height: 13,
                background:
                  'repeating-conic-gradient(#a5a4a4 0% 25%, transparent 0% 50%) 50% / 15px 15px',
                border: '1px solid black',
                marginLeft: 10,
              }}
            />
          </MenuItem>
          <MenuItem value={2}>
            Completed Orders{' '}
            <div
              style={{
                width: 13,
                height: 13,
                background: 'rgba(255, 255, 0, 0.3)',
                marginLeft: 10,
              }}
            />
          </MenuItem>
          <MenuItem value={3}>
            Cancelled Orders{' '}
            <div
              style={{
                width: 13,
                height: 13,
                background: 'rgba(225,60,10, 0.85)',
                marginLeft: 10,
              }}
            />
          </MenuItem>
          <MenuItem value={'all'}>All Orders</MenuItem>
        </Select>
      )}
      {toolbarFilter ? (
        <Select
          value={toolbarFilterStatus}
          onChange={(event) => {
            setToolbarFilterStatus(event.target.value);
            showToolbarDropdown(event.target.value);
          }}
          style={{ marginLeft: 15 }}
        >
            {Object.entries(toolbarFilter?.list || {})?.map(([key, value])=><MenuItem value={key}>{value}</MenuItem>)}
        </Select>
      ) : null}
      {tableName && tableName.includes('Query') && (
        <Select
          value={queryOrderStatus}
          onChange={(event) => {
            setQueryOrderStatus(event.target.value);
            showQueryOrderStatus(event.target.value);
          }}
          style={{ marginLeft: 15 }}
        >
          <MenuItem value={0}>Resolved</MenuItem>
          <MenuItem value={1}>Active</MenuItem>
          <MenuItem value={'all'}>All</MenuItem>
        </Select>
      )}
      {props.children}
    </React.Fragment>
  );
};

export default withStyles(defaultToolbarStyles, {
  name: 'CustomToolbar',
})(CustomToolbar);
