import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import { Checkbox, Button, CircularProgress } from '@material-ui/core';

import SaveChangesModal from '../SaveChangesModal';

class DownloadCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      certificateData: null,
      loader: null,
    };
  }

  componentDidMount() {
    const { id } = this.props;
    console.log(id);
    if (id) this.fetchUserData();
  }
  fetchUserData = () => {
    const { id, adminId } = this.props;
    axios
      .get(`/v1/admin/${adminId}/student/${id}/certificate/`)
      .then((response) => {
        if (response.status == 200 && response?.data?.data)
          this.setState({ certificateData: response.data.data });
        this.props.enqueueSnackbar(
          response?.data?.data?.message || 'Fetched Data Successfully',
          { variant: 'success' }
        );
      })
      .catch((err) => {
        this.props.enqueueSnackbar(
          err?.response?.data?.data?.error || 'Something went wrong',
          { variant: 'error' }
        );
      });
  };
  downloadPdf = (link, packageName) => {
    const { name } = this.props;
    axios
      .get(link, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
      })
      .then((response) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = `${name}-${packageName}-Certificate${link.substring(
          link.lastIndexOf('.')
        )}`;
        a.click();
        this.setState({ loader: false });
      })
      .catch((err) => {
        this.setState({ loader: false})
      });
  };
  fetchCertificate = (packageData, index) => {
    const { id, adminId } = this.props;
    this.setState({ loader: packageData.package_subscription_id });
    axios
      .post(`/v1/admin/${adminId}/student/${id}/certificate/`, {
        package_subscription_id: packageData.package_subscription_id,
        send_email: packageData.send_email || false,
      })
      .then((response) => {
        if (response.status == 200 && response?.data?.data) {
          const link = response?.data?.data;
          this.downloadPdf(link, packageData.package_name);
        }
      })
      .catch((err) => {
        this.props.enqueueSnackbar(
          err?.response?.data?.data?.error || 'Something went wrong',
          { variant: 'error' }
        );
        const certificateDetails = this.state.certificateData;
        certificateDetails[index].error = err?.response?.data?.data?.error[0];
        this.setState({ loader: false, certificateData: certificateDetails });
      });
  };
  renderData = () => {
    const { certificateData, loader } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        {certificateData?.map((packageData, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.35vw',
              fontSize: '1.15vw',
              fontWeight: 500,
              border: '1px solid gray',
              borderRadius: 8,
              padding: '1vw',
            }}
          >
            <div>Package Name : {packageData.package_name}</div>
            <div>Grade : {packageData.grade}</div>
            <div>
              Certificate Sent : {packageData.certificate_sent ? 'Yes' : 'No'}
            </div>
            <div>
              <Checkbox
                checked={packageData.send_email}
                onChange={(event) => {
                  const certificateDetails = this.state.certificateData;
                  certificateDetails[index] = {
                    ...packageData,
                    send_email: event.target.checked,
                  };
                  this.setState({ certificateData: certificateDetails });
                }}
              />{' '}
              Email Certificate to the User
            </div>
            <Button
              color={packageData.error ? 'secondary' : 'primary'}
              variant={packageData.error ? 'outlined' : 'contained'}
              onClick={() =>
                !(packageData.error || loader) &&
                this.fetchCertificate(packageData, index)
              }
              // disabled={packageData.error || loader}
            >
              {packageData.error ? (
                packageData.error
              ) : loader == packageData.package_subscription_id ? (
                <CircularProgress
                  style={{
                    width: '2vw',
                    height: '2vw',
                    marginRight: '1vw',
                    color: '#ffffff',
                  }}
                />
              ) : null}
              {packageData.sendToUser
                ? 'Download and Email Certificate to the user'
                : 'Download Certificate'}
            </Button>
          </div>
        ))}
      </div>
    );
  };
  render() {
    const { id, onClose } = this.props;
    return (
      <SaveChangesModal
        openModal={id}
        closeModal={onClose}
        text={'Certificate Details'}
        displayData={this.renderData()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminId: state?.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(DownloadCertificate));
