/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import Typography from '@material-ui/core/Typography';
import React, { Component, Fragment } from 'react';
import Loader from '../Loader';
import { contentType } from 'mime-types'
// const config = require('./config');
import S3FileUpload, { uploadFile } from 'react-s3';
import { connect } from 'react-redux'
import { Icon, IconButton } from '@material-ui/core';


class S3UploadButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enableUpload: false,
            file: '',
            fileType: '',
            isUploading: false,
            buttonValue: 'Submit'
        };
    }
    selectFile = async (event) => {

        const file = event.target.files[0]
        const extension = file && file.name.split('.').pop()
        const myRenamedFile = new File([file], this.props.fileName + '.' + extension);
        if(extension)
        {
            await this.setState({ file: myRenamedFile, fileType: file && file.type })
            await this.setState({ enableUpload: true, buttonValue: 'Submit' })
            await this.uploadFile()
        }
        
    }

    uploadFile = () => {
        const file = this.state.file
        const key = this.props.dirName ? `${this.props.dirName}/${file.name}` : `${file.name}`
        var bodyFormData = new FormData();
        bodyFormData.append('file', file);
        bodyFormData.append('content_type', this.state.fileType)
        bodyFormData.append('bucket_name', 'lq-live')
        bodyFormData.append('key', key)
        axios({
            method: "post",
            url: `v1/admin/${this.props.user_id}/s3/upload/`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((response) => {
                if (response.status === 200 && response.data) {
                    response.data.data && response.data.data && this.props.setField(response.data.data)
                    this.setState({ enableUpload: false, buttonValue: 'Submitted' })
                }
            })
            .catch((error) => {
                console.log('S3 upload', error && error.response);
            });
    }


    render() {
        const { classes, disabled, fileType } = this.props;
        const { enableUpload, file, isUploading } = this.state;
        return (
            this.props.directUpload ?

                <Button                    
                    component="label"
                >
                    <Icon>upload_file_icon</Icon>
                    <input
                        type="file"
                        hidden
                        onChange={this.selectFile}
                    />
                </Button>

                :
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 6 }}>
                        <Badge badgeContent={fileType} classes={{ badge: classes.badge }}>
                            <Button
                                className={classes.sizeFix}
                                color="primary"
                                component="label"
                                disabled={disabled || false}
                                variant="contained"
                            >
                                {this.props.title}
                                <input onChange={this.selectFile} style={{ display: 'none' }} type="file" accept={fileType == 'zip' ? '.zip' : true} />
                            </Button>
                        </Badge>
                        {/* <Button
                            className={`${classes.sizeFix} ${classes.leftMargin}`}
                            color="primary"
                            component="label"
                            disabled={!enableUpload}
                            onClick={this.uploadFile}
                            variant="contained"
                        >
                            {isUploading ? <Loader /> : this.state.buttonValue}
                            {isUploading ? null : <SendIcon className={classes.rightIcon} />}
                        </Button> */}
                    </div>
                    {file.name && enableUpload && false ? <Typography className={` ${classes.centerVeritically}`}>
                        <span className={classes.fileText}>File Name: </span>
                        <span>{file.name || ''}</span>
                    </Typography> : null}
                </div>
        );
    }
}

const styles = theme => ({
    centerVeritically: {
        display: 'flex',
        alignItems: 'center',
    },
    leftMargin: {
        marginLeft: '2%',
    },
    fileText: {
        fontSize: '1em',

    },
    sizeFix: {
        minWidth: '128px',
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    badge: {
        color: 'white',
        backgroundColor: 'green',
        zIndex: 'auto'
    },
});


function mapStateToProps(state) {
    return {
        user_id: state.user && state.user.user_id,
    }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(S3UploadButton));