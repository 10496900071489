export const BACKEND_ERROR = "BACKEND_ERROR";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const FETCH_TABLE_DATA = "FETCH_TABLE_DATA";
export const HIDE_FULL_LOADER = "HIDE_FULL_LOADER";
export const HIDE_MODAL = "HIDE_MODAL";
export const LOGOUT_USER = "LOGOUT_USER";
export const PASSWORD_ERROR = "PASSWORD_ERROR";
export const RESET_ERRORS = "RESET_ERRORS";
export const SET_IS_MOBILE = "SET_IS_MOBILE";
export const SHOW_FULL_LOADER = "SHOW_FULL_LOADER";
export const SHOW_MODAL = "SHOW_MODAL";
export const TOP_LOADER_DISABLE = "TOP_LOADER_DISABLE";
export const TOP_LOADER_ENABLE = "TOP_LOADER_ENABLE";
export const USER_LOGGEDIN = "USER_LOGGEDIN";
export const USER_REGISTERED = "USER_REGISTERED";
export const SET_CLASS_LIST = "SET_CLASS_LIST";
export const SET_PACKAGE_LIST = "SET_PACKAGE_LIST";
export const USER_PROFILE_FETCHED = 'USER_PROFILE_FETCHED'


// CITY LIST ACTIONS
