import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateRangePickerModal from '../../components/DateRangePickerModal';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  CircularProgress,
  Button,
  Icon,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import SessionSelector from '../../components/SessionSelector';
import SaveChangesModal from '../../components/SaveChangesModal';
import axios from 'axios';
import './style.scss';

class TeacherTrainerStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teacherTrainerStats: null,
      startDate: null,
      endDate: null,
      classId: null,
      packageId: null,
      isLoading: false,
      individualSessionData: null,
      activeSort: { id: '', asc: true },
    };
    this.subHeaderArray = ['free_trial', 'conversion', 'paid', 'homework'];
    this.sortHeaders = ['cw', 'no_of_reschedules'];
  }

  setSessionData = (packageId, classId) => {
    this.setState({ packageId: packageId, classId: classId }, () =>
      this.fetchTeacherTrainerStats(this.state.startDate, this.state.endDate)
    );
  };

  fetchSessionDetails = (session_activity_id) => {
    axios
      .get(
        `/v1/admin/${this.props.adminId}/activity/${session_activity_id}/performance/`
      )
      .then((response) => {
        if (response?.data?.data) {
          const data = response.data.data;
          this.setState({
            sessionData: data,
          });
        }
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error?.response?.data?.data?.error, {
          variant: 'error',
        });
      });
  };

  fetchTeacherTrainerStats = (
    startDate = this.state.startDate,
    endDate = this.state.endDate
  ) => {
    this.setState({
      teacherTrainerStats: null,
      startDate: startDate,
      endDate: endDate,
    });
    if (this.state.classId && this.state.packageId) {
      this.setState({ isLoading: true });
      axios
        .get(
          `v1/admin/${this.props.adminId}/teacher/performance/stats/?from_date=${startDate}&to_date=${endDate}&class_ids=${this.state.classId}&package_ids=${this.state.packageId}`
        )
        .then((response) => {
          if (response?.data?.data) {
            const data = response.data.data;
            data.map((teacherTrainerData) => {
              Object.assign(teacherTrainerData, { homework: {} });
              Object.keys(teacherTrainerData).map((keyName) => {
                if (keyName.includes('hw')) {
                  Object.assign(teacherTrainerData.homework, {
                    [keyName]: teacherTrainerData[keyName],
                  });
                  delete teacherTrainerData[keyName];
                }
              });
            });
            this.setState({
              teacherTrainerStats: data,
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.props.enqueueSnackbar(error?.response?.data?.data?.error, {
            variant: 'error',
          });
        });
    }
  };

  fetchIndividualTeacherData = (teacherId) => {
    const { startDate, endDate, packageId, classId } = this.state;
    axios
      .get(
        `v1/admin/${this.props.adminId}/teacher/performance/stats/?from_date=${startDate}&to_date=${endDate}&class_ids=${classId}&package_ids=${packageId}&teacher_id=${teacherId}`
      )
      .then((response) => {
        if (response?.data?.data) {
          this.setState({ individualSessionData: response.data.data });
        }
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error?.response?.data?.data?.error, {
          variant: 'error',
        });
      });
  };

  sortIcon = (header, subHeader) => {
    const { teacherTrainerStats, activeSort, individualSessionData } =
      this.state;
    const sortingData = individualSessionData
      ? individualSessionData
      : teacherTrainerStats;
    return (
      <Icon
        className='sortIcon'
        onClick={() =>
          this.setState({
            activeSort: {
              id: subHeader ? `${header}.${subHeader}` : header,
              asc:
                activeSort.id == header ||
                activeSort.id == `${header}.${subHeader}`
                  ? !activeSort.asc
                  : true,
            },
            [individualSessionData
              ? 'individualSessionData'
              : 'teacherTrainerStats']: sortingData.sort((a, b) =>
              subHeader
                ? activeSort.id == `${header}.${subHeader}`
                  ? activeSort.asc
                    ? b[header][subHeader] - a[header][subHeader]
                    : a[header][subHeader] - b[header][subHeader]
                  : a[header][subHeader] - b[header][subHeader]
                : typeof a[header] === 'string'
                ? this.state.activeSort.id == header
                  ? this.state.activeSort.asc
                    ? b[header] > a[header]
                      ? 1
                      : -1
                    : a[header] > b[header]
                    ? 1
                    : -1
                  : a[header] > b[header]
                  ? 1
                  : -1
                : activeSort.id == header
                ? activeSort.asc
                  ? b[header] - a[header]
                  : a[header] - b[header]
                : a[header] - b[header]
            ),
          })
        }
      >
        {activeSort.id == `${header}.${subHeader}` || activeSort.id == header
          ? activeSort.asc
            ? 'arrow_downward_icon'
            : 'arrow_upward_icon'
          : 'sort_icon'}
      </Icon>
    );
  };

  toTitleCase = (text) => {
    return text.charAt(0).toUpperCase() + text.replaceAll('_', ' ').slice(1);
  };

  render() {
    const { teacherTrainerStats, isLoading, individualSessionData } =
      this.state;
    const headerData = {
      classwork: {
        columns: ['qns', 'time', 'acc'],
      },
      homework: {
        columns: ['qns', 'att', 'acc'],
      },
    };

    return (
      <div className='TeacherTrainerContainer'>
        <SaveChangesModal
          openModal={individualSessionData}
          closeModal={() => this.setState({ individualSessionData: null })}
          style={{ overflow: 'auto', width: '90vw', maxHeight: '90vh' }}
          text={'Teacher Session Details'}
          displayData={
            <Table>
              <TableHead>
                <TableRow>
                  {individualSessionData &&
                    Object.keys(individualSessionData?.[0])?.map((header) =>
                      header == 'accuracy' ? (
                        <TableCell
                          colSpan={
                            Object.keys(individualSessionData[0][header]).length
                          }
                          rowSpan={2}
                        >
                          {this.toTitleCase(header)}
                        </TableCell>
                      ) : header == 'homework' || header == 'classwork' ? (
                        <TableCell
                          colSpan={
                            individualSessionData?.[0]?.[header]?.length *
                            headerData[header]?.columns.length
                          }
                        >
                          {this.toTitleCase(header)}
                        </TableCell>
                      ) : (
                        <TableCell rowSpan={3}>
                          <div className='headers'>
                            {this.sortIcon(header)}
                            {this.toTitleCase(header)}
                          </div>
                        </TableCell>
                      )
                    )}
                </TableRow>
                <TableRow>
                  {individualSessionData &&
                    Object.keys(individualSessionData[0]).map((header) =>
                      header == 'homework' || header == 'classwork'
                        ? [
                            ...Array(
                              individualSessionData?.[0]?.[header]?.length
                            ),
                          ].map((_, i) => (
                            <TableCell
                              colSpan={headerData[header]?.columns.length}
                            >
                              {this.toTitleCase(header + ' ' + (i + 1))}
                            </TableCell>
                          ))
                        : null
                    )}
                </TableRow>
                <TableRow>
                  {individualSessionData &&
                    Object.keys(individualSessionData[0]).map((header) =>
                      header == 'accuracy'
                        ? Object.keys(individualSessionData[0][header])?.map(
                            (subHeader) => (
                              <TableCell>
                                <div className='headers'>
                                  {this.sortIcon(header, subHeader)}
                                  {this.toTitleCase(subHeader)}
                                </div>
                              </TableCell>
                            )
                          )
                        : header == 'homework' || header == 'classwork'
                        ? [
                            ...Array(
                              individualSessionData?.[0]?.[header]?.length
                            ),
                          ].map((_, i) =>
                            headerData[header]?.columns?.map((subHeader) => (
                              <TableCell>
                                <div className='headers'>
                                  {this.sortIcon(header, subHeader)}
                                  {this.toTitleCase(subHeader)}
                                </div>
                              </TableCell>
                            ))
                          )
                        : null
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {individualSessionData?.map((individualSessionDetails) => (
                  <TableRow>
                    {Object.keys(individualSessionData[0]).map(
                      (header, index) =>
                        header == 'homework' || header == 'classwork' ? (
                          [
                            ...Array(
                              individualSessionData?.[0]?.[header]?.length
                            ),
                          ].map((_, i) =>
                            headerData[header]?.columns?.map((subHeader) => (
                              <TableCell>
                                {individualSessionDetails?.[header]?.[i]?.[
                                  subHeader
                                ] || '-'}
                              </TableCell>
                            ))
                          )
                        ) : header == 'accuracy' ? (
                          <>
                            {Object.keys(individualSessionData[0][header]).map(
                              (subHeader) => (
                                <TableCell>
                                  {individualSessionDetails[header][subHeader]}
                                </TableCell>
                              )
                            )}
                          </>
                        ) : (
                          <TableCell>
                            {header == 'below_par_students'
                              ? `${individualSessionDetails[header].percent}%(${individualSessionDetails[header].count})`
                              : individualSessionDetails[header]}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        />
        <div className='TeacherTrainerHeaderContainer'>
          <div className='TeacherTrainerHeader'>Teacher Performance</div>
          <DateRangePickerModal fetchData={this.fetchTeacherTrainerStats} />
        </div>
        <SessionSelector
          setFilterData={this.setSessionData}
          filterList={['grade', 'package']}
          onFilterChange={() => this.setState({ teacherTrainerStats: null })}
          multiSelect={['grade', 'package']}
        />
        {teacherTrainerStats?.length ? (
          <div className='tableContainer'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Object.keys(teacherTrainerStats[0]).map((header, index) =>
                    this.subHeaderArray.includes(header) ? (
                      <TableCell
                        colSpan={
                          Object.keys(teacherTrainerStats[0][header]).length
                        }
                      >
                        {this.toTitleCase(header)}
                      </TableCell>
                    ) : (
                      <TableCell
                        rowSpan={2}
                        className={
                          index < 2 ? 'tableCellFixed tableHeaderFixed' : ''
                        }
                        style={{ left: index < 2 ? `${index * 6}vw` : 'none' }}
                      >
                        <div className='headers'>
                          {this.sortHeaders.includes(header) &&
                            this.sortIcon(header)}
                          {this.toTitleCase(header)}
                        </div>
                      </TableCell>
                    )
                  )}
                </TableRow>
                <TableRow>
                  {Object.keys(teacherTrainerStats[0]).map((header) =>
                    this.subHeaderArray.includes(header)
                      ? Object.keys(teacherTrainerStats[0]) &&
                        Object.keys(teacherTrainerStats[0][header]).map(
                          (subHeader) => (
                            <TableCell>
                              <div className='headers'>
                                {this.sortIcon(header, subHeader)}
                                {this.toTitleCase(subHeader)}
                              </div>
                            </TableCell>
                          )
                        )
                      : null
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {teacherTrainerStats.map((TeacherTrainerData) => (
                  <TableRow>
                    {Object.keys(teacherTrainerStats[0]).map((header, index) =>
                      this.subHeaderArray.includes(header) ? (
                        <>
                          {Object.keys(teacherTrainerStats[0][header]).map(
                            (subHeader) => (
                              <TableCell>
                                {TeacherTrainerData[header][subHeader] != null
                                  ? TeacherTrainerData[header][subHeader]
                                  : '-'}
                              </TableCell>
                            )
                          )}
                        </>
                      ) : (
                        <TableCell
                          className={`${index < 2 ? 'tableCellFixed ' : ''}
                            ${header == 'id' ? 'clickable' : ''}`}
                          style={{
                            left: index < 2 ? `${index * 6}vw` : 'none',
                          }}
                          onClick={() => {
                            if (header == 'id')
                              this.fetchIndividualTeacherData(
                                TeacherTrainerData.id
                              );
                          }}
                        >
                          {header.includes('date') ? (
                            new Date(
                              TeacherTrainerData[header]
                            ).toLocaleString()
                          ) : typeof TeacherTrainerData[header] == 'boolean' ? (
                            TeacherTrainerData[header] ? (
                              'Yes'
                            ) : (
                              'No'
                            )
                          ) : header.includes('link') ? (
                            <Button
                              color='primary'
                              variant='contained'
                              size='small'
                              style={{ fontSize: 12 }}
                              onClick={() =>
                                window.open(
                                  TeacherTrainerData[header],
                                  '_blank'
                                )
                              }
                            >
                              View
                            </Button>
                          ) : TeacherTrainerData[header] != null ? (
                            TeacherTrainerData[header]
                          ) : (
                            '-'
                          )}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : teacherTrainerStats?.length == 0 ? (
          <div className='centerAligned'>No data</div>
        ) : isLoading ? (
          <CircularProgress className='centerAligned' />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  return {
    adminId: states?.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(TeacherTrainerStats));
