import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Snackbar, IconButton, CircularProgress } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import './style.scss';
import '../../Utils/util.scss';
import copyIcon from '../../assets/copy-icon.png';
import h1Logo from '../../assets/heading-1-logo.png';
import h2Logo from '../../assets/heading-2-logo.png';
import imageLogo from '../../assets/image-logo.png';
import videoLogo from '../../assets/video-logo.png';
import gifLogo from '../../assets/gif-logo.png';
import textLogo from '../../assets/normal-text-logo.png';
import TrashIcon from '../../assets/trash_full.png';
import Col from '../../components/Elements/Flex/col';
import Row from '../../components/Elements/Flex/row';
import BackgroundComponent from './DragElements/BackgroundComponent/BackgroundComponent';
import ButtonComponent from './DragElements/ButtonComponent/ButtonComponent';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import DropZone from './Dropzone/Dropzone';
import MediaBox from './DragElements/MediaBox/MediaBox';
import SingleSelectComponent from './DragElements/SingleSelectComponent/SingleSelectComponent';
import TextComponent from './DragElements/TextComponent/TextComponent';
import LoaderErrorBox from '../../components/LoaderErrorBox/LoaderErrroBox';

const mediaTypes = [
  {
    icon: imageLogo,
    type: 'image',
    title: 'IMAGE',
    subtitle: 'Recommended size- 3:2',
  },
  {
    icon: videoLogo,
    type: 'video',
    title: 'VIDEO',
    subtitle: null,
  },
  {
    icon: gifLogo,
    type: 'gif',
    title: "GIF'S",
    subtitle: null,
  },
];

const textTypes = [
  {
    icon: h1Logo,
    type: 'heading-1',
    title: 'Heading 1',
    subtitle: 'Big section heading',
    style: { color: '#0D0D0D', fontSize: 18, textAlign: 'left' },
  },
  {
    icon: h2Logo,
    type: 'heading-2',
    title: 'Heading 2',
    subtitle: 'Medium section heading',
    style: { color: '#0D0D0D', fontSize: 16, textAlign: 'left' },
  },
  {
    icon: textLogo,
    type: 'text',
    title: 'Text',
    subtitle: 'Just start writing with plain text',
    style: { color: '#0D0D0D', fontSize: 14, textAlign: 'left' },
  },
];

const initialSlideContent = {
  backgroundImgUrl: '',
  backgroundColor: '',
  slideData: [],
};

class FlashCards extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      apiError: '',
      saveBtnLoader: false,
      publishBtnLoader: false,
      snackbarData: { message: '', type: '' },
      elementsAdded: [null],
      slidesValidation: [],
      flashCards: [
        { backgroundImgUrl: '', backgroundColor: '', slideData: [] },
      ],
      currentSlideIndex: 0,
      selectedElementIndex: undefined,
      showDeleteModal: false,
      moduleStatus: null,
    };

    this.moduleId = window.location.pathname.split('/')[2];
  }

  addComponent = (element) => {
    const { flashCards, currentSlideIndex, elementsAdded } = this.state;

    const updatedFlashCards = JSON.parse(JSON.stringify(flashCards));
    updatedFlashCards[currentSlideIndex].slideData.push(element);

    const updatedElementsAdded = JSON.parse(JSON.stringify(elementsAdded));

    if (element.type === 'single-select' || element.type === 'button') {
      if (!!updatedElementsAdded[currentSlideIndex] === false)
        updatedElementsAdded[currentSlideIndex] = {};
      updatedElementsAdded[currentSlideIndex][element.type] = true;
    }

    this.setState({
      flashCards: updatedFlashCards,
      selectedElementIndex:
        updatedFlashCards[currentSlideIndex].slideData.length - 1,
      elementsAdded: updatedElementsAdded,
    });
  };

  deleteComponent = (elementIndex, elementType) => {
    const { flashCards, currentSlideIndex, elementsAdded, slidesValidation } =
      this.state;

    const updatedFlashCards = JSON.parse(JSON.stringify(flashCards));
    updatedFlashCards[currentSlideIndex].slideData.splice(elementIndex, 1);

    const updatedElementsAdded = JSON.parse(JSON.stringify(elementsAdded));
    if (elementType === 'single-select' || elementType === 'button') {
      updatedElementsAdded[currentSlideIndex][elementType] = false;
    }

    const updatedSlidesValidation = JSON.parse(
      JSON.stringify(slidesValidation)
    );
    if (updatedSlidesValidation[currentSlideIndex]) {
      updatedSlidesValidation[currentSlideIndex].splice(elementIndex, 1);
    }

    this.setState({
      flashCards: updatedFlashCards,
      selectedElementIndex: undefined,
      elementsAdded: updatedElementsAdded,
      slidesValidation: updatedSlidesValidation,
    });
  };

  rearrangeElements = (dragIndex, hoverIndex) => {
    const { flashCards, currentSlideIndex, slidesValidation } = this.state;

    const updatedFlashCards = JSON.parse(JSON.stringify(flashCards));
    const updatedSlidesValidation = JSON.parse(
      JSON.stringify(slidesValidation)
    );

    const dragItem = updatedFlashCards[currentSlideIndex].slideData[dragIndex];

    if (dragItem) {
      //removing item and putting drag item
      const prevItem = updatedFlashCards[currentSlideIndex].slideData.splice(
        hoverIndex,
        1,
        dragItem
      );
      //remove by drageindex and put prev item instead
      updatedFlashCards[currentSlideIndex].slideData.splice(
        dragIndex,
        1,
        prevItem[0]
      );

      if (updatedSlidesValidation[currentSlideIndex]) {
        const prevElementItem = updatedSlidesValidation[
          currentSlideIndex
        ].splice(
          hoverIndex,
          1,
          updatedSlidesValidation[currentSlideIndex][dragIndex]
        );
        updatedSlidesValidation[currentSlideIndex].splice(
          dragIndex,
          1,
          prevElementItem[0]
        );
      }

      this.setState({
        flashCards: updatedFlashCards,
        selectedElementIndex: undefined,
        slidesValidation: updatedSlidesValidation,
      });
    }
  };

  validatePreviousSlide = () => {
    const { currentSlideIndex, flashCards, slidesValidation } = this.state;

    const elementsValidated = flashCards[currentSlideIndex].slideData.map(
      (elementData) => {
        const {
          type,
          text,
          imageUrl,
          options,
          solution,
          answer,
          optionType,
          videoUrl,
          gifUrl,
        } = elementData;

        if (type === 'text' || type === 'heading-1' || type === 'heading-2') {
          if (text === '') {
            return false;
          }
        } else if (type === 'image') {
          if (imageUrl === '') {
            return false;
          }
        } else if (type === 'video') {
          if (videoUrl === '') {
            return false;
          }
        } else if (type === 'gif') {
          if (gifUrl === '') {
            return false;
          }
        } else if (type === 'button') {
          if (text === '') {
            return false;
          }
        } else if (type === 'single-select') {
          const optionsValidated = options.every((option) => {
            if (optionType === 'text' && option.text === '') {
              return false;
            } else if (optionType === 'image' && option.imageUrl === null) {
              return false;
            } else {
              return true;
            }
          });
          const solutionsValidated = Object.keys(solution).every(
            (solutionKey) => {
              if (
                solutionKey === 'solutionText' &&
                solution[solutionKey]?.text === ''
              ) {
                return false;
              } else if (
                solutionKey === 'button' &&
                solution[solutionKey]?.text === ''
              ) {
                return false;
              } else if (
                solutionKey === 'image' &&
                solution[solutionKey]?.imageUrl === ''
              ) {
                return false;
              }
              return true;
            }
          );
          return optionsValidated && answer !== null && solutionsValidated;
        }
        return true;
      }
    );

    const updatedSlidesValidation = [...slidesValidation];
    updatedSlidesValidation[currentSlideIndex] = elementsValidated;

    const isValidated =
      elementsValidated.length === 0
        ? false
        : !elementsValidated.some((slideElement) => slideElement === false);

    const updatedState = {
      ...this.state,
      slidesValidation: updatedSlidesValidation,
    };

    if (!isValidated) {
      updatedState.snackbarData = {
        message:
          elementsValidated.length === 0
            ? 'Please add some elements in current slide before moving to any other slide'
            : 'Please fill the data inside the elements shown in red',
        type: 'error',
      };
    }

    this.setState(updatedState);

    return isValidated;
  };

  addSlide = () => {
    if (this.validatePreviousSlide()) {
      this.setState((prevState) => ({
        flashCards: [...prevState.flashCards, { ...initialSlideContent }],
        currentSlideIndex: prevState.flashCards.length,
        selectedElementIndex: undefined,
        elementsAdded: [...prevState.elementsAdded, null],
      }));
    }
  };

  deleteSlide = (flag) => {
    if (flag) {
      const { flashCards, currentSlideIndex, slidesValidation, elementsAdded } =
        this.state;

      if (flashCards.length > 1) {
        const updatedFlashCards = [...flashCards];
        updatedFlashCards.splice(currentSlideIndex, 1);

        const updatedSlidesValidation = [...slidesValidation];
        updatedSlidesValidation.splice(currentSlideIndex, 1);

        const updatedElementsAdded = [...elementsAdded];
        updatedElementsAdded.splice(currentSlideIndex, 1);

        this.setState({
          flashCards: updatedFlashCards,
          currentSlideIndex:
            currentSlideIndex === flashCards.length - 1
              ? currentSlideIndex - 1
              : currentSlideIndex,
          selectedElementIndex: undefined,
          slidesValidation: updatedSlidesValidation,
          elementsAdded: updatedElementsAdded,
        });
      }
    }
    this.setState({ showDeleteModal: false });
  };

  duplicateSlide = () => {
    if (this.validatePreviousSlide()) {
      const { flashCards, currentSlideIndex } = this.state;

      const newSlide = JSON.parse(
        JSON.stringify(flashCards[currentSlideIndex])
      );
      delete newSlide.id;

      this.setState((prevState) => ({
        flashCards: [...prevState.flashCards, newSlide],
        currentSlideIndex: prevState.flashCards.length,
        selectedElementIndex: undefined,
        elementsAdded: [
          ...prevState.elementsAdded,
          JSON.parse(
            JSON.stringify(prevState.elementsAdded[currentSlideIndex])
          ),
        ],
      }));
    }
  };

  changeSlide = (slideIndex) => {
    if (this.validatePreviousSlide()) {
      this.setState({
        currentSlideIndex: slideIndex,
        selectedElementIndex: undefined,
      });
    }
  };

  updateSlideData = (data) => {
    const { flashCards, currentSlideIndex, selectedElementIndex } = this.state;

    const updatedData = JSON.parse(JSON.stringify(flashCards));
    updatedData[currentSlideIndex].slideData[selectedElementIndex][data.key] =
      data.value;
    this.setState({ flashCards: updatedData });
  };

  updateElementData = (index, data) => {
    const { selectedElementIndex } = this.state;

    if (selectedElementIndex === index) {
      this.updateSlideData(data);
    } else {
      this.setState({ selectedElementIndex: index }, () => {
        this.updateSlideData(data);
      });
    }
  };

  setBackgroundColor = (color) => {
    const { flashCards, currentSlideIndex } = this.state;

    const updatedData = JSON.parse(JSON.stringify(flashCards));
    updatedData[currentSlideIndex].backgroundColor = color;

    this.setState({ flashCards: updatedData });
  };

  hideSnackbar = () => {
    this.setState({ snackbarData: { message: '', type: '' } });
  };

  saveModule = () => {
    if (this.validatePreviousSlide()) {
      const { flashCards, elementsAdded } = this.state;
      const { adminId } = this.props;

      this.setState({ saveBtnLoader: true });

      axios({
        method: 'POST',
        url: `v1/admin/${adminId}/flashcard/update/${this.moduleId}/`,
        data: { module: flashCards, one_time_allowed_elements: elementsAdded },
      })
        .then((res) => {
          const { data } = res.data;

          if (data.error) {
            this.setState({
              snackbarData: { type: 'error', message: data.error[0] },
              saveBtnLoader: false,
            });
          } else {
            this.setState({
              snackbarData: {
                type: 'success',
                message: 'Module Saved Successfully!',
              },
              saveBtnLoader: false,
            });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.data?.error) {
            this.setState({
              snackbarData: {
                type: 'error',
                message: err.response.data.data.error[0],
              },
              saveBtnLoader: false,
            });
          } else {
            this.setState({
              snackbarData: {
                type: 'error',
                message: 'Something went wrong!',
              },
              saveBtnLoader: false,
            });
          }
        });
    }
  };

  publishModule = () => {
    if (this.validatePreviousSlide()) {
      const { adminId } = this.props;
      const { flashCards, elementsAdded } = this.state;
      this.setState({ publishBtnLoader: true });

      axios({
        method: 'POST',
        url: `v1/admin/${adminId}/flashcard/update/${this.moduleId}/`,
        data: {
          module: flashCards,
          one_time_allowed_elements: elementsAdded,
          is_published: 1,
        },
      })
        .then((res) => {
          const { data } = res.data;

          if (data.error) {
            this.setState({
              snackbarData: { type: 'error', message: data.error[0] },
              publishBtnLoader: false,
            });
          } else {
            this.setState({
              snackbarData: {
                type: 'success',
                message: 'Module Published Successfully!',
              },
              publishBtnLoader: false,
            });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.data?.error) {
            this.setState({
              snackbarData: {
                type: 'error',
                message: err.response.data.data.error[0],
              },
              publishBtnLoader: false,
            });
          } else {
            this.setState({
              snackbarData: {
                type: 'error',
                message: 'Something went wrong!',
              },
              publishBtnLoader: false,
            });
          }
        });
    }
  };

  alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  componentDidMount() {
    const { adminId, router, route } = this.props;

    window.addEventListener('beforeunload', this.alertUser);
    router.setRouteLeaveHook(route, () => {
      if (window.confirm('Are you sure you want to leave the page'))
        return true;
      else {
        window.history.pushState(null, null, router.location.pathname);
        return false;
      }
    });

    axios({
      method: 'get',
      url: `v1/admin/${adminId}/flashcard/particularcard/${this.moduleId}/`,
    })
      .then((res) => {
        const { data } = res.data;

        if (data.error) {
          this.setState({ apiError: data.error[0], loader: false });
        } else {
          this.setState({
            flashCards:
              data.module.length !== 0
                ? data.module
                : [{ ...initialSlideContent }],
            elementsAdded: data.one_time_allowed_elements || [],
            moduleStatus: data.status,
            loader: false,
          });
        }
      })
      .catch((err) => {
        if (err?.response?.data?.data?.error) {
          this.setState({
            apiError: err.response.data.data.error[0],
            loader: false,
          });
        } else {
          this.setState({ apiError: 'Something went wrong!', loader: false });
        }
      });
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.alertUser);
  }

  render() {
    const {
      flashCards,
      currentSlideIndex,
      selectedElementIndex,
      showDeleteModal,
      elementsAdded,
      slidesValidation,
      loader,
      apiError,
      saveBtnLoader,
      publishBtnLoader,
      snackbarData,
      moduleStatus,
    } = this.state;
    const { router } = this.props;

    return (
      <Row className='flash-cards'>
        {loader ? (
          <LoaderErrorBox {...{ loader: true, loaderSize: '2vw' }} />
        ) : apiError ? (
          <LoaderErrorBox {...{ loader: false, errorMsg: apiError }} />
        ) : (
          <>
            <DndProvider backend={HTML5Backend}>
              <Col className='flash-cards-left'>
                <Row
                  className='back-navigation align-center'
                  onClick={() => router.goBack()}
                >
                  <svg
                    width='16'
                    height='12'
                    viewBox='0 0 16 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3.83 5L7.41 1.41L6 0L0 6L6 12L7.41 10.59L3.83 7H16V5H3.83Z'
                      fill='#333333'
                    />
                  </svg>
                  Back
                </Row>
                <Col className='draggable-elements'>
                  <p className='elements-title'>Elements</p>
                  <Row className='media-section'>
                    {mediaTypes.map((mediaType, index) => (
                      <MediaBox
                        key={mediaType.type + index}
                        {...{ ...mediaType, addComponent: this.addComponent }}
                      />
                    ))}
                  </Row>
                  <Row className='text-section'>
                    {textTypes.map((textType, index) => (
                      <TextComponent
                        key={textType.type + index}
                        {...{ ...textType, addComponent: this.addComponent }}
                      />
                    ))}
                  </Row>
                  <p className='elements-title'>Background</p>
                  <BackgroundComponent
                    setBackgroundColor={this.setBackgroundColor}
                    selectedColor={
                      flashCards[currentSlideIndex].backgroundColor
                    }
                  />
                  <p className='elements-title'>Controls</p>
                  {!elementsAdded[currentSlideIndex]?.['single-select'] && (
                    <SingleSelectComponent addComponent={this.addComponent} />
                  )}
                  {!elementsAdded[currentSlideIndex]?.button &&
                    !elementsAdded[currentSlideIndex]?.['single-select'] && (
                      <ButtonComponent addComponent={this.addComponent} />
                    )}
                </Col>
              </Col>
              <Row className='flash-cards-right'>
                <Col className='flash-cards-ui'>
                  <Row className='slides-stepper'>
                    {flashCards.map((card, index) => (
                      <Row
                        key={index}
                        className={`stepper ${
                          currentSlideIndex === index
                            ? 'current'
                            : 'non-current'
                        }`}
                        onClick={() => this.changeSlide(index)}
                      >
                        {index + 1}
                      </Row>
                    ))}
                    {flashCards.length < 15 && (
                      <Row
                        className={`stepper add-slide-btn`}
                        onClick={this.addSlide}
                      >
                        <Add />
                      </Row>
                    )}
                  </Row>
                  <DropZone
                    currentSlideData={flashCards[currentSlideIndex]}
                    updateState={(data, afterUpdateFn = () => {}) => {
                      this.setState({ ...data }, afterUpdateFn);
                    }}
                    updateElementData={this.updateElementData}
                    rearrangeElements={this.rearrangeElements}
                    deleteComponent={this.deleteComponent}
                    selectedElementIndex={selectedElementIndex}
                    currentSlideValidation={
                      slidesValidation[currentSlideIndex] || {}
                    }
                    elementsAdded={elementsAdded[currentSlideIndex]}
                    currentSlideIndex={currentSlideIndex}
                  />
                  <Row className='slides-actions-box'>
                    <button
                      className='delete-btn btn'
                      onClick={() => this.setState({ showDeleteModal: true })}
                      disabled={flashCards.length === 1}
                    >
                      <img className='icon' src={TrashIcon} />
                      Delete
                    </button>
                    <button
                      className='dublicate-btn btn'
                      onClick={this.duplicateSlide}
                      disabled={flashCards.length === 15}
                    >
                      <img className='icon' src={copyIcon} />
                      Duplicate
                    </button>
                  </Row>
                </Col>
                <Col className='flash-cards-action'>
                  <Row
                    className={`status ${moduleStatus ? 'live' : 'not-live'}`}
                  >
                    {moduleStatus === 1 && (
                      <svg
                        width='6'
                        height='6'
                        viewBox='0 0 6 6'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3C6 3.79565 5.68393 4.55871 5.12132 5.12132C4.55871 5.68393 3.79565 6 3 6Z'
                          fill='#DD234C'
                        />
                      </svg>
                    )}
                    {moduleStatus ? 'Live' : 'Not Live'}
                  </Row>
                  <Col className='preview-box'>
                    <button
                      className='preview-btn btn'
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_FLASH_CARDS_URL}/${this.moduleId}?role=admin`
                        )
                      }
                    >
                      <svg
                        width='14'
                        height='10'
                        viewBox='0 0 14 10'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6.99967 9.66592C5.90626 9.67954 4.82414 9.44355 3.83567 8.97592C3.06947 8.60207 2.38144 8.08577 1.80834 7.45459C1.2013 6.802 0.723319 6.04036 0.399674 5.20992L0.333008 4.99925L0.403008 4.78859C0.726885 3.95887 1.20383 3.19743 1.80901 2.54392C2.3819 1.9128 3.0697 1.3965 3.83567 1.02259C4.82415 0.554979 5.90626 0.318994 6.99967 0.332587C8.09309 0.319018 9.17519 0.555001 10.1637 1.02259C10.9299 1.39641 11.6179 1.91272 12.191 2.54392C12.7992 3.19562 13.2773 3.9575 13.5997 4.78859L13.6663 4.99925L13.5963 5.20992C12.5505 7.93245 9.91581 9.71215 6.99967 9.66592ZM6.99967 1.66592C4.73025 1.59481 2.64729 2.91598 1.74434 4.99925C2.64714 7.08265 4.7302 8.40389 6.99967 8.33259C9.26904 8.40351 11.3519 7.0824 12.255 4.99925C11.3532 2.91497 9.2695 1.59331 6.99967 1.66592ZM6.99967 6.99925C6.03789 7.00562 5.20592 6.33083 5.01365 5.38844C4.82138 4.44604 5.32252 3.49927 6.20993 3.12836C7.09734 2.75745 8.12316 3.06601 8.6587 3.86492C9.19425 4.66384 9.08992 5.72997 8.40967 6.40992C8.03722 6.78676 7.52951 6.99896 6.99967 6.99925Z'
                          fill='white'
                        />
                      </svg>
                      Preview
                    </button>
                    <p>Only the saved changes can be seen in preview!</p>
                  </Col>
                  <button
                    className='save-btn btn'
                    onClick={this.saveModule}
                    disabled={saveBtnLoader}
                  >
                    {saveBtnLoader ? (
                      <CircularProgress size='1.25vw' />
                    ) : moduleStatus ? (
                      'Save'
                    ) : (
                      'Save Draft'
                    )}
                  </button>
                  {moduleStatus === 0 && (
                    <button
                      className='publish-btn btn'
                      onClick={this.publishModule}
                      disabled={publishBtnLoader}
                    >
                      {publishBtnLoader ? (
                        <CircularProgress size='1.25vw' />
                      ) : (
                        'Go Live'
                      )}
                    </button>
                  )}
                </Col>
              </Row>
            </DndProvider>
            {showDeleteModal && (
              <ConfirmationModal
                onClickFunction={this.deleteSlide}
                text='Are you sure you want to delete the slide ?'
              />
            )}
            {snackbarData.message && (
              <Snackbar
                open={true}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000}
                action={
                  <IconButton onClick={this.hideSnackbar}>
                    <Close />
                  </IconButton>
                }
                onClose={this.hideSnackbar}
              >
                <Alert severity={snackbarData.type} onClose={this.hideSnackbar}>
                  {snackbarData.message}
                </Alert>
              </Snackbar>
            )}
          </>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  adminId: state.user.user_id,
});

export default withRouter(connect(mapStateToProps, null)(FlashCards));
