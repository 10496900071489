import React from 'react';
import { useDrag } from 'react-dnd';

import './style.scss';
import { TEXT_TYPE } from '../../draggableItemTypes';
import Row from '../../../../components/Elements/Flex/row';
import Col from '../../../../components/Elements/Flex/col';

const TextComponent = (props) => {
  const { icon, type, title, subtitle, addComponent, style } = props;

  const [{}, drag] = useDrag(() => ({
    type: TEXT_TYPE,
    item: { type, text: '', style },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) addComponent(item);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <Row boxRef={drag} className='text-component row-flex'>
      <img src={icon} alt='' />
      <Col className='col-flex'>
        <p className='text-title'>{title}</p>
        <p className='text-subtitle'>{subtitle}</p>
      </Col>
    </Row>
  );
};

export default TextComponent;
