import React from 'react';
import { getIn } from 'formik';
import delve from 'dlv';
// import Select from 'react-select';
import SingleSelect from '../AutoSuggestSelectBox/SingleSelect';
import MultipleSelect from '../AutoSuggestSelectBox/MultipleSelect';

const fieldToSelectField = ({ field, form, variant, disabled = false, ...props }) => {
    const { name } = field;
    const { touched, errors, isSubmitting, values, setFieldTouched, setFieldValue } = form;

    const fieldError = getIn(errors, name);
    const showError = getIn(touched, name) && !!fieldError;

    const myOnChange = (data) => {
        setFieldValue(name, data);
    };

    const onBlur = () => {
        setFieldTouched(name, true);
    };

    // console.log('Date Values', delve(values, name, null));

    return {
        ...props,
        ...field,
        // Hack to work around type issue
        // See: https://github.com/Microsoft/TypeScript/issues/28791
        variant,
        error: showError,
        helperText: showError ? fieldError : props.helperText,
        disabled: isSubmitting || disabled,
        onChange: myOnChange,
        onBlur,
        value: delve(values, name, null),
    };
};

const Select = ({ children, ...props }) => (props.multiple ? (
    <MultipleSelect {...fieldToSelectField(props)} />
    ) : (
        <SingleSelect {...fieldToSelectField(props)} />
    ));

export default Select;
