import { withSnackbar } from 'notistack';
import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  List,
  Grid,
  ListItem,
  Box,
  Checkbox,
  Icon,
  Button,
  IconButton,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  TextField as MaterialUiTextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import Activity from '../../components/QuestionPreview/activityArena';
import ExpandedReview from '../../components/Expanded Review/expanded_review';
import moment from 'moment';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';
import S3UploadButton from '../../components/S3UploadButton';
import axios from 'axios';
import ToggleButton from '@material-ui/lab/ToggleButton';
import SingleSelect from '../../components/AutoSuggestSelectBox/SingleSelect';

function CreateQuestion(props) {
  const initialValue = {
    activity_type: '',
    answer: '',
    id: '',
    marks: 50,
    options: [
      {
        order: 1,
        text: '',
        image: '',
      },
      {
        order: 2,
        text: '',
        image: '',
      },
    ],
    question: { image: '', text: '', media_type: null, media: '' },
    time: props.time || '',
    sol_img: '',
    sol_text: '',
    difficulty: 0,
    objectives: null,
  };

  const mediaTypeList = [
    {
      value: 'image',
      label: 'Image',
    },
    {
      value: 'audio',
      label: 'Audio',
    },
    {
      value: 'video',
      label: 'Video',
    },
  ];
  const activityTypeList = [
    {
      value: 6,
      label: 'Single Select',
    },
    {
      value: 7,
      label: 'Multi Select',
      // },
      // {
      //     "value": 8,
      //     "label": "Tap and fill"
      // },
      // {
      //     "value": 9,
      //     "label": "Tap and type"
      // },
      // {
      //     "value": 3,
      //     "label": "Drag and drop rearrange"
    },
  ];
  const [answerCheck, handleAnswerCheck] = useState([1]);
  const [activityType, setActivityType] = useState(6);
  const [mediaType, setMediaType] = useState('image');
  const useStyles = makeStyles((theme) =>
    createStyles({
      appBar: {
        position: 'relative',
      },
      flex: {
        flex: 1,
      },
      rightIcon: {
        marginLeft: theme.spacing(1),
      },
      formControl: {
        margin: `${theme.spacing(2)}px 0px`,
        width: 450,
      },
      grid: {
        margin: '8px',
        padding: '0px 16px',
      },
      error: {
        color: 'red',
        fontSize: '16px',
        margin: `${theme.spacing(2)}px 8px`,
      },
    })
  );
  const [ref, setRef] = useState(React.createRef());
  const [optionType, setOptionType] = React.useState('Text');
  const [previewModalOpened, setPreviewModalOpened] = useState(false);
  const [summaryPreviewModalOpened, setSummaryPreviewModalOpened] =
    useState(false);
  useEffect(() => {
    setActivityType(6);
    if (ref && ref.current) {
      ref.current.setValues(initialValue);
    }
  }, []);

  const classes = styles();

  function uploadFile(fileData, dirName, fileName, optionName, formikActions) {
    const file = fileData;
    const key = dirName ? `${dirName}/${fileName}` : `${fileName}`;
    const user_id = props && props.user && props.user.user_id;
    var bodyFormData = new FormData();
    bodyFormData.append('file', file);
    bodyFormData.append('content_type', 'image');
    bodyFormData.append('bucket_name', 'lq-live');
    bodyFormData.append('key', key);
    axios({
      method: 'post',
      url: `v1/admin/${user_id}/s3/upload/`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        if (response.status === 200 && response.data) {
          response.data.data &&
            response.data.data &&
            formikActions.setFieldValue(optionName, response.data.data);
        }
      })
      .catch((error) => {
        console.log('S3 upload', error && error.response);
      });
  }
  function postQuestion(formikProps, formikActions) {
    {
      const user_id = props && props.user && props.user.user_id;
      let url = `/v1/admin/${user_id}/question/create/`;
      let api_data = {
        activity_type_id: activityType,
        question: {
          media_type: mediaType == 'image' ? null : mediaType,
          media: mediaType == 'image' ? null : formikProps.question.media,
          image: (mediaType == 'image' && formikProps.question.image) || null,
          text: formikProps.question.text,
        },
        options:
          formikProps.options &&
          formikProps.options.map(
            (opt, index) =>
              (formikProps.options[index] = {
                text: opt.image ? null : opt.text,
                image: opt.image ? opt.image : null,
                order: opt.order,
              })
          ),
        answer:
          activityType == 6 || activityType == 7
            ? ('[' + answerCheck + ']').toString()
            : formikProps.answer,
        time: eval(formikProps.time),
        marks: eval(formikProps.marks),
        sol_img: formikProps.sol_img || null,
        difficulty: formikProps.difficulty || null,
        objectives: formikProps.objectives || null,
        sol_text: formikProps.sol_text || null,
        session_activity_id: props.session_activity_id || null,
      };
      axios
        .post(url, api_data)
        .then((response) => {
          if (response.status === 200 && response.data) {
            props.enqueueSnackbar(
              (response.data &&
                response.data.data &&
                response.data.data.message) ||
                'Question updated successfully',
              { variant: 'success' }
            );
            formikActions.setSubmitting(false);
            props.onSuccess && props.onSuccess();
            return Promise.resolve();
          }
          return Promise.reject('Status code not correct');
        })
        .catch((error) => {
          console.log('error', error);
          formikActions.setSubmitting(false);
          if (
            error?.response?.data?.data?.error &&
            error.response.data.data.error[0]
          ) {
            const errors = error.response.data.data.error;
            errors.map((errorDisplay) =>
              props.enqueueSnackbar(errorDisplay || 'error', {
                variant: 'error',
              })
            );
          } else {
            props.enqueueSnackbar('Something went wrong', { variant: 'error' });
          }
        });
    }
  }
  async function submitForm(formikProps, formikActions) {
    // await formikProps.options && formikProps.options.map(async(opt, index) => {
    //   if(opt && opt.image && opt.image.startsWith("data")){
    //     const dirName="option_images"
    //     const fileName=moment().format('YYYYMMDDHHmmss') + '_O' + opt.order
    //     await uploadFile(opt.image, dirName, fileName, `options[${index}].image`, formikActions)

    //   }
    //   // if(index==formikProps.options.length-1)

    // })
    await postQuestion(formikProps, formikActions);
  }
  const schema = Yup.object().shape({
    marks: Yup.number('Please enter numeric value').required(
      'Please enter marks'
    ),
    time: Yup.number('Please enter numeric value').required(
      'Please enter time'
    ),
    question: Yup.object({
      text: Yup.string().required(`Please enter question text`),
    }),
    // options: Yup.array().of(Yup.object({
    //   text: optionType == 'Text' ? Yup.string().required("Please enter option text") : Yup.string().nullable(),
    //   image: optionType == 'Image' ? Yup.string().ensure().required("Please upload option image") : Yup.string().nullable()
    // }))
  });

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  return (
    <div style={{ minHeight: '70vh' }}>
      <Formik
        initialValues={initialValue}
        onSubmit={submitForm}
        validationSchema={schema}
        innerRef={ref}
        validateOnChange
        render={(formProps) => {
          const form_values = ref && ref.current && ref.current.values;
          const form_erros = ref && ref.current && ref.current.errors;
          const data = {
            questions: [
              {
                type: 'single_select',
                text:
                  form_values &&
                  form_values.question &&
                  form_values.question.text,
                image:
                  form_values &&
                  form_values.question &&
                  form_values.question.image,
                options: form_values && form_values.options,
                marks: form_values && form_values.marks,
                time: form_values && form_values.time,
                currentQuestion: props.questionListlength
                  ? props.questionListlength + 1
                  : 1,
                totalQuestions: props.questionListlength
                  ? props.questionListlength + 1
                  : 10,
                media:
                  form_values &&
                  form_values.question &&
                  form_values.question.media,
                media_type: mediaType,
              },
            ],
          };
          const activityTypeLabel = activityTypeList.find((acitivty) => {
            return acitivty.value === activityType;
          });
          var summaryOptions = form_values && form_values.options;
          // summaryOptions && summaryOptions.map((optionData) =>
          //   optionType == 'Image' ? optionData.text = '' : optionData.image = null
          // )
          const questionValue = {
            type: activityTypeLabel && activityTypeLabel.label,
            order: props.questionListlength ? props.questionListlength + 1 : 1,
            text:
              form_values && form_values.question && form_values.question.text,
            image:
              form_values && form_values.question && form_values.question.image,
            user_response: answerCheck,
            answer: answerCheck,
            options: summaryOptions,
            solution_image: form_values && form_values.sol_img,
            solution_text: form_values && form_values.sol_text,
            timed_out: false,
            is_correct: true,
            media:
              form_values && form_values.question && form_values.question.media,
            media_type: mediaType,
          };
          async function pasteFromClipboard(
            event,
            optionName,
            dirName,
            fileName
          ) {
            navigator.permissions
              .query({ name: 'clipboard-read' })
              .then(async (result) => {
                // If permission to read the clipboard is granted or if the user will
                // be prompted to allow it, we proceed.

                if (result.state == 'granted' || result.state == 'prompt') {
                  const clipboardData =
                    navigator &&
                    navigator.clipboard &&
                    (await navigator.clipboard.read());

                  let image_type;
                  const item =
                    clipboardData &&
                    clipboardData.find((item) =>
                      item.types.some((type) => {
                        if (type.startsWith('image/')) {
                          image_type = type;
                          return true;
                        }
                      })
                    );
                  var file = item && (await item.getType(image_type));
                  // const fileName=moment().format('YYYYMMDDHHmmss') + '_O' + order + (props.session_activity_id ? '_SAID_'+ props.session_activity_id : '') + '_AID_'+ props.adminId
                  if (optionName == 'question') {
                    setMediaType('image');
                    formProps.setFieldValue('question.media', null);
                  }
                  if (optionName == 'solution') {                    
                    formProps.setFieldValue('sol_img', null);
                  }
                  if (file) {
                    uploadFile(
                      file,
                      dirName,
                      fileName,
                      optionName == 'solution' ? 'sol_img' : optionName + '.image',
                      formProps
                    );
                    // file = new File([file], "name");
                    // blobToBase64(file).then((file)=>
                    // {formProps.setFieldValue(`${optionName}.image`, file)
                    // console.log("helo", formProps, file)
                    // }
                    // )
                  }
                }
              });
          }
          return (
            <Form className={classes.main}>
              <Modal
                open={previewModalOpened}
                onClose={() => setPreviewModalOpened(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
              >
                <Box
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#fff',
                    border: '2px solid #000',
                    boxShadow: 24,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                  }}
                >
                  <Activity
                    data={data}
                    onClose={() => setPreviewModalOpened(false)}
                  />
                </Box>
              </Modal>
              {summaryPreviewModalOpened && (
                <ExpandedReview
                  question={questionValue}
                  close={() => setSummaryPreviewModalOpened(false)}
                />
              )}
              <List>
                {!props.session_activity_id && <h2>Create Question</h2>}
                <ListItem
                  style={{
                    display: 'flex',
                    gap: 20,
                    justifyContent: 'space-between',
                  }}
                >
                  <SingleSelect
                    name='Activity Type'
                    onChange={(event) => {
                      setActivityType(event.value), handleAnswerCheck(1);
                      event.value != 6 &&
                        event.value != 7 &&
                        formProps.setFieldValue('options', '');
                    }}
                    selectList={activityTypeList}
                    style={{ cursor: 'pointer' }}
                    defaultValue={[
                      {
                        value: 6,
                        label: 'Single Select',
                      },
                    ]}
                    isDisabled={false}
                  />
                  <Field
                    name='marks'
                    type='text'
                    component={TextField}
                    label='Marks'
                    variant='outlined'
                    placeholder='Marks'
                    disabled
                  />

                  <Field
                    name='time'
                    type='text'
                    component={TextField}
                    label='Time'
                    variant='outlined'
                    placeholder='Time'
                  />
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => setPreviewModalOpened(true)}
                  >
                    Question Preview
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => setSummaryPreviewModalOpened(true)}
                  >
                    Summary Preview
                  </Button>
                </ListItem>
                <ListItem>
                  <Grid style={{ width: '50%' }}>
                    <div
                      style={{
                        width: '80%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div>Difficulty Level</div>
                      <Slider
                        defaultValue={0}
                        valueLabelDisplay='auto'
                        min={0}
                        max={3}
                        value={form_values?.difficulty}
                        onChangeCommitted={(event, newValue) =>
                          formProps.setFieldValue('difficulty', newValue)
                        }
                        marks={[
                          {
                            value: 0,
                            label: 'None',
                          },
                          {
                            value: 1,
                            label: 'Easy',
                          },
                          {
                            value: 2,
                            label: 'Medium',
                          },
                          {
                            value: 3,
                            label: 'Difficult',
                          },
                        ]}
                        track={false}
                      />
                    </div>
                  </Grid>
                  <Grid style={{ width: '50%' }}>
                  <Autocomplete
                    options={props.objectivesSuggestions?.map((option) => option) || []}
                    freeSolo     
                    onChange={(event)=>{formProps.setFieldValue('objectives', event.target.textContent)                       
                  }}                    
                    value={form_values?.objectives}               
                    renderInput={(params) => <MaterialUiTextField {...params} label="Objectives"                   
                    variant={'outlined'}
                    multiline
                    rows={2}                    
                    />}                    
                    />
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid style={{ width: '100%' }}>
                    <Field
                      name='question.text'
                      className={classes.fullName}
                      type='text'
                      component={TextField}
                      label='Question Text'
                      variant='outlined'
                      placeholder='Question Text'
                      fullWidth
                      style={{ width: '100%' }}
                      multiline
                      rows={4}
                      onPaste={(event) =>
                        pasteFromClipboard(
                          event,
                          'question',
                          'question_images',
                          moment().format('YYYYMMDDHHmmssSSS') +
                            (props.session_activity_id
                              ? '_SAID_' + props.session_activity_id
                              : '') +
                            '_AID_' +
                            props.adminId
                        )
                      }
                    />

                    <Grid
                      container
                      style={{
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                      }}
                    >
                      <FormControl style={{ minWidth: 100 }}>
                        <InputLabel>Media Type</InputLabel>
                        <Select
                          name='Media Type'
                          onChange={(event) => {
                            setMediaType(event.target.value);
                            formProps.setFieldValue('question.media', null);
                            formProps.setFieldValue('question.image', null);
                          }}
                          value={mediaType}
                          // selectList={mediaTypeList}
                          style={{ cursor: 'pointer' }}
                          // defaultValue={[{
                          //   "value": 'image',
                          //   "label": "Image"
                          // }]}
                        >
                          {mediaTypeList.map((mediaTypeData) => (
                            <MenuItem value={mediaTypeData.value}>
                              {mediaTypeData.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <div
                        style={{
                          display:
                            form_values &&
                            form_values.question &&
                            (form_values.question.media ||
                              form_values.question.image)
                              ? 'none'
                              : 'flex',
                        }}
                      >
                        <S3UploadButton
                          bucketName='lq-live'
                          fileType={mediaType}
                          title={'Upload Question ' + mediaType}
                          dirName={
                            mediaType == 'image'
                              ? 'question_images'
                              : `question_${mediaType}`
                          }
                          fileName={
                            moment().format('YYYYMMDDHHmmssSSS') +
                            (props.session_activity_id
                              ? '_SAID_' + props.session_activity_id
                              : '') +
                            '_AID_' +
                            props.adminId
                          }
                          setField={(value) =>
                            formProps.setFieldValue(
                              mediaType == 'image'
                                ? 'question.image'
                                : 'question.media',
                              value
                            )
                          }
                        />
                      </div>
                      {form_values &&
                        form_values.question &&
                        form_values.question.media && (
                          <Button
                            variant='contained'
                            color='primary'
                            style={{ margin: 6 }}
                            onClick={() =>
                              formProps.setFieldValue('question.media', null)
                            }
                          >
                            Remove {mediaType}
                          </Button>
                        )}
                      <div style={{ justifyContent: 'center' }}>
                        {form_values &&
                          form_values.question &&
                          form_values.question.image && (
                            <div style={{ position: 'relative' }}>
                              <img
                                src={form_values.question.image}
                                style={{ maxHeight: 200 }}
                              />
                              <IconButton
                                variant='contained'
                                color='primary'
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                }}
                                onClick={() =>
                                  formProps.setFieldValue(
                                    'question.image',
                                    null
                                  )
                                }
                              >
                                <Icon>close</Icon>
                              </IconButton>
                            </div>
                          )}
                        {form_values &&
                        form_values.question &&
                        form_values.question.media ? (
                          mediaType == 'audio' ? (
                            <audio controls>
                              <source src={form_values.question.media} />
                            </audio>
                          ) : (
                            <video width='auto' height='200px' controls>
                              <source src={form_values.question.media} />
                            </video>
                          )
                        ) : null}
                      </div>

                      <Grid item style={{ display: 'none' }}>
                        <Field
                          name='question.image'
                          className={classes.fullName}
                          type='text'
                          component={TextField}
                          label='Question Image URL'
                          variant='outlined'
                          placeholder='Question Image'
                          // onChange={handleQuestionImage}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  Options
                  <IconButton
                    onClick={() =>
                      formProps.setFieldValue('options', [
                        ...form_values.options,
                        {
                          order: form_values.options.length + 1,
                          text: '',
                          image: '',
                        },
                      ])
                    }
                  >
                    <Icon>add</Icon>
                  </IconButton>
                </ListItem>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {form_values &&
                    form_values.options &&
                    form_values.options.map((option, index) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: optionType == 'Image' ? '45%' : '50%',
                          margin: '1% auto',
                        }}
                      >
                        <ListItem
                          style={{
                            borderRadius: 5,
                            border:
                              optionType == 'Image' ? '1px solid' : 'none',
                            minHeight: optionType == 'Image' ? 200 : 'none',
                          }}
                        >
                          {activityType == 6 || activityType == 7 ? (
                            <Checkbox
                              style={{
                                position: 'absolute',
                                top: optionType == 'Image' ? 0 : 15,
                                left: optionType == 'Image' ? 0 : 15,
                                color: 'green',
                              }}
                              checked={
                                answerCheck &&
                                answerCheck.toString().includes(option.order)
                              }
                              value={option.order}
                              onChange={(event) => {
                                let answer = answerCheck;
                                if (activityType == 6) {
                                  answer[0] = eval(event.target.value);
                                } else {
                                  if (
                                    answerCheck
                                      .toString()
                                      .indexOf(option.order) > -1
                                  ) {
                                    answer.splice(
                                      answer.indexOf(eval(event.target.value)),
                                      1
                                    );
                                  } else {
                                    answer.push(eval(event.target.value));
                                  }
                                }
                                handleAnswerCheck(answer);
                                formProps.setFieldValue(
                                  'answer',
                                  answerCheck.toString()
                                );
                                // formProps.setFieldTouched('options['+index+'].text', true)
                              }}
                            />
                          ) : null}

                          {form_values.options[index].image ? (
                            <div
                              style={{ position: 'relative', width: '100%' }}
                            >
                              <img
                                src={form_values.options[index].image}
                                style={{ maxHeight: 180, marginLeft: 50 }}
                              />
                              <IconButton
                                variant='contained'
                                color='primary'
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                }}
                                onClick={() =>
                                  formProps.setFieldValue(
                                    `options[${index}].image`,
                                    null
                                  )
                                }
                              >
                                <Icon>close</Icon>
                              </IconButton>
                            </div>
                          ) : (
                            <div style={{ width: '100%' }}>
                              <Field
                                name={'options[' + index + '].text'}
                                className={classes.optionStyle}
                                type='text'
                                component={TextField}
                                // label={'Option'+option.order}
                                variant='outlined'
                                inputProps={{
                                  style: {
                                    padding: 15,
                                  },
                                }}
                                onPaste={(event) =>
                                  pasteFromClipboard(
                                    event,
                                    'options[' + index + ']',
                                    'option_images',
                                    moment().format('YYYYMMDDHHmmss') +
                                      '_O' +
                                      option.order +
                                      (props.session_activity_id
                                        ? '_SAID_' + props.session_activity_id
                                        : '') +
                                      '_AID_' +
                                      props.adminId
                                  )
                                }
                                style={{ margin: '0px auto', padding: 0 }}
                                placeholder={'Write here..'}
                                multiline
                                rows={5}
                              />
                              <Grid
                                item
                                style={{
                                  position: 'absolute',
                                  bottom: 10,
                                  right: 10,
                                }}
                              >
                                <S3UploadButton
                                  bucketName='lq-live'
                                  fileType='img'
                                  title={'Option ' + option.order + ' Image'}
                                  dirName='option_images'
                                  directUpload={true}
                                  fileName={
                                    moment().format('YYYYMMDDHHmmss') +
                                    '_O' +
                                    option.order +
                                    (props.session_activity_id
                                      ? '_SAID_' + props.session_activity_id
                                      : '') +
                                    '_AID_' +
                                    props.adminId
                                  }
                                  setField={(value) =>
                                    formProps.setFieldValue(
                                      'options[' + index + '].image',
                                      value
                                    )
                                  }
                                />
                              </Grid>
                            </div>
                          )}
                        </ListItem>
                        <p style={{ color: '#f44336', margin: '2px 10px' }}>
                          {optionType == 'Image' &&
                            form_erros &&
                            form_erros.options &&
                            form_erros.options[index] &&
                            form_erros.options[index].image}
                        </p>
                      </div>
                    ))}
                </div>
                {activityType &&
                (activityType == 7 || activityType == 6) ? null : (
                  <ListItem>
                    <Field
                      name='answer'
                      className={classes.fullName}
                      type='text'
                      component={TextField}
                      label='Answer'
                      variant='outlined'
                      placeholder='Answer'
                    />
                  </ListItem>
                )}
                <ListItem>
                  <Grid
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Field
                      name='sol_text'
                      className={classes.fullName}
                      type='text'
                      component={TextField}
                      label='Solution Text'
                      variant='outlined'
                      placeholder='Solution Text'
                      fullWidth
                      style={{ minWidth: '50%' }}
                      multiline
                      rows={4}
                      onPaste={(event) =>
                        pasteFromClipboard(
                          event,
                          'solution',
                          'solution_images',
                          moment().format('YYYYMMDDHHmmss.SSS') +
                          '_QID' +
                          '_S' +
                          (props.session_activity_id
                            ? '_SAID_' + props.session_activity_id
                            : '') +
                          '_AID_' +
                          props.adminId
                        )
                      }
                    />
                    <Grid
                      container
                      style={{
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        width: 'auto',
                        display:
                          form_values && form_values.sol_img ? 'none' : 'flex',
                      }}
                    >
                      <Grid item style={{}}>
                        {/* <Button variant="outlined" color="secondary">Upload Image</Button> */}
                        <S3UploadButton
                          bucketName='lq-live'
                          fileType='img'
                          title='Upload Solution Image'
                          dirName='solution_images'
                          fileName={
                            moment().format('YYYYMMDDHHmmss.SSS') +
                            '_QID' +
                            '_S' +
                            (props.session_activity_id
                              ? '_SAID_' + props.session_activity_id
                              : '') +
                            '_AID_' +
                            props.adminId
                          }
                          setField={(value) =>
                            formProps.setFieldValue('sol_img', value)
                          }
                        />
                      </Grid>

                      <Grid item style={{ display: 'none' }}>
                        <Field
                          name='sol_img'
                          className={classes.fullName}
                          type='text'
                          component={TextField}
                          label='Solution Image URL'
                          variant='outlined'
                          placeholder='Solution Image'
                          // onChange={handleQuestionImage}
                        />
                      </Grid>
                    </Grid>
                    {form_values && form_values.sol_img && (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={form_values.sol_img}
                          style={{ maxHeight: 200 }}
                        />
                        <IconButton
                          variant='contained'
                          color='primary'
                          style={{ position: 'absolute', top: 0, right: 0 }}
                          onClick={() =>
                            formProps.setFieldValue('sol_img', null)
                          }
                        >
                          <Icon>close</Icon>
                        </IconButton>
                      </div>
                    )}
                  </Grid>
                </ListItem>
              </List>
              <Button
                color='primary'
                variant='contained'
                type='submit'
                style={{
                  display: 'flex',
                  margin: 'auto',
                  padding: '15px 20px',
                  fontSize: 15,
                  fontWeight: 600,
                }}
                size='large'
              >
                {props.session_activity_id ? 'Add Question' : 'Create Question'}
              </Button>
            </Form>
          );
        }}
      />
    </div>
  );
}

const styles = makeStyles((theme) => ({
  fullWidth: { width: '100%' },
  lables: {},
  formComponent: {
    width: '100%',
    padding: 15,
  },
  grades: {
    minWidth: 200,
    maxWidth: 300,
  },
  formControl: {
    minWidth: 500,
    maxWidth: 500,

    // marginLeft: theme.spacing.unit * 2
  },
  school: {
    margin: `${theme.spacing(2)}px 0px`,
    width: 500,
  },
  fullName: {
    margin: `${theme.spacing(2)}px 0px`,
    width: 500,
  },
  optionStyle: {
    margin: `${theme.spacing(2)}px 0px`,
    width: '100%',
  },

  longText: {
    minWidth: 400,
  },
  twoForms: {
    display: 'flex',
    flexDirection: 'row',
  },
  main: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    minHeight: '60vh',
  },
}));

function mapStateToProps(state) {
  return {
    user: state.user,
    adminId: state.user && state.user.user_id,
  };
}

export default withSnackbar(connect(mapStateToProps)(CreateQuestion));
