import React, { useState } from 'react';
import { Link } from 'react-router';
import {
  makeStyles,
  IconButton,
  Icon,
  Tooltip
} from '@material-ui/core';
import Table from '../../components/LQDataTables/demo';
import ReassignMissedSessionStudent from '../../components/ReassignMissedSessionStudent';
import AssignSessionAbsent from '../../components/AssignSessionAbsent';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },

  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});

let tableRef = null;

const ref = React.createRef();

function MissedSessionTable(props) {
  const classes = userStyles();
  const [reAssignStudent, setReAssignStudent] = useState(null);
  const [assignSession, setAssignSession] = useState(null);
  const [name, setName] = useState(null);
  function handleCallBack() {
    tableRef && tableRef.refreshTable();
  }

  const ref = React.createRef();
  function formatTable(tableData) {
    const newData =
      tableData &&
      tableData.map((entry, index) => {
        const {
          id,
          scheduled_at,
          started_at,
          completed_at,
          class_type,
          participant_id,
          student_name,
          student_id,
        } = entry;

        const newEntry = { ...entry };
        newEntry.id = (
          <Link
            to={{
              pathname: '/sessions',
              search: `?id=${newEntry.id}`,
            }}
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            {newEntry.id}
          </Link>
        );
        newEntry.student_name = (
          <Link
            to={{
              pathname: '/student-session-details',
              search: `?student_id=${student_id}`,
            }}
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            <div>{student_name}</div>
          </Link>
        );
        newEntry.group_id = (
          <Link
            to={{
              pathname: `/group-class/${
                class_type.toLowerCase().includes('paid') ? 'paid' : 'free'
              }`,
              search: `?id=${newEntry.group_id}`,
            }}
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            {newEntry.group_id}
          </Link>
        );
        newEntry.student_id = (
          <Link
            to={{
              pathname: '/user',
              search: `?id=${newEntry.student_id}`,
            }}
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            {newEntry.student_id}
          </Link>
        );
        newEntry.scheduled_at =
          scheduled_at &&
          new Date(scheduled_at).toLocaleString('en-IN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          });
        newEntry.started_at =
          started_at &&
          new Date(started_at).toLocaleString('en-IN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          });
        newEntry.completed_at =
          completed_at &&
          new Date(completed_at).toLocaleString('en-IN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          });
        const updatedEntry = {
          reassign: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <IconButton onClick={() => setReAssignStudent(participant_id)}>
                <Tooltip title='Reassign'>
                  <Icon className='iconButton'>person_add_icon</Icon>
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() => {
                  setAssignSession(participant_id), setName(student_name);
                }}
              >
                <Tooltip title={'Create Extra Session for ' + student_name}>
                  <Icon className='iconButton'>add_circle_icon</Icon>
                </Tooltip>
              </IconButton>
            </div>
          ),
          ...newEntry,
        };
        return Object.keys(updatedEntry).map((subEntry) =>
          updatedEntry[subEntry]
            ? '' || updatedEntry[subEntry]
            : updatedEntry[subEntry] == 0
            ? '0'
            : '-'
        );
      });
    return newData;
  }

  return (
    <div>
      <Table
        onRef={(ref) => {
          tableRef = ref;
        }}
        firstCol='Reassign'
        headerFilterList={HEADER_FILTER_LIST}
        formatTable={formatTable}
        sortList={SORT_LIST}
        sortDate={[]}
        search={props.location && props.location.query}
        tableName='Missed-Session Table'
        title='Missed Session Table'
        scrollType='stacked'
        dateFilter={true}
        apiUrl={`session/missed/list/`}
      />
      {reAssignStudent && (
        <ReassignMissedSessionStudent
          participant_id={reAssignStudent}
          closeModal={() => setReAssignStudent(null)}
          handleCallBack={handleCallBack}
        />
      )}
      {assignSession && (
        <AssignSessionAbsent
          participant_id={assignSession}
          participant_name={name}
          closeModal={() => setAssignSession(null)}
          handleCallBack={handleCallBack}
        />
      )}
    </div>
  );
}

export default MissedSessionTable;

const HEADER_FILTER_LIST = [
  {
    filter_label: 'id',
    type: 'text',
  },
  {
    filter_label: 'student_id',
    type: 'text',
  },
  {
    filter_label: 'student_name',
    type: 'text',
  },
  {
    filter_label: 'group_id',
    type: 'text',
  },
  {
    filter_label: 'group_name',
    type: 'text',
  },
  {
    filter_label: 'class_type',
    type: 'text',
  },
  {
    filter_label: 'teacher',
    type: 'text',
  },
  {
    filter_label: 'session',
    type: 'text',
  },
  {
    filter_label: 'grade',
    type: 'text',
  },
];
const SORT_LIST = ['id', 'student_id'];
