import React, {
  Component,
} from 'react';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import { connect } from 'react-redux';
import { Table, TableRow, TableHead, TableBody, TableCell, TablePagination, TableSortLabel, InputAdornment, Icon, IconButton, Tooltip, CircularProgress, Checkbox } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

class AvailabilityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teacherPerformanceData: null,
      date: new Date(),
      teacherPerformanceDetail: null,
      type: null,
      page: 0,
      teacher: null,
      no_of_rows: 10,
      activeSort: { id: 'rank', asc: true },

    };
  }
  componentDidMount() {
    this.getTeacherPerformanceData(new Date())
  }
  getTeacherPerformanceDetail = (date, type_id, teacher_id, teacher_name) => {
    this.setState({ date: new Date(date), type: type_id, teacher: { teacher_id, teacher_name } })
    const month = new Date(date).getMonth() + 1
    const year = new Date(date).getFullYear()
    const page = this.state.page ? this.state.page + 1 : 1
    const url = type_id == 4 ?
      `v1/teacher/${this.props.adminId}/performance/${type_id}/details/?month=${month}&year=${year}&user_id=${teacher_id}&page=${page}&page_size=${this.state.no_of_rows}`
      : `v1/teacher/${this.props.adminId}/performance/${type_id}/details/?month=${month}&year=${year}&user_id=${teacher_id}`
    axios.get(url)
      .then((response) => {
        if (response && response.status == 200 && response.data && response.data.data) {
          const performance_detail = response.data && response.data.data
          performance_detail.teacher_name = teacher_name
          this.setState({ teacherPerformanceDetail: response.data.data })
        }
      })
  }
  getTeacherPerformanceData = (date) => {
    this.setState({ date: new Date(date) })
    const month = new Date(date).getMonth() + 1
    const year = new Date(date).getFullYear()
    axios.get(`/v1/admin/${this.props.adminId}/teacher/performance/analysis/?month=${month}&year=${year}`)
      .then((response) => {
        if (response && response.status == 200 && response.data)
          this.setState({ teacherPerformanceData: response.data.data, teacherPerformanceDetail: null, activeSort: { id: 'rank', asc: true } })
      })
  }

  render() {
    return (
      <div>
        <div style={{ minHeight: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {this.state.teacherPerformanceDetail && this.state.teacherPerformanceDetail.headers && this.state.teacherPerformanceDetail.results ?
            <div style={{ alignSelf: 'self-start', width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
              <div>
                <div style={{ display: 'flex', gap: 10, fontSize: '1.5vw', fontWeight: 600, marginBottom: 10, alignItems: 'center' }}>
                  <Icon
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.setState({ teacherPerformanceDetail: null })}>keyboard_backspace_icon</Icon>
                  <div>{this.state.teacherPerformanceDetail.title + ' of ' + this.state.teacherPerformanceDetail.teacher_name}</div>
                </div>
                <div>{this.state.teacherPerformanceDetail.subtitle}</div>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    {this.state.teacherPerformanceDetail.headers.map((header) =>
                      <TableCell style={{ textAlign: 'left', fontSize: '1vw', fontWeight: 600, border: '1px solid #e0e0e0', padding: '6px 24px 6px 16px', height: '10vh', backgroundColor: '#e0e0e0' }}>
                        {header.title}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.teacherPerformanceDetail.results.map((teacherPerformance) =>
                    <TableRow>
                      {this.state.teacherPerformanceDetail.headers.map((header) =>
                        <TableCell style={{ textAlign: 'left', fontSize: '1vw', fontWeight: 500, border: '1px solid #e0e0e0', padding: '6px 24px 6px 16px', height: '7vh' }}>
                          {header.title == 'Details' ?
                            teacherPerformance[header.title].map((conversionDetails) => {
                              if (this.state.teacherPerformanceDetail.title == "Training Completion")
                                return <div>
                                  <Checkbox checked={conversionDetails.is_completed} disabled size="small"
                                    style={{ color: 'rgba(26,41,128, 0.9)', padding: '2px 9px' }}
                                  />
                                  {conversionDetails.title}</div>
                              else if (this.state.teacherPerformanceDetail.title == "Conversion Rate")
                                return <div>{conversionDetails.name + '-' + conversionDetails.text}</div>
                            }
                            )
                            :
                            teacherPerformance[header.title]}
                        </TableCell>)}
                    </TableRow>
                  )}
                </TableBody>
                {this.state.teacherPerformanceDetail.title == "Conversion Rate" ?
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={this.state.teacherPerformanceDetail.count}
                    rowsPerPage={this.state.no_of_rows}
                    page={this.state.page}
                    onChangePage={(event, newPage) => {
                      this.setState({ page: newPage })
                      this.getTeacherPerformanceDetail(this.state.date, this.state.type, this.state.teacher.teacher_id, this.state.teacher.teacher_name)
                    }
                    }
                    onChangeRowsPerPage={(event) => {
                      this.setState({ no_of_rows: parseInt(event.target.value, 10) }, () =>
                        this.getTeacherPerformanceDetail(this.state.date, this.state.type, this.state.teacher.teacher_id, this.state.teacher.teacher_name, 0,)
                      )
                    }}
                  /> : null}
              </Table>
            </div>
            :


            <div style={{ alignSelf: 'self-start', width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ fontSize: '1.5vw', fontWeight: 600 }}>Teacher Performance</div>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    onChange={(date) => this.getTeacherPerformanceData(date)}
                    value={this.state.date}
                    views={['year', 'month']}
                    label="Month and Year"
                    format="MM/yyyy"
                    inputVariant="outlined"
                    InputProps={
                      {
                        endAdornment: (

                          <InputAdornment position="end">
                            <Tooltip title="Schedule">
                              <IconButton>
                                <Icon>date_range_icon</Icon>
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              {this.state.teacherPerformanceData ? this.state.teacherPerformanceData[0] ?
                <Table>
                  <TableHead>
                    <TableRow>
                      {Object.keys(this.state.teacherPerformanceData[0]).map((header) => header != 'user_id' &&
                        <TableCell style={{
                          textAlign: 'left', fontSize: '1vw', fontWeight: 600, border: '1px solid #e0e0e0', padding: '6px 24px 6px 16px', height: '10vh', backgroundColor: '#e0e0e0',                         
                        }}
                        >
                          <Icon
                            style={{ fontSize: '1.25vw', cursor: 'pointer', verticalAlign: 'middle', marginRight: 5 }}
                            onClick={() => {
                              this.setState({
                                activeSort: { id: header, asc: this.state.activeSort.id == header ? !this.state.activeSort.asc : true },
                                teacherPerformanceData:
                                  this.state.teacherPerformanceData.sort((a, b) =>
                                    (b[header]?.value || a[header]?.value) ?
                                      this.state.activeSort.id == header ? this.state.activeSort.asc ?
                                        b[header]?.value - a[header]?.value
                                        : a[header]?.value - b[header]?.value
                                        : a[header]?.value - b[header]?.value
                                      :
                                      typeof a[header] === 'string' ?
                                        this.state.activeSort.id == header ?
                                          this.state.activeSort.asc ?
                                            b[header] > a[header] ? 1 : -1
                                            : a[header] > b[header] ? 1 : -1
                                          : a[header] > b[header] ? 1 : -1
                                        :
                                        this.state.activeSort.id == header ?
                                          this.state.activeSort.asc ?
                                            b[header] - a[header]
                                            : a[header] - b[header]
                                          : a[header] - b[header]
                                  )

                              })
                            }
                            }
                          >{this.state.activeSort.id == header ? this.state.activeSort.asc ? 'arrow_downward_icon' : 'arrow_upward_icon' : 'sort_icon'}</Icon>
                          {header.charAt(0).toUpperCase() + header.replaceAll('_', ' ').slice(1)}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.teacherPerformanceData.map((teacherPerformance) =>
                      <TableRow>
                        {Object.keys(this.state.teacherPerformanceData[0]).map((header) => header != 'user_id' &&
                          <TableCell style={{ textAlign: 'left', fontSize: '1vw', fontWeight: 500, border: '1px solid #e0e0e0', padding: teacherPerformance[header]?.value >= 0 ? 0 : '6px 24px 6px 16px', height: '7vh' }}>
                            {teacherPerformance[header]?.value >= 0 ?
                              teacherPerformance[header]?.value != null ?
                                (<div style={{
                                  display: 'flex', justifyContent: 'space-between', padding: 10,
                                  color: teacherPerformance[header]?.flag === 3 ? "#2c2a2a" : "#fff",
                                  background: teacherPerformance[header]?.flag === 1 ? '#EB3434' : teacherPerformance[header]?.flag === 2 ? '#fba913' : 'transparent',
                                  cursor: teacherPerformance[header]?.value >= 0 ? 'pointer' : 'inherit',
                                }}
                                  onClick={() => this.getTeacherPerformanceDetail(this.state.date, teacherPerformance[header].type_id, teacherPerformance.user_id, teacherPerformance.teacher_name)}
                                >
                                  <div style={{ display: 'flex' }}>
                                    <div>{teacherPerformance[header].value || '0'}</div>
                                    <div>{teacherPerformance[header].unit}</div>
                                  </div>
                                  <Icon>chevron_right_icon</Icon>
                                </div>) :
                                <div style={{ padding: 10 }}>-</div> :
                              teacherPerformance[header]
                            }
                          </TableCell>)}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                : <div>
                  <Icon>file_copy_icon</Icon>
                  No Data Found
                </div>
                : <CircularProgress />
              }
            </div>

          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    adminId: state?.user?.user_id
  };
};

export default withSnackbar(connect(mapStateToProps)(AvailabilityModal));
