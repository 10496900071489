import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { orange } from '@material-ui/core/colors';
import {
  CircularProgress,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextareaAutosize,
  withStyles,
} from '@material-ui/core';

import './style.scss';
import closeIcon from '../../../../assets/close-icon.png';
import UploadImage from '../../../../assets/image-logo1.png';
import EditIcon from '../../../../assets/edit-icon.png';
import trashIcon from '../../../../assets/trash_full.png';
import Col from '../../../../components/Elements/Flex/col';
import Row from '../../../../components/Elements/Flex/row';
import CssModal from '../../PopUps/Text/TextCssPopup';
import Button from '../Button/Button';
import SolutionImage from './SolutionImage/SolutionImage';

const OrangeRadio = withStyles({
  root: {
    '&$checked': {
      color: orange[600],
    },
    fontSize: 28,
    fontWeight: 600,
  },
  checked: {},
})((props) => <Radio color='default' {...props} />);

const SingleSelect = (props) => {
  const [selectOption, setSelectOption] = useState(null);
  const [isSolutionActive, setIsSolutionActive] = useState(false);
  const [isOptionImage, setIsOptionImage] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);

  const {
    type,
    options,
    updateState,
    updateElementData,
    index,
    solution,
    answer,
    selectedElementIndex,
    deleteComponent,
    validated,
    style,
    elementsAdded,
    currentSlideIndex,
    optionType,
  } = props;
  const isSelected = selectedElementIndex === index ? true : false;

  const updateSolutionStyle = (key, value) => {
    let updatedStyle = JSON.parse(JSON.stringify(solution));
    updatedStyle.solutionText.style[key] = value;

    updateElementData(index, {
      key: 'solution',
      value: updatedStyle,
    });
  };

  const updateElementStyle = (key, value) => {
    let updatedStyle = JSON.parse(
      JSON.stringify(isSolutionActive ? solution.solutionText.style : style)
    );
    updatedStyle[key] = value;

    updateElementData(index, {
      key: 'style',
      value: updatedStyle,
    });
  };

  const checkCallStyling = (key, value) => {
    isSolutionActive
      ? updateSolutionStyle(key, value)
      : updateElementStyle(key, value);
  };

  const deleteOptionPromise = () => {
    return new Promise((resolve) => {
      let arr = JSON.parse(JSON.stringify(options));
      arr.pop();
      updateElementData(index, {
        key: 'options',
        value: arr,
      });
      return resolve();
    });
  };

  const imageHandler = (value) => {
    setImageLoader(true);

    const { updateElementData, index } = props;
    const adminId = props?.user?.user_id;
    let dataURl = value;

    const bodyFormData = new FormData();
    bodyFormData.append('content_type', 'image');
    bodyFormData.append('bucket_name', 'lq-live');
    bodyFormData.append(
      'key',
      `flashcard/${moment().format('YYYYMMDDHHmmssSSS')}_AID_${adminId}`
    );
    bodyFormData.append('file', dataURl);

    axios({
      method: 'post',
      url: `v1/admin/${adminId}/s3/upload/`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        if (response.status === 200) {
          setImageLoader(false);
          let arr = JSON.parse(JSON.stringify(options));
          arr[selectOption] = {
            sNo: selectOption + 1,
            imageUrl: response.data.data,
            text: '',
          };
          updateElementData(index, {
            key: 'options',
            value: arr,
          });
        }
      })
      .catch((error) => {
        setImageLoader(false);
      });
  };

  const pasteFromClipboard = () => {
    navigator.permissions
      .query({ name: 'clipboard-read' })
      .then(async (result) => {
        // If permission to read the clipboard is granted or if the user will
        // be prompted to allow it, we proceed.

        if (result.state == 'granted' || result.state == 'prompt') {
          const clipboardData =
            navigator &&
            navigator.clipboard &&
            (await navigator.clipboard.read());

          let image_type;
          const item =
            clipboardData &&
            clipboardData.find((item) =>
              item.types.some((type) => {
                if (type.startsWith('image/')) {
                  image_type = type;
                  return true;
                }
              })
            );
          var file = item && (await item.getType(image_type));
          if (file) {
            imageHandler(file);
          } else {
            alert('Please copy image again & paste.');
          }
        }
      });
  };

  return (
    <div
      className={`single-select-box ${isSelected ? 'selected' : ''} ${
        !validated ? 'error' : ''
      }`}
      onClick={() => updateState({ selectedElementIndex: index })}
    >
      <div className='radio-div'>
        <div className='option-type-title'>Option type</div>
        <RadioGroup
          row
          aria-label='position'
          name='position'
          value={optionType}
          onChange={(event) => {
            updateElementData(index, {
              key: 'optionType',
              value: event.target.value,
            });
            setIsOptionImage(event.target.value === 'text' ? false : true);
          }}
        >
          <FormControlLabel
            className='label'
            value='text'
            control={<OrangeRadio />}
            label='Text'
          />
          <FormControlLabel
            className='label'
            value='image'
            control={<OrangeRadio />}
            label='Image'
          />
        </RadioGroup>
      </div>

      {optionType === 'text' ? (
        <div className='options-container-text'>
          {options.map((optionValue, indexs) => (
            <li
              key={indexs}
              className='single-select-option'
              style={
                optionValue.text ? { border: `1px solid ${style.color}` } : {}
              }
            >
              <div className='option-div'>
                {String.fromCharCode(65 + indexs)}
              </div>
              <TextareaAutosize
                className='option-input'
                style={style}
                placeholder='Type option here'
                onFocus={() => {
                  setSelectOption(indexs);
                  setIsSolutionActive(false);
                }}
                value={optionValue.text}
                onChange={(event) => {
                  let arr = JSON.parse(JSON.stringify(options));
                  arr[selectOption] = {
                    sNo: selectOption + 1,
                    text: event.target.value,
                    imageUrl: null,
                  };
                  updateElementData(index, {
                    key: 'options',
                    value: arr,
                  });
                }}
              />

              {isSelected &&
              options &&
              options.length > 2 &&
              options.length === indexs + 1 ? (
                <Row
                  className='close-icon-option'
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteOptionPromise().then(() => {
                      answer === options.length &&
                        updateElementData(index, {
                          key: 'answer',
                          value: null,
                        });
                    });
                  }}
                >
                  <img src={trashIcon} className='delete-icon' />
                </Row>
              ) : null}
            </li>
          ))}
        </div>
      ) : (
        <div className='options-container'>
          {options.map((optionValue, indexs) => (
            <li key={indexs} className='single-select-option'>
              <div className='option-div'>
                {String.fromCharCode(65 + indexs)}
              </div>
              {optionValue.imageUrl ? (
                <div className='image-uploaded-div'>
                  <img
                    src={optionValue.imageUrl}
                    className='option-image'
                    alt={indexs + 1}
                  />
                </div>
              ) : (
                <>
                  <input
                    type='file'
                    accept='image/*'
                    id='option-input-image'
                    onChange={(e) => {
                      imageHandler(e.target.files[0]);
                    }}
                  />
                  <input
                    type='text'
                    className='option-input-paste'
                    id={`option-input-image-paste${currentSlideIndex}${indexs}`}
                    onPaste={() => {
                      pasteFromClipboard();
                    }}
                  />
                  {imageLoader && selectOption === indexs ? (
                    <CircularProgress size={24} className='loader' />
                  ) : (
                    <div
                      className='upload-img-option'
                      onClick={() => {
                        setSelectOption(indexs);
                        setIsSolutionActive(false);
                        document
                          .getElementById(
                            `option-input-image-paste${currentSlideIndex}${indexs}`
                          )
                          .focus();
                      }}
                    >
                      <label htmlFor='option-input-image'>
                        <img
                          src={UploadImage}
                          className='option-image-icon'
                          htmlFor='option-input-image'
                        />
                      </label>
                      <label
                        className='label-text'
                        htmlFor='option-input-image'
                      >
                        UPLOAD{' '}
                      </label>
                      <label className='label-text'>or</label>

                      <label className='label-text'>
                        Click & paste image here
                      </label>
                    </div>
                  )}
                </>
              )}

              {isSelected &&
              options &&
              options.length > 2 &&
              options.length === indexs + 1 ? (
                <Row
                  className='close-icon-option'
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteOptionPromise().then(() => {
                      answer === options.length &&
                        updateElementData(index, {
                          key: 'answer',
                          value: null,
                        });
                    });
                  }}
                >
                  <img src={trashIcon} className='delete-icon' />
                </Row>
              ) : null}
              {optionValue?.imageUrl && isSelected && (
                <Row
                  className='edit-option-icon'
                  onClick={(e) => {
                    setIsSolutionActive(false);
                    e.stopPropagation();
                    let arr = JSON.parse(JSON.stringify(options));
                    arr[indexs] = {
                      sNo: indexs + 1,
                      imageUrl: null,
                      text: '',
                    };
                    updateElementData(index, {
                      key: 'options',
                      value: arr,
                    });
                  }}
                >
                  <img src={EditIcon} className='edit-icon' />
                </Row>
              )}
            </li>
          ))}
        </div>
      )}
      <Row className='correct-answer-div'>
        <p className='correct-answer-title'>Correct Answer :</p>
        <Row className='correct-answer-option-box'>
          {options.map((value, indexs) => (
            <p
              key={indexs}
              id={`correct-answer-option-${value.sNo}`}
              className='correct-answer-option'
              style={{
                backgroundColor: answer === indexs + 1 ? '#1BB55C' : '#e5e5e5',
              }}
              onClick={() =>
                updateElementData(index, {
                  key: 'answer',
                  value: value.sNo,
                })
              }
            >
              {String.fromCharCode(65 + value.sNo - 1)}
            </p>
          ))}
        </Row>
      </Row>
      <Col className='solution-container'>
        <p className='solution-title'>Solution</p>
        <Col className='solution-box'>
          <TextareaAutosize
            className='solution-box-input'
            style={{ ...solution?.solutionText?.style }}
            type='text'
            placeholder='Add solution'
            value={solution?.solutionText?.['text'] || ''}
            onFocus={() => {
              setIsSolutionActive(true);
              setSelectOption(5);
            }}
            onChange={(event) => {
              const solutionObj = JSON.parse(JSON.stringify(solution)) || {};

              if (event.target.value) {
                if (!!solution?.solutionText === false) {
                  solutionObj.solutionText = {
                    type: 'answereText',
                    text: '',
                    style: {
                      color: '#0D0D0D',
                      fontSize: 14,
                      textAlign: 'left',
                    },
                  };
                }
                solutionObj.solutionText['text'] = event.target.value;
                updateElementData(index, {
                  key: 'solution',
                  value: solutionObj,
                });
              } else {
                delete solutionObj.solutionText;
                updateElementData(index, {
                  key: 'solution',
                  value: solutionObj,
                });
              }
            }}
          />
          <SolutionImage
            {...{
              ...solution?.image,
              index,
              selectedElementIndex,
              updateState,
              updateElementData,
              deleteComponent,
              solution,
              validated: true,
              currentSlideIndex,
            }}
          />
          {elementsAdded?.button !== true &&
            (solution?.button ? (
              <Button
                {...{
                  ...solution?.button,
                  index,
                  selectedElementIndex,
                  updateState,
                  updateElementData,
                  deleteComponent,
                  importedIn: 'singleSelect',
                  solution,
                  selectOption,
                  validated: true,
                  selectButton: () => {
                    setSelectOption(null);
                    setIsSolutionActive(false);
                  },
                }}
              />
            ) : (
              <Row
                className='solution-box-action'
                onClick={(e) => {
                  e.stopPropagation();
                  const solutionObj = JSON.parse(JSON.stringify(solution));
                  solutionObj.button = {
                    type: 'button',
                    buttonType: 'scroll',
                    text: 'Proceed',
                    style: { backgroundColor: '#FF9F0A' },
                  };

                  updateElementData(index, {
                    key: 'solution',
                    value: solutionObj,
                  });
                }}
              >
                Add Button
              </Row>
            ))}
        </Col>
      </Col>
      {isSelected ? (
        <Row
          className='close-icon-div'
          onClick={(e) => {
            e.stopPropagation();
            deleteComponent(index, type);
          }}
        >
          <img src={closeIcon} className='close-icon' />
        </Row>
      ) : null}
      {isSelected && !isOptionImage && selectOption !== null ? (
        <CssModal
          style={
            isSolutionActive
              ? solution?.solutionText?.style || {
                  color: '#0D0D0D',
                  fontSize: 14,
                  textAlign: 'left',
                }
              : style
          }
          checkCallStyling={checkCallStyling}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(SingleSelect);
