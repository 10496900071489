import React from 'react';
import { useDrag } from 'react-dnd';

import './style.scss';
import { BUTTON_TYPE } from '../../draggableItemTypes';

const buttonType = {
  type: 'button',
  buttonType: 'scroll',
  text: 'Proceed',
  style: { backgroundColor: '#FF9F0A' },
};

const ButtonComponent = (props) => {
  const { addComponent } = props;

  const [{}, drag] = useDrag(() => ({
    type: BUTTON_TYPE,
    item: buttonType,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) addComponent(item);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div ref={drag} className='button-box'>
      Button
    </div>
  );
};

export default ButtonComponent;
