import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateRangePickerModal from '../../components/DateRangePickerModal';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  CircularProgress,
  Button,
  Icon,
  TextField,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import SessionSelector from '../../components/SessionSelector';
import SaveChangesModal from '../../components/SaveChangesModal';
import axios from 'axios';
import './style.scss';

class contentHead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentHeadData: null,
      startDate: null,
      endDate: null,
      classId: null,
      packageId: null,
      isLoading: false,
      headerData: { homework: [], classwork: [] },
      sessionData: null,
      individualSessionData: null,
      activeSort: { id: '', asc: true },
    };
    this.hiddenColumns = ['query_ids'];
  }

  setSessionData = (packageId, classId) => {
    this.setState({ packageId: packageId, classId: classId }, () =>
      this.fetchContentHeadData(this.state.startDate, this.state.endDate)
    );
  };

  fetchSessionDetails = (session_activity_id) => {
    axios
      .get(
        `/v1/admin/${this.props.adminId}/activity/${session_activity_id}/performance/`
      )
      .then((response) => {
        if (response?.data?.data) {
          const data = response.data.data;
          this.setState({
            sessionData: data,
          });
        }
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error?.response?.data?.data?.error, {
          variant: 'error',
        });
      });
  };

  fetchContentHeadData = (
    startDate = this.state.startDate,
    endDate = this.state.endDate
  ) => {
    this.setState({
      contentHeadData: null,
      startDate: startDate,
      endDate: endDate,
    });
    if (this.state.classId && this.state.packageId) {
      this.setState({ isLoading: true });
      axios
        .get(
          `v1/admin/${this.props.adminId}/session/student/performance/?from_date=${startDate}&to_date=${endDate}&class_id=${this.state.classId}&package_id=${this.state.packageId}`
        )
        .then((response) => {
          if (response?.data?.data) {
            const { data, homework, classwork } = response.data.data;
            this.setState({
              contentHeadData: data,
              isLoading: false,
              headerData: { homework, classwork },
            });
          }
        })
        .catch((error) => {
          this.props.enqueueSnackbar(error?.response?.data?.data?.error, {
            variant: 'error',
          });
        });
    }
  };

  fetchIndividualSessionData = (sessionId) => {
    const { startDate, endDate, classId, packageId } = this.state;
    axios
      .get(
        `v1/admin/${this.props.adminId}/session/student/performance/?from_date=${startDate}&to_date=${endDate}&class_id=${classId}&package_id=${packageId}&session_id=${sessionId}`
      )
      .then((response) => {
        if (response?.data?.data) {
          const { data, homework, classwork } = response.data.data;
          this.setState({
            individualSessionData: data,
          });
        }
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error?.response?.data?.data?.error, {
          variant: 'error',
        });
      });
  };

  toTitleCase = (text) => {
    return text.charAt(0).toUpperCase() + text.replaceAll('_', ' ').slice(1);
  };

  searchFields = (fieldName) => {
    const { individualSessionData } = this.state;
    var searchValue = '';
    return (
      <TextField
        label={fieldName}
        variant='outlined'
        onChange={(event) => {
          searchValue = event.target.value;
        }}
        InputProps={{
          endAdornment: (
            <Icon
              onClick={() => {
                individualSessionData.filter((individualSessionDetails) =>
                  individualSessionDetails[fieldName].includes(searchValue)
                );
              }}
            >
              search
            </Icon>
          ),
        }}
      />
    );
  };

  sortIcon = (header, subHeader) => {
    const { contentHeadData, activeSort, individualSessionData } = this.state;
    const sortingData = individualSessionData
      ? individualSessionData
      : contentHeadData;
    return (
      <Icon
        className='sortIcon'
        onClick={() =>
          this.setState({
            activeSort: {
              id: subHeader ? subHeader : header,
              asc:
                activeSort.id == header || activeSort.id == subHeader
                  ? !activeSort.asc
                  : true,
            },
            [individualSessionData
              ? 'individualSessionData'
              : 'contentHeadData']: sortingData.sort((a, b) =>
              subHeader
                ? activeSort.id == subHeader
                  ? activeSort.asc
                    ? b[header][subHeader] - a[header][subHeader]
                    : a[header][subHeader] - b[header][subHeader]
                  : a[header][subHeader] - b[header][subHeader]
                : typeof a[header] === 'string'
                ? this.state.activeSort.id == header
                  ? this.state.activeSort.asc
                    ? b[header] > a[header]
                      ? 1
                      : -1
                    : a[header] > b[header]
                    ? 1
                    : -1
                  : a[header] > b[header]
                  ? 1
                  : -1
                : activeSort.id == header
                ? activeSort.asc
                  ? b[header] - a[header]
                  : a[header] - b[header]
                : a[header] - b[header]
            ),
          })
        }
      >
        {activeSort.id == subHeader || activeSort.id == header
          ? activeSort.asc
            ? 'arrow_downward_icon'
            : 'arrow_upward_icon'
          : 'sort_icon'}
      </Icon>
    );
  };

  render() {
    const {
      contentHeadData,
      isLoading,
      headerData,
      sessionData,
      individualSessionData,
    } = this.state;
    return (
      <div className='contentHeadContainer'>
        <SaveChangesModal
          openModal={sessionData}
          closeModal={() => this.setState({ sessionData: null })}
          text={'Session Questions'}
          style={{ overflow: 'auto', width: '90vw', maxHeight: '90vh' }}
          displayData={
            <Table>
              <TableHead>
                <TableRow>
                  {sessionData &&
                    Object.keys(sessionData?.[0])?.map((header) => (
                      <TableCell>{this.toTitleCase(header)}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sessionData?.map((sessionDetails) => (
                  <TableRow>
                    {Object.keys(sessionData?.[0])?.map((header) =>
                      header == 'question' ? (
                        <TableCell>
                          <div>{sessionDetails[header].text}</div>
                          <img src={sessionDetails[header].image} />
                        </TableCell>
                      ) : (
                        <TableCell>{sessionDetails[header]}</TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        />
        <SaveChangesModal
          openModal={individualSessionData}
          closeModal={() => this.setState({ individualSessionData: null })}
          style={{ overflow: 'auto', width: '90vw' }}
          text={'Session Details'}
          displayData={
            <Table>
              <TableHead>
                <TableRow>
                  {individualSessionData &&
                    Object.keys(individualSessionData?.[0])?.map((header) =>
                      header == 'accuracy' ? (
                        <TableCell
                          colSpan={
                            Object.keys(contentHeadData[0][header]).length
                          }
                          rowSpan={2}
                        >
                          {this.toTitleCase(header)}
                        </TableCell>
                      ) : header == 'homework' || header == 'classwork' ? (
                        <TableCell
                          colSpan={
                            headerData[header]?.max_order *
                            headerData[header]?.columns.length
                          }
                        >
                          {this.toTitleCase(header)}
                        </TableCell>
                      ) : (
                        <TableCell rowSpan={3}>
                          <div className='headers'>
                            {this.sortIcon(header)}
                            {this.toTitleCase(header)}
                          </div>
                        </TableCell>
                      )
                    )}
                </TableRow>
                <TableRow>
                  {individualSessionData &&
                    Object.keys(individualSessionData[0]).map((header) =>
                      header == 'homework' || header == 'classwork'
                        ? [...Array(headerData[header]?.max_order)].map(
                            (_, i) => (
                              <TableCell
                                colSpan={headerData[header]?.columns.length}
                              >
                                {this.toTitleCase(header + ' ' + (i + 1))}
                              </TableCell>
                            )
                          )
                        : null
                    )}
                </TableRow>
                <TableRow>
                  {individualSessionData &&
                    Object.keys(individualSessionData[0]).map((header) =>
                      header == 'accuracy'
                        ? Object.keys(individualSessionData[0][header])?.map(
                            (subHeader) => (
                              <TableCell>
                                <div className='headers'>
                                  {this.sortIcon(header, subHeader)}
                                  {this.toTitleCase(subHeader)}
                                </div>
                              </TableCell>
                            )
                          )
                        : header == 'homework' || header == 'classwork'
                        ? [...Array(headerData[header]?.max_order)].map(
                            (_, i) =>
                              headerData[header]?.columns?.map((subHeader) => (
                                <TableCell>
                                  <div className='headers'>
                                    {this.sortIcon(header, subHeader)}
                                    {this.toTitleCase(subHeader)}
                                  </div>
                                </TableCell>
                              ))
                          )
                        : null
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {individualSessionData?.map((individualSessionDetails) => (
                  <TableRow>
                    {Object.keys(individualSessionData[0]).map(
                      (header, index) =>
                        header == 'homework' || header == 'classwork' ? (
                          [...Array(headerData[header]?.max_order)].map(
                            (_, i) =>
                              headerData[header]?.columns?.map((subHeader) => (
                                <TableCell>
                                  {individualSessionDetails?.[header]?.[i]?.[
                                    subHeader
                                  ] || '-'}
                                </TableCell>
                              ))
                          )
                        ) : header == 'accuracy' ? (
                          <>
                            {Object.keys(individualSessionData[0][header]).map(
                              (subHeader) => (
                                <TableCell>
                                  {individualSessionDetails[header][subHeader]}
                                </TableCell>
                              )
                            )}
                          </>
                        ) : (
                          <TableCell>
                            {header == 'below_par_students'
                              ? `${individualSessionDetails[header].percent}%(${individualSessionDetails[header].count})`
                              : individualSessionDetails[header]}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        />
        <div className='contentHeadHeaderContainer'>
          <div className='contentHeadHeader'>Content Head</div>
          <DateRangePickerModal fetchData={this.fetchContentHeadData} />
        </div>
        <SessionSelector
          setFilterData={this.setSessionData}
          filterList={['grade', 'package']}
          onFilterChange={() => this.setState({ contentHeadData: null })}
        />
        {contentHeadData?.length ? (
          <div className='tableContainer'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Object.keys(contentHeadData[0]).map((header, index) =>
                    this.hiddenColumns.includes(header) ? null : header ==
                      'accuracy' ? (
                      <TableCell
                        colSpan={Object.keys(contentHeadData[0][header]).length}
                        rowSpan={2}
                      >
                        {this.toTitleCase(header)}
                      </TableCell>
                    ) : header == 'homework' || header == 'classwork' ? (
                      <TableCell
                        colSpan={
                          headerData[header]?.max_order *
                          headerData[header]?.columns.length
                        }
                      >
                        {this.toTitleCase(header)}
                      </TableCell>
                    ) : (
                      <TableCell
                        rowSpan={3}
                        className={
                          index < 4 ? 'tableCellFixed tableHeaderFixed' : ''
                        }
                        style={{ left: index < 4 ? `${index * 6}vw` : 'none' }}
                      >
                        <div className='headers'>
                          {header == 'average_duration' ||
                          header == 'no_of_students'
                            ? this.sortIcon(header)
                            : header == 'below_par_students'
                            ? this.sortIcon(header, 'percent')
                            : null}
                          {this.toTitleCase(header)}
                        </div>
                      </TableCell>
                    )
                  )}
                </TableRow>
                <TableRow>
                  {Object.keys(contentHeadData[0]).map((header) =>
                    header == 'homework' || header == 'classwork'
                      ? [...Array(headerData[header]?.max_order)].map(
                          (_, i) => (
                            <TableCell
                              colSpan={headerData[header]?.columns.length}
                            >
                              {this.toTitleCase(header + ' ' + (i + 1))}
                            </TableCell>
                          )
                        )
                      : null
                  )}
                </TableRow>
                <TableRow>
                  {Object.keys(contentHeadData[0]).map((header) =>
                    header == 'accuracy'
                      ? Object.keys(contentHeadData[0]) &&
                        Object.keys(contentHeadData[0][header]).map(
                          (subHeader) => (
                            <TableCell>
                              <div className='headers'>
                                {this.sortIcon(header, subHeader)}
                                {this.toTitleCase(subHeader)}
                              </div>
                            </TableCell>
                          )
                        )
                      : header == 'homework' || header == 'classwork'
                      ? [...Array(headerData[header]?.max_order)].map((_, i) =>
                          headerData[header]?.columns?.map((subHeader) => (
                            <TableCell>
                              <div className='headers'>
                                {this.sortIcon(header, subHeader)}
                                {this.toTitleCase(subHeader)}
                              </div>
                            </TableCell>
                          ))
                        )
                      : null
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {contentHeadData.map((contentHead) => (
                  <TableRow>
                    {Object.keys(contentHeadData[0]).map((header, index) =>
                      this.hiddenColumns.includes(header) ? null : header ==
                          'homework' || header == 'classwork' ? (
                        [...Array(headerData[header]?.max_order)].map((_, i) =>
                          headerData[header]?.columns?.map((subHeader) => (
                            <TableCell
                              onClick={() => {
                                if (subHeader == 'qns')
                                  this.fetchSessionDetails(
                                    contentHead?.[header]?.[i]?.id
                                  );
                              }}
                              className={subHeader == 'qns' ? 'clickable' : ''}
                            >
                              {contentHead?.[header]?.[i]?.[subHeader] || '-'}
                            </TableCell>
                          ))
                        )
                      ) : header == 'accuracy' ? (
                        <>
                          {Object.keys(contentHeadData[0][header]).map(
                            (subHeader) => (
                              <TableCell>
                                {contentHead[header][subHeader]}
                              </TableCell>
                            )
                          )}
                        </>
                      ) : (
                        <TableCell
                          className={`${index < 4 ? 'tableCellFixed ' : ''}
                              ${
                                (header == 'open_queries' &&
                                  contentHead.query_ids?.length) ||
                                header == 'id'
                                  ? 'clickable'
                                  : ''
                              }`}
                          style={{
                            left: index < 4 ? `${index * 6}vw` : 'none',
                          }}
                          onClick={() => {
                            if (header == 'id')
                              this.fetchIndividualSessionData(contentHead.id);
                            if (
                              header == 'open_queries' &&
                              contentHead.query_ids?.length
                            )
                              window.open(
                                `/queries?ids=${contentHead.query_ids}`,
                                '_blank'
                              );
                          }}
                        >
                          {header.includes('date') ||
                          header.includes('schedule') ? (
                            new Date(contentHead[header]).toLocaleString()
                          ) : typeof contentHead[header] == 'boolean' ? (
                            contentHead[header] ? (
                              'Yes'
                            ) : (
                              'No'
                            )
                          ) : header.includes('link') ? (
                            <Button
                              color='primary'
                              variant='contained'
                              size='small'
                              style={{ fontSize: 12 }}
                              onClick={() =>
                                window.open(contentHead[header], '_blank')
                              }
                            >
                              View
                            </Button>
                          ) : header == 'below_par_students' ? (
                            `${contentHead[header].percent}%(${contentHead[header].count})`
                          ) : (
                            contentHead[header]
                          )}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : contentHeadData?.length == 0 ? (
          <div className='centerAligned'>No data</div>
        ) : isLoading ? (
          <CircularProgress className='centerAligned' />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  return {
    adminId: states?.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(contentHead));
