import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import { Edit, Delete, Close, ChevronRight } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import './style.scss';
import '../../Utils/util.scss';
import Col from '../../components/Elements/Flex/col';
import Row from '../../components/Elements/Flex/row';
import AddModuleModal from './AddModuleModal/AddModuleModal';
import LoaderErrorBox from '../../components/LoaderErrorBox/LoaderErrroBox';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';

class FlashCardsDashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageLoader: true,
      flashCardsTableLoader: true,
      apiError: '',
      flashCardsApiError: '',
      packages: {},
      classes: {},
      flashCards: [],
      showAddModule: false,
      selectedPackageId: '4',
      selectedClassId: '1',
      pageLoader: true,
      deleteModalData: { id: '', name: '' },
      snackbarData: { message: '', type: '' },
    };
  }

  deleteModule = (flag) => {
    const { adminId } = this.props;
    const { deleteModalData } = this.state;

    if (flag) {
      axios
        .post(
          `/v1/admin/${adminId}/flashcard/module/delete/${deleteModalData.id}/`
        )
        .then((response) => {
          if (response && response.status === 200) {
            this.fetchFlashCards();
            this.setState({
              snackbarData: {
                message: `Module "${deleteModalData.name}" Succesfully deleted!`,
                type: 'success',
              },
            });
          }
        })
        .catch((err) => {
          this.setState({
            snackbarData: {
              message: err.response.data.data.error[0]
                ? err.response.data.data.error[0]
                : 'Something went wrong! ',
              type: 'error',
            },
          });
        });
    }
    this.setState({ deleteModalData: { id: false, name: '' } });
  };

  updateSelectedFilter = (key, value) => {
    this.setState({ [key]: value });
  };

  hideSnackbar = () => {
    this.setState({ snackbarData: { message: '', type: '' } });
  };

  fetchPackagesAndGrades = () =>
    axios({
      method: 'get',
      url: `v1/teacher/${this.props.adminId}/package-classes/`,
    }).then((res) => {
      const { data } = res.data;
      if (data.error) {
        this.setState({
          apiError: data.error[0],
          pageLoader: false,
        });
      } else {
        this.setState({
          ...data,
          pageLoader: false,
        });
      }
    });

  fetchFlashCards = () => {
    const { adminId } = this.props;
    const { selectedClassId, selectedPackageId } = this.state;

    this.setState({ flashCardsTableLoader: true });

    axios({
      method: 'get',
      url: `v1/admin/${adminId}/flashcard/${selectedClassId}/${selectedPackageId}/classwiseflashcards/data/`,
    })
      .then((res) => {
        const { data } = res.data;

        if (!!data.error === false) {
          this.setState({
            flashCards: data,
            flashCardsTableLoader: false,
          });
        }
      })
      .catch((err) => {
        if (err?.response?.data?.data?.error) {
          this.setState({
            flashCardsTableLoader: false,
            apiError: err.response.data.data.error[0],
          });
        } else {
          this.setState({
            flashCardsTableLoader: false,
            apiError: 'Something went wrong!',
          });
        }
      });
  };

  async componentDidMount() {
    const { pid, gid } = this.props.router.location.query;

    await this.fetchPackagesAndGrades();
    if (pid && gid) {
      this.setState({ selectedPackageId: pid, selectedClassId: gid });
    } else {
      this.fetchFlashCards();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedPackageId, selectedClassId } = this.state;

    if (
      prevState.selectedPackageId !== selectedPackageId ||
      prevState.selectedClassId !== selectedClassId
    )
      this.fetchFlashCards();
  }

  render() {
    const {
      pageLoader,
      flashCardsTableLoader,
      apiError,
      flashCardsApiError,
      showAddModule,
      packages,
      classes,
      selectedPackageId,
      selectedClassId,
      flashCards,
      deleteModalData,
      snackbarData,
    } = this.state;
    const { router } = this.props;

    console.log(this.state);

    return (
      <Col className='flash-cards-intro'>
        {pageLoader ? (
          <LoaderErrorBox {...{ loader: true }} />
        ) : apiError ? (
          <LoaderErrorBox {...{ loader: false, errorMsg: apiError }} />
        ) : (
          <>
            {showAddModule !== false && (
              <AddModuleModal
                {...{
                  editExistingModule:
                    typeof showAddModule === 'number' ? showAddModule : false,
                  existingModuleData:
                    typeof showAddModule === 'number'
                      ? flashCards[showAddModule]
                      : {},
                  closeModal: () => {
                    this.setState({ showAddModule: false });
                  },
                  packageName: packages[selectedPackageId],
                  className: classes[selectedClassId],
                  selectedPackageId,
                  selectedClassId,
                  fetchFlashCards: this.fetchFlashCards,
                }}
              />
            )}
            {deleteModalData.id && (
              <ConfirmationModal
                onClickFunction={this.deleteModule}
                text={`Are you sure you want to delete Module "${deleteModalData.name}" ?`}
              />
            )}
            {snackbarData.message && (
              <Snackbar
                open={true}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000}
                action={
                  <IconButton onClick={this.hideSnackbar}>
                    <Close />
                  </IconButton>
                }
                onClose={this.hideSnackbar}
              >
                <Alert severity={snackbarData.type} onClose={this.hideSnackbar}>
                  {snackbarData.message}
                </Alert>
              </Snackbar>
            )}
            {/* package */}
            <Row className='package-parent-row'>
              {Object.keys(packages).map((packageId) => (
                <Row
                  className={`package-name-box ${
                    selectedPackageId === packageId ? 'selected' : ''
                  }`}
                  key={packageId}
                  onClick={() => {
                    this.setState({ selectedPackageId: packageId });
                    router.push(
                      `/interactive-flash-cards?pid=${packageId}&gid=${selectedClassId}`
                    );
                  }}
                >
                  {packages[packageId]}
                </Row>
              ))}
            </Row>
            {/* grade */}
            <Row className='grades-row'>
              <Row className='grades-list'>
                {Object.keys(classes).map((classId) => (
                  <Row
                    className={`grade-box ${
                      selectedClassId === classId ? 'selected' : ''
                    }`}
                    key={classId}
                    onClick={() => {
                      this.setState({ selectedClassId: classId });
                      router.push(
                        `/interactive-flash-cards?pid=${selectedPackageId}&gid=${classId}`
                      );
                    }}
                  >
                    {classes[classId]}
                  </Row>
                ))}
              </Row>
              <button
                className='add-module-button'
                onClick={() => this.setState({ showAddModule: true })}
              >
                + Create Module
              </button>
            </Row>
            <TableContainer className='flash-cards-table-container'>
              <Table className='flash-cards-table' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className='table-head-cell' size='small'>
                      S No
                    </TableCell>
                    <TableCell className='table-head-cell name' size='small'>
                      Name
                    </TableCell>
                    <TableCell
                      className='table-head-cell description'
                      size='small'
                    >
                      Description
                    </TableCell>
                    <TableCell className='table-head-cell' size='small'>
                      Total Cards
                    </TableCell>
                    <TableCell className='table-head-cell' size='small'>
                      Status
                    </TableCell>
                    <TableCell className='table-head-cell' size='small' />
                  </TableRow>
                </TableHead>
                {flashCardsTableLoader ? (
                  <LoaderErrorBox
                    {...{ loader: true, loaderSize: '2vw', textSize: '1.25vw' }}
                  />
                ) : flashCardsApiError ? (
                  <LoaderErrorBox
                    {...{
                      loader: false,
                      textSize: '1.25vw',
                      errorMsg: flashCardsApiError,
                    }}
                  />
                ) : (
                  <TableBody>
                    {flashCards.length !== 0 ? (
                      flashCards.map((flashCardsData, flashCardsIndex) => (
                        <TableRow
                          className='table-row'
                          key={flashCardsIndex}
                          hover
                          onClick={() =>
                            router.push(
                              `interactive-flash-cards/${flashCardsData.module_id}`
                            )
                          }
                        >
                          <TableCell
                            className='table-body-cell sl-no'
                            size='small'
                          >
                            {flashCardsIndex + 1}
                          </TableCell>
                          <TableCell
                            className='table-body-cell title'
                            size='small'
                          >
                            <Row className='justify-center align-center'>
                              <img src={flashCardsData.image} alt='thumbnail' />
                              <p>{flashCardsData.name}</p>
                              <ChevronRight className='chevron-right' />
                            </Row>
                          </TableCell>
                          <TableCell className='table-body-cell' size='small'>
                            {flashCardsData.description}
                          </TableCell>
                          <TableCell className='table-body-cell' size='small'>
                            {flashCardsData.module_length}
                          </TableCell>
                          <TableCell className='table-body-cell' size='small'>
                            <Row
                              className={`status ${
                                flashCardsData.status ? 'live' : 'not-live'
                              }`}
                            >
                              {flashCardsData.status ? 'Live' : 'Not Live'}
                            </Row>
                          </TableCell>
                          <TableCell className='table-body-cell' size='small'>
                            <Row className='row-action'>
                              <button
                                className='preview'
                                onClick={(event) => {
                                  event.stopPropagation();
                                  window.open(
                                    `${process.env.REACT_APP_FLASH_CARDS_URL}/${flashCardsData.module_id}?role=admin`
                                  );
                                }}
                              >
                                Preview
                              </button>
                              <button
                                className='circular-button'
                                onClick={(event) => {
                                  event.stopPropagation();
                                  this.setState({
                                    showAddModule: flashCardsIndex,
                                  });
                                }}
                              >
                                <Edit />
                              </button>
                              <button
                                className='circular-button delete'
                                onClick={(event) => {
                                  event.stopPropagation();
                                  this.setState({
                                    deleteModalData: {
                                      id: flashCardsData.module_id,
                                      name: flashCardsData.name,
                                    },
                                  });
                                }}
                              >
                                <Delete />
                              </button>
                            </Row>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <LoaderErrorBox
                        {...{ loader: false, normalMsg: 'No Data Found!' }}
                      />
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </>
        )}
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({ adminId: state.user.user_id });

export default withRouter(connect(mapStateToProps)(FlashCardsDashboard));
