export const COLOR_ARRAY = [
  "#1BB55C",
  "#BF5AF2",
  "#FFCC00",
  "#006AFF",
  "#FF9F0A",
  "#DD234C",
  "#FFF",
  "#0D0D0D",
];

export const FONT_ARRAY = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
