import React from 'react';
import _equal from 'lodash.isequal';
// import { motion } from 'framer-motion';
import Row from '../../../Elements/Flex/row';
// import { NUM_TO_ALPHA } from '../../../../Util/util';
import './style.scss';

class MultipleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options,
      selected: props.selected || {},
      answer: props.answer,
      loader: props.loader,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loader !== this.props.loader) {
      this.setState({ loader: this.props.loader });
    }

    if (!_equal(prevProps.options, this.props.options)) {
      this.setState({ options: this.props.options });
    }

    if (!_equal(prevProps.answer, this.props.answer)) {
      this.setState({ answer: this.props.answer });
    }
  }

  handleChange(i) {
    let { selected } = this.state;

    if (selected[i]) {
      selected[i] = !selected[i];
    } else {
      selected[i] = true;
    }
    this.setState({ selected }, () => {
      this.props.changeSelected(this.state.selected);
    });
  }

  componentDidMount() {
    let { options } = this.state;
    options.forEach((o, i) => {
      let optionElem = document.getElementById('option-' + i);
      if (optionElem.scrollHeight > optionElem.clientHeight) {
        optionElem.style.fontSize = '1.6vw';
      }
    });
  }

  render() {
    let { options, selected, answer, loader } = this.state;
    return (
      <Row className='multiple-select'>
        {options.map((x, i) => (
          <div
            id={'option-' + i}
            className={selected[i] ? 'option selected' : 'option'}
            style={{
              background: loader
                ? selected[i]
                  ? answer.includes(x.id)
                    ? 'rgb(86,178,102)'
                    : 'rgb(204,56,80)'
                  : answer.includes(x.id)
                  ? 'rgb(86,178,102)'
                  : 'rgba(230,179,97, 0.2)'
                : 'rgb(230,179,97)',
            }}
            onClick={this.handleChange.bind(this, i)}
            key={i}
          >
            <div className='index'>{NUM_TO_ALPHA[i + 1]}</div>
            {selected[i] && (
              <div
                className='tick-mark'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.25 }}
              >
                <svg
                  width='38'
                  height='33'
                  viewBox='0 0 38 33'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    x='0.5'
                    y='0.5'
                    width='37'
                    height='32'
                    rx='5.5'
                    fill='#A03DD2'
                    stroke='#A03DD2'
                  />
                  <path
                    d='M15.1432 20.923C14.3672 21.6761 13.1332 21.6761 12.3573 20.923L8.7623 17.4337C8.22638 16.9136 7.37408 16.9136 6.83816 17.4337C6.27913 17.9763 6.27913 18.8736 6.83816 19.4162L12.3573 24.773C13.1332 25.5261 14.3672 25.5261 15.1432 24.773L29.729 10.6162C30.288 10.0736 30.288 9.17631 29.729 8.63373C29.193 8.11357 28.3407 8.11357 27.8048 8.63373L15.1432 20.923Z'
                    fill='white'
                  />
                </svg>
              </div>
            )}
            <div className={x.image && 'full-height'}>
              {x.image ? (
                <img
                  alt='option'
                  src={x.image}
                  style={{ height: '100%', objectFit: 'contain' }}
                />
              ) : (
                x.text
              )}
            </div>
          </div>
        ))}
      </Row>
    );
  }
}

export default MultipleSelect;
