import React, { useEffect, useState, Fragment, useRef } from 'react';
import {
  makeStyles,
  Select,
  MenuItem,
  Icon,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Table from '../../components/LQDataTables/demo';
import SaveChangesModal from '../../components/SaveChangesModal';
import axios from 'axios';
import { connect } from 'react-redux';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },

  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});

let tableRef = null;
const ref = React.createRef();

function AdminTable(props) {
  const [changeAdminRoleModal, setChangeAdminRoleModal] = useState(false);
  const [deleteAdmin, setDeleteAdmin] = useState(null);
  const ref = React.createRef();
  function adminRolesDropDown() {
    return (
      <Select
        value={changeAdminRoleModal?.role}
        onChange={(e) =>
          setChangeAdminRoleModal({
            ...changeAdminRoleModal,
            role: e.target.value,
          })
        }
        variant='outlined'
        style={{ width: '50%', alignSelf: 'center' }}
        inputProps={{
          name: 'user Role',
        }}
      >
        {changeAdminRoleModal?.admin_roles?.map((adminRolesList) => (
          <MenuItem value={adminRolesList}>{adminRolesList}</MenuItem>
        ))}
      </Select>
    );
  }

  function onUpdateRole() {
    const admin_id = props?.user?.user_id;
    axios
      .post(`/v1/admin/${admin_id}/admins/roles/`, {
        action: 'update_role',
        user_id: changeAdminRoleModal.user_id,
        role: changeAdminRoleModal.role,
      })
      .then((res) => {
        if (res && res.status == 200) {
          const message = res?.data?.data?.message;
          props.enqueueSnackbar(message || 'Role updated Successfully', {
            variant: 'success',
          });
          setChangeAdminRoleModal(false);
          tableRef && tableRef.refreshTable();
        }
      })
      .catch((err) => {
        props.enqueueSnackbar(
          err?.response?.data?.data?.error || 'Something went wrong',
          { variant: 'error' }
        );
      });
  }
  function onDeleteAdminUser() {
    const admin_id = props?.user?.user_id;
    axios
      .post(`/v1/admin/${admin_id}/admin/${deleteAdmin}/disable/`)
      .then((res) => {
        if (res && res.status == 200) {
          const message = res?.data?.data?.message;
          props.enqueueSnackbar(message || 'Role updated Successfully', {
            variant: 'success',
          });
          setDeleteAdmin(null);
          tableRef && tableRef.refreshTable();
        }
      })
      .catch((err) => {
        props.enqueueSnackbar(
          err?.response?.data?.data?.error || 'Something went wrong',
          { variant: 'error' }
        );
      });
  }
  function formatTable(tableData, params, admin_roles) {
    const newData =
      tableData &&
      tableData.map((entry, index) => {
        const { id, role } = entry;

        const newEntry = { ...entry };
        const updatedEntry = {
          edit: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <IconButton
                onClick={() =>
                  setChangeAdminRoleModal({
                    admin_roles: admin_roles,
                    role: role,
                    user_id: id,
                  })
                }
              >
                <Tooltip title='Edit'>
                  <Icon className='iconButton'>edit</Icon>
                </Tooltip>
              </IconButton>
              <IconButton onClick={() => setDeleteAdmin(id)}>
                <Tooltip title='Disable Admin'>
                  <Icon className='iconButton'>delete</Icon>
                </Tooltip>
              </IconButton>
            </div>
          ),
          ...newEntry,
        };
        return Object.keys(updatedEntry).map((subEntry) =>
          updatedEntry[subEntry]
            ? '' || updatedEntry[subEntry]
            : updatedEntry[subEntry] == 0
            ? '0'
            : '-'
        );
      });
    return newData;
  }

  return (
    <div>
      <Table
        onRef={(ref) => {
          tableRef = ref;
        }}
        firstCol='Edit'
        createNew='/admin/create'
        formatTable={formatTable}
        sortList={[]}
        sortDate={[]}
        search={props.location && props.location.query}
        tableName='Admin Table'
        title='Admin Table'
        scrollType='stacked'
        apiUrl={'admins/list/'}
      />
      <SaveChangesModal
        openModal={changeAdminRoleModal}
        closeModal={() => setChangeAdminRoleModal(null)}
        displayData={adminRolesDropDown()}
        text={'Update user role'}
        onSave={() => onUpdateRole()}
        style={{ width: '40vw' }}
      />
      <SaveChangesModal
        openModal={deleteAdmin}
        closeModal={() => setDeleteAdmin(null)}
        text={'Do you want to disable admin user?'}
        onSave={() => onDeleteAdminUser()}
        style={{ width: '40vw' }}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withSnackbar(connect(mapStateToProps)(AdminTable));
