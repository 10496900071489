import React, { Component } from 'react';
import { PageNotFoundContainer } from './style';

function PageNotFound(props) {
    return (
        <PageNotFoundContainer>
            <div>
            <h1>404</h1>
            <h3>PAGE NOT FOUND</h3>
            <h5>The page you are loooking for was moved, removed, renamed or might never existed.</h5>
            </div>
        </PageNotFoundContainer>
    );
}

export default PageNotFound;
