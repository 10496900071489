import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
} from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, Modal, List, Button, TextField } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import { connect } from 'react-redux';
import SingleSelect from '../AutoSuggestSelectBox/SingleSelect';
import { Field } from 'formik';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  list: {
    display: 'flex',
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },
  Main: {
    width: '95%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 30,
    top: 0,
    alignItems: 'center',
  },
  Modal: {
    background: '#fff',
    borderRadius: 5,
    width: '70vw',
    height:'fit-content',
    maxHeight: '80vh',
    margin: '10vh auto',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    overflowY:'auto'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 600,
  },
  button: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },

  root: {
    paddingTop: 10,
    paddingBottom: 20,
    '& > *': {
      width: '70%',
      margin: 'auto',
      display: 'flex',
    },
  },
});

let tableRef = null;

function CreateSessionAbsentStudent(props) {
  const ref = React.createRef();
  const classes = userStyles();
  const [classList, setClassList] = useState(null);
  const [classId, setClassId] = useState('');
  const [sessionList, setSessionList] = useState(null);
  const [sessionId, setSessionId] = useState('');
  const [packageId, setPackageId] = useState(4);
  const [packageIdList, setPackageIdList] = useState(null);
  const [sessionActivityId, setSessionActivityID] = useState(null);
  const [activityList, setActivityList] = useState(null);
  const [questionOrder, setQuestionOrder] = useState(null);
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    getPackageData(), getClassDetail();
  }, []);

  function getClassDetail() {
    axios.get('v1/classes/').then((res) => {
      if (res && res.status == 200) {
        let data = res && res.data && res.data.data;
        data.map(
          (ele, index) => (data[index] = { label: ele.name, value: ele.id })
        );
        setClassList(data);
      }
    });
  }

  function getPackageData() {
    const admin_id = props && props.user.user_id;
    axios.get(`/v1/admin/${admin_id}/assign-package/`).then((res) => {
      if (res && res.status == 200) {
        let temp = [];
        let format = {
          label: '',
          value: '',
        };
        const data = res && res.data && res.data.data;
        data &&
          data.map((packageData) => {
            format = {
              value: packageData.id,
              label: packageData.id + '. ' + packageData.name,
            };
            format == undefined ? null : temp.push(format);
          });
        setPackageIdList(temp);
      }
    });
  }

  function getSessionDetail(class_id) {
    const admin_id = props.user && props.user.user_id;
    axios
      .get(
        `v1/admin/${admin_id}/content/${class_id}/sessions-list/?package_id=${packageId}`
      )
      .then((res) => {
        if (res && res.status == 200) {
          let data = res && res.data && res.data.data;
          data.map(
            (ele, index) =>
              (data[index] = {
                label: ele.order + '. ' + ele.name,
                value: ele.id,
              })
          );
          setSessionList(res.data.data);
        }
      });
  }

  function postLinkQuestion() {
    const admin_id = props && props.user && props.user.user_id;
    axios
      .post(`/v1/admin/${admin_id}/content/session/create-question-mapping/`, {
        question_id: props.questionId,
        session_activity_id: parseInt(sessionActivityId),
        question_number: questionOrder ? questionOrder : null,
        ...(duration && { duration: duration }),
      })
      .then((res) => {
        if (res && res.status && res.status == 200) {
          props.enqueueSnackbar(
            (res && res.data && res.data.data && res.data.data.message) ||
              'Question mapped successfully',
            { variant: 'success' }
          );
          setSessionActivityID(null);
          setQuestionOrder(null);
          props.onSuccess();
        }
      })
      .catch((error) => {
        props.enqueueSnackbar(
          error.response &&
            error.response.data &&
            error.response.data.data.error &&
            error.response.data.data.error &&
            error.response.data.data.error[0],
          { variant: 'error' }
        );
      });
  }
  function getActivityDetail(session_id) {
    const admin_id = props.user && props.user.user_id;
    axios
      .get(
        `v1/admin/${admin_id}/content/session/${session_id}/activities-list/?details=1`
      )
      .then((res) => {
        if (res && res.status == 200) {
          if (res.data?.data?.activities) {
            let data = res && res.data && res.data.data.activities;
            data.map(
              (ele, index) =>
                (data[index] = {
                  label: ele.activity_name,
                  value: ele.id,
                  type: ele.type,
                })
            );
            setActivityList(data);
          }
        }
      })
      .catch((error) => {
        const errors =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.error;
        errors &&
          errors[0] &&
          errors.map((err) => props.enqueueSnackbar(err, { variant: 'error' }));
        console.log(error, error.response);
      });
  }
  const selectedActivityType = activityList?.filter(
    (activityData) => activityData.value == sessionActivityId
  )?.[0]?.type;
  return (
    <div className={classes.Main}>
      <Modal
        open={props.createMapping}
        onClose={() => {
          props.onClose();
        }}
      >
        <div className={classes.Modal}>
          <div className={classes.header}>
            <div>Link Question</div>
            <IconButton onClick={props.onClose}>
              <Tooltip title='Close Modal'>
                <Icon className='iconButton'>close</Icon>
              </Tooltip>
            </IconButton>
          </div>

          <List style={{ zIndex: 10 }}>
            <List item>
              <SingleSelect
                name='Package'
                onChange={(event) => {
                  setClassId(null),
                    setSessionId(null),
                    setPackageId(event.value),
                    classId && getSessionDetail(event.value);
                }}
                selectList={packageIdList}
                style={{ cursor: 'pointer' }}
                defaultValue={[{ label: 'LogiMath', value: 4 }]}
                isDisabled={false}
              />
            </List>
            <List item>
              <SingleSelect
                name='Class'
                onChange={(event) => {
                  setClassId(event.value),
                    setSessionId(null),
                    getSessionDetail(event.value);
                }}
                selectList={classList}
                value={
                  classId && classList && classList[classList.includes(classId)]
                }
                style={{ cursor: 'pointer' }}
                isDisabled={!packageId}
              />
            </List>
            <List item>
              <SingleSelect
                name='Session'
                onChange={(event) => {
                  setSessionId(event.value),
                    getActivityDetail(event.value),
                    setSessionActivityID(null);
                }}
                selectList={sessionList}
                value={
                  sessionId &&
                  sessionList &&
                  sessionList[sessionList.includes(sessionId)]
                }
                style={{ cursor: 'pointer' }}
                isDisabled={!classId}
              />
            </List>
            <List item>
              <SingleSelect
                name='Activity'
                onChange={(event) => {
                  setSessionActivityID(event.value);
                }}
                selectList={activityList}
                value={
                  sessionActivityId &&
                  activityList &&
                  activityList[activityList.includes(sessionActivityId)]
                }
                style={{ cursor: 'pointer' }}
                isDisabled={!sessionId}
              />
            </List>
          </List>
          <Field
            name='question_order'
            type='number'
            component={TextField}
            label='Question Order'
            variant='outlined'
            placeholder='Enter Question Order'
            onChange={(event) => setQuestionOrder(event.target.value)}
          />
          {selectedActivityType == 'activity' ||
          selectedActivityType == 'assessment' ? (
            <Field
              name='duration'
              type='number'
              component={TextField}
              label='Duration'
              variant='outlined'
              placeholder='Enter Duration'
              onChange={(event) => setDuration(event.target.value)}
            />
          ) : null}
          <div style={{ textAlign: 'center' }}>
            Empty Question Order field will add the question in the end the list
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              marginTop: 10,
            }}
          >
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                props.onClose(),
                  setSessionActivityID(null),
                  setQuestionOrder(null);
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={
                !sessionActivityId ||
                !(selectedActivityType == 'activity' ||
                selectedActivityType == 'assessment'
                  ? duration
                  : true)
              }
              onClick={() => {
                postLinkQuestion();
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withSnackbar(
  connect(mapStateToProps)(CreateSessionAbsentStudent)
);
