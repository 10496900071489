import React from 'react';
import { Link } from 'react-router';
import { Button, Icon, IconButton, Tooltip } from '@material-ui/core';
import Table from '../../components/LQDataTables/demo';
import moment from 'moment';
import { withSnackbar } from 'notistack';

function DoubtSessionTable(props) {
  function formatTable(tableData) {
    const newData = tableData?.map((entry, index) => {
      const { zoom_link, students_info } = entry;
      const newEntry = { ...entry };

      newEntry.zoom_link = zoom_link && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Link
            to={{
              pathname: zoom_link,
            }}
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            <Button
              color='primary'
              variant='contained'
              size='small'
              style={{ fontSize: 12 }}
            >
              View
            </Button>
          </Link>
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(zoom_link);
              props.enqueueSnackbar('Zoom Link Copied to Clipboard', {
                variant: 'info',
              });
            }}
          >
            <Tooltip title='Copy Link'>
              <Icon className='iconButton'>content_copy_icon</Icon>
            </Tooltip>
          </IconButton>
        </div>
      );

      newEntry.students_info = (
        <div>
          {students_info?.map((studentDetails, index) => (
            <Link
              to={{
                pathname: '/student-session-details',
                search: `?student_id=${studentDetails.student_id}`,
              }}
              target='_blank'
              style={{ textDecoration: 'none' }}
            >
              <div>
                {studentDetails.name}({studentDetails.phone})
              </div>
            </Link>
          ))}
        </div>
      );
      const updatedEntry = {
        ...newEntry,
      };
      return Object.keys(updatedEntry).map((subEntry) =>
        updatedEntry[subEntry]
          ? '' || updatedEntry[subEntry]
          : updatedEntry[subEntry] == 0
          ? '0'
          : '-'
      );
    });
    return newData;
  }

  return (
    <div>
      <Table
        headerFilterList={[]}
        formatTable={formatTable}
        sortList={[]}
        sortDate={[]}
        search={props.location && props.location.query}
        tableName='Doubt Session Table'
        title='Doubt Session Table'
        scrollType='stacked'
        dateFilter={'date'}
        defaultParams={{ date: moment(new Date()).format('yyyy-MM-DD') }}
        apiUrl={'doubt/sessions/'}
      />
    </div>
  );
}

export default withSnackbar(DoubtSessionTable);
