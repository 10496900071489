/**
 * Created by sumantaparida on 9/1/17.
 */
import { css } from 'styled-components';

export default {
	input: css`
		border-radius: 4px;
		background-color: #ffffff;
		border: solid 1px #dfe3e9;
		color: #354052;
		width: 100%;
		padding: 13px;
		font-size: 14px;
		&::-webkit-input-placeholder {
			color: rgba(0, 0, 0, 0.34);
		}
		&::-moz-placeholder {
			color: rgba(0, 0, 0, 0.34);
		}
		&:-ms-input-placeholder {
			color: rgba(0, 0, 0, 0.34);
		}
		&:-moz-placeholder {
			color: rgba(0, 0, 0, 0.34);
		}
		&:focus {
			outline: none;
		}
	`,
	button: css`
		-webkit-appearance: button;
		cursor: pointer;
		margin: 0px;
		text-align: center;
		font-size: 14px;
		border-radius: 4px;
		border: solid 1px #dfe3e9;
		background-color: #ffffff;
	`,
	flex: css`
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
	`,
	flexcenter: css`
		align-items: center;
		justify-content: center;
	`,
	flexstretch: css`align-items: stretch;`,
	columnCenterStretch: css`
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
	`,
	columnCenterStart: css`
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	`,
	columnCenterEnd: css`
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
	`,
	columnCenterCenter: css`
		flex-direction: column;
		align-items: center;
		justify-content: center;
	`,
	rowCenterStretch: css`
		flex-direction: row;
		align-items: stretch;
		justify-content: center;
	`,
	rowCenterStart: css`
		flex-direction: row;
		align-items: flex-start;
		justify-content: center;
	`,
	rowCenterEnd: css`
		flex-direction: row;
		align-items: flex-end;
		justify-content: center;
	`,
	rowCenterCenter: css`
		flex-direction: row;
		align-items: center;
		justify-content: center;
	`,
	// Animation
	transition: css`
		-webkit-transition: all 1s ease-in-out;
		-moz-transition: all 1s ease-in-out;
		-o-transition: all 1s ease-in-out;
		transition: all 1s ease-in-out;
	`,
	// Color
	green1: css`#47c998`,
	green2: css`#0ab775`,
	green3: css`#52bad5`,
	green4: css`#18c37f`,
	green5: css`#00ae68`,
	green6: css`#afefff`,
	green7: css`#00dbab`,
	white: css`#ffffff`,
	gray: css`#5d5c5d`,
	gray2: css`#7f8fa4`,
	gray3: css`#d8d8d8`,
	gray4: css`#919191`,
	gray5: css`#354052`,
	gray6: css`#b1b1b1`,
	gray7: css`#f9f9f9`,
	gray8: css`#F7F7F7`,
	gray9: css`#eeeeee`,
	gray10: css`#939393`,
	black: css`#000000`,
	black2: css`#253140`,
	red: css`#eb6b56`,
	yellow: css`#f8bc3d`,
	yellow2: css`#d88e19`,
	brdrGreyDark1: css`#dfe3e9`,
	lightBlue: css`#eff3f6`,
	headingColor: css`#6a6f71`,
	purpleBlue: css`#8A76B7`,
	large: css`1147px`,
	Xlarge: css`1480px`,
	medium: css`990px`,
	small: css`600px`,
	logicodeGreen: css`#27debf`,
};
