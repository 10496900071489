import React from 'react';
import { makeStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Table from '../../components/LQDataTables/demo';
import { connect } from 'react-redux';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },

  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});

let tableRef = null;

function CancelledOrderTable(props) {
  const classes = userStyles();

  function handleCallBack() {
    tableRef && tableRef.refreshTable();
  }

  function formatTable(tableData) {
    const newData =
      tableData &&
      tableData.map((entry, index) => {
        const { id, group_details } = entry;        
        const newEntry = { ...entry };        
        newEntry.group_details = group_details ? <div>{
          Object.entries(group_details).map(([key, value])=><div>{key?.replaceAll("_", " ")}: {value}</div>)
          }</div> : '-'
        const updatedEntry = {
          ...newEntry,
        };
        return Object.keys(updatedEntry).map((subEntry) =>
          updatedEntry[subEntry]
            ? '' || updatedEntry[subEntry]
            : updatedEntry[subEntry] == 0
            ? '0'
            : '-'
        );
      });
    return newData;
  }

  return (
    <div>
      <Table
        onRef={(ref) => {
          tableRef = ref;
        }}
        headerFilterList={HEADER_FILTER_LIST}
        formatTable={formatTable}
        sortList={SORT_LIST}
        sortDate={[]}
        search={props.location && props.location.query}
        tableName='Cancelled Order Table'
        title='Cancelled Order Table'
        scrollType='stacked'
        apiUrl={'orders/cancelled/'}
        hideColumn={HIDE_COLUMN}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    adminId: state?.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(CancelledOrderTable));

const HEADER_FILTER_LIST = [];
const SORT_LIST = [];
const HIDE_COLUMN = [];
