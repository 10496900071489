import styled from 'styled-components';
import base from '../../styles/base';

const FullPageLoaderContainer = styled.div`
    display: ${props => (props.show ? 'block' : 'none')};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    overflow: hidden;
    z-index: 20;
    .container {
        margin: auto;
        width: 50px;
        @media (max-width: ${base.small}) {
            padding-top: 0px;
            padding-left: 25px;
        }
    }
    .loaderText {
        text-align: center;
        font-weight: 600;
        color: #5a5555;
        padding-left: 50px;
    }
    .aligningImage {
        padding-top: 50px;
        padding-left: 30px;
    }

    .power {
        margin-top: 250px;
        margin-left: -92px;
    }

    #on {
        transform: translate(50%, 50%) scale(0);
        opacity: 0;
    }

    .active #on {
        opacity: 1;
        transform: translate(0) scale(1);
        transition: all 0.14s ease-in;
    }

    .fancy-bulb {
        position: absolute;
    }

    .streaks,
    .streaks:after,
    .streaks:before {
        position: absolute;
        background: #ffdb55;
        border-radius: 5.5px;
        height: 3px;
    }

    .streaks:after,
    .streaks:before {
        content: '';
        display: block;
    }

    .streaks:before {
        bottom: 65px;
    }

    .streaks:after {
        top: 65px;
    }

    .left-streaks {
        right: 55px;
        top: 0px;
    }

    .active .left-streaks {
        animation: move-left 0.5s ease-out, width-to-zero 0.5s ease-out;
        animation-delay: 0.4s;
        animation-iteration-count: infinite;
    }

    .left-streaks:before,
    .left-streaks:after {
        left: 15px;
    }

    .active .left-streaks:before {
        animation: width-to-zero 0.5s ease-out, move-up 0.5s ease-out;
        animation-delay: 0.4s;
        animation-iteration-count: infinite;
    }

    .active .left-streaks:after {
        animation: width-to-zero 0.5s ease-out, move-down 0.5s ease-out;
        animation-delay: 0.4s;
        animation-iteration-count: infinite;
    }

    .right-streaks {
        left: 55px;
        top: 0px;
    }

    .active .right-streaks {
        animation: move-right 0.5s ease-out, width-to-zero 0.5s ease-out;
        animation-delay: 0.4s;
        animation-iteration-count: infinite;
    }

    .right-streaks:before,
    .right-streaks:after {
        right: 5px;
    }

    .active .right-streaks:before {
        animation: width-to-zero 0.5s ease-out, move-up 0.5s ease-out;
        animation-delay: 0.4s;
        animation-iteration-count: infinite;
    }

    .active .right-streaks:after {
        animation: width-to-zero 0.5s ease-out, move-down 0.5s ease-out;
        animation-delay: 0.4s;
        animation-iteration-count: infinite;
    }

    .left-streaks:before,
    .right-streaks:after {
        transform: rotate(34deg);
    }

    .left-streaks:after,
    .right-streaks:before {
        transform: rotate(-34deg);
    }

    @keyframes move-left {
        0% {
            transform: none;
        }
        65% {
            transform: translateX(-80px);
        }
        100% {
            transform: translateX(-80px);
        }
    }
    @keyframes move-right {
        0% {
            transform: none;
        }
        65% {
            transform: translateX(80px);
        }
        100% {
            transform: translateX(80px);
        }
    }
    @keyframes width-to-zero {
        0% {
            width: 30px;
        }
        100% {
            width: 11px;
        }
    }
    @keyframes move-up {
        100% {
            bottom: 100.75px;
        }
    }
    @keyframes move-down {
        100% {
            top: 100.75px;
        }
    }
`;
export { FullPageLoaderContainer };
