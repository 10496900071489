import React, { Component } from 'react';
import SessionSelector from '../SessionSelector';
import SaveChangesModal from '../SaveChangesModal';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  CircularProgress,
  IconButton,
  Tooltip,
  Button,
} from '@material-ui/core';
import { TransferWithinAStation } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import queryString from 'query-string';
import axios from 'axios';
import { Link } from 'react-router';
import './style.scss';

class GroupMisfits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupMisfitData: null,
      groupMisfitFilteredData: {
        filter: null,
        data: null,
      },
      classId: null,
      packageId: null,
      isLoading: false,
      switchGroupStudentDetails: false,
      suggestedGroupData: false,
    };
    this.backgroundColors = {
      red: 'lightcoral',
      green: 'lightgreen',
    };
  }

  moveStudentGroup = () => {
    const { switchGroupStudentDetails } = this.state;
    const { student_id, switchTo } = switchGroupStudentDetails;
    axios
      .post(`v1/admin/${this.props.adminId}/group/paid/misfits/`, {
        student_id,
        group_id: switchTo?.group_id,
      })
      .then((response) => {
        if (response?.data?.data) {
          this.setState(
            {
              suggestedGroupData: null,
              switchGroupStudentDetails: null,
            },
            () => this.fetchGroupMisfitData()
          );
          this.props.enqueueSnackbar(
            response.data.data?.message || 'Updated Successfully',
            { variant: 'success' }
          );
        }
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error?.response?.data?.data?.error || 'Something Went Wrong',
          {
            variant: 'error',
          }
        );
      });
  };

  fetchGroupMisfitData = (mappingId) => {
    const { classId, packageId } = this.state;
    if (classId || packageId) {
      if (!mappingId) this.setState({ isLoading: true });
      const urlParams = `?${queryString.stringify({
        ...(classId && { class_id: classId }),
        ...(packageId && { package_id: packageId }),
        ...(mappingId && { mapping_id: mappingId }),
      })}`;
      axios
        .get(`v1/admin/${this.props.adminId}/group/paid/misfits/${urlParams}`)
        .then((response) => {
          if (response?.data?.data) {
            const { data } = response.data;
            this.setState(
              mappingId
                ? { suggestedGroupData: data }
                : {
                    groupMisfitData: data,
                    groupMisfitFilteredData: { filter: 'all', data: data },
                    isLoading: false,
                  }
            );
          }
        })
        .catch((error) => {
          this.props.enqueueSnackbar(error?.response?.data?.data?.error, {
            variant: 'error',
          });
        });
    }
  };

  setFilter = (newAlignment) => {
    const { groupMisfitData } = this.state;
    let filteredData = groupMisfitData;
    if (newAlignment != 'all')
      filteredData = groupMisfitData.filter((groupMisfitDetails) =>
        groupMisfitDetails?.students_data.some(
          (studentData) => studentData.flag == newAlignment
        )
      );

    this.setState({
      groupMisfitFilteredData: { filter: newAlignment, data: filteredData },
    });
  };

  setSessionData = (packageId, classId) => {
    this.setState(
      { packageId: packageId || null, classId: classId || null },
      () => this.fetchGroupMisfitData()
    );
  };

  renderGroupTable = (isSuggestionTable) => {
    const {
      isLoading,
      groupMisfitFilteredData,
      suggestedGroupData,
      switchGroupStudentDetails,
    } = this.state;
    const groupTableData = isSuggestionTable
      ? suggestedGroupData
      : groupMisfitFilteredData?.data;
    return (
      <div className='group-misfit-center'>
        {isLoading ? (
          <CircularProgress />
        ) : groupTableData?.length ? (
          <div
            className={`group-misfit-inner-container ${
              !isSuggestionTable && 'width-95'
            }`}
          >
            {isSuggestionTable ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={7}>User Details</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>User ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone No</TableCell>
                    <TableCell>Accuracy</TableCell>
                    <TableCell>Sessions Completed</TableCell>
                    <TableCell>Teacher</TableCell>
                    <TableCell>Batch Timing</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {switchGroupStudentDetails.student_id}
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: '/student-session-details',
                          search: `?student_id=${switchGroupStudentDetails.student_id}`,
                        }}
                        target='_blank'
                        style={{ textDecoration: 'none' }}
                      >
                        {switchGroupStudentDetails.name}
                      </Link>
                    </TableCell>
                    <TableCell>{switchGroupStudentDetails.phone}</TableCell>
                    <TableCell>{switchGroupStudentDetails.accuracy}</TableCell>
                    <TableCell>
                      {switchGroupStudentDetails.classes_completed}
                    </TableCell>
                    <TableCell>{switchGroupStudentDetails.teacher}</TableCell>
                    <TableCell>
                      {switchGroupStudentDetails.batch_timings}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : null}
            {groupTableData?.map((groupMisfitDetails, index) => (
              <Table className='group-table'>
                <TableHead>
                  <TableRow>
                    <TableCell className='no-bottom-border' rowSpan={2} />
                    <TableCell>
                      <Link
                        to={{
                          pathname: `/group-class/paid`,
                          search: `?id=${groupMisfitDetails.group_id}`,
                        }}
                        target='_blank'
                        style={{ textDecoration: 'none' }}
                      >
                        {groupMisfitDetails.group_name}
                      </Link>
                    </TableCell>
                    {groupMisfitDetails?.students_data?.map((studentData) => (
                      <TableCell
                        style={
                          studentData.flag && {
                            background: this.backgroundColors[studentData.flag],
                          }
                        }
                      >
                        <Link
                          to={{
                            pathname: '/student-session-details',
                            search: `?student_id=${studentData.student_id}`,
                          }}
                          target='_blank'
                          style={{ textDecoration: 'none' }}
                        >
                          {studentData.name}
                        </Link>
                        {!isSuggestionTable && studentData.flag ? (
                          <Tooltip title='View group suggestions to move'>
                            <IconButton
                              onClick={() =>
                                this.setState(
                                  {
                                    switchGroupStudentDetails: {
                                      ...studentData,
                                      median_accuracy:
                                        groupMisfitDetails.median_accuracy,
                                      classes_completed:
                                        groupMisfitDetails.classes_completed,
                                      batch_timings:
                                        groupMisfitDetails.batch_timings
                                          ?.map(
                                            (batchTiming) =>
                                              `${batchTiming.day} ${batchTiming.time}`
                                          )
                                          .join(', '),
                                      teacher: groupMisfitDetails.teacher,
                                    },
                                  },
                                  () =>
                                    this.fetchGroupMisfitData(
                                      studentData.mapping_id
                                    )
                                )
                              }
                            >
                              <TransferWithinAStation />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </TableCell>
                    ))}
                    <TableCell>Group Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className='no-bottom-border vertical-align-initial'
                      rowSpan={3}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell>Phone No.</TableCell>
                    {groupMisfitDetails?.students_data?.map((studentData) => (
                      <TableCell
                        style={
                          studentData.flag && {
                            background: this.backgroundColors[studentData.flag],
                          }
                        }
                      >
                        {studentData.phone || '-'}
                      </TableCell>
                    ))}
                    <TableCell rowSpan={3}>
                      <div className='group-details-container'>
                        <div>Teacher : {groupMisfitDetails.teacher}</div>
                        <div>
                          Batch Timing :
                          {groupMisfitDetails.batch_timings
                            ?.map(
                              (batchTiming) =>
                                `${batchTiming.day} ${batchTiming.time}`
                            )
                            .join(', ')}
                        </div>
                        <div>
                          Sessions Completed :{' '}
                          {groupMisfitDetails.classes_completed}
                        </div>
                        <div>
                          Median Accuracy : {groupMisfitDetails.median_accuracy}
                        </div>
                        {isSuggestionTable ? (
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() =>
                              this.setState({
                                switchGroupStudentDetails: {
                                  ...switchGroupStudentDetails,
                                  switchTo: {
                                    group_id: groupMisfitDetails.group_id,
                                    group_name: groupMisfitDetails.group_name,
                                  },
                                },
                              })
                            }
                          >
                            Move here
                          </Button>
                        ) : null}
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{groupMisfitDetails.package}</TableCell>
                    {groupMisfitDetails?.students_data?.map((studentData) => (
                      <TableCell
                        style={
                          studentData.flag && {
                            background: this.backgroundColors[studentData.flag],
                          }
                        }
                      >
                        {studentData.accuracy || '-'}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>Difference</TableCell>
                    {groupMisfitDetails?.students_data?.map((studentData) => (
                      <TableCell
                        style={
                          studentData.flag && {
                            background: this.backgroundColors[studentData.flag],
                          }
                        }
                      >
                        {studentData.difference || '-'}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            ))}
          </div>
        ) : groupTableData?.length == 0 ? (
          'No Data'
        ) : null}
      </div>
    );
  };

  render() {
    const {
      groupMisfitData,
      groupMisfitFilteredData,
      switchGroupStudentDetails,
      suggestedGroupData,
    } = this.state;
    return (
      <div className='group-misfit-outer-container'>
        <div className='group-misfit-header'>Group Misfits</div>
        <SessionSelector
          setFilterData={this.setSessionData}
          filterList={['grade', 'package']}
          onFilterChange={() =>
            this.setState({
              groupMisfitData: null,
              groupMisfitFilteredData: { data: null, filter: null },
            })
          }
          buttonDisabled={(packageId, gradeId, sessionId) => {
            if (packageId || gradeId) return false;
            else return true;
          }}
        />
        <div>
          <ToggleButtonGroup
            value={groupMisfitFilteredData.filter}
            exclusive
            onChange={(event, newAlignment) => this.setFilter(newAlignment)}
          >
            <ToggleButton
              value='all'
              aria-label='left aligned'
              disabled={!groupMisfitData}
            >
              All
            </ToggleButton>
            <ToggleButton
              value='red'
              aria-label='centered'
              disabled={!groupMisfitData}
            >
              Red
            </ToggleButton>
            <ToggleButton
              value='green'
              aria-label='right aligned'
              disabled={!groupMisfitData}
            >
              Green
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {this.renderGroupTable(false)}
        <SaveChangesModal
          openModal={suggestedGroupData}
          closeModal={() =>
            this.setState({
              switchGroupStudentDetails: null,
              suggestedGroupData: null,
            })
          }
          text={`Group Suggestions for ${switchGroupStudentDetails?.name}`}
          displayData={this.renderGroupTable(true)}
        />
        <SaveChangesModal
          openModal={switchGroupStudentDetails?.switchTo}
          closeModal={() =>
            this.setState({
              switchGroupStudentDetails: {
                ...switchGroupStudentDetails,
                switchTo: null,
              },
            })
          }
          text={`Do you want to move ${switchGroupStudentDetails?.name} to ${switchGroupStudentDetails?.switchTo?.group_name}?`}
          onSave={() => this.moveStudentGroup()}
          style={{ width: '50vw' }}
        />
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  return {
    adminId: states?.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(GroupMisfits));
