import React, { Component } from "react";
import SweetAlert from "sweetalert2-react";

export default class SweetAlertBox extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<SweetAlert
				show={this.props.show}
				title={this.props.title}
				text={this.props.text}
				type={this.props.type}
				onCancel={() =>
					this.props.onCancel
						? (this.props.handleAlertClose(),
							this.props.onCancel(true))
						: this.props.handleAlertClose()
				}
				onConfirm={() => this.props.onConfirm ? (this.props.handleAlertClose(), this.props.onConfirm()) : this.props.handleAlertClose()}
				showCancelButton={this.props.showCancel}
				confirmButtonText={this.props.confirmButtonText}
				cancelButtonText={this.props.cancelButtonText}
			/>
		);
		return null;
	}
}
