import React, { useEffect, useState, Fragment, useRef, useCallback } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {
  makeStyles,
  Modal,
  List,
  Button,
  Select,
  MenuItem
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import axios from 'axios'
import { connect } from "react-redux";
import SingleSelect from '../AutoSuggestSelectBox/SingleSelect';
import { MuiPickersUtilsProvider, DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import { InputAdornment } from "@material-ui/core";
import DateRangeIcon from '@material-ui/icons/DateRange';
import MomentUtils from '@date-io/moment';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2
  },
  list: {
    display: 'flex',
  },
  iconButton: {
    fontSize: 12
  },
  formControl: {
    width: '53%'
  },
  Main: {
    width: '95%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 30,
    top: 0,
    alignItems: 'center',

  },
  Modal: {
    background: '#fff',
    borderRadius: 5,
    width: '70vw',
    maxHeight: '80vh',
    height:'fit-content',
    margin: 'auto',
    position: 'absolute',
    top:0,
    bottom:0,
    left:0,
    right:0,
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    padding: 20,
    // overflowY:'scroll'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    margin: 'auto 25px'
  },
  button: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },

  root: {
    paddingTop: 10,
    paddingBottom: 20,
    '& > *': {
      width: '70%',
      margin: 'auto',
      display: 'flex'
    },
  },
});

let tableRef = null;



function CreateSessionAbsentStudent(props) {
  const ref = React.createRef();
  const classes = userStyles();
  const [classList, setClassList] = useState(null)
  const [classId, setClassId] = useState('')
  const [sessionList, setSessionList] = useState(null)
  const [sessionId, setSessionId] = useState('')
  const [packageId, setPackageId] = useState(4)
  const [teacherList, setTeacherList] = useState(null)
  const [teacherId, setTeacherId] = useState(null)
  const [scheduledAt, setscheduledAt] = useState(null)
  const [packageIdList, setPackageIdList] = useState(null)
  const [keepCurrentTopic, setKeepCurrentTopic] = useState(true)

  useEffect(() => {
    getPackageData(),
      getClassDetail(),
      getTeacherList()
  }, []);

  function getClassDetail() {
    axios.get('v1/classes/').then((res) => {
      if (res && res.status == 200) {
        let data = res && res.data && res.data.data
        data.map((ele, index) =>
          data[index] = { label: ele.name, value: ele.id }
        )
        setClassList(data)
      }
    })
  }

  function getPackageData() {
    const admin_id = props && props.user.user_id
    axios.get(`/v1/admin/${admin_id}/assign-package/`)
      .then((res) => {
        if (res && res.status == 200) {
          let temp = []
          let format = {
            label: "",
            value: ""
          }
          const data = res && res.data && res.data.data
          data && data.map(packageData => {
            format = {
              value: packageData.id,
              label: packageData.id + '. ' + packageData.name
            };
            format == undefined ? null : temp.push(format);
          });
          setPackageIdList(temp);
        }
      }
      )

  }

  function getTeacherList() {
    const admin_id = props.user && props.user.user_id;
    axios.get(`v1/admin/${admin_id}/teacher/`)
      .then((res) => {
        if (res.status == 200) {
          let data = res && res.data && res.data.data
          data.map((ele, index) =>
            data[index] = { label: ele.name, value: ele.id }
          )
          setTeacherList(data)
        }
      })
  }
  function getSessionDetail(class_id) {
    const admin_id = props.user && props.user.user_id;
    axios.get(`v1/admin/${admin_id}/content/${class_id}/sessions-list/?package_id=${packageId}`).then((res) => {
      if (res && res.status == 200) {
        let data = res && res.data && res.data.data
        data.map((ele, index) =>
          data[index] = {
            label: ele.order + '. ' + ele.name,
            value: ele.id
          }
        )
        setSessionList(res.data.data)
      }
    })
  }


  function postCreateSession() {
    const admin_id = props && props.user && props.user.user_id
    axios.post(`/v1/admin/${admin_id}/missed/${props.participant_id}/re-assign/`,
      {
        "scheduled_at": scheduledAt,
        "teacher_id": teacherId,
        ...!keepCurrentTopic && {"session_id": sessionId}
      })
      .then((response) => {
        if (response && response.status == 200) {
          props.enqueueSnackbar(response && response.data && response.data.data && response.data.data.message || 'Session Created Successfully', { variant: 'success' });
          props.closeModal()
          props.handleCallBack()
        }
      }
      )
      .catch(err => {
        const data = err.response && err.response.data && err.response.data.data && err.response.data.data.error
        props.enqueueSnackbar(data || 'Something Went Wrong', { variant: 'error' });
      });
  }

  return (
    <div className={classes.Main}>
      <Modal
        open={props.participant_id}
        onClose={() => {
          props.closeModal
        }}
      >
        <div className={classes.Modal}>
          <div className={classes.header}>
            <h3>Create Session for {props.participant_name || props.participant_id}</h3>
            <IconButton onClick={props.closeModal}>
              <Tooltip title='Close Modal'>
                <Icon className='iconButton'>close</Icon>
              </Tooltip>
            </IconButton>
          </div>

          <List>
          <List item>
              <Select
                value={keepCurrentTopic}
                onChange={(e) => setKeepCurrentTopic(e.target.value)}
              >
                <MenuItem value={true}>
                  Keep Current Topic
                </MenuItem>
                <MenuItem value={false}>
                  Select Session
                </MenuItem>
              </Select>
            </List>
            {keepCurrentTopic ? null :
            <>
            <List item>
              <SingleSelect
                name="Package"
                onChange={(event) => { setClassId(null), setSessionId(null), setPackageId(event.value), classId && getSessionDetail(event.value) }}
                selectList={packageIdList}
                style={{ cursor: 'pointer' }}
                defaultValue={[{ label: "LogiMath", value: 4 }]}
                isDisabled={false}
              />
            </List>
            <List item>
              <SingleSelect
                name="Class"
                onChange={(event) => { setClassId(event.value), setSessionId(null), getSessionDetail(event.value) }}
                selectList={classList}
                value={classId && classList && classList[classList.includes(classId)]}
                style={{ cursor: 'pointer' }}
                isDisabled={!packageId}
              />
            </List>                        
            <List item>
              <SingleSelect
                name="Session"
                onChange={(event) => { setSessionId(event.value) }}
                selectList={sessionList}
                value={sessionId && sessionList && sessionList[sessionList.includes(sessionId)]}
                style={{ cursor: 'pointer' }}
                isDisabled={!classId}
              />
            </List></>}
            <List item>
              <SingleSelect
                name="Teacher"
                onChange={(event) => { setTeacherId(event.value) }}
                selectList={teacherList}
                value={teacherList && teacherList[teacherList.includes(teacherId)]}
                style={{ cursor: 'pointer' }}
              />
            </List>
            <List Item>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                  variant="inline"
                  label="Schedule Time"
                  value={scheduledAt}
                  onChange={setscheduledAt}
                  dateRangeIcon
                  disablePast={true}
                  InputProps={
                    {
                      endAdornment: (

                        <InputAdornment position="end">
                          <Tooltip title="Schedule">
                            <IconButton>
                              <DateRangeIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                />
              </MuiPickersUtilsProvider>
            </List>
          </List>
          <div className={classes.button}>
            <Button color='primary' variant='contained' onClick={props.closeModal}>Cancel</Button>
            {((packageId && classId && sessionId) || keepCurrentTopic) && teacherId && scheduledAt && <Button color='primary' variant='contained' onClick={postCreateSession}>Create Session</Button>}
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default withSnackbar(connect(mapStateToProps)(CreateSessionAbsentStudent));
