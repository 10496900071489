import React, { useState } from 'react';
import {
  ListItem,
  Typography,
  Modal,
  Grid,
  Button,
  TextField,
  Checkbox,
  Divider,
  List,
  Icon,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Table from '../../components/LQDataTables/demo';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import moment from 'moment';

const useStyles = makeStyles({
  text: {
    fontSize: 12,
  },
  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});

let tableRef = null;
function setRowProps(row, index, tabledata) {
  const status = tabledata?.originalData[index]?.status;
  const classes_paid =
    (tabledata &&
      tabledata.originalData &&
      tabledata.originalData[index] &&
      tabledata.originalData[index].classes_paid_for &&
      tabledata.originalData[index].classes_paid_for
        .toString()
        .includes('Package ending')) ||
    false;
  return status
    ? status == 2
      ? {
          style: {
            backgroundColor: 'rgba(255, 255, 0, 0.3)',
            border: '2px solid',
          },
        }
      : status == 3
      ? {
          style: {
            backgroundColor: 'rgba(225,60,10, 0.85)',
            border: '2px solid',
          },
        }
      : classes_paid
      ? {
          style: {
            backgroundColor: 'rgba(255, 0, 0, 0.3)',
            border: '2px solid',
          },
        }
      : null
    : {
        style: {
          backgroundColor: 'rgba(0, 255, 0, 0.3)',
          border: '2px solid',
        },
      };
}
function OrderTable(props) {
  const [cancelPackage, setCancelPackage] = useState(null);
  const [cancelAfterClass, setCancelAfterClass] = useState({
    value: '',
    error: null,
  });
  const [comments, setComments] = useState('');
  const [isActive, setIsActive] = useState(true);

  const classes = useStyles();

  function renderItems(items) {
    const itemObj = items.map((item) => (
      <List key={item}>
        <Typography className={classes.text}>{item}</Typography>
      </List>
    ));
    return itemObj;
  }

  function updatePackageSubscription(
    id,
    action,
    commentText,
    is_active,
    cancel_after_class
  ) {
    if (
      (commentText && cancel_after_class?.value) ||
      action == 'enable' ||
      action == 'provisional'
    ) {
      const user_id = props && props.user && props.user.user_id;
      const data = {
        action: action,
        ...(commentText && {
          comments: commentText,
          is_active: is_active,
          cancel_after_class: parseInt(cancel_after_class.value),
        }),
      };
      axios
        .post(`/v1/admin/${user_id}/subscriptions/${id}/change-status/`, data)
        .then((response) => {
          if (response && response.status === 200) {
            props.enqueueSnackbar(
              response?.data?.data?.message || 'Updated Successfully',
              { variant: 'success' }
            );
            setCancelPackage(null);
            setComments('');
            setIsActive(true);
            setCancelAfterClass({
              value: '',
              error: null,
            });
            tableRef && tableRef.refreshTable();

            return Promise.resolve();
          }
          return Promise.reject('Could not update');
        })
        .catch((err) => {
          props.enqueueSnackbar(
            (err?.response?.data?.data?.error &&
              err.response.data.data.error[0]) ||
              'Something went wrong',
            { variant: 'error' }
          );
          setCancelPackage(null);
          setComments('');
          setIsActive(true);
          setCancelAfterClass({
            value: '',
            error: null,
          });
        });
    } else {
      if (!commentText)
        props.enqueueSnackbar('Please add comments', { variant: 'error' });
      if (!cancel_after_class.value)
        props.enqueueSnackbar(
          cancel_after_class.error ||
            'Please enter the class number after which you want to cancel the order',
          { variant: 'error' }
        );
    }
  }

  function packageCancelModal() {
    // 'Please enter the class number after you want to cancel the order'}
    let commentText = comments;
    let is_active = isActive;
    let cancel_after_class = cancelAfterClass;
    const { disableData } = cancelPackage;
    return (
      <Modal
        open={cancelPackage ? true : false}
        onClose={() => {
          setCancelPackage(null);
          setComments('');
          setIsActive(true);
          setCancelAfterClass({
            value: '',
            error: null,
          });
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            margin: 'auto',
            width: 750,
            padding: '20px 50px',
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            height: 'fit-content',
          }}
        >
          <List style={{ width: '100%' }}>
            <ListItem>
              <Typography style={{ fontSize: 20 }}>
                Do you want to {cancelPackage?.action} the package subscription?
              </Typography>
            </ListItem>
            {cancelPackage?.action == 'disable' && (
              <>
                <ListItem>
                  <TextField
                    label={'Comment'}
                    // value={commentText}
                    variant={'outlined'}
                    onChange={(event) => {
                      commentText = event.target.value;
                    }}
                    multiline={true}
                    rows={6}
                    style={{ width: '100%' }}
                  />
                </ListItem>
                <ListItem>
                  <Checkbox
                    onChange={(event) => (is_active = !event.target.checked)}
                  />
                  Delete completed classes
                </ListItem>
                {disableData ? (
                  <List>
                    <Divider />
                    {Object.entries(disableData)?.map((disableInfo) => (
                      <div style={{ padding: '0.25vw 1vw' }}>
                        {disableInfo[0].charAt(0).toUpperCase() +
                          disableInfo[0].replaceAll('_', ' ').slice(1)}
                        : {disableInfo[1]}
                      </div>
                    ))}
                  </List>
                ) : null}
                <List>
                  <ListItem>
                    <TextField
                      label={
                        'Please enter the class number after which you want to cancel the order'
                      }
                      // value={cancel_after_class.value}
                      variant={'outlined'}
                      error={cancel_after_class.error}
                      helperText={cancel_after_class.error}
                      onChange={(event) => {
                        if (
                          parseInt(event.target.value) ||
                          parseInt(event.target.value) == 0
                        ) {
                          if (disableData) {
                            if (
                              disableData.last_completed_class_order <=
                              event.target.value
                            )
                              cancel_after_class = {
                                value: event.target.value,
                              };
                            else
                              cancel_after_class = {
                                error: `Please enter class number greater than ${disableData.last_completed_class_order}`,
                              };
                          } else
                            cancel_after_class = {
                              value: event.target.value,
                            };
                        } else
                          cancel_after_class = {
                            error: 'Please enter the integer for class number',
                          };
                      }}
                      style={{ width: '100%' }}
                    />
                  </ListItem>
                </List>
              </>
            )}
            <ListItem>
              <Grid container spacing={8} style={{ justifyContent: 'center' }}>
                <Grid item>
                  <Button
                    color='primary'
                    type='submit'
                    variant='outlined'
                    onClick={() => {
                      setCancelPackage(null);
                      setComments('');
                      setIsActive(true);
                      setCancelAfterClass({
                        value: '',
                        error: null,
                      });
                    }}
                  >
                    {'Cancel'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color='primary'
                    type='submit'
                    variant='outlined'
                    onClick={() => {
                      setComments(commentText);
                      setIsActive(is_active);
                      setCancelAfterClass(cancel_after_class);
                      updatePackageSubscription(
                        cancelPackage.id,
                        cancelPackage.action,
                        commentText,
                        is_active,
                        cancel_after_class
                      );
                    }}
                    // disabled={!comments}
                  >
                    {'Confirm'}
                  </Button>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </div>
      </Modal>
    );
  }

  function formatTable(tableData) {
    const newData =
      tableData &&
      tableData.map((entry, index) => {
        const {
          created_at,
          id,
          name,
          status,
          student_id,
          classes_paid_for,
          group_details,
        } = entry;
        const newEntry = { ...entry };
        newEntry.name = (
          <Link
            to={{
              pathname: '/student-session-details',
              search: `?student_id=${student_id}`,
            }}
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            <div>{name}</div>
          </Link>
        );
        newEntry.created_at = moment(created_at).format('DD-MM-YYYY hh:mm A');
        newEntry.group_id = (
          <Link
            to={{
              pathname: '/group-class/paid',
              search: `?id=${group_details.group_id}`,
            }}
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            {group_details.group_id}
          </Link>
        );
        newEntry.classes_left = group_details?.classes_remaining;
        newEntry.student_id = (
          <Link
            to={{
              pathname: '/user',
              search: `?id=${newEntry.student_id}`,
            }}
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            {newEntry.student_id}
          </Link>
        );
        newEntry.classes_paid_for =
          classes_paid_for == 0
            ? '0'
            : classes_paid_for +
              (group_details?.package_ending ? ' (Package ending)' : null);

        const updatedEntry = {
          edit: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Link
                to={{
                  pathname: '/orders/update',
                  search: `?id=${id}`,
                }}
                target='_blank'
                style={{ textDecoration: 'none' }}
              >
                <IconButton>
                  <Tooltip title='Update'>
                    <Icon className='iconButton'>edit</Icon>
                  </Tooltip>
                </IconButton>
              </Link>
              <IconButton
                onClick={() => {
                  const user_id = props?.user?.user_id;
                  axios
                    .post(
                      `/v1/admin/${user_id}/subscriptions/${id}/change-status/`,
                      { action: 'get_disable_info' }
                    )
                    .then((response) => {
                      if (response && response.status === 200) {
                        const disableDetails = response?.data?.data;
                        setCancelPackage({
                          id: id,
                          action: 'disable',
                          disableData: disableDetails,
                        });
                      }
                    })
                    .catch((e) =>
                      setCancelPackage({ id: id, action: 'disable' })
                    );
                }}
              >
                <Tooltip title='Cancel Package Subcription'>
                  <Icon className='iconButton'>delete</Icon>
                </Tooltip>
              </IconButton>
              {status == 2 ? null : (
                <IconButton
                  onClick={() =>
                    setCancelPackage({
                      id: id,
                      action: status == 0 ? 'enable' : 'provisional',
                    })
                  }
                >
                  <Tooltip
                    title={
                      status == 0 ? 'Enable Order' : 'Mark Provisional Order'
                    }
                  >
                    <Icon className='iconButton'>
                      {status == 0
                        ? 'credit_score_icon'
                        : 'credit_card_off_icon'}
                    </Icon>
                  </Tooltip>
                </IconButton>
              )}
            </div>
          ),
          ...newEntry,
        };
        return Object.keys(updatedEntry).map((subEntry) =>
          updatedEntry[subEntry]
            ? '' || updatedEntry[subEntry]
            : updatedEntry[subEntry] == 0
            ? '0'
            : '-'
        );
      });
    return newData;
  }

  function packageClassDetails(name, id) {
    return (
      <div className={classes.packageClassDetails}>
        <Link
          to={{
            pathname: '/student-package-info',
            search: `?studentId=${id}`,
          }}
          target='_blank'
          style={{ textDecoration: 'none' }}
        >
          {name}
        </Link>
      </div>
    );
  }

  return (
    <>
      <Table
        onRef={(ref) => {
          tableRef = ref;
        }}
        createNew='/package-assign'
        firstCol='update order'
        headerFilterList={HEADER_FILTER_LIST}
        formatTable={formatTable}
        sortList={SORT_LIST}
        sortDate={[]}
        tableName='orders'
        title='Order Table'
        setRowProps={setRowProps}
        apiUrl={'orders/'}
        hideColumn={['group_details', 'status']}
        newColumns={['group_id', 'classes_left']}
      />
      {cancelPackage ? packageCancelModal() : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withSnackbar(connect(mapStateToProps)(OrderTable));

const HEADER_FILTER_LIST = [
  {
    filter_label: 'id',
    type: 'text',
  },
  {
    filter_label: 'student_id',
    type: 'text',
  },
  {
    filter_label: 'name',
    type: 'text',
  },
  {
    filter_label: 'grade',
    type: 'text',
  },
  {
    filter_label: 'phone',
    type: 'text',
  },
  {
    filter_label: 'city',
    type: 'text',
  },
  {
    filter_label: 'school',
    type: 'text',
  },
  {
    filter_label: 'ac',
    type: 'text',
  },
  {
    filter_label: 'no_of_classes',
    type: 'text',
  },
  {
    filter_label: 'package',
    type: 'text',
  },
];

const SORT_LIST = [
  'id',
  'no_of_classes',
  'total_amount',
  'amount',
  'completed_classes',
];
