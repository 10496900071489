export const FILTER_TYPES_DEFAULT_VALUE = Object.freeze({
    date: {},
    email: '',
    multiselect: [],
    text: '',
});

export const FILTER_TYPE_ENUM = Object.freeze({
    after: 1,
    before: 2,
    between: 3,
    equals: 4,
    none: 5,
    notequals: 6,
});

export const FILTER_INPUT_TYPE_APPLICABLE_FILTERS = Object.freeze({
    date: {
        filter_types: [
            {
                label: 'Equals',
                value: FILTER_TYPE_ENUM.equals,
            },
            {
                label: 'None',
                value: FILTER_TYPE_ENUM.none,
            },
        ],
    },
    email: {
        filter_types: [
            {
                label: 'Equals',
                value: FILTER_TYPE_ENUM.equals,
            },
            {
                label: 'None',
                value: FILTER_TYPE_ENUM.none,
            },
            {
                label: 'Not Equals',
                value: FILTER_TYPE_ENUM.notequals,
            },
        ],
    },
    singleselect: {
        filter_types: [
            {
                label: 'Equals',
                value: FILTER_TYPE_ENUM.equals,
            },
            {
                label: 'None',
                value: FILTER_TYPE_ENUM.none,
            },
        ],
    },
    multiselect: {
        filter_types: [
            {
                label: 'Equals',
                value: FILTER_TYPE_ENUM.equals,
            },
            {
                label: 'Not In',
                value: FILTER_TYPE_ENUM.notequals,
            },
            {
                label: 'None',
                value: FILTER_TYPE_ENUM.none,
            },
        ],
    },
    text: {
        filter_types: [
            {
                label: 'Equals',
                value: FILTER_TYPE_ENUM.equals,
            },
            {
                label: 'Not In',
                value: FILTER_TYPE_ENUM.notequals,
            },
            {
                label: 'None',
                value: FILTER_TYPE_ENUM.none,
            },
        ],
    },
    text_n: {
        filter_types: [
            {
                label: 'Equals',
                value: FILTER_TYPE_ENUM.equals,
            },
            {
                label: 'None',
                value: FILTER_TYPE_ENUM.none,
            },
            {
                label: 'Not Equals',
                value: FILTER_TYPE_ENUM.notequals,
            },
        ],
    },
});

// export const USER_MANAGEMENT_QUERY_INTERFACE = Object.freeze({
//     city: '',
//     class: '',
//     email: '',
//     from_last_login: '',
//     fromreg: '',
//     name: '',
//     ncity: '',
//     nclass: '',
//     nemail: '',
//     nphone: '',
//     nschool: '',
//     phone: '',
//     school: '',
//     to_last_login: '',
//     toreg: '',
// });
