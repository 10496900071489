import { combineReducers } from "redux";

import UtilsReducer from "./UtilsReducer";
import UsersReducer from "./UserReducer";
import ErrorReducer from "./ErrorReducer";
import ListReducer from "./ListReducer";
import UserProfileReducer from './UserProfileReducer'

import Loaders from "./Loaders";

const rootReducer = combineReducers({
    utils: UtilsReducer,
    user: UsersReducer,
    userProfile: UserProfileReducer,
    error: ErrorReducer,
    loader: Loaders,
    lists:ListReducer
});

export default rootReducer;
