import React from 'react';
import { browserHistory, Router } from 'react-router';
import Main from './components/Main';

export function getRoutes() {
  return {
    path: '/',
    component: Main,
    indexRoute: {
      getComponent(location, cb) {
        cb(null, require('./containers/Login').default); // eslint-disable-line global-require
      },
    },
    childRoutes: [
      {
        path: 'orders',
        icon: '',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/OrderTable').default);
          },
        },
        childRoutes: [
          {
            path: 'update',
            icon: '',
            indexRoute: {
              getComponent(location, cb) {
                cb(null, require('./containers/UpdateOrder').default);
              },
            },
          },
        ],
      },
      {
        path: 'group-class',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/GroupClassTable').default); // eslint-disable-line global-require
          },
        },
        childRoutes: [
          {
            path: 'paid',
            icon: 'home',
            indexRoute: {
              getComponent(location, cb) {
                cb(null, require('./containers/GroupClassTable').default); // eslint-disable-line global-require
              },
            },
          },
          {
            path: 'free',
            icon: 'home',
            indexRoute: {
              getComponent(location, cb) {
                cb(null, require('./containers/GroupClassTable').default); // eslint-disable-line global-require
              },
            },
          },
          {
            path: 'create',
            indexRoute: {
              getComponent(location, cb) {
                cb(null, require('./containers/CreateandEditGroup').default); // eslint-disable-line global-require
              },
            },
          },
          {
            path: 'edit',
            indexRoute: {
              getComponent(location, cb) {
                cb(null, require('./containers/CreateandEditGroup').default); // eslint-disable-line global-require
              },
            },
          },
          {
            path: 'schedule',
            indexRoute: {
              getComponent(location, cb) {
                cb(null, require('./containers/StudentSchedule').default); // eslint-disable-line global-require
              },
            },
          },
        ],
      },
      {
        path: 'group-misfits',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./components/GroupMisfits').default);
          },
        },
      },
      {
        path: 'user',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/UserManagementTable').default); // eslint-disable-line global-require
          },
        },
        childRoutes: [
          {
            path: 'create',
            indexRoute: {
              getComponent(location, cb) {
                cb(null, require('./containers/CreateUserForm').default); // eslint-disable-line global-require
              },
            },
          },
          {
            path: 'schedule',
            indexRoute: {
              getComponent(location, cb) {
                cb(null, require('./containers/StudentSchedule').default); // eslint-disable-line global-require
              },
            },
          },
        ],
      },
      {
        path: 'leads',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/LeadsTable').default);
          },
        },
      },
      {
        path: 'sessions',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/SessionTable').default);
          },
        },
      },
      {
        path: 'doubt-sessions',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/DoubtSessionTable').default);
          },
        },
      },
      {
        path: 'demo-calling-panel',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/DemoCallingPanel').default);
          },
        },
      },
      {
        path: 'queries',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/QueryTable').default);
          },
        },
      },
      {
        path: 'class-feedback',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./components/ClassFeedback').default);
          },
        },
      },
      {
        path: 'monthly-feedback',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./components/MonthlyFeedback').default);
          },
        },
      },
      {
        path: 'missed-sessions',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/MissedSessionTable').default);
          },
        },
      },
      {
        path: 'questions',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/QuestionTable').default);
          },
        },
        childRoutes: [
          {
            path: 'edit',
            indexRoute: {
              getComponent(location, cb) {
                cb(null, require('./containers/EditQuestion').default); // eslint-disable-line global-require
              },
            },
          },
          {
            path: 'create',
            indexRoute: {
              getComponent(location, cb) {
                cb(null, require('./containers/CreateQuestion').default); // eslint-disable-line global-require
              },
            },
          },
        ],
      },
      {
        path: 'package-assign',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/PackageAssign').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'teacher-slot',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./components/TeacherSlot').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'student-session-details',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/StudentSessionDetails').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'upload-question',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/UploadQuestion').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'availability',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./components/AvailabilityModal').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'teacher-performance',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./components/TeacherPerformance').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'leave-management',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./components/LeaveManagement').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'announcements',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./components/Announcements').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'reschedule-request',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/RescheduleTable').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'edit-session',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/EditSessionData').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'bulk-question-upload',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/BulkQuestionUpload').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'bulk-question-mapping',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/BulkQuestionMapping').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'teacher',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/TeacherTable').default); // eslint-disable-line global-require
          },
        },
        childRoutes: [
          {
            path: 'create',
            indexRoute: {
              getComponent(location, cb) {
                cb(null, require('./containers/CreateUserForm').default); // eslint-disable-line global-require
              },
            },
          },
        ],
      },
      {
        path: 'calendar',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./components/CalendarScheduler').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'ptm',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./components/PTM').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'referral',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./components/ReferralContainer').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'interactive-flash-cards',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(
              null,
              require('./containers/FlashCardsDashboard/FlashCardsDashboard')
                .default
            ); // eslint-disable-line global-require
          },
        },
        childRoutes: [
          {
            path: ':cardId',
            icon: 'home',
            indexRoute: {
              getComponent(location, cb) {
                cb(null, require('./containers/FlashCards/FlashCards').default); // eslint-disable-line global-require
              },
            },
          },
        ],
      },
      {
        path: 'admin',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/AdminRolesTable').default); // eslint-disable-line global-require
          },
        },
        childRoutes: [
          {
            path: 'create',
            indexRoute: {
              getComponent(location, cb) {
                cb(null, require('./containers/CreateUserForm').default); // eslint-disable-line global-require
              },
            },
          },
        ],
      },
      {
        path: 'roles-and-authentication',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(
              null,
              require('./containers/AdminRolesAndAuthentication').default
            ); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'teacher-trainer',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/TeacherTrainer').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'student-mentor-view-1',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/StudentMentorView1').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'student-mentor-view-2',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/StudentMentorView2').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'student-mentor-view-3',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/StudentMentorView3').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'cancelled-orders',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/CancelledOrderTable').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'content-head',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/ContentHead').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: 'teacher-trainer-stats',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./containers/TeacherTrainerStats').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: '404',
        icon: 'power_settings_new',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./components/PageNotFound').default); // eslint-disable-line global-require
          },
        },
      },
      {
        path: '*',
        icon: 'home',
        indexRoute: {
          getComponent(location, cb) {
            cb(null, require('./components/PageNotFound').default); // eslint-disable-line global-require
          },
        },
      },
    ],
  };
}

export function routes(props) {
  const routesConfigured = getRoutes();
  return (
    <Router
      history={browserHistory}
      routes={routesConfigured}
      onUpdate={() => window.scrollTo(0, 0)}
    />
  );
}
