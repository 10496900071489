import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import configureStore from './store/configureStore';
import theme from './theme';
import { routes } from './Route';

import { persistStore } from 'redux-persist';
import localForage from 'localforage';
import { setAuthorizationToken } from './config/axios';
import axios from 'axios';

const store = configureStore(window.__REDUX_STATE__);

const App = (props) => {
  const [rehydrated, setRehydrated] = useState(false);

  useEffect(() => {
    persistStore(
      store,
      { storage: localForage },
      () => {
        const { user } = store.getState();
        setAuthorizationToken(user);
        // if (user && user.user_id) {
        //     store.dispatch(fetchUserProfile());
        // }
        axios.defaults.headers.common['Session-Token'] =
          user && user.session_token;
        setRehydrated(true);
      }
    );
  }, []);

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  return rehydrated ? (
    <div className='App'>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {routes()}
        </ThemeProvider>
      </Provider>
    </div>
  ) : null;
};

export default App;
