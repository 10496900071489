import React, { useEffect, useState } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {
  ListItem,
  Typography,
  makeStyles,
  Modal,
  List,
  Grid,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { Link } from 'react-router';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import { connect } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ReassignMissedSessionStudent from '../../components/ReassignMissedSessionStudent';
import AssignSessionAbsent from '../AssignSessionAbsent';
import SaveChangesModal from '../SaveChangesModal';
import SessionSelector from '../SessionSelector';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  list: {
    display: 'flex',
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },
  Main: {
    width: '95%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 10,
    top: 0,
    alignItems: 'center',
  },
  Modal: {
    background: '#fff',
    borderRadius: 5,
    width: '70vw',
    maxHeight: '80vh',
    margin: 'auto',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    padding: 10,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    gap: '1vw',
    position: 'fixed',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },

  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});

function EditSessionDetails(props) {
  const classes = userStyles();
  const [sessionDetails, setSessionDetails] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [ind, setIndex] = useState(null);
  const [recordingLink, setRecordingLink] = useState('');
  const [Value, setValue] = useState(null);
  const [reAssignStudent, setReAssignStudent] = useState(null);
  const [assignSession, setAssignSession] = useState(null);
  const [name, setName] = useState(null);
  const [participant_details, setParticipant_details] = useState(null);
  useEffect(() => {
    getSessionDetails();
  }, []);

  function getSessionDetails() {
    const admin_id = props && props.user && props.user.user_id;
    axios
      .get(`/v1/admin/${admin_id}/session/${props.id}/details/`)
      .then((response) => {
        if (response && response.status == 200) {
          const data = response && response.data && response.data.data;
          data.participant_details &&
            setParticipant_details(data.participant_details);
          if (data.completed_at && data.participant_details) {
            let feedbackDetails = [];
            data.participant_details.map((student) => {
              feedbackDetails.push({
                student_id: student.student_id,
                is_present: student.is_present == 1 ? 1 : 0,
                student_remark: student.remark || null,
              });
            });
            setFeedback(feedbackDetails);
            setRecordingLink(data.recording_link);
          }
          setSessionDetails(response && response.data && response.data.data);
          props.enqueueSnackbar('Successfully fetched the session details', {
            variant: 'success',
          });
        }
      });
  }

  function postSessionDetails() {
    const admin_id = props && props.user && props.user.user_id;
    axios
      .post(`/v1/admin/${admin_id}/session/${props.id}/update-feedback/`, {
        feedback: feedback,
        recording_link: recordingLink,
      })
      .then((response) => {
        if (response && response.status == 200) {
          props.enqueueSnackbar('Successfully updated the session details', {
            variant: 'success',
          });
          props.closeModal();
        }
      });
  }

  const handleChange = (event, index) => {
    setIndex(index);
    let feedbackValue = JSON.parse(JSON.stringify(feedback));
    feedbackValue[index].is_present = parseInt(event.target.value);
    setFeedback(feedbackValue);
  };

  function ReassignTeacher(teacherId) {
    const admin_id = props && props.user && props.user.user_id;
    axios
      .post(`v1/admin/${admin_id}/session/${props.id}/assign-teacher/`, {
        teacher_id: teacherId,
      })
      .then((response) => {
        if (response && response.status === 200) {
          props.enqueueSnackbar(
            response?.data?.data?.message || 'Updated Successfully',
            { variant: 'success' }
          );
          props.closeModal();
          props.handleCallBack();
        }
      })
      .catch((err) => {
        const data = err?.response?.data?.data?.error;
        props.enqueueSnackbar(data || 'Something Went Wrong', {
          variant: 'error',
        });
      });
  }

  return reAssignStudent ? (
    <ReassignMissedSessionStudent
      participant_id={reAssignStudent}
      closeModal={() => setReAssignStudent(null)}
      handleCallBack={() => setReAssignStudent(null)}
    />
  ) : assignSession ? (
    <AssignSessionAbsent
      participant_id={assignSession}
      participant_name={name}
      closeModal={() => setAssignSession(null)}
      handleCallBack={() => setAssignSession(null)}
    />
  ) : (
    <div className={classes.Main}>
      <Modal
        open={props.id}
        onClose={() => {
          props.closeModal;
          setSessionDetails(null);
        }}
      >
        <div className={classes.Modal}>
          <div className={classes.header}>
            <h3>Session Details</h3>
            <IconButton onClick={props.closeModal}>
              <Tooltip title='Close Modal'>
                <Icon className='iconButton'>close</Icon>
              </Tooltip>
            </IconButton>
          </div>
          {props.showTeacherReassign ? (
            <SessionSelector
              setFilterData={(packageId, GradeId, SessionId, TeacherId) =>
                ReassignTeacher(TeacherId)
              }
              filterList={['teacher']}
              sessionId={props.id}
              buttonName={'Assign'}
              selectedTeacher={props.selectedTeacher}
            />
          ) : null}
          {sessionDetails?.participant_details ? (
            sessionDetails?.participant_details?.length ? (
              sessionDetails.participant_details?.map((student, index) => (
                <div
                  style={{
                    border: '2px solid lightgrey',
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  <List className={classes.list}>
                    <ListItem>
                      Student Name :{' '}
                      <Link
                        to={{
                          pathname: '/student-session-details',
                          search: `?student_id=${student.student_id}`,
                        }}
                        target='_blank'
                        style={{ textDecoration: 'none' }}
                      >
                        <div>{student.name}</div>
                      </Link>
                    </ListItem>
                    <ListItem>
                      Student ID :
                      <Link
                        to={{
                          pathname: '/user',
                          search: `?id=${student.student_id}`,
                        }}
                        target='_blank'
                        style={{ textDecoration: 'none' }}
                      >
                        <div>{student.student_id}</div>
                      </Link>
                    </ListItem>
                    {sessionDetails.status.toLowerCase() == 'upcoming' ? (
                      <>
                        <IconButton
                          onClick={() =>
                            setReAssignStudent(
                              participant_details[index].participant_id
                            )
                          }
                        >
                          <Tooltip title='Reassign'>
                            <Icon className='iconButton'>person_add_icon</Icon>
                          </Tooltip>
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setAssignSession(
                              participant_details[index].participant_id
                            ),
                              setName(student.name);
                          }}
                        >
                          <Tooltip
                            title={'Create Extra Session for ' + student.name}
                          >
                            <Icon className='iconButton'>add_circle_icon</Icon>
                          </Tooltip>
                        </IconButton>
                      </>
                    ) : null}
                  </List>

                  {sessionDetails?.completed_at && feedback ? (
                    <FormControl component='fieldset' fullWidth>
                      <RadioGroup
                        value={feedback[index].is_present}
                        onChange={(event) => {
                          handleChange(event, index);
                        }}
                      >
                        <List
                          className={classes.list}
                          style={{ paddingLeft: 20 }}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label='Absent'
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label='Present'
                          />
                          {!feedback[index].is_present && (
                            <>
                              <IconButton
                                onClick={() =>
                                  setReAssignStudent(
                                    participant_details[index].participant_id
                                  )
                                }
                              >
                                <Tooltip title='Reassign'>
                                  <Icon className='iconButton'>
                                    person_add_icon
                                  </Icon>
                                </Tooltip>
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setAssignSession(
                                    participant_details[index].participant_id
                                  ),
                                    setName(student.name);
                                }}
                              >
                                <Tooltip
                                  title={
                                    'Create Extra Session for ' + student.name
                                  }
                                >
                                  <Icon className='iconButton'>
                                    add_circle_icon
                                  </Icon>
                                </Tooltip>
                              </IconButton>
                            </>
                          )}
                        </List>
                      </RadioGroup>
                      <TextField
                        label={'Student Feedback(' + student.name + ')'}
                        variant='outlined'
                        value={feedback[index].student_remark}
                        onChange={(event) => {
                          const feedbackValue = JSON.parse(
                            JSON.stringify(feedback)
                          );
                          feedbackValue[index].student_remark =
                            event.target.value;
                          setFeedback(feedbackValue);
                        }}
                        multiline
                        rows={2}
                      />
                    </FormControl>
                  ) : null}
                </div>
              ))
            ) : (
              'No Data'
            )
          ) : (
            <CircularProgress style={{ margin: 'auto' }} />
          )}
          {sessionDetails?.completed_at && (
            <TextField
              label='Loom Link'
              variant='outlined'
              value={recordingLink}
              onChange={(event) => setRecordingLink(event.target.value)}
            />
          )}
          {sessionDetails?.completed_at ? (
            <Button
              color='primary'
              variant='contained'
              onClick={postSessionDetails}
            >
              Save Changes
            </Button>
          ) : null}
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withSnackbar(connect(mapStateToProps)(EditSessionDetails));
