import { USER_REGISTERED, USER_LOGGEDIN, LOGOUT_USER } from '../actions/types';

const INITIAL_STATE = null;

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case USER_REGISTERED:
            return action.payload;
        case USER_LOGGEDIN:
            return Object.assign({}, state, action.payload);
        case LOGOUT_USER:
            return INITIAL_STATE;
        default:
            return state;
    }
}
