import React, { Component } from 'react'
import SaveChangesModal from '../SaveChangesModal';
import {
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    CircularProgress,
    Button,
    Icon
} from '@material-ui/core';
import './styles.scss'
import axios from 'axios';

export default class ContentHistoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentHistoryData: null,
            showDetail: null
        }
    }
    componentDidMount() {
        this.fetchContentHistory()
    }
    fetchContentHistory = () => {
        axios(`/v1/admin/${this.props.adminId}/content/history/?${this.props.params}`)
            .then((response) => {
                if (response?.status && response?.data?.data)
                    this.setState({ contentHistoryData: response.data.data })
            })
    }

    contentHistoryTable = () => {
        const { contentHistoryData, showDetail } = this.state        
        return contentHistoryData ?
            contentHistoryData.length
                ? <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Model</TableCell>
                            <TableCell>Timestamp</TableCell>
                            <TableCell colSpan={2}>Changes</TableCell>
                            <TableCell>Updated by</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            contentHistoryData.map((historyData, index) =>                                
                                <>
                                    <TableRow>
                                        <TableCell rowSpan={historyData.action==0 && showDetail!=index? 1 :Object.keys(historyData.changes).length * 3}>{historyData.model}</TableCell>
                                        <TableCell rowSpan={historyData.action==0 && showDetail!=index? 1 :Object.keys(historyData.changes).length * 3}>{new Date(historyData.timestamp).toLocaleString()}</TableCell>
                                        <TableCell colSpan={2} className='bold'>{
                                            historyData.action==0 && showDetail!=index? 
                                            <Button
                                                onClick={()=>this.setState({showDetail:index})}
                                            >show more<Icon>keyboard_arrow_down_icon</Icon></Button> : 
                                            Object.keys(historyData.changes)[0].charAt(0).toUpperCase() + Object.keys(historyData.changes)[0].slice(1).replaceAll("_", " ")
                                        }
                                        </TableCell>
                                        <TableCell rowSpan={historyData.action==0 && showDetail!=index? 1 : Object.keys(historyData.changes).length * 3}>{historyData.updated_by}</TableCell>
                                    </TableRow>{historyData.action==0 && showDetail!=index? null :
                                    <>
                                    <TableRow>
                                        <TableCell>Old</TableCell>
                                        <TableCell>Updated</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{historyData.changes[Object.keys(historyData.changes)[0]][0]}</TableCell>
                                        <TableCell>{historyData.changes[Object.keys(historyData.changes)[0]][1]}</TableCell>
                                    </TableRow>
                                    </>}
                                    {
                                        historyData.action==0 && showDetail!=index? null : Object.keys(historyData.changes).length > 1 ?
                                            Object.keys(historyData.changes).slice(1).map((historyDataKey) =>
                                                <>
                                                    <TableRow>
                                                        <TableCell colSpan={2} className='bold'>{
                                                            historyDataKey.charAt(0).toUpperCase() + historyDataKey.slice(1).replaceAll("_", " ")
                                                        }</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Old</TableCell>
                                                        <TableCell>Updated</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>{historyData.changes[historyDataKey][0]}</TableCell>
                                                        <TableCell>{historyData.changes[historyDataKey][1]}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                            : null
                                    }
                                </>
                            )
                        }
                    </TableBody>
                </Table> : 'No data' : <CircularProgress />
    }

    render() {
        return <div>
            <SaveChangesModal
                openModal={this.props.openModal}
                closeModal={() => this.props.closeModal()}
                text={'Content Update History'}
                displayData={this.contentHistoryTable()}
                style={{ minHeight:'30vh', maxHeight: '80vh', overflowY: 'scroll', gap: '1vw' }}
            />
        </div>
    }
}