import React, { Component } from 'react';
import { connect } from 'react-redux';
import SaveChangesModal from '../SaveChangesModal';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  CircularProgress,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
  Divider,
} from '@material-ui/core';
import { Link } from 'react-router';
import { ArrowRight } from '@material-ui/icons';
import axios from 'axios';
import './style.scss';

class Announcements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      announcementData: null,
      isLoading: true,
      showTeacherDetails: null,
      addAnnouncement: false,
      teacherList: [],
    };
  }
  componentDidMount() {
    this.fetchAnnouncementData();
    this.fetchTeacherList();
  }
  fetchTeacherList() {
    axios.get(`v1/admin/${this.props.adminId}/teacher/`).then((res) => {
      if (res.status == 200) {
        const teacherListData = res?.data?.data;
        this.setState({ teacherList: teacherListData });
      }
    });
  }
  fetchAnnouncementData = () => {
    this.setState({ announcementData: null, isLoading: true });
    axios
      .get(`v1/admin/${this.props.adminId}/announcement/list/`)
      .then((response) => {
        if (response?.data?.data?.results) {
          const data = response.data.data;
          this.setState({
            announcementData: data.results,
            isLoading: false,
            addAnnouncement: false,
          });
        }
      });
  };

  renderTeacherDetails = () => {
    const { showTeacherDetails } = this.state;
    const acknowledgedData = showTeacherDetails?.filter(
      (teacherDetails) => teacherDetails.acknowledged
    );
    const notAcknowledgedData = showTeacherDetails?.filter(
      (teacherDetails) => !teacherDetails.acknowledged
    );
    return (
      <div
        style={{
          display: 'flex',
          gap: '1vw',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            borderRadius: 8,
            border: '1px solid grey',
            padding: '1vw',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5vw',
          }}
        >
          <div style={{ fontSize: '1.05vw', fontWeight: 600 }}>
            Acknowledged - {acknowledgedData?.length}/
            {showTeacherDetails?.length}
          </div>
          <Divider />
          {acknowledgedData?.length
            ? acknowledgedData?.map((teacherDetails) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowRight style={{ fontSize: '1.5vw', marginRight: 5 }} />
                  <Link
                    to={{
                      pathname: `/teacher`,
                      search: `?id=${teacherDetails.teacher_id}`,
                    }}
                    target='_blank'
                    style={{ textDecoration: 'none' }}
                  >
                    {teacherDetails.teacher_name}
                  </Link>
                </div>
              ))
            : 'No Data'}
        </div>
        <div
          style={{
            borderRadius: 8,
            border: '1px solid grey',
            padding: '1vw',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5vw',
          }}
        >
          <div style={{ fontSize: '1.05vw', fontWeight: 600 }}>
            Not Acknowledged - {notAcknowledgedData?.length}/
            {showTeacherDetails?.length}
          </div>
          <Divider />
          {notAcknowledgedData?.length
            ? notAcknowledgedData?.map((teacherDetails) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowRight style={{ fontSize: '1.5vw' }} />
                  <Link
                    to={{
                      pathname: `/teacher`,
                      search: `?id=${teacherDetails.teacher_id}`,
                    }}
                    target='_blank'
                    style={{ textDecoration: 'none' }}
                  >
                    {teacherDetails.teacher_name}
                  </Link>
                </div>
              ))
            : 'No Data'}
        </div>
      </div>
    );
  };

  renderAddAnnouncement = () => {
    const { teacherList, addAnnouncement } = this.state;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1vw',
          width: '100%',
        }}
      >
        <FormControl
        // style={{
        //   minWidth: 210,
        //   maxWidth: 400,
        //   marginLeft: 16,
        //   marginRight: 16,
        // }}
        >
          <InputLabel>Select Teacher</InputLabel>
          <Select
            style={{ width: '100%' }}
            multiple
            value={addAnnouncement?.teacherIds || []}
            onChange={(event) => {
              var teacherIdData = [...new Set(event.target.value.flat(1))];
              if (teacherIdData.includes('select_all'))
                teacherIdData = teacherList.map(
                  (teacherDetails) => teacherDetails.id
                );
              else if (teacherIdData.includes('clear_all')) teacherIdData = [];
              this.setState({
                addAnnouncement: {
                  ...addAnnouncement,
                  teacherIds: teacherIdData,
                },
              });
            }}
            renderValue={
              (selected) => (
                <div style={{ whiteSpace: 'initial' }}>
                  {teacherList
                    ?.filter(
                      (teacherListData) =>
                        selected.indexOf(teacherListData.id) > -1
                    )
                    .map((selectedTeacher) => selectedTeacher.name)
                    .join(', ')}
                </div>
              )

              // teacherList && (
              //   <div
              //     style={{
              //       width: '100%',
              //       display: 'flex',
              //       gap: 10,
              //       flexWrap: 'wrap',
              //     }}
              //   >
              //     {teacherList.map(
              //       (teacherListData) =>
              //         selected.indexOf(teacherListData.id) > -1 && (
              //           <div>{teacherListData.name}</div>
              //         )
              //     )}
              //   </div>
              // )
            }
          >
            {addAnnouncement?.teacherIds?.length != teacherList.length && (
              <MenuItem value={'select_all'}>
                <ListItemText primary={'Select All'} />
              </MenuItem>
            )}
            {addAnnouncement?.teacherIds?.length && (
              <MenuItem value={'clear_all'}>
                <ListItemText primary={'Clear All'} />
              </MenuItem>
            )}
            {teacherList.map((teacherListData) => (
              <MenuItem key={teacherListData.id} value={teacherListData.id}>
                <Checkbox
                  checked={
                    addAnnouncement?.teacherIds?.indexOf(teacherListData.id) >
                    -1
                  }
                />
                <ListItemText primary={teacherListData.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label='Announcement'
          variant='outlined'
          value={addAnnouncement?.description}
          multiline
          rows={2}
          onChange={(event) =>
            this.setState({
              addAnnouncement: {
                ...addAnnouncement,
                description: event.target.value,
              },
            })
          }
        />
      </div>
    );
  };

  postAddAnnouncement = () => {
    const { addAnnouncement } = this.state;
    const { description, teacherIds } = addAnnouncement;
    axios
      .post(`v1/admin/${this.props.adminId}/add/announcement/`, {
        description,
        teacher_ids: teacherIds,
      })
      .then((response) => {
        if (response?.data && response.status == 200) {
          this.fetchAnnouncementData();
        }
      });
  };

  render() {
    const { announcementData, isLoading, showTeacherDetails, addAnnouncement } =
      this.state;
    return (
      <div className='announcementDetailsContainer'>
        <SaveChangesModal
          openModal={addAnnouncement}
          closeModal={() => this.setState({ addAnnouncement: null })}
          text={`Add Announcement`}
          onSave={this.postAddAnnouncement}
          displayData={this.renderAddAnnouncement()}
          disableConfirm={
            !addAnnouncement?.teacherIds?.length ||
            !addAnnouncement?.description
          }
        />
        <SaveChangesModal
          style={{ gap: '1vw' }}
          openModal={showTeacherDetails}
          closeModal={() => this.setState({ showTeacherDetails: null })}
          displayData={this.renderTeacherDetails()}
          text={`Teacher Details`}
        />
        <div className='announcementDetailsHeaderContainer'>
          <div className='announcementDetailsHeader'>Announcements</div>
          <Button
            onClick={() => this.setState({ addAnnouncement: true })}
            variant={'contained'}
            style={{ backgroundColor: '#BF5AF2', color: 'white' }}
          >
            Add Announcement
          </Button>
        </div>
        {announcementData?.length ? (
          <div className='tableContainer'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Object.keys(announcementData[0]).map((header) => (
                    <TableCell>
                      {header.charAt(0).toUpperCase() +
                        header.replaceAll('_', ' ').slice(1)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {announcementData.map((teacherData) => (
                  <TableRow>
                    {Object.keys(announcementData[0]).map((header) => (
                      <TableCell>
                        {Array.isArray(teacherData[header]) ? (
                          <div
                            onClick={() =>
                              this.setState({
                                showTeacherDetails: teacherData[header],
                              })
                            }
                            style={{
                              color: 'blue',
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                          >
                            {teacherData[header].length}
                          </div>
                        ) : (
                          teacherData[header] || '-'
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : announcementData?.length == 0 ? (
          <div className='centerAligned'>No data</div>
        ) : isLoading ? (
          <CircularProgress className='centerAligned' />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  return {
    adminId: states?.user?.user_id,
  };
};

export default connect(mapStateToProps)(Announcements);
