import React from 'react';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MuiTablePagination from '@material-ui/core/TablePagination';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const defaultFooterStyles = {};

class CustomFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumberValue: 1,
        };
    }

    handleRowChange = (event) => {
        this.props.changeRowsPerPage(event.target.value);
    };

    handlePageChange = (event, page) => {
        this.setState({ pageNumberValue: eval(page)+1 }, this.props.changePage(page));
    };

    onChange = (event) => {
        // event.preventDefault();
        // event.stopPropagation();
        this.setState({ pageNumberValue: event.target.value});
 
    };

    render() {
        const { count, classes, rowsPerPage, page } = this.props;

        const footerStyle = {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0px 24px 0px 24px',
        };

        const textLabels = {
            rowsPerPage: 'Rows per Page',
            displayRows: 'of',
            previous: 'previous',
            next: 'next',
        };

        return (
            <TableFooter>
                <TableRow>
                    <TableCell style={footerStyle} colSpan={1000}>
                        <TextField
                            id="outlined-name"
                            label="Current Row"
                            value={this.state.pageNumberValue}
                            onChange={this.onChange}
                            margin="normal"
                            variant="outlined"
                            onKeyPress={(ev) => {
                                // ev.preventDefault();
                                ev.stopPropagation();
                                if (ev.key === 'Enter') {
                                    this.props.changePage(eval(this.state.pageNumberValue)-1);
                                    // this.onChange(ev)
                                }
                            }}
                        />
                        <MuiTablePagination
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={textLabels.rowsPerPage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${textLabels.displayRows} ${count}`
                            }
                            backIconButtonProps={{
                                'aria-label': textLabels.previous,
                            }}
                            nextIconButtonProps={{
                                'aria-label': textLabels.next,
                            }}
                            rowsPerPageOptions={this.props.rowsPerPageOptions}
                            onChangePage={this.handlePageChange}
                            onChangeRowsPerPage={this.handleRowChange}
                        />
                    </TableCell>
                </TableRow>
            </TableFooter>
        );
    }
}

export default withStyles(defaultFooterStyles, { name: 'CustomFooter' })(CustomFooter);
