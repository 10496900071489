import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateRangePickerModal from '../../components/DateRangePickerModal';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  CircularProgress,
  Icon,
  TablePagination,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';
import axios from 'axios';
import './style.scss';
import moment from 'moment';

class StudentMentorView1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentMentorData: null,
      startDate: null,
      endDate: null,
      sessionId: null,
      isLoading: false,
      student_id: null,
      name: null,
      grade: null,
      teacher: null,
      studentDetailId: null,
      count: null,
      page: 0,
      no_of_rows: 50,
      showDateRange: true,
      packageData: null,
      packageId: null,
      classes: null,
      activeSort: { id: '', asc: true },
    };
  }

  getPackage = () => {
    const admin_id = this.props && this.props.adminId;
    axios
      .get(`/v1/admin/${admin_id}/assign-package/?paid=1`)
      .then((res) => {
        if (res && res.data) {
          this.setState({ packageData: res.data.data });
        }
      })
      .catch((err) => {
        console.log('Something went wrong!');
      });
  };

  getClasses = () => {
    const admin_id = this.props && this.props.adminId;
    axios
      .get('v1/classes/')
      .then((res) => {
        if (res && res.data) {
          this.setState({ classes: res.data.data });
        }
      })
      .catch((err) => {
        console.log('Something went wrong!');
      });
  };

  fetchStudentMentorData = (
    startDate = this.state.startDate,
    endDate = this.state.endDate
  ) => {
    const params = {
      name: this.state.name,
      teacher: this.state.teacher,
      student_id: this.state.student_id,
      package: this.state.packageId,
      student_details_id: this.state.studentDetailId,
      grade: this.state.grade,
    };
    const qs = Object.entries(params)
      .map(([key, value]) => {
        if (value) return `${key}=${value}`;
      })
      .filter((e) => e)
      .join('&');

    this.getPackage();
    this.getClasses();

    this.setState({
      studentMentorData: null,
      startDate: startDate,
      endDate: endDate,
    });
    this.setState({ isLoading: true });
    axios
      .get(
        `v1/admin/${
          this.props.adminId
        }/student/session/performance/?from_date=${startDate}&to_date=${endDate}&page_size=${
          this.state.no_of_rows
        }&page=${this.state.page + 1}&${qs}`
      )
      .then((response) => {
        if (response?.data?.data?.results) {
          const data = response.data.data;
          this.setState({
            studentMentorData: data.results,
            startDate: data.from_date,
            endDate: data.to_date,
            isLoading: false,
            count: data.count,
          });
        }
      });
    // }
  };

  showSortingIcon = (header, overallHeader, label) => {
    return (
      <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
        <div style={{ lineHeight: 'initial' }}>{label || header}</div>
        <Icon
          style={{
            fontSize: '1.25vw',
            cursor: 'pointer',
            verticalAlign: 'middle',
            marginRight: 5,
          }}
          onClick={() => {
            this.setState({
              activeSort: {
                id: header,
                asc:
                  this.state.activeSort.id == header
                    ? !this.state.activeSort.asc
                    : true,
              },
              studentMentorData: this.state.studentMentorData.sort((a, b) => {
                const prevScore = overallHeader
                  ? a.percentage_score[overallHeader][header]
                  : a.percentage_score[header];
                const nextScore = overallHeader
                  ? b.percentage_score[overallHeader][header]
                  : b.percentage_score[header];
                return this.state.activeSort.id == header
                  ? this.state.activeSort.asc
                    ? nextScore - prevScore
                    : prevScore - nextScore
                  : prevScore - nextScore;
              }),
            });
          }}
        >
          {this.state.activeSort.id == header
            ? this.state.activeSort.asc
              ? 'arrow_downward_icon'
              : 'arrow_upward_icon'
            : 'sort_icon'}
        </Icon>
      </div>
    );
  };

  render() {
    const {
      studentMentorData,
      isLoading,
      count,
      page,
      startDate,
      endDate,
      showDateRange,
      packageId,
      packageData,
      classes,
      grade,
    } = this.state;
    return (
      <div className='studentMentorContainer'>
        <div className='studentMentorHeaderContainer'>
          <div className='studentMentorHeader'>Student Mentor View 3</div>

          <div style={{ width: '15vw' }}>
            <DateRangePickerModal
              defaultStartDate={moment(new Date())
                .subtract(2, 'd')
                .format('YYYY-MM-DD')}
              defaultDateRangeLabel='Last 2 days'
              hide={this.state.studentDetailId}
              fetchData={this.fetchStudentMentorData}
            />
          </div>
        </div>

        <div className='tableContainer'>
          {!showDateRange && (
            <KeyboardBackspace
              onClick={() => {
                this.setState(
                  { studentDetailId: null, showDateRange: true },
                  () => {
                    this.fetchStudentMentorData(startDate, endDate);
                  }
                );
              }}
              className='keyboard-backspace'
            />
          )}
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={3}>
                  Student Id{' '}
                  {
                    <TextField
                      type={'number'}
                      value={this.state.student_id}
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      onChange={(ev) => {
                        this.setState({ student_id: ev.target.value });
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          this.fetchStudentMentorData(startDate, endDate);
                        }
                      }}
                    />
                  }
                </TableCell>
                <TableCell rowSpan={3}>
                  Name
                  {
                    <TextField
                      type={'text'}
                      value={this.state.name}
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      onChange={(ev) => {
                        this.setState({ name: ev.target.value });
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          this.fetchStudentMentorData(startDate, endDate);
                        }
                      }}
                    />
                  }
                </TableCell>
                <TableCell rowSpan={3}>Phone</TableCell>
                <TableCell rowSpan={3}>Schedule at</TableCell>
                <TableCell rowSpan={3}>
                  Grade
                  {
                    <FormControl
                      style={{
                        width: '12vw',
                        backgroundColor: '#fff',
                        borderRadius: '4px',
                        height: '3.5vw',
                      }}
                    >
                      <InputLabel>Select Grade</InputLabel>
                      <Select
                        onChange={(e) => {
                          this.setState({ grade: e.target.value }, () => {
                            this.fetchStudentMentorData(startDate, endDate);
                          });
                        }}
                        value={grade}
                      >
                        <MenuItem value={0}>None</MenuItem>
                        {classes?.map((value) => {
                          return (
                            <MenuItem value={value.id}>{value.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  }
                </TableCell>
                <TableCell rowSpan={3}>
                  Teacher
                  {
                    <TextField
                      type={'text'}
                      value={this.state.teacher}
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      onChange={(ev) => {
                        this.setState({ teacher: ev.target.value });
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          console.log(ev.target.value);

                          this.fetchStudentMentorData(startDate, endDate);
                        }
                      }}
                    />
                  }
                </TableCell>
                <TableCell rowSpan={3}>
                  Package
                  {
                    <FormControl
                      style={{
                        width: '12vw',
                        backgroundColor: '#fff',
                        borderRadius: '4px',
                        height: '3.5vw',
                      }}
                    >
                      <InputLabel>Select Package</InputLabel>
                      <Select
                        onChange={(e) => {
                          this.setState({ packageId: e.target.value }, () => {
                            this.fetchStudentMentorData(startDate, endDate);
                          });
                        }}
                        value={packageId}
                      >
                        <MenuItem value={0}>None</MenuItem>
                        {packageData?.map((value) => {
                          return (
                            <MenuItem value={value.id}>{value.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  }
                </TableCell>
                <TableCell rowSpan={3}>Order</TableCell>
                <TableCell rowSpan={3}>Topic</TableCell>
                <TableCell rowSpan={3}>Ppt link</TableCell>
                <TableCell colSpan={4}>Percentage score</TableCell>
                <TableCell rowSpan={3}>Duration</TableCell>
                <TableCell rowSpan={3}>Feedback</TableCell>
                <TableCell rowSpan={3}>Recording link</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2}>{this.showSortingIcon('cw')}</TableCell>
                <TableCell rowSpan={2}>{this.showSortingIcon('hw')}</TableCell>
                <TableCell colSpan={2}>Overall Average</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={1}>
                  {this.showSortingIcon(
                    'student',
                    'overall',
                    "Student's Average"
                  )}
                </TableCell>
                <TableCell colSpan={1}>
                  {this.showSortingIcon(
                    'session',
                    'overall',
                    "Other's Average"
                  )}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {studentMentorData?.length ? (
                studentMentorData.map((studentMentor, index) => (
                  <TableRow>
                    {Object.keys(studentMentorData[0]).map((header) =>
                      header == 'percentage_score' ? (
                        <>
                          <TableCell>
                            {studentMentor[header].cw
                              ? studentMentor[header].cw
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {studentMentor[header].hw
                              ? studentMentor[header].hw
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {studentMentor[header].overall.student
                              ? studentMentor[header].overall.student
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {studentMentor[header].overall.session
                              ? studentMentor[header].overall.session
                              : '-'}
                          </TableCell>
                        </>
                      ) : (
                        <TableCell>
                          {header === 'name' ? (
                            <div
                              className='student-detail-arrow'
                              onClick={() =>
                                this.setState(
                                  {
                                    studentDetailId:
                                      studentMentorData[index].student_id,
                                    showDateRange: false,
                                  },
                                  () =>
                                    this.fetchStudentMentorData(
                                      startDate,
                                      endDate
                                    )
                                )
                              }
                            >
                              {studentMentor[header]}
                              <Icon>chevron_right_icon</Icon>
                            </div>
                          ) : header === 'scheduled_at' ? (
                            new Date(studentMentor[header]).toLocaleString()
                          ) : (header === 'ppt_link' ||
                              header === 'recording_link') &&
                            studentMentor[header] ? (
                            <Button
                              color='primary'
                              variant='contained'
                              size='small'
                              style={{ fontSize: 10 }}
                              onClick={() =>
                                window.open(studentMentor[header], '_blank')
                              }
                            >
                              View
                            </Button>
                          ) : (
                            studentMentor[header] || '-'
                          )}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))
              ) : studentMentorData?.length == 0 ? (
                <div className='centerAligned'>No data</div>
              ) : isLoading ? (
                <CircularProgress className='centerAligned' />
              ) : null}
            </TableBody>
          </Table>
        </div>

        {count ? (
          <TablePagination
            className='teacherTrainerPagination'
            rowsPerPageOptions={[50]}
            component='div'
            count={count}
            rowsPerPage={50}
            page={page}
            onChangePage={(event, newPage) => {
              this.setState({ page: newPage }, () =>
                this.fetchStudentMentorData(startDate, endDate)
              );
            }}
            onChangeRowsPerPage={(event) => {
              this.setState(
                { no_of_rows: parseInt(event.target.value, 10) },
                () => this.fetchStudentMentorData(startDate, endDate)
              );
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  return {
    adminId: states?.user?.user_id,
  };
};

export default connect(mapStateToProps)(StudentMentorView1);
