import React, {
  Fragment,
  useCallback,
  useState,
  useEffect,
  useReducer,
} from 'react';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { makeStyles, createStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Icon,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { Button, Chip } from '@material-ui/core';
import { Link } from 'react-router';

function StudentSessionDetails(props) {
  const useStyles = makeStyles((theme) =>
    createStyles({
      appBar: {
        position: 'relative',
      },
      flex: {
        flex: 1,
      },
      rightIcon: {
        marginLeft: theme.spacing(1),
      },
      formControl: {
        margin: `${theme.spacing(2)}px`,
        width: 450,
      },
      grid: {
        margin: '8px',
        padding: '0px 16px',
      },
      error: {
        color: '#f44336',
        fontSize: '12px',
        margin: `0px ${theme.spacing(2)}px`,
      },
      table: {
        border: null,
      },
      tablecell: {
        border: 'transparent !important',
        borderBottom: '2px solid rgba(224, 224, 224, 1) !important',
        padding: 15,
      },
      gridBox: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '12px',
      },
      card: {
        display: 'flex',
        border: '1px solid black',
        borderRadius: '6px',
        padding: '12px',
      },
      cardButton: { display: 'flex', alignItems: 'center' },
      cardFileName: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        wordBreak: 'break-word',
      },
    })
  );

  const classes = useStyles();
  const [studentId, setStudentId] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [sessionDetail, setSessionDetail] = useState(null);
  const [eachSessionDetail, setEachSessionDetail] = useState(null);
  const [filterList, setFilterList] = useState(['All']);
  const [filter, setFilter] = useState('All');
  const [academicYearFilterList, setAcademicYearFilterList] = useState([
    { id: 0, name: 'All' },
  ]);
  const [academicYearFilter, setAcademicYearFilter] = useState(0);

  const studentBaseUrl = (params) =>
    `${
      process.env.REACT_APP_LQLIVE_STUDENT_FRONTEND_SERVER ||
      'https://lq-live.logiqids.com/'
    }activity-admin-view?${params}&studentId=${studentId}&adminId=${
      props?.user?.user_id
    }&sessionToken=${props?.user?.session_token}`;

  useEffect(() => {
    setStudentId(
      props &&
        props.location &&
        props.location.query &&
        props.location.query.student_id
    ),
      getDetails(
        props &&
          props.location &&
          props.location.query &&
          props.location.query.student_id,
        0
      );
  }, []);

  function getDetails(student_id, academic_session_data) {
    setSessionDetail(null);
    const admin_id = props && props.user && props.user.user_id;
    setAcademicYearFilter(academic_session_data);
    const paramsData = new URLSearchParams({
      ...(academic_session_data && {
        academic_session_id: academic_session_data,
      }),
    }).toString();
    const apiParams = paramsData ? '?' + paramsData : '';
    console.log(apiParams);
    axios
      .get(
        `/v1/admin/${admin_id}/student/${student_id}/session/list/` + apiParams
      )
      .then((res) => {
        if (res.status == 200 && res) {
          setSessionDetail(res?.data?.data?.data);
          const filterData = [
            ...new Set(res?.data?.data?.data?.map((item) => item.track)),
          ];
          filterData.push('All');
          setFilterList(filterData);
          setAcademicYearFilterList(
            [{ id: 0, name: 'All' }].concat(res?.data?.data?.academic_sessions)
          );
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.data?.error;
        props.enqueueSnackbar(errorMessage || 'Something Went Wrong', {
          variant: 'error',
        });
        setSessionDetail([]);
      });
  }

  function getSessionDetails(session_id) {
    setEachSessionDetail(null);
    const admin_id = props && props.user && props.user.user_id;
    axios
      .get(
        `/v1/admin/${admin_id}/student/${studentId}/session/${session_id}/details/`
      )
      .then((res) => {
        if (res.status == 200 && res) {
          setEachSessionDetail(res && res.data && res.data.data);
        }
      });
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: '1.75vw', fontWeight: 500 }}>Sessions List</div>
        <FormControl style={{ minWidth: 150 }}>
          <InputLabel>Academic Year</InputLabel>
          <Select
            value={academicYearFilter}
            onChange={(e) => getDetails(studentId, e.target.value)}
            variant='standard'
          >
            {academicYearFilterList?.map((academicYearFilterData) => (
              <MenuItem value={academicYearFilterData.id}>
                {academicYearFilterData.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {sessionDetail ? (
        sessionDetail[0] ? (
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                {sessionDetail &&
                  sessionDetail[0] &&
                  Object.getOwnPropertyNames(
                    sessionDetail && sessionDetail[0]
                  ).map((header, i) => {
                    const headername = header.replaceAll('_', ' ');
                    return header != 'ptm' ? (
                      header == 'track' ? (
                        <TableCell className={classes.tablecell}>
                          <div>
                            {headername.charAt(0).toUpperCase() +
                              headername.slice(1)}
                          </div>
                          <FormControl style={{ minWidth: 100 }}>
                            <Select
                              value={filter || ''}
                              onChange={(e) => setFilter(e.target.value)}
                              variant='standard'
                            >
                              {filterList?.map((filterData) => (
                                <MenuItem value={filterData}>
                                  {filterData}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      ) : (
                        <TableCell className={classes.tablecell}>
                          {headername.charAt(0).toUpperCase() +
                            headername.slice(1)}
                        </TableCell>
                      )
                    ) : null;
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {sessionDetail &&
                sessionDetail.map((data) =>
                  filter == 'All' || filter == data.track ? (
                    <>
                      <TableRow
                        key={data.session_name}
                        style={{ position: 'relative' }}
                      >
                        {sessionDetail &&
                          sessionDetail[0] &&
                          Object.getOwnPropertyNames(
                            sessionDetail && sessionDetail[0]
                          ).map((header, i) => {
                            const cell =
                              header == 'scheduled_at' ||
                              header == 'join_time' ||
                              header == 'completed_at'
                                ? eval('data.' + header) &&
                                  new Date(
                                    eval('data.' + header)
                                  ).toLocaleString('en-IN', {
                                    year: '2-digit',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                  })
                                : eval('data.' + header);
                            return (
                              header != 'ptm' && (
                                <TableCell className={classes.tablecell}>
                                  {header == 'status'
                                    ? cell.charAt(0).toUpperCase() +
                                      cell.slice(1)
                                    : cell}
                                </TableCell>
                              )
                            );
                          })}
                        <TableCell className={classes.tablecell}>
                          <IconButton
                            onClick={() => {
                              sessionId != data.session_id &&
                                getSessionDetails(data.session_id),
                                setSessionId(
                                  sessionId == data.session_id
                                    ? null
                                    : data.session_id
                                );
                            }}
                          >
                            <Icon>
                              {data.session_id == sessionId
                                ? 'expand_less'
                                : 'expand_more'}
                            </Icon>
                          </IconButton>
                        </TableCell>
                        {data.ptm ? (
                          <Chip
                            label='PTM'
                            color='primary'
                            style={{
                              position: 'absolute',
                              height: 20,
                              marginTop: 5,
                              left: 0,
                            }}
                          />
                        ) : null}
                      </TableRow>
                      {sessionId == data.session_id && eachSessionDetail ? (
                        <TableRow>
                          <TableCell
                            align='left'
                            colSpan={11}
                            className={classes.tablecell}
                          >
                            <Collapse
                              in={open}
                              margin={1}
                              padding={1}
                              timeout='auto'
                              unmountOnExit
                              style={{
                                border: '2px solid grey',
                                borderRadius: 8,
                                padding: 8,
                              }}
                            >
                              <Box margin={1}>
                                <Typography
                                  variant='h6'
                                  gutterBottom
                                  component='div'
                                >
                                  Session Details
                                </Typography>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div>
                                    <Typography
                                      variant='body2'
                                      gutterBottom
                                      component='div'
                                      style={{
                                        fontWeight: 'bold',
                                        color: '#808080',
                                      }}
                                    >
                                      Session ID : {sessionId || '-'}
                                    </Typography>
                                    <Typography
                                      variant='body2'
                                      gutterBottom
                                      component='div'
                                      style={{
                                        fontWeight: 'bold',
                                        color: '#808080',
                                      }}
                                    >
                                      Teacher :{' '}
                                      {eachSessionDetail.teacher || '-'}
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      variant='body2'
                                      gutterBottom
                                      component='div'
                                      style={{
                                        fontWeight: 'bold',
                                        color: '#808080',
                                      }}
                                    >
                                      Rank : {eachSessionDetail.rank || '-'}
                                    </Typography>

                                    <Typography
                                      variant='body2'
                                      gutterBottom
                                      component='div'
                                      style={{
                                        fontWeight: 'bold',
                                        color: '#808080',
                                      }}
                                    >
                                      Marks : {eachSessionDetail.marks}
                                    </Typography>
                                  </div>
                                </div>
                                <Typography
                                  variant='body2'
                                  gutterBottom
                                  component='div'
                                  style={{
                                    fontWeight: 'bold',
                                    color: '#808080',
                                  }}
                                >
                                  Student Remark:{' '}
                                  {eachSessionDetail?.student_remark || '-'}
                                </Typography>
                                {eachSessionDetail?.ptm_remarks ? (
                                  <Typography
                                    variant='subtitle1'
                                    gutterBottom
                                    component='div'
                                    style={{
                                      fontWeight: 'bold',
                                      color: '#808080',
                                    }}
                                  >
                                    PTM Remarks: {eachSessionDetail.ptm_remarks}
                                  </Typography>
                                ) : null}
                                {eachSessionDetail?.activities ? (
                                  <Typography
                                    variant='subtitle2'
                                    gutterBottom
                                    component='div'
                                    style={{
                                      border: '1px solid black',
                                      borderRadius: 8,
                                      padding: 12,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Typography
                                        variant='subtitle1'
                                        gutterBottom
                                        component='div'
                                        style={{ fontWeight: 600 }}
                                      >
                                        Activity
                                      </Typography>
                                      {eachSessionDetail.activities &&
                                        (eachSessionDetail.activities.stars ||
                                          eachSessionDetail.activities.stars ==
                                            0) && (
                                          <Rating
                                            name='customized-empty'
                                            defaultValue={
                                              eachSessionDetail.activities &&
                                              eachSessionDetail.activities.stars
                                            }
                                            precision={0.5}
                                            emptyIcon={
                                              <StarBorderIcon fontSize='inherit' />
                                            }
                                            readOnly
                                          />
                                        )}
                                    </div>
                                    {eachSessionDetail &&
                                      eachSessionDetail.activities &&
                                      eachSessionDetail.activities.data &&
                                      eachSessionDetail.activities.data[0] && (
                                        <Table
                                          size='small'
                                          aria-label='purchases'
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell
                                                className={classes.tablecell}
                                              ></TableCell>
                                              {eachSessionDetail?.activities
                                                ?.data &&
                                                eachSessionDetail.activities
                                                  .data[0] &&
                                                Object.getOwnPropertyNames(
                                                  eachSessionDetail.activities
                                                    .data[0]
                                                ).map((header, i) => {
                                                  const headername =
                                                    header !=
                                                      'activity_paper_id' &&
                                                    header !=
                                                      'session_activity_id' &&
                                                    header.replaceAll('_', ' ');
                                                  return (
                                                    headername && (
                                                      <TableCell
                                                        className={
                                                          classes.tablecell
                                                        }
                                                      >
                                                        {headername
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                          headername.slice(1)}
                                                      </TableCell>
                                                    )
                                                  );
                                                })}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {eachSessionDetail &&
                                              eachSessionDetail.activities &&
                                              eachSessionDetail.activities
                                                .data &&
                                              eachSessionDetail.activities.data.map(
                                                (data) => (
                                                  <TableRow
                                                    key={data.activity_name}
                                                  >
                                                    <TableCell
                                                      className={
                                                        classes.tablecell
                                                      }
                                                    >
                                                      <Link
                                                        to={{
                                                          pathname:
                                                            studentBaseUrl(
                                                              `type=cw&activityPaperId=${data.activity_paper_id}&sessionScheduleId=${sessionId}`
                                                            ),
                                                        }}
                                                        target='_blank'
                                                        style={{
                                                          textDecoration:
                                                            'none',
                                                        }}
                                                      >
                                                        <Button
                                                          color='primary'
                                                          variant='outlined'
                                                        >
                                                          View Summary
                                                        </Button>
                                                      </Link>
                                                    </TableCell>
                                                    {eachSessionDetail &&
                                                      eachSessionDetail.activities &&
                                                      eachSessionDetail
                                                        .activities.data &&
                                                      eachSessionDetail
                                                        .activities.data[0] &&
                                                      Object.getOwnPropertyNames(
                                                        eachSessionDetail
                                                          .activities.data[0]
                                                      ).map((header, i) => {
                                                        const cell =
                                                          header !=
                                                            'activity_paper_id' &&
                                                          header !=
                                                            'session_activity_id' &&
                                                          eval(
                                                            'data.' + header
                                                          );
                                                        return (
                                                          cell && (
                                                            <TableCell
                                                              className={
                                                                classes.tablecell
                                                              }
                                                            >
                                                              {cell}
                                                            </TableCell>
                                                          )
                                                        );
                                                      })}
                                                  </TableRow>
                                                )
                                              )}
                                          </TableBody>
                                        </Table>
                                      )}
                                  </Typography>
                                ) : null}
                                {eachSessionDetail?.homework ? (
                                  <Typography
                                    variant='subtitle2'
                                    gutterBottom
                                    component='div'
                                    style={{
                                      border: '1px solid black',
                                      borderRadius: 8,
                                      padding: 12,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Typography
                                        variant='subtitle1'
                                        gutterBottom
                                        component='div'
                                        style={{ fontWeight: 600 }}
                                      >
                                        Homework
                                      </Typography>
                                      {eachSessionDetail.homework &&
                                        (eachSessionDetail.homework.stars ||
                                          eachSessionDetail.homework.stars ==
                                            0) && (
                                          <Rating
                                            name='customized-empty'
                                            defaultValue={
                                              eachSessionDetail.homework &&
                                              eachSessionDetail.homework.stars
                                            }
                                            precision={0.5}
                                            emptyIcon={
                                              <StarBorderIcon fontSize='inherit' />
                                            }
                                            readOnly
                                          />
                                        )}
                                    </div>
                                    {eachSessionDetail &&
                                      eachSessionDetail.homework &&
                                      eachSessionDetail.homework.data &&
                                      eachSessionDetail.homework.data[0] && (
                                        <Table
                                          size='small'
                                          aria-label='purchases'
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell
                                                className={classes.tablecell}
                                              />
                                              {eachSessionDetail &&
                                                eachSessionDetail.homework &&
                                                eachSessionDetail.homework
                                                  .data &&
                                                eachSessionDetail.homework
                                                  .data[0] &&
                                                Object.getOwnPropertyNames(
                                                  eachSessionDetail.homework
                                                    .data[0]
                                                ).map((header, i) => {
                                                  const headername =
                                                    header !=
                                                      'homework_paper_id' &&
                                                    header !=
                                                      'session_activity_id' &&
                                                    header.replaceAll('_', ' ');
                                                  return (
                                                    headername && (
                                                      <TableCell
                                                        className={
                                                          classes.tablecell
                                                        }
                                                      >
                                                        {headername
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                          headername.slice(1)}
                                                      </TableCell>
                                                    )
                                                  );
                                                })}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {eachSessionDetail &&
                                              eachSessionDetail.homework &&
                                              eachSessionDetail.homework.data &&
                                              eachSessionDetail.homework.data.map(
                                                (data) => (
                                                  <TableRow
                                                    key={data.activity_name}
                                                  >
                                                    <TableCell
                                                      className={
                                                        classes.tablecell
                                                      }
                                                    >
                                                      <Link
                                                        to={{
                                                          pathname:
                                                            studentBaseUrl(
                                                              `type=hw&homeworkPaperId=${data.homework_paper_id}&sessionScheduleId=${sessionId}`
                                                            ),
                                                        }}
                                                        target='_blank'
                                                        style={{
                                                          textDecoration:
                                                            'none',
                                                        }}
                                                      >
                                                        <Button
                                                          color='primary'
                                                          variant='outlined'
                                                        >
                                                          View Summary
                                                        </Button>
                                                      </Link>
                                                    </TableCell>
                                                    {eachSessionDetail &&
                                                      eachSessionDetail.homework &&
                                                      eachSessionDetail.homework
                                                        .data &&
                                                      eachSessionDetail.homework
                                                        .data[0] &&
                                                      Object.getOwnPropertyNames(
                                                        eachSessionDetail
                                                          .homework.data[0]
                                                      ).map((header, i) => {
                                                        const cell =
                                                          header ==
                                                            'scheduled_at' ||
                                                          header ==
                                                            'join_time' ||
                                                          header ==
                                                            'completed_at'
                                                            ? eval(
                                                                'data.' + header
                                                              ) &&
                                                              new Date(
                                                                eval(
                                                                  'data.' +
                                                                    header
                                                                )
                                                              ).toLocaleString(
                                                                'en-IN',
                                                                {
                                                                  year: '2-digit',
                                                                  month:
                                                                    '2-digit',
                                                                  day: '2-digit',
                                                                  hour: '2-digit',
                                                                  minute:
                                                                    '2-digit',
                                                                }
                                                              )
                                                            : header !=
                                                                'homework_paper_id' &&
                                                              header !=
                                                                'session_activity_id' &&
                                                              eval(
                                                                'data.' + header
                                                              );
                                                        return (
                                                          cell && (
                                                            <TableCell
                                                              className={
                                                                classes.tablecell
                                                              }
                                                            >
                                                              {cell || '-'}
                                                            </TableCell>
                                                          )
                                                        );
                                                      })}
                                                  </TableRow>
                                                )
                                              )}
                                          </TableBody>
                                        </Table>
                                      )}
                                  </Typography>
                                ) : null}
                                {eachSessionDetail.assessment ? (
                                  <Typography
                                    variant='subtitle2'
                                    gutterBottom
                                    component='div'
                                    style={{
                                      border: '1px solid black',
                                      borderRadius: 8,
                                      padding: 12,
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle1'
                                      gutterBottom
                                      component='div'
                                      style={{ fontWeight: 600 }}
                                    >
                                      Assessment
                                    </Typography>
                                    <Table size='small'>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            className={classes.tablecell}
                                          ></TableCell>
                                          {Object.getOwnPropertyNames(
                                            eachSessionDetail.assessment
                                          )?.map((header, i) => {
                                            const headername =
                                              header != 'assessment_paper_id' &&
                                              header != 'session_activity_id' &&
                                              header
                                                .replaceAll('_', ' ')
                                                .replaceAll('activity', '')
                                                .trim();
                                            return (
                                              headername && (
                                                <TableCell
                                                  className={classes.tablecell}
                                                >
                                                  {headername
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    headername.slice(1)}
                                                </TableCell>
                                              )
                                            );
                                          })}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow key={data.activity_name}>
                                          <TableCell
                                            className={classes.tablecell}
                                          >
                                            <Link
                                              to={{
                                                pathname: studentBaseUrl(
                                                  `type=am&assessmentPaperId=${eachSessionDetail.assessment.assessment_paper_id}`
                                                ),
                                              }}
                                              target='_blank'
                                              style={{
                                                textDecoration: 'none',
                                              }}
                                            >
                                              <Button
                                                color='primary'
                                                variant='outlined'
                                              >
                                                View Summary
                                              </Button>
                                            </Link>
                                          </TableCell>
                                          {Object.getOwnPropertyNames(
                                            eachSessionDetail.assessment
                                          ).map((header, i) => {
                                            const cell =
                                              header != 'assessment_paper_id' &&
                                              header != 'session_activity_id' &&
                                              eachSessionDetail.assessment[
                                                header
                                              ];
                                            return (
                                              cell && (
                                                <TableCell
                                                  className={classes.tablecell}
                                                >
                                                  {cell}
                                                </TableCell>
                                              )
                                            );
                                          })}
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </Typography>
                                ) : null}

                                {eachSessionDetail.assignments &&
                                eachSessionDetail.assignments.length ? (
                                  <Typography
                                    variant='subtitle2'
                                    gutterBottom
                                    component='div'
                                    style={{
                                      border: '1px solid black',
                                      borderRadius: 8,
                                      padding: 12,
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle1'
                                      gutterBottom
                                      component='div'
                                      style={{ fontWeight: 600 }}
                                    >
                                      Assignments
                                    </Typography>

                                    <div className={classes.gridBox}>
                                      {eachSessionDetail.assignments.map(
                                        (assignment) => (
                                          <div className={classes.card}>
                                            <div
                                              className={classes.cardFileName}
                                            >
                                              {assignment.filename}
                                            </div>

                                            <div className={classes.cardButton}>
                                              <Link
                                                to={assignment.assignment_link}
                                                target='_blank'
                                                style={{
                                                  textDecoration: 'none',
                                                }}
                                              >
                                                <Button
                                                  color='primary'
                                                  variant='outlined'
                                                  style={{ minWidth: '105px' }}
                                                >
                                                  View File
                                                </Button>
                                              </Link>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </Typography>
                                ) : null}

                                {eachSessionDetail?.stickers &&
                                eachSessionDetail.stickers[0] ? (
                                  <Typography
                                    variant='subtitle2'
                                    gutterBottom
                                    component='div'
                                    style={{
                                      border: '1px solid black',
                                      borderRadius: 8,
                                      padding: 12,
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle1'
                                      gutterBottom
                                      component='div'
                                      style={{ fontWeight: 600 }}
                                    >
                                      Sticker
                                    </Typography>
                                    {eachSessionDetail &&
                                      eachSessionDetail.stickers &&
                                      eachSessionDetail.stickers[0] && (
                                        <Table
                                          size='small'
                                          aria-label='purchases'
                                        >
                                          <TableHead>
                                            <TableRow>
                                              {eachSessionDetail &&
                                                eachSessionDetail.stickers &&
                                                eachSessionDetail.stickers[0] &&
                                                Object.getOwnPropertyNames(
                                                  eachSessionDetail.stickers[0]
                                                ).map((header, i) => {
                                                  const headername =
                                                    header == 'url'
                                                      ? 'Sticker'
                                                      : header.replaceAll(
                                                          '_',
                                                          ' '
                                                        );
                                                  return (
                                                    <TableCell
                                                      className={
                                                        classes.tablecell
                                                      }
                                                    >
                                                      {headername
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        headername.slice(1)}
                                                    </TableCell>
                                                  );
                                                })}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {eachSessionDetail &&
                                              eachSessionDetail.stickers &&
                                              eachSessionDetail.stickers.map(
                                                (data) => (
                                                  <TableRow key={data.name}>
                                                    {eachSessionDetail &&
                                                      eachSessionDetail.stickers &&
                                                      eachSessionDetail
                                                        .stickers[0] &&
                                                      Object.getOwnPropertyNames(
                                                        eachSessionDetail
                                                          .stickers[0]
                                                      ).map((header, i) => {
                                                        const cell = eval(
                                                          'data.' + header
                                                        );
                                                        return (
                                                          <TableCell
                                                            className={
                                                              classes.tablecell
                                                            }
                                                          >
                                                            {header == 'url' ? (
                                                              <img
                                                                src={data.url}
                                                                height={100}
                                                              />
                                                            ) : (
                                                              cell
                                                            )}
                                                          </TableCell>
                                                        );
                                                      })}
                                                  </TableRow>
                                                )
                                              )}
                                          </TableBody>
                                        </Table>
                                      )}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  ) : null
                )}
            </TableBody>
          </Table>
        ) : (
          <div style={{ textAlign: 'center' }}>No data to display</div>
        )
      ) : (
        <CircularProgress
          style={{
            margin: 'auto',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default withSnackbar(connect(mapStateToProps)(StudentSessionDetails));
