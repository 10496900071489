import React from 'react';
import UploadQuestionStyle from './style';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
  Icon,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Box,
  Typography,
  TextField,
  IconButton,
  Tooltip,
  List,
  ListItem,
  Paper,
} from '@material-ui/core';
import { Edit, Add } from '@material-ui/icons';
import { Link } from 'react-router';
import { withSnackbar } from 'notistack';

import CreateQuestion from '../CreateQuestion';
import Activity from '../../components/QuestionPreview/activityArena';
import HomeworkReordering from '../../components/HomeworkReordering';
import SaveChangesModal from '../../components/SaveChangesModal';

class UploadQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packageList: null,
      selectedPackage: false,
      gradeList: null,
      selectedGrade: false,
      sessionList: null,
      selectedSession: false,
      activityList: null,
      addActivityModalOpened: false,
      activateActivityModalOpened: false,
      questionList: null,
      questionListModalOpened: false,
      activateActivityData: null,
      activityOrder: null,
      assessmentDetails: {
        name: null,
        duration: null,
        topicsCovered: [''],
      },
      activityType: null,
      activityTypeList: [
        { label: 'Activity', value: 'Activity' },
        { label: 'Homework', value: 'Homework' },
        { label: 'Training', value: 'Training' },
        { label: 'Assessment', value: 'Assessment' },
      ],
      deleteMapping: null,
      unlinkMapping: null,
      questionAddModalOpened: false,
      session_activity_id: null,
      packageLabel: null,
      activityLabel: null,
      sessionLabel: null,
      isActivityLive: false,
      previewQuestionData: null,
      createMapping: false,
      objectivesSuggestions: [],
      assessmentEditState: false,
      activityCurrentIndex: 0,
      isFirstTopicsAvailable: true,
      showHomeworkReorderingModal: false,
    };
  }

  componentDidMount() {
    this.getPackageList();
  }

  getPackageList = () => {
    axios
      .get(`/v1/admin/${this.props.admin_id}/assign-package/`)
      .then((res) => {
        if (res && res.status == 200) {
          let data = res && res.data && res.data.data;
          data.map(
            (ele, index) =>
              (data[index] = { label: ele.id + '. ' + ele.name, value: ele.id })
          );
          this.setState({ packageList: data });
        }
      });
  };

  getGradeList = () => {
    axios.get('v1/classes/').then((res) => {
      if (res && res.status == 200) {
        let data = res && res.data && res.data.data;
        data.map(
          (ele, index) => (data[index] = { label: ele.name, value: ele.id })
        );
        this.setState({ gradeList: data });
      }
    });
  };
  getSessionList(class_id) {
    axios
      .get(
        `v1/admin/${this.props.admin_id}/content/${class_id}/sessions-list/?package_id=${this.state.selectedPackage}`
      )
      .then((res) => {
        if (res && res.status == 200) {
          let data = res && res.data && res.data.data;
          data.map(
            (ele, index) =>
              (data[index] = {
                label: ele.order + '. ' + ele.name,
                value: ele.id,
                order: ele.order,
              })
          );
          this.setState({ sessionList: data });
        }
      });
  }
  getActivityList() {
    axios
      .get(
        `v1/admin/${this.props.admin_id}/content/session/${this.state.selectedSession}/activities-list/?details=1`
      )
      .then((res) => {
        if (res && res.status == 200) {
          res?.data?.data?.activities &&
            this.setState({
              activityList: res.data.data.activities,
              objectivesSuggestions: res.data.data.objectives,
            });
        }
      });
    // .catch(error => {
    //   const errors = error && error.response && error.response.data && error.response.data.data && error.response.data.data.error
    // //   errors && errors[0] && errors.map((err)=>
    // //   this.props.enqueueSnackbar(err, {variant: 'error'})
    // //   )
    //   console.log(error, error.response);
    // });
  }
  getQuestionList(session_activity_id) {
    this.state.sessionList.filter((list) => {
      if (list.value == this.state.selectedSession) {
        axios
          .get(
            `v1/admin/${this.props.admin_id}/question/list/?session_activity_id=${session_activity_id}&package_id=${this.state.selectedPackage}`
          )
          .then((res) => {
            if (res && res.status == 200) {
              res.data &&
                res.data.data &&
                this.setState({
                  questionList: res.data.data,
                  questionListModalOpened: true,
                });
            }
          });
      }
    });
  }
  createMappingModal = () => {
    let question_number = null;
    let question_id = null;
    return (
      <Modal
        open={this.state.createMapping}
        onClose={() => {
          this.setState({ createMapping: null });
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            margin: '30vh 25vw',
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            width: '50vw',
            zIndex: 10,
            position: 'absolute',
            borderRadius: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: 50,
              gap: 20,
            }}
          >
            <List style={{ fontSize: 18 }}>Link Question</List>
            <TextField
              label='Question ID'
              variant='outlined'
              type='number'
              value={question_id}
              onChange={(event) => {
                question_id = event.target.value;
              }}
            />
            <TextField
              label='Order'
              variant='outlined'
              type='number'
              value={question_number}
              onChange={(event) => {
                question_number = event.target.value;
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => this.setState({ createMapping: null })}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  axios
                    .post(
                      `/v1/admin/${this.props.admin_id}/content/session/create-question-mapping/`,
                      {
                        question_id: question_id,
                        session_activity_id: this.state.session_activity_id,
                        question_number: question_number,
                      }
                    )
                    .then((res) => {
                      if (res && res.status && res.status == 200) {
                        this.props.enqueueSnackbar(
                          (res &&
                            res.data &&
                            res.data.data &&
                            res.data.data.message) ||
                            'Question linked successfully',
                          { variant: 'success' }
                        );
                        this.setState({ createMapping: null });
                        this.getQuestionList(this.state.session_activity_id);
                      }
                    })
                    .catch((error) => {
                      this.props.enqueueSnackbar(
                        error.response &&
                          error.response.data &&
                          error.response.data.data.error &&
                          error.response.data.data.error &&
                          error.response.data.data.error[0],
                        { variant: 'error' }
                      );
                    });
                }}
              >
                Link Question
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  deleteMappingModal = () => {
    return (
      <SaveChangesModal
        openModal={this.state.deleteMapping}
        closeModal={() => {
          this.setState({ deleteMapping: null });
        }}
        text={`Do you want to delete question id ${this.state.deleteMapping}?`}
        onSave={() => {
          axios
            .post(
              `/v1/admin/${this.props.admin_id}/content/session/delete-question/`,
              { question_id: this.state.deleteMapping }
            )
            .then((res) => {
              if (res && res.status && res.status == 200) {
                this.props.enqueueSnackbar(
                  (res && res.data && res.data.data && res.data.data.message) ||
                    'Question mapping deleted successfully',
                  { variant: 'success' }
                );
                this.setState({ deleteMapping: null });
                this.getQuestionList(this.state.session_activity_id);
              }
            })
            .catch((error) => {
              this.props.enqueueSnackbar(
                error?.response?.data?.data?.error || 'Something went wrong',
                { variant: 'error' }
              );
            });
        }}
      />
    );
  };

  editAssessment() {
    const { activityList, activityCurrentIndex } = this.state;
    const { assessmentDetails } = this.state;
    const url = `/v1/admin/${this.props.admin_id}/content/activity/${activityList[activityCurrentIndex].id}/update/`;
    const apiData = {
      name: assessmentDetails.name,
      duration: assessmentDetails.duration,
      topics_covered:
        assessmentDetails?.topicsCovered && assessmentDetails.topicsCovered[0]
          ? assessmentDetails.topicsCovered
          : [],
    };
    axios
      .post(url, apiData)
      .then((res) => {
        if (res && res.status == 200) {
          res?.data?.data?.message &&
            this.props.enqueueSnackbar(res.data.data.message, {
              variant: 'success',
            });
          this.getActivityList();
          this.setState({
            addActivityModalOpened: false,
            assessmentEditState: false,
          });
        }
      })
      .catch((error) => {
        const errors = error?.response?.data?.data?.error;
        errors?.map((err) =>
          this.props.enqueueSnackbar(err, { variant: 'error' })
        );
      });
  }

  addActivity() {
    const { assessmentDetails, activityType } = this.state;
    const url =
      activityType == 'Assessment'
        ? `/v1/admin/${this.props.admin_id}/content/session/${this.state.selectedSession}/update/`
        : `v1/admin/${this.props.admin_id}/content/session/add-activity/`;
    const apiData =
      this.state.activityType == 'Assessment'
        ? {
            update_type: 'assessment',
            assessment: {
              ...assessmentDetails,
              topics_covered:
                assessmentDetails.topicsCovered[0] != ''
                  ? assessmentDetails.topicsCovered
                  : [],
            },
          }
        : {
            activity_name: this.state.activityType,
            activity_order: this.state.activityOrder,
            is_competitive: this.state.activityType == 'Activity' ? 1 : 0,
            status: this.state.activityType == 'Training' ? 2 : 1,
            session_id: this.state.selectedSession,
            ...(assessmentDetails?.duration && {
              duration: assessmentDetails.duration,
            }),
          };
    axios
      .post(url, apiData)
      .then((res) => {
        if (res && res.status == 200) {
          res?.data?.data?.message &&
            this.props.enqueueSnackbar(res.data.data.message, {
              variant: 'success',
            });
          this.getActivityList();
          this.setState({
            activityType: null,
            activityOrder: null,
            addActivityModalOpened: false,
            assessmentEditState: false,
          });
        }
      })
      .catch((error) => {
        const errors = error?.response?.data?.data?.error;
        if (errors?.length)
          errors?.map((err) =>
            this.props.enqueueSnackbar(err, { variant: 'error' })
          );
        else
          this.props.enqueueSnackbar('Something went wrong', {
            variant: 'error',
          });
      });
  }
  getActivateActivity(activity_id, action) {
    axios
      .get(
        `v1/admin/${this.props.admin_id}/content/session/${activity_id}/activate/`
      )
      .then((res) => {
        if (res && res.status == 200) {
          this.setState({
            activateActivityModalOpened: activity_id,
            activateActivityData: { ...res.data.data, action },
          });
        }
      });
  }
  postActivateActivity(activity_id) {
    const { activateActivityData } = this.state;
    const { duration, action } = activateActivityData;
    axios
      .post(
        `v1/admin/${this.props.admin_id}/content/session/${activity_id}/activate/`,
        { ...(action == 'enable' && { duration: duration }), action }
      )
      .then((res) => {
        if (res && res.status == 200) {
          res.data &&
            res.data.data &&
            res.data.data.message &&
            this.props.enqueueSnackbar(res.data.data.message, {
              variant: 'success',
            });
          this.getActivityList();
          this.setState({
            activateActivityModalOpened: false,
            activateActivityData: null,
          });
        }
      })
      .catch((error) =>
        this.props.enqueueSnackbar(error?.response?.data?.data?.error, {
          variant: 'error',
        })
      );
  }
  onPackageChange = (event) => {
    this.setState({
      selectedPackage: event.target.value,
      selectedGrade: false,
      selectedSession: false,
      sessionList: null,
    });
    this.state.packageList &&
      this.state.packageList.filter((list) => {
        if (list.value == event.target.value)
          this.setState({ packageLabel: list.label });
      });
    this.getGradeList();
  };
  onGradeChange = (event) => {
    this.setState({
      selectedGrade: event.target.value,
      selectedSession: false,
      sessionList: null,
    });
    this.state.selectedPackage && this.getSessionList(event.target.value);
  };
  onSessionChange = (event) => {
    this.setState({ selectedSession: event.target.value });
    this.state.sessionList &&
      this.state.sessionList.filter((list) => {
        if (list.value == event.target.value)
          this.setState({ sessionLabel: list.label });
      });
  };

  filter(value, listData, label, onChangeFunction) {
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ width: '20%' }}>
        <InputLabel
          id={label}
          style={{
            fontSize: 22,
            fontWeight: 500,
            marginTop: -10,
            color: '#000',
          }}
        >
          {label}
        </InputLabel>
        <Select
          labelId={label}
          id={label}
          value={parseInt(value)}
          label={label}
          onChange={onChangeFunction}
          disabled={!listData}
          defaultValue=''
        >
          {listData &&
            listData.map((list) => (
              <MenuItem value={list.value}>{list.label}</MenuItem>
            ))}
        </Select>
        <FormHelperText>Please select {label}</FormHelperText>
      </FormControl>
    );
  }

  assessmentModalDetail() {
    const {
      assessmentDetails,
      isFirstTopicsAvailable,
      activityType,
      assessmentEditState,
    } = this.state;
    const assessmentTopics = assessmentDetails.topicsCovered;
    return (
      <>
        {activityType == 'Activity' ? null : (
          <TextField
            label='Name'
            variant='outlined'
            type='text'
            value={assessmentDetails?.name}
            onChange={(event) =>
              this.setState({
                assessmentDetails: {
                  ...assessmentDetails,
                  name: event.target.value,
                },
              })
            }
            disabled={assessmentDetails.type == 'activity'}
          />
        )}
        {assessmentEditState ? (
          <TextField
            label='Duration(in minutes)'
            variant='outlined'
            type='number'
            value={assessmentDetails?.duration}
            onChange={(event) =>
              this.setState({
                assessmentDetails: {
                  ...assessmentDetails,
                  duration: parseInt(event.target.value),
                },
              })
            }
          />
        ) : null}
        {activityType == 'Assessment' ||
        assessmentDetails.type == 'assessment' ? (
          <div
            style={{
              width: '100%',
              border: '1px solid  rgb(196 196 196)',
              borderRadius: '5px',
              padding: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h3>Focus Areas</h3>
              <Add
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  if (assessmentTopics[assessmentTopics.length - 1] == '') {
                    this.setState({ isFirstTopicsAvailable: false });
                  } else {
                    this.setState({
                      assessmentDetails: {
                        ...assessmentDetails,
                        topicsCovered: [...assessmentDetails.topicsCovered, ''],
                      },
                      isFirstTopicsAvailable: true,
                    });
                  }
                }}
              />
            </div>
            <div>
              {(assessmentDetails?.topicsCovered || [''])?.map((val, index) => {
                return (
                  <>
                    <TextField
                      style={{ width: '100%', marginBottom: '1vw' }}
                      label='Topics covered'
                      variant='outlined'
                      type='text'
                      value={val}
                      helperText={
                        isFirstTopicsAvailable
                          ? ''
                          : 'Focus Areas topics required'
                      }
                      error={isFirstTopicsAvailable ? false : true}
                      onChange={(event) => {
                        const assessmentNewArr = [
                          ...assessmentDetails.topicsCovered,
                        ];
                        assessmentNewArr[index] = event.target.value;
                        this.setState({
                          assessmentDetails: {
                            ...assessmentDetails,
                            topicsCovered: assessmentNewArr,
                          },
                        });
                      }}
                    />
                  </>
                );
              })}
            </div>
          </div>
        ) : null}
      </>
    );
  }

  addActivityModal() {
    const {
      assessmentDetails,
      assessmentEditState,
      addActivityModalOpened,
      activityOrder,
      activityType,
    } = this.state;
    return (
      <Modal
        open={addActivityModalOpened}
        onClose={() => {
          this.setState({
            addActivityModalOpened: false,
            activityType: null,
            activityOrder: null,
            assessmentDetails: {
              name: null,
              duration: null,
              topicsCovered: [''],
            },
          });
        }}
      >
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            backgroundColor: '#fff',
            border: '2px solid #000',
            boxShadow: 24,
            padding: 30,
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            overflow: 'auto',
            maxHeight: '80vh',
            height: 'fit-content',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant='h6' component='h2' style={{ p: 2 }}>
              {assessmentEditState
                ? `Edit ${assessmentDetails.type}`
                : 'Add Activity/Homework/Training/Assessment'}
            </Typography>
            <IconButton
              onClick={() =>
                this.setState({
                  addActivityModalOpened: false,
                  assessmentDetails: {
                    name: null,
                    duration: null,
                    topicsCovered: [''],
                  },
                  assessmentEditState: false,
                })
              }
            >
              <Icon>close</Icon>
            </IconButton>
          </div>
          {assessmentEditState ? null : (
            <FormControl>
              <InputLabel>Type</InputLabel>
              <Select
                value={this.state.activityType}
                label='Type'
                variant='standard'
                defaultValue=''
                onChange={(event) =>
                  this.setState({ activityType: event.target.value })
                }
              >
                {this.state.activityTypeList &&
                  this.state.activityTypeList.map((list) => (
                    <MenuItem value={list.value}>{list.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {activityType == 'Assessment' || assessmentEditState ? null : (
            <TextField
              label='Order'
              variant='outlined'
              type='number'
              value={this.state.activityOrder}
              onChange={(event) =>
                this.setState({
                  activityOrder: parseInt(event.target.value),
                })
              }
            />
          )}
          {assessmentEditState ||
          this.state.activityType == 'Assessment' ||
          this.state.activityType == 'Activity'
            ? this.assessmentModalDetail()
            : null}
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              if (assessmentEditState) this.editAssessment();
              else this.addActivity();
              this.setState({ isFirstTopicsAvailable: true });
            }}
            disabled={
              assessmentEditState
                ? !(assessmentDetails?.name && assessmentDetails?.duration)
                : !(activityOrder && activityType)
            }
          >
            {assessmentEditState ? 'Submit' : 'Add'}
          </Button>
        </Box>
      </Modal>
    );
  }
  activateActivityModal() {
    const { activateActivityData } = this.state;
    return (
      <Modal
        open={this.state.activateActivityModalOpened}
        onClose={() => this.setState({ activateActivityModalOpened: false })}
      >
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            backgroundColor: '#fff',
            border: '2px solid #000',
            boxShadow: 24,
            padding: 30,
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h6' component='h2' style={{ p: 2 }}>
              {`Do you want to ${activateActivityData?.action} this activity?`}
            </Typography>
            <IconButton
              onClick={() =>
                this.setState({ activateActivityModalOpened: false })
              }
            >
              <Icon>close</Icon>
            </IconButton>
          </div>
          <Typography>Name : {activateActivityData?.activity_name}</Typography>
          <Typography>
            No. of questions : {activateActivityData?.no_of_questions}
          </Typography>
          {activateActivityData?.activity_type === 'assessment' ||
          activateActivityData?.activity_type === 'activity' ? (
            <TextField
              label='Duration'
              variant='outlined'
              type='number'
              disabled={
                !activateActivityData?.edit_duration ||
                activateActivityData?.action == 'disable'
              }
              value={activateActivityData?.duration}
              onChange={(event) => {
                const activateActivityTempData = activateActivityData;
                activateActivityTempData.duration = event.target.value;
                this.setState({
                  activateActivityData: activateActivityTempData,
                });
              }}
            />
          ) : null}
          <Button
            variant='contained'
            color='primary'
            onClick={() =>
              this.postActivateActivity(this.state.activateActivityModalOpened)
            }
          >
            YES
          </Button>
        </Box>
      </Modal>
    );
  }

  questionAddModal() {
    return (
      <Box
        style={{
          backgroundColor: '#fff',
          border: '2px solid #000',
          boxShadow: 24,
          padding: 30,
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          justifyContent: 'center',
          margin: '10px 20px 10px 20px',
          width: '80vw',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            id='modal-modal-title'
            variant='h5'
            component='h2'
            style={{ p: 2, fontWeight: 600, fontSize: 24 }}
          >
            Add Question
          </Typography>
          {/* <IconButton onClick={()=>this.setState({ questionAddModalOpened: false})}>
                <Icon>close</Icon>
                </IconButton> */}
        </div>
        <CreateQuestion
          session_activity_id={this.state.session_activity_id}
          questionListlength={
            this.state.questionList &&
            this.state.questionList.results &&
            this.state.questionList.results.length
          }
          time={
            this.state.activityLabel &&
            this.state.activityLabel.includes('Activity')
              ? 180
              : ''
          }
          onSuccess={() => {
            this.setState({ questionAddModalOpened: false }),
              this.getQuestionList(this.state.session_activity_id),
              this.getActivityList();
          }}
          objectivesSuggestions={this.state.objectivesSuggestions}
        />
      </Box>
    );
  }

  questionListModal() {
    const questionListData =
      this.state.questionList && this.state.questionList.results;
    return (
      <Box
        style={{
          width: '90vw',
          border: '1px solid #000',
          boxShadow: 24,
          padding: 30,
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          justifyContent: 'center',
          overflowX: 'scroll',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h6' component='h2'>
            Question Table
          </Typography>
          <div style={{ display: 'flex', gap: 10 }}>
            <Button
              variant='contained'
              style={this.state.isActivityLive ? { display: 'none' } : null}
              color='primary'
              onClick={() => this.setState({ questionAddModalOpened: true })}
            >
              <Icon>add</Icon>Add Question
            </Button>
            <Button
              variant='contained'
              style={this.state.isActivityLive ? { display: 'none' } : null}
              color='primary'
              onClick={() => this.setState({ createMapping: true })}
            >
              <Icon>add_link_icon</Icon>Link Question
            </Button>
          </div>
        </div>
        {questionListData && questionListData[0] ? (
          <Table>
            <TableHead>
              <TableRow className='tableRow'>
                <TableCell className='tableCell'></TableCell>
                <TableCell className='tableCell'>Edit</TableCell>

                <TableCell className='tableCell'>Id</TableCell>
                <TableCell className='tableCell'>Question</TableCell>
                <TableCell className='tableCell'>Options</TableCell>
                <TableCell className='tableCell'>Answer</TableCell>
                <TableCell className='tableCell'>Solution</TableCell>
                <TableCell className='tableCell'>Activity type</TableCell>
                <TableCell className='tableCell'>Time</TableCell>
                <TableCell className='tableCell'>Marks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questionListData.map((questionListData, index) => (
                <TableRow className='tableRow'>
                  <TableCell className='tableCell'>Q.{index + 1}</TableCell>
                  <TableCell className='tableCell'>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Link
                        to={{
                          pathname: '/questions/edit',
                          search: `?questionId=${questionListData.id}`,
                        }}
                        target='_blank'
                        style={{ textDecoration: 'none' }}
                      >
                        <IconButton>
                          <Tooltip title='Edit'>
                            <Icon>edit</Icon>
                          </Tooltip>
                        </IconButton>
                      </Link>
                      <IconButton
                        onClick={() =>
                          this.setState({
                            previewQuestionData: {
                              questions: [
                                {
                                  type:
                                    'single_select' ||
                                    questionListData.activity_type,
                                  text:
                                    questionListData.question &&
                                    questionListData.question.text,
                                  image:
                                    questionListData.question &&
                                    questionListData.question.image,
                                  options: questionListData.options,
                                  marks: questionListData.marks,
                                  time: questionListData.time,
                                  totalQuestions:
                                    this.state.questionList &&
                                    this.state.questionList.results &&
                                    this.state.questionList.results.length,
                                  currentQuestion: index + 1,
                                  media: questionListData.question.media,
                                  media_type:
                                    questionListData.question.media_type,
                                },
                              ],
                            },
                          })
                        }
                      >
                        <Tooltip title='Preview Question'>
                          <Icon>library_books</Icon>
                        </Tooltip>
                      </IconButton>
                      {this.state.isActivityLive ? null : (
                        <IconButton
                          onClick={() =>
                            this.setState({
                              deleteMapping: questionListData.id,
                            })
                          }
                        >
                          <Tooltip title='Delete Question'>
                            <Icon>delete</Icon>
                          </Tooltip>
                        </IconButton>
                      )}
                      {this.state.isActivityLive ? null : (
                        <IconButton
                          onClick={() =>
                            this.setState({
                              unlinkMapping: questionListData.id,
                            })
                          }
                        >
                          <Tooltip title='Unlink Question'>
                            <Icon>link_off</Icon>
                          </Tooltip>
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className='tableCell'>
                    {questionListData.id}
                  </TableCell>
                  <TableCell className='tableCell'>
                    {
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          maxWidth: 300,
                          textAlign: 'left',
                        }}
                      >
                        {questionListData.question && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                questionListData.question.text &&
                                questionListData.question.text.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  '<br />'
                                ),
                            }}
                          />
                        )}
                        {questionListData.question &&
                          questionListData.question.image && (
                            <img
                              src={questionListData.question.image}
                              style={{
                                objectFit: 'scale-down',
                                maxHeight: 200,
                                maxWidth: 300,
                              }}
                            />
                          )}
                        {questionListData.question &&
                        questionListData.question.media &&
                        questionListData.question.media_type ? (
                          questionListData.question.media_type == 'audio' ? (
                            <audio controls>
                              <source src={questionListData.question.media} />
                            </audio>
                          ) : (
                            <video width='auto' height='200px' controls>
                              <source src={questionListData.question.media} />
                            </video>
                          )
                        ) : null}
                      </div>
                    }
                  </TableCell>
                  <TableCell className='tableCell'>
                    {
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 10,
                        }}
                      >
                        {questionListData.options &&
                          questionListData.options.map((option, index) => (
                            <div
                              style={{
                                display: 'flex',
                                gap: 10,
                                maxWidth: 300,
                              }}
                            >
                              {option.order && <div>{option.order})</div>}
                              {option.text && <div>{option.text}</div>}
                              {option.image && (
                                <img
                                  src={option.image}
                                  style={{
                                    objectFit: 'scale-down',
                                    maxHeight: 100,
                                    maxWidth: 300,
                                  }}
                                />
                              )}
                              {questionListData.activity_type ==
                                'Single Select' &&
                                option &&
                                option.order &&
                                questionListData.answer.slice(1, 2) ==
                                  option.order && (
                                  <div style={{ alignSelf: 'center' }}>
                                    {' '}
                                    &#9989;
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                    }
                  </TableCell>
                  <TableCell className='tableCell'>
                    {questionListData.answer}
                  </TableCell>
                  <TableCell className='tableCell'>
                    {questionListData.solution &&
                      (questionListData.solution.sol_img ||
                        questionListData.solution.sol_text) && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: 300,
                          }}
                        >
                          {questionListData.solution && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  questionListData.solution.sol_text &&
                                  questionListData.solution.sol_text.replace(
                                    /(?:\r\n|\r|\n)/g,
                                    '<br />'
                                  ),
                              }}
                            />
                          )}
                          {questionListData.solution &&
                            questionListData.solution.sol_img && (
                              <img
                                src={questionListData.solution.sol_img}
                                style={{
                                  objectFit: 'scale-down',
                                  maxHeight: 200,
                                  maxWidth: 300,
                                }}
                              />
                            )}
                        </div>
                      )}
                  </TableCell>
                  <TableCell className='tableCell'>
                    {questionListData.activity_type}
                  </TableCell>
                  <TableCell className='tableCell'>
                    {questionListData.time}
                  </TableCell>
                  <TableCell className='tableCell'>
                    {questionListData.marks}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          'No Questions in this Activity'
        )}
      </Box>
    );
  }
  questionPreviewModalOpen() {
    return (
      <Modal
        open={this.state.previewQuestionData}
        onClose={() => this.setState({ previewQuestionData: null })}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: '#fff',
            border: '2px solid #000',
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}
        >
          <Activity
            data={this.state.previewQuestionData}
            onClose={() => this.setState({ previewQuestionData: null })}
          />
        </Box>
      </Modal>
    );
  }

  homeworkReorderingModal() {
    const { activityList, showHomeworkReorderingModal, selectedSession } =
      this.state;
    return showHomeworkReorderingModal ? (
      <HomeworkReordering
        openModal={showHomeworkReorderingModal}
        closeModal={() => this.setState({ showHomeworkReorderingModal: false })}
        homeworkData={activityList?.filter(
          (activityData) => activityData?.type == 'homework'
        )}
        sessionId={selectedSession}
        getActivityList={() => this.getActivityList()}
      />
    ) : null;
  }

  render() {
    const {
      activityList,
      showHomeworkReorderingModal,
      selectedSession,
      unlinkMapping,
      session_activity_id,
    } = this.state;

    return this.state.previewQuestionData ? (
      this.questionPreviewModalOpen()
    ) : this.state.questionListModalOpened ? (
      <UploadQuestionStyle>
        {this.deleteMappingModal()}
        {this.state.createMapping ? this.createMappingModal() : null}
        <SaveChangesModal
          openModal={unlinkMapping}
          closeModal={() => this.setState({ unlinkMapping: null })}
          text={`Do you want to unlink question id ${unlinkMapping}?`}
          onSave={() => {
            axios
              .post(
                `/v1/admin/${this.props.admin_id}/content/session/disable-question-mapping/`,
                {
                  action: 'update',
                  question_id: unlinkMapping,
                  session_activity_id,
                }
              )
              .then((res) => {
                if (res && res.status && res.status == 200) {
                  this.props.enqueueSnackbar(
                    res?.data?.data?.message ||
                      'Question unlinked successfully',
                    { variant: 'success' }
                  );
                  this.setState({ unlinkMapping: null });
                  this.getQuestionList(session_activity_id);
                }
              })
              .catch((error) => {
                this.props.enqueueSnackbar(
                  error?.response?.data?.data?.error?.[0] ||
                    'Something went wrong',
                  { variant: 'error' }
                );
              });
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            justifyContent: 'space-between',
            alignSelf: 'stretch',
            width: '90vw',
            alignSelf: 'center',
            padding: 20,
            color: '#000',
            borderColor: '#3f51b5',
            boxShadow:
              '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
            borderRadius: 5,
            border: '1px solid #000',
          }}
          // color='primary' variant='contained' style={{display:'flex', justifyContent:'space-between'}}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant='contained'
              color='primary'
              onClick={() =>
                this.state.questionAddModalOpened
                  ? this.setState({ questionAddModalOpened: false })
                  : this.setState({ questionListModalOpened: false }, () =>
                      this.getActivityList()
                    )
              }
            >
              <Icon>keyboard_backspace_icon</Icon>
              <Typography>Go back</Typography>
            </Button>
            <Button variant='outlined' color='black'>
              <Typography variant='h6' style={{ fontWeight: 600 }}>
                {this.state.activityLabel}
              </Typography>
            </Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant='outlined' color='primary'>
              <Typography>Package: {this.state.packageLabel}</Typography>
            </Button>
            <Button variant='outlined' color='primary'>
              <Typography>Session {this.state.sessionLabel}</Typography>
            </Button>
            <Button variant='outlined' color='primary'>
              <Typography>Grade: {this.state.selectedGrade}</Typography>
            </Button>
          </div>
        </div>
        {this.state.questionAddModalOpened
          ? this.questionAddModal()
          : this.questionListModal()}
      </UploadQuestionStyle>
    ) : (
      <UploadQuestionStyle>
        {this.addActivityModal()}
        {this.activateActivityModal()}
        {this.homeworkReorderingModal()}
        <Typography style={{ fontSize: 24, fontWeight: 600, marginBottom: 30 }}>
          Upload Questions
        </Typography>
        <div className='filters'>
          {this.filter(
            this.state.selectedPackage,
            this.state.packageList,
            'Package',
            this.onPackageChange
          )}
          {this.filter(
            this.state.selectedGrade,
            this.state.gradeList,
            'Grade',
            this.onGradeChange
          )}
          {this.filter(
            this.state.selectedSession,
            this.state.sessionList,
            'Session',
            this.onSessionChange
          )}
          <Button
            variant='contained'
            color='primary'
            onClick={() => this.getActivityList()}
            disabled={
              !(
                this.state.selectedPackage &&
                this.state.selectedGrade &&
                this.state.selectedSession
              )
            }
          >
            Search<Icon>play_arrow</Icon>
          </Button>
          {activityList ? (
            <Button
              variant='outlined'
              color='primary'
              onClick={() =>
                this.setState({ showHomeworkReorderingModal: true })
              }
              // size='small'
              style={{ fontSize: '1vw' }}
            >
              Homework Reordering<Icon>low_priority_icon</Icon>
            </Button>
          ) : null}
        </div>
        {(this.state.activityList && this.state.activityList[0] && (
          <Table>
            <TableHead>
              <TableRow className='tableRow'>
                {Object.getOwnPropertyNames(this.state.activityList[0]).map(
                  (header, i) => {
                    const headername = header.replaceAll('_', ' ');
                    return (
                      header != 'is_active' &&
                      header != 'type' &&
                      header != 'topics_covered' && (
                        <TableCell className='tableCell'>
                          {headername.charAt(0).toUpperCase() +
                            headername.slice(1)}
                        </TableCell>
                      )
                    );
                  }
                )}
                <TableCell className='tableCell'>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.activityList.map((rows, index) => (
                <TableRow className='tableRow'>
                  {Object.getOwnPropertyNames(this.state.activityList[0]).map(
                    (header, i) => {
                      const cell = rows[header];
                      return (
                        header != 'is_active' &&
                        header != 'type' &&
                        header != 'topics_covered' && (
                          <TableCell className='tableCell'>{cell}</TableCell>
                        )
                      );
                    }
                  )}
                  <TableCell
                    className='tableCell'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 10,
                    }}
                  >
                    {rows.is_active ? (
                      <>
                        <Button
                          variant='contained'
                          style={{ backgroundColor: 'green', color: 'white' }}
                          disabled
                        >
                          LIVE
                        </Button>
                        <Button
                          variant='outlined'
                          style={{ borderColor: 'green', color: 'green' }}
                          onClick={() =>
                            this.getActivateActivity(rows.id, 'disable')
                          }
                        >
                          Disable
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant='outlined'
                        style={
                          rows.no_of_questions > 0
                            ? { borderColor: 'green', color: 'green' }
                            : null
                        }
                        onClick={() =>
                          this.getActivateActivity(rows.id, 'enable')
                        }
                        disabled={rows.no_of_questions <= 0}
                      >
                        GO LIVE <Icon>play_arrow</Icon>
                      </Button>
                    )}
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => {
                        this.getQuestionList(rows.id),
                          this.setState({
                            session_activity_id: rows.id,
                            activityLabel: rows.activity_name,
                            isActivityLive: rows.is_active,
                          });
                      }}
                    >
                      Questions{' '}
                      <Icon style={{ fontSize: 'small' }}>
                        arrow_forward_ios_icon
                      </Icon>
                    </Button>
                    {rows.type === 'assessment' || rows.type === 'activity' ? (
                      <Button
                        onClick={() =>
                          this.setState({
                            addActivityModalOpened: true,
                            assessmentEditState: true,
                            activityCurrentIndex: index,
                            assessmentDetails: {
                              name: this.state.activityList[index]
                                .activity_name,
                              duration: this.state.activityList[index].duration,
                              topicsCovered:
                                this.state.activityList[index]
                                  .topics_covered === null
                                  ? ['']
                                  : this.state.activityList[index]
                                      .topics_covered,
                              type: rows.type,
                            },
                          })
                        }
                      >
                        <Edit className='activity-edit-icon' />
                      </Button>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )) ||
          (this.state.activityList &&
            !this.state.activityList[0] &&
            'No activity to display')}
        {this.state.activityList && (
          <Button
            variant='contained'
            color='primary'
            style={{ alignSelf: 'flex-start' }}
            onClick={() => this.setState({ addActivityModalOpened: true })}
          >
            <Icon>add</Icon>Add Activity/Homework/Training/Assessment
          </Button>
        )}
      </UploadQuestionStyle>
    );
  }
}

function mapStateToProps(state) {
  return {
    admin_id: state.user && state.user.user_id,
  };
}

export default withSnackbar(connect(mapStateToProps)(UploadQuestion));
