import styled from 'styled-components';
import base from '../../styles/base';

const LQDataTableContainer = styled.div`
    ${base.flex};
    ${base.flexcenter};
    flex-direction: column;
    @media (min-width: ${base.small}) {
        padding-top: 93px;
    }
`;

export const DemoContainer = styled.div`
    ${base.flex};
    ${base.flexcenter};
    flex-direction: column;
    @media (min-width: ${base.small}) {
        padding-top: 93px;
    }
`;

export default { LQDataTableContainer };
