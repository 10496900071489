import React, { useEffect, useState, Fragment, useRef } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router';
import {
  ListItem,
  Modal,
  List,
  makeStyles,
  Grid,
  Button
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import SaveIcon from '@material-ui/icons/SaveRounded';
import Table from '../../components/LQDataTables/demo';
import EditSessionDetails from '../../components/EditSessionDetails'
import StudentSessionStatus from '../../components/StudentSessionStatus'
import SaveChangesModal from '../../components/SaveChangesModal'
import DetailDisplayModal from '../../components/DetailDisplayModal';
import axios from 'axios'
import { connect } from "react-redux";
import ContentHistoryModal from '../../components/ContentHistoryModal';


const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2
  },
  iconButton: {
    fontSize: 12
  },
  formControl: {
    width: '53%'
  },

  packageClassDetails: {
    '& a': {
      color: '#000'
    }
  }
});

let tableRef = null;

const ref = React.createRef();

function setRowProps(row, index, tabledata) {
  const status = tabledata?.originalData[index]?.status
  return status ? {
    style: {
      backgroundColor: "rgba(255, 0, 0, 0.3)",
    }
  } : {
    style: {
      backgroundColor: "rgba(0, 255, 0, 0.3)",
    }
  };
}

function QueryTable(props) {
  const classes = userStyles();
  const [markResolve, setMarkResolve] = useState(null)
  const [sessionDetailModal, setSessionDetailModal] = useState(null)
  const [contentUpdateModal, setContentUpdateModal] = useState(null)

  function postMarkResolve() {
    axios
      .post(`/v1/admin/${props?.user?.user_id}/query/${markResolve}/change-status/`, {
        "status": 0
      })
      .then((response) => {
        if (response && response.status === 200) {
          props.enqueueSnackbar(response?.data?.data?.message || "Query Marked Resolved", { variant: "success" });
          setMarkResolve(null);
          tableRef && tableRef.refreshTable();
        }
      })
      .catch((err) => {
        console.log("error ", err.response);
        props.enqueueSnackbar(err?.response?.data?.data?.error && err.response.data.data.error[0] ||
          "Something Went Wrong", { variant: 'error' }
        );
      });

  }

  function sessionData() {
    return <div style={{ display: 'flex', flexDirection: 'column', gap: 5, fontSize: '1.25vw' }}>
      <div>Topic Name: {sessionDetailModal.topic_name}</div>
      <div>Topic Id: {sessionDetailModal.topic_id}</div>
      <div>Grade: {sessionDetailModal.Grade}</div>
      <div>Package: {sessionDetailModal.Package}</div>
      <div>Session: {sessionDetailModal.Session}</div>
      <Button color="primary"
        variant="contained"
        size='small'
        style={{ fontSize: '1vw', width: 'fit-content' }}
        onClick={() => window.open(sessionDetailModal.ppt_link, '_blank')}>View PPT Link</Button>
    </div>
  }
  const ref = React.createRef();
  function formatTable(tableData) {
    const newData =
      tableData &&
      tableData.map((entry, index) => {
        const {
          id,
          user,
          screenshot,
          date,
          status,
          question_id,
          source,
          session_details
        } = entry;

        const newEntry = { ...entry };
        newEntry.session_details = session_details ? <div>
          <div>{session_details.topic_name} - {session_details.Grade}</div>
          <Button color="primary"
            variant="contained"
            size='small'
            style={{ fontSize: 10 }}
            onClick={() => setSessionDetailModal(session_details)}
          >
            View Details
          </Button>
        </div>
          : null
        newEntry.user = (<Link
          to={{
            pathname: '/' + (user.sent_by == 'Student' ? 'user' : 'teacher'),
            search: `?id=${user.user_id}`
          }}
          target='_blank'
          style={{ textDecoration: 'none' }}
        >{user.name} ({user.sent_by})</Link>)
        newEntry.question_id = question_id ? <Link
          to={{
            pathname: '/questions',
            search: `?id=${question_id}`
          }}
          target='_blank'
          style={{ textDecoration: 'none' }}
        >{question_id}</Link> :
          null
        newEntry.source = source
        newEntry.date = date && new Date(date).toLocaleString('en-IN', {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit"
        })
        newEntry.screenshot = screenshot && <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Link
            to={{
              pathname: screenshot,
            }}
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            <Button color="primary"
              variant="contained"
              size='small'
              style={{ fontSize: 12 }}
            >
              View
            </Button>
          </Link>
        </div>
        const updatedEntry = {
          edit: 
            <div style={{ display: 'flex', flexDirection: 'row' }}>                            
              {status ? <IconButton onClick={() => setMarkResolve(id)}>
                <Tooltip title="Mark Resolve">
                  <Icon className="iconButton">check_box_icon</Icon>
                </Tooltip>
              </IconButton> : null}
              <IconButton onClick={() => setContentUpdateModal({adminId:props?.user?.user_id, params: question_id ? `question_id=${question_id}` : `session_id=${session_details.topic_id}`})}>
                <Tooltip title="View Content Update History">
                  <Icon className="iconButton">manage_search_icon</Icon>
                </Tooltip>
              </IconButton>
            </div>,
          ...newEntry,
        };
        return Object.keys(updatedEntry).map(subEntry =>
          updatedEntry[subEntry] ? '' || updatedEntry[subEntry] : updatedEntry[subEntry] == 0 ? '0' : '-'
        );
      });
    return newData;
  }

  return (
    <div>
      <Table
        onRef={ref => {
          tableRef = ref;
        }}
        firstCol='Edit'
        headerFilterList={HEADER_FILTER_LIST}
        formatTable={formatTable}
        sortList={SORT_LIST}
        sortDate={[]}
        search={props.location && props.location.query}
        tableName='Query Table'
        title='Query Table'
        scrollType='stacked'
        dateFilter={'date'}
        apiUrl={'query/list/'}
        setRowProps={setRowProps}
        hideColumn={HIDE_COLUMN}
        defaultParams={{ query_status: 1 }}
      />
      {markResolve ?
        <SaveChangesModal
          openModal={markResolve ? true : false}
          closeModal={() =>
            setMarkResolve(null)
          }
          text={'Do you want to mark the query as resolved?'}
          onSave={() => postMarkResolve()}
        /> : null}
        {sessionDetailModal ?
          <DetailDisplayModal
            openModal={sessionDetailModal ? true : false}
            closeModal={() =>
              setSessionDetailModal(null)
            }
            headerText={'Session Details'}
            displayData={sessionData()}
          />
          :
          null}
            {contentUpdateModal ?
        <ContentHistoryModal
          openModal={contentUpdateModal ? true : false}
          closeModal={() =>
            setContentUpdateModal(null)
          }
          adminId={contentUpdateModal.adminId}   
          params={contentUpdateModal.params}   
        /> : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default withSnackbar(connect(mapStateToProps)(QueryTable));

const HEADER_FILTER_LIST = [
  {
    filter_label: 'id',
    type: 'text'
  },
  {
    filter_label: 'question_id',
    type: 'text'
  },
];
const SORT_LIST = ['id', 'date'];
const HIDE_COLUMN = ['status']
// sconst SORT_DATE = ['date'];
