import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Modal,
  TextField,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import PendingSaveChangesModal from '../PendingSaveChangesModal';
import SaveChangesModal from '../SaveChangesModal'

class PTM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packageList: [],
      selectedPackage: null,
      ptmData: [],
      openModal: false,
      selectedIndex: 0,
      pendingChanges: false,
      saveChangesModalOpen: false,
    };
    this.unEditedData = []
  }

  componentDidMount() {
    this.getPackageData();
  }

  getPackageData() {
    const admin_id = this.props && this.props.user && this.props.user.user_id;
    axios.get(`/v1/admin/${admin_id}/assign-package/`).then((res) => {
      if (res && res.status == 200) {
        const data = res && res.data && res.data.data;
        this.setState({ packageList: data });
      }
    });
  }

  fetchPtmPackageData(package_id, setIndexZero) {
    this.setState({ ptmData: [] });
    const admin_id = this.props && this.props.user && this.props.user.user_id;
    axios
      .get(`/v1/admin/${admin_id}/session/${package_id}/ptm-mapping/`)
      .then((res) => {
        if (res && res.status) {
          const data = res && res.data && res.data.data;
          this.setState({ ptmData: data });
          this.unEditedData = JSON.parse(JSON.stringify(data));
          if (setIndexZero)
            this.setState({ selectedIndex: 0 });
        }
      });
  }

  postPtmPackageData = (ptmData) => {
    const admin_id = this.props && this.props.user && this.props.user.user_id;
    axios
      .post(
        `/v1/admin/${admin_id}/session/${this.state.selectedPackage}/ptm-mapping/`,
        ptmData
      )
      .then((res) => {
        if (res && res.status == 200) {
          const message =
            res && res.data && res.data.data && res.data.data.message;
          this.setState({ pendingChanges: false, saveChangesModalOpen: false })          
          this.fetchPtmPackageData(this.state.selectedPackage);
          this.props.enqueueSnackbar(message || 'Data updated Successfully', {
            variant: 'success',
          });
        }
      })

      .catch((err) => {
        this.props.enqueueSnackbar(
          err.response &&
          err.response.data &&
          err.response.data.data &&
          err.response.data.data.error &&
          err.response.data.data.error[0],
          { variant: 'error' }
        );
      });
  };

  renderPendingChangesModal() {
    return <PendingSaveChangesModal
      openModal={this.state.pendingChanges && this.state.pendingChanges.showModal}
      closeModal={() => this.setState({ pendingChanges: { ...this.state.pendingChanges, showModal: false } })}
      saveAndContinue={false}
      continueFunction={() => {
        this.setState({ pendingChanges: false, ptmData: this.unEditedData })
        this.performPendingOperation()
      }}
    />
  }

  
  renderSaveChangesModal() {
    return <SaveChangesModal
      openModal={this.state.saveChangesModalOpen}
      closeModal={() => this.setState({ saveChangesModalOpen: false })}
      onSave={() => {
        this.postPtmPackageData({...this.state.ptmData[this.state.selectedIndex], resync:this.state.saveChangesModalOpen==='resync' ? true : false}, true)            
      }}      
      text={'Do you want to save changes?'}
    />
  }

  performPendingOperation = () => {
    switch (this.state.pendingChanges.operation) {
      case 'add':        
          this.setState({ openModal: true })
          break;        
      case 'package_change':      
          this.setState({ selectedPackage: this.state.pendingChanges.value });
          this.fetchPtmPackageData(this.state.pendingChanges.value, true);
          break;        
      case 'no_of_class_change':        
          this.setState({ selectedIndex: this.state.pendingChanges.value });
          break;        
    }
  }

  renderAddModal() {
    let numberOfClass = null;
    return (
      <Modal
        open={this.state.openModal}
        onClose={() => this.setState({ openModal: false })}
      >
        <div
          style={{
            background: '#fff',
            height: '30vh',
            width: '60vw',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 20,
            gap: 10,
            justifyContent: 'center',
          }}
        >
          <TextField
            value={numberOfClass}
            label='Enter total number of classes'
            variant='outlined'
            type='number'
            style={{ width: 300 }}
            onChange={(event) => {
              numberOfClass = event.target.value;
            }}
          />
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              if (
                this.state.ptmData.find((o) => o.no_of_classes == numberOfClass)
              )
                this.props.enqueueSnackbar(
                  'The same number of class already exists',
                  {
                    variant: 'error',
                  }
                );
              else if (numberOfClass && eval(numberOfClass) > 0)
                this.setState((prevState) => ({
                  ptmData: [
                    { no_of_classes: eval(numberOfClass), session_numbers: [] },
                    ...prevState.ptmData,
                  ],
                  openModal: false,
                  selectedIndex: 0
                }));
              else
                this.props.enqueueSnackbar('Enter a valid number', {
                  variant: 'error',
                });
            }}
          >
            Add
          </Button>
        </div>
      </Modal>
    );
  }

  render() {
    console.log("aaaa", this.state.pendingChanges.showModal)
    const ptmData = this.state.ptmData[this.state.selectedIndex]
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
        {this.state.openModal && this.renderAddModal()}
        {this.state.pendingChanges && this.state.pendingChanges.showModal && this.renderPendingChangesModal()}
        {this.state.saveChangesModalOpen && this.renderSaveChangesModal()}
        <div style={{ fontSize: '1.5vw', fontWeight: 600 }}>PTM Schedule</div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormControl style={{ minWidth: 200 }}>
            <InputLabel>Select Package</InputLabel>
            <Select
              value={this.state.selectedPackage}
              onChange={(e) => {
                if (this.state.pendingChanges && this.state.pendingChanges.madeChanges)
                  this.setState({ pendingChanges: { showModal: true, madeChanges: true, operation: 'package_change', value: e.target.value } })
                else {
                  this.setState({ selectedPackage: e.target.value });
                  this.fetchPtmPackageData(e.target.value, true);
                }
              }}
            >
              {this.state.packageList &&
                this.state.packageList.map((packageData) => (
                  <MenuItem value={packageData.id}>{packageData.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
          <Button
            onClick={() => {
              if (this.state.pendingChanges && this.state.pendingChanges.madeChanges)
                this.setState({ pendingChanges: { showModal: true, madeChanges: true, operation: 'add' } })
              else
                this.setState({ openModal: true })
            }}
            variant='contained'
            color='primary'
            disabled={!this.state.selectedPackage}
            style={{ heigh: 'fit-content' }}
          >
            Add
          </Button>
        </div>

        {ptmData ?
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ fontSize: '1.25vw', fontWeight: 600 }}>
                {/* No. of classes: {ptmData.no_of_classes} */}
                <Select
                  value={this.state.selectedIndex}
                  onChange={(e) => {
                    if (this.state.pendingChanges && this.state.pendingChanges.madeChanges)
                      this.setState({ pendingChanges: { showModal: true, madeChanges: true, operation: 'no_of_class_change', value: e.target.value } })
                    else
                      this.setState({ selectedIndex: e.target.value });
                  }}
                >
                  {this.state.ptmData &&
                    this.state.ptmData.map((ptmData, index) => (
                      <MenuItem value={index}>No. of classes: {ptmData.no_of_classes}</MenuItem>
                    ))}
                </Select>
              </div>
              {this.state.selectedPackage ? (
                <div style={{display:'flex', gap:10}}>    
                  <Button
                  variant='outlined'
                  color='primary'
                  disabled={!this.state.selectedPackage}
                  style={{ width: 'fit-content' }}
                  onClick={() => this.setState({saveChangesModalOpen: 'resync'})
                    // this.postPtmPackageData(ptmData)
                  }
                >
                  Resync
                </Button>    
                <Button
                  variant='outlined'
                  color='primary'
                  disabled={!this.state.selectedPackage}
                  style={{ width: 'fit-content' }}
                  onClick={() => this.setState({saveChangesModalOpen: 'save'})
                    // this.postPtmPackageData(ptmData)
                  }
                >
                  Save
                </Button>
               </div>
              ) : null}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {ptmData.no_of_classes &&
                [...Array(ptmData.no_of_classes).keys()].map((index, key) => (
                  <div
                    style={{
                      width: '10%',
                      height: '8vh',
                      background:
                        ptmData.session_numbers.indexOf(index + 1) > -1
                          ? 'rgb(0 119 190)'
                          : 'rgb(0 119 190 / 30%)',
                      border: '1px black solid',
                      cursor: 'pointer',
                      fontSize: '1vw',
                      paddingLeft: 10,
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: 5,
                      fontWeight: 500,
                    }}
                    onClick={() => {
                      const classIndex = ptmData.session_numbers.indexOf(
                        index + 1
                      );
                      const data = this.state.ptmData;
                      if (classIndex > -1) {
                        ptmData.session_numbers.splice(classIndex, 1);
                      } else {
                        ptmData.session_numbers.push(index + 1);
                      }
                      data[this.state.selectedIndex] = ptmData;
                      this.setState({ ptmData: data, pendingChanges: { showModal: false, madeChanges: true } });
                    }}
                  >
                    Class {index + 1}
                  </div>
                ))}
            </div>
          </div>
          : this.state.selectedPackage ? 'No data' : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withSnackbar(connect(mapStateToProps)(PTM));
