import React, { Component } from 'react';
import TeachersOnLeaveList from './TeachersOnLeaveList';
import LeaveClassesImpacted from './LeaveClassesImpacted';
import { Button } from '@material-ui/core';

export default class LeaveManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLeaveList: true,
    };
  }
  render() {
    const { isLeaveList } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1vw' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontSize: '1.5vw', fontWeight: 600 }}>
            Leave Management
          </div>
          <div style={{ display: 'flex', gap: '1vw' }}>
            <Button
              onClick={() => this.setState({ isLeaveList: true })}
              variant={isLeaveList ? 'contained' : 'outlined'}
              style={
                isLeaveList
                  ? { backgroundColor: '#BF5AF2', color: 'white' }
                  : { color: '#BF5AF2', borderColor: '#BF5AF2' }
              }
            >
              Teacher Leave List
            </Button>
            <Button
              onClick={() => this.setState({ isLeaveList: false })}
              variant={!isLeaveList ? 'contained' : 'outlined'}
              style={
                !isLeaveList
                  ? { backgroundColor: '#BF5AF2', color: 'white' }
                  : { color: '#BF5AF2', borderColor: '#BF5AF2' }
              }
            >
              Classes Impacted
            </Button>
          </div>
        </div>
        {isLeaveList ? <TeachersOnLeaveList /> : <LeaveClassesImpacted />}
      </div>
    );
  }
}
