import { createStore, applyMiddleware, compose } from 'redux';
import { autoRehydrate } from 'redux-persist';

import reduxThunk from 'redux-thunk';
import { browserHistory } from 'react-router';
import { instance as api, setAuthorizationToken } from '../config/axios';
import reducers from '../reducers';
import { BACKEND_ERROR, LOGOUT_USER } from '../actions/types';
import axios from 'axios';

export default (initialSavedState, req) => {
  const composeEnhancers =
    (typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const initialState =
    initialSavedState ||
    (typeof window !== 'undefined' && window.__REDUX_STATE__) ||
    {};
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(
      applyMiddleware(
        reduxThunk.withExtraArgument({
          api,
          setAuthorizationToken,
        })
      ),
      autoRehydrate()
    )
  );
  axios.interceptors.request.use(function (config) {
    let state = store.getState();
    if (state.user && state.user.user_id && state.user.session_token) {
      config.headers[`Session-Token`] = state.user.session_token;
    }
    return config;
  });
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // Do something with response erro
      if (error?.response?.status) {
        if (error.response.status === 401) {
          store.dispatch({
            type: LOGOUT_USER,
          });
          browserHistory.push({
            pathname: '/',
            // search: typeof window !== 'undefined' && window && window.location.search,
          });
        }
        if (error.response.status === 403) {
          store.dispatch({
            type: BACKEND_ERROR,
            payload: 'You do not have the permission to access or update this resource',
          });
        }
      }
      return Promise.reject(error);
    }
  );

  return store;
};
