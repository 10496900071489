import { withSnackbar } from 'notistack';
import React, { Component, useEffect, useState, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import * as actions from '../../actions';
import S3UploadButton from '../../components/S3UploadButton';
import axios from 'axios'
import SingleSelect from '../../components/AutoSuggestSelectBox/SingleSelect';
import { withStyles } from '@material-ui/core/styles';
import {
  ListItem,
  Typography,
  Modal,
  List,
  Grid,
  Button
} from '@material-ui/core';
import { Link } from 'react-router';
import { TextField } from '@material-ui/core';
import { IconButton, Icon } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function BulkQuestionUpload(props) {
 
const [ref, setRef] = useState(React.createRef());
const [classList, setClassList] = useState(null)
const [classId, setClassId] = useState('')
const [sessionList, setSessionList] = useState(null)
const [sessionId, setSessionId] = useState('')
const [pptModal, setpptModal] = useState(false)
const [pptLink, setpptLink] = useState('')
const [recordingModal, setrecordingModal] = useState(false)
const [recordingLink, setrecordingLink] = useState('')
const [errors, setErrors] = useState('')
const [packageId,setPackageId]=useState(4)
const [activityList , setActivityList] = useState(null)
const [packageIdList,setPackageIdList] = useState(null)

useEffect(() => {
 getClassDetail(),
 getPackageData()
}, [ref]);

  const classes = styles();
  function getPackageData(){
    const admin_id=props && props.user.user_id
    axios.get(`/v1/admin/${admin_id}/assign-package/`)
    .then((res)=>
    {
        if(res && res.status==200){
        let temp = []
        let format={
            label:"",
            value:""
        }
        const data = res && res.data && res.data.data
        data && data.map(packageData => {
            format = {
                value: packageData.id,
                label: packageData.id + '. ' + packageData.name
            };
            format == undefined ? null : temp.push(format);
            });
        setPackageIdList(temp);
        }
    }
    )

  }
  function getClassDetail(){
    axios.get('v1/classes/').then((res)=>{
      if(res && res.status==200){
        let data = res && res.data && res.data.data
        data.map((ele,index)=>
        data[index]={label:ele.name, value:ele.id}
        )
        setClassList(data)
      }
    })
  }
  function getSessionDetail(class_id){
    const admin_id=props.user && props.user.user_id;
    axios.get(`v1/admin/${admin_id}/content/${class_id}/sessions-list/?package_id=${packageId}`).then((res)=>{
      if(res && res.status==200){
       let data=res && res.data && res.data.data
       data.map((ele,index)=>
       data[index]={
         label:ele.order + '. ' + ele.name,
         value:ele.id
        }
       )
        setSessionList(res.data.data)
      }
    })
  }
  function getPPTLink(){
    const admin_id=props.user && props.user.user_id;
    axios.get(`v1/admin/${admin_id}/content/session/${sessionId}/update-ppt/?link_type=ppt`).then((res)=>{
      if(res && res.status==200){
       let data=res && res.data && res.data.data
        setpptLink(data)
      }
    })
    .catch(error => {
      const errors = error && error.response.data && error.response.data.data && error.response.data.data.error
      errors && errors[0] && errors.map((err)=>
      props.enqueueSnackbar(err, {variant: 'error'})
      )  
      console.log(error, error.response);
    })
  }
  function postPPTLink(){
    const admin_id=props.user && props.user.user_id;
    axios.post(`v1/admin/${admin_id}/content/session/${sessionId}/update-ppt/`,{
      "link": pptLink,
      "link_type": 'ppt'
  }).then((res)=>{
      if(res && res.status==200){
        const message = res && res.data && res.data.data && res.data.data.message
        message && props.enqueueSnackbar(message, {variant: 'success'});
        setpptModal(false)
      }
    })
  }
  function getRecordingLink(){
    const admin_id=props.user && props.user.user_id;
    axios.get(`v1/admin/${admin_id}/content/session/${sessionId}/update-ppt/?link_type=recording`).then((res)=>{
      if(res && res.status==200){
       let data=res && res.data && res.data.data
        setrecordingLink(data)
      }
    })
    .catch(error => {
      const errors = error && error.response.data && error.response.data.data && error.response.data.data.error
      errors && errors[0] && errors.map((err)=>
      props.enqueueSnackbar(err, {variant: 'error'})
      )  
      console.log(error, error.response);
    })
  }
  function postRecordingLink(){
    const admin_id=props.user && props.user.user_id;
    axios.post(`v1/admin/${admin_id}/content/session/${sessionId}/update-ppt/`,{
      "link": recordingLink,
      "link_type": 'recording'
  }).then((res)=>{
      if(res && res.status==200){
        const message = res && res.data && res.data.data && res.data.data.message
        message && props.enqueueSnackbar(message, {variant: 'success'});
        setrecordingModal(false)
      }
    })
  }
  function getActivityDetail(session_id){
    const admin_id=props.user && props.user.user_id;
    axios.get(`v1/admin/${admin_id}/content/session/${session_id}/activities-list/?details=1`)
    .then((res)=>{
      if(res && res.status==200){
        res.data?.data?.activities && setActivityList(res.data.data.activities)
      }
    })
    .catch(error => {
      const errors = error && error.response && error.response.data && error.response.data.data && error.response.data.data.error
      errors && errors[0] && errors.map((err)=>
      props.enqueueSnackbar(err, {variant: 'error'})
      )  
      console.log(error, error.response);
    });
  }
  function bulkUpload(s3Link){
    const admin_id=props.user && props.user.user_id;
    axios.post(`v1/admin/${admin_id}/question/bulk-upload/`,
    {
      "s3_link": s3Link,
      "session_id": sessionId
    }
    )
    .then((res)=>{
      if(res && res.status==200){
      const message = res && res.data && res.data.data && res.data.data.message
      const error = res && res.data && res.data.data && res.data.data.error
      !error && getActivityDetail(sessionId)
      setErrors(error)
      message && props.enqueueSnackbar(message, {variant: error ? 'error':'success'});
      }
    })
    .catch(error => {
      const errors = error && error.response.data && error.response.data.data && error.response.data.data.error
      errors && errors[0] && errors.map((err)=>
      props.enqueueSnackbar(err, {variant: 'error'})
      )  
      console.log(error, error.response);
    });
  }

  return (
   <div style={{minHeight:'80vh', width:'100%'}}>
  
  {pptModal && 
  <div style={{width:'95%', display:'flex', justifyContent:'center', marginTop:'20vh',zIndex:5, position:'absolute'}}>
  <div style={{ width:'70vw', background:'#fff',padding:50, display:'flex',  justifyContent:'center', justifySelf:'center', flexDirection:'column', border:'1px solid', borderRadius:10}}>
    <List>
    <TextField
    value={pptLink}
    onChange={(event)=>setpptLink(event.target.value)}
    label='PPT Link'
    variant='outlined'
    style={{width:500}}
    />
    </List>
    <div style={{display:'flex', gap:10}}>
      <Button
          color="primary"
          variant="contained"
          onClick={()=>{postPPTLink()}}
      >
        Update Link
      </Button>
      <Button
          color="primary"
          variant="contained"
          onClick={()=>{setpptModal(false)}}
      >
        Cancel
      </Button>
      </div>
  </div>
  </div>
  }
  {recordingModal && 
  <div style={{width:'95%', display:'flex', justifyContent:'center', marginTop:'20vh',zIndex:5, position:'absolute'}}>
  <div style={{ width:'70vw', background:'#fff',padding:50, display:'flex',  justifyContent:'center', justifySelf:'center', flexDirection:'column', border:'1px solid', borderRadius:10}}>
    <List>
    <TextField
    value={recordingLink}
    onChange={(event)=>setrecordingLink(event.target.value)}
    label='Recording Link'
    variant='outlined'
    style={{width:500}}
    />
    </List>
    <div style={{display:'flex', gap:10}}>
      <Button
          color="primary"
          variant="contained"
          onClick={()=>{postRecordingLink()}}
      >
        Update Link
      </Button>
      <Button
          color="primary"
          variant="contained"
          onClick={()=>{setrecordingModal(false)}}
      >
        Cancel
      </Button>
      </div>
  </div>
  </div>
  }
  
  <div style={{width:'40vw',display:'flex', flexDirection:'column', gap:'4vh'}}>
  <h2>Upload Question</h2>
  <SingleSelect
    name="Package"
    onChange={(event)=>{ setClassId(null), setSessionId(null), setPackageId(event.value), setActivityList(null), classId &&  getSessionDetail(event.value)}}
    selectList={packageIdList}
    style={{cursor:'pointer'}}
    defaultValue={[{ label: "LogiMath", value: 4}]}
    isDisabled={false}
    />
  
  <SingleSelect
    name="Class"
    onChange={(event)=>{setClassId(event.value), getSessionDetail(event.value), setActivityList(null), setSessionId(null)}}
    selectList={classList}
    value={classId && classList && classList[classList.includes(classId)]}
    style={{cursor:'pointer'}}
    isDisabled={!packageId}
    />
   
    <SingleSelect
    name="Session"
    onChange={(event)=>{setSessionId(event.value), setActivityList(null)}}
    selectList={sessionList}
    value={sessionId && sessionList && sessionList[sessionList.includes(sessionId)]}
    style={{cursor:'pointer'}}
    isDisabled={!classId}
    />
    <S3UploadButton
    bucketName="lq-live"
    fileType="zip"
    title="Upload Zip"
    // dirName="question_images"
    fileName="question_upload_file"
    setField={(value)=>bulkUpload(value)}
    disabled={!(classId && sessionId)}
    />
    <List style={{display:'flex', gap:10}}>
    <Button
    color="primary"
    variant="contained"
    onClick={()=>{setpptModal(true),getPPTLink()}}
    disabled={!(classId && sessionId)}
    >
      Change PPT Link
    </Button>
    <Button
    color="primary"
    variant="contained"
    onClick={()=>{setrecordingModal(true),getRecordingLink()}}
    disabled={!(classId && sessionId)}
    >
      Change Recording Link
    </Button>
   
    <Button
          color="primary"
          variant="contained"
          disabled={!(classId && sessionId)}
      >
         <Link
                to={{
                  pathname: '/questions',
                  search: `?session_id=${sessionId}`
                }}
                target='_blank'
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
       View Question
       </Link>
      </Button>

      <Button
    color="primary"
    variant="contained"
    onClick={()=>{activityList && activityList[0] ? setActivityList(null) : getActivityDetail(sessionId)}}
    disabled={!(classId && sessionId)}
    >
      {activityList && activityList[0] ? 'Hide Activity List' : 'View Activity List'}
    </Button>
      
    </List>
   </div>
   {errors && errors[0] && 
   <List style={{fontSize:16}}>
     <hr/>
    <h4>Errors found in file</h4>
    <ul>
     {errors && errors[0] && errors.map((err)=>
    <li style={{color:'red'}}>{err}</li> 
     )}
     </ul>
   </List>}
   {activityList && activityList[0] && 
    <Table className={classes.table}>             
    <TableHead>
        <TableRow>
            {Object.getOwnPropertyNames(activityList[0]).map((header, i)=>
                {  
                    const headername = header.replaceAll("_", " ")
                    return(<TableCell className={classes.tablecell}>{headername.charAt(0).toUpperCase() + headername.slice(1)}</TableCell>)
                }
            )}
        </TableRow>
    </TableHead>
    <TableBody>
        {activityList.map((rows, index)=>
            <TableRow>
                {Object.getOwnPropertyNames(activityList[0]).map((header, i)=>
                    {  
                        const cell = eval('rows.' + header) 
                        return(<TableCell className={classes.tablecell}>{cell}</TableCell>)
                    }
                )}
            </TableRow>
        )}
    </TableBody>
</Table>
   || activityList && !activityList[0] && 'No activity to display'
   }
   </div>
    );
}

const styles = makeStyles(theme => ({
  select: {
    minWidth: 200,
    maxWidth: 300
  },
  main: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    minHeight:'60vh'
  }
}));

function mapStateToProps(state) {

  return {
    user: state.user,
  };
}

export default withStyles(styles, { withTheme: true })(withSnackbar(
  connect(
    mapStateToProps,
    actions,
    null
  )(BulkQuestionUpload))
);
