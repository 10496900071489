import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateRangePicker from 'react-daterange-picker';
import '../LQDataTables/dateRangeStyle.css';
import moment from 'moment';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  CircularProgress,
  Modal,
  TextField,
  Chip,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import UpdateResolution from '../UpdateResolution';

class ClassFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      rating: null,
      feedbackData: null,
      ratingValue: null,
      showCalendar: false,
      selectedDateRange: 'Last 7 days',
      ratingType: '',
      ratingTypeList: ['teaching', 'content', 'platform'],
      page: 0,
      no_of_rows: 50,
      count: null,
    };
    this.calendarData = [
      {
        label: 'Today',
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
      {
        label: 'Yesterday',
        startDate: moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'),
        endDate: moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'),
      },
      {
        label: 'Last 7 days',
        startDate: moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
      {
        label: 'Last 15 days',
        startDate: moment(new Date()).subtract(15, 'd').format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
      {
        label: 'This Month',
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
    ];
  }

  fetchRatingData = () => {
    axios
      .get(
        `/v1/admin/${this.props.adminId}/student-feedback/count/?start_date=${this.state.startDate}&end_date=${this.state.endDate}&rating_type=${this.state.ratingType}`
      )
      .then((res) => {
        if (res?.status == 200 && res.data) {
          const { ratings } = res.data.data;
          this.setState({ rating: ratings, page: 0 });
          if (this.state.ratingValue) {
            this.fetchFeedbackData(this.state.ratingValue);
          }
        }
      });
  };
  fetchFeedbackData = (value) => {
    this.setState({ feedbackData: null });
    axios
      .get(
        `/v1/admin/${this.props.adminId}/student-feedback/list/?start_date=${
          this.state.startDate
        }&end_date=${this.state.endDate}&rating=${value}&rating_type=${
          this.state.ratingType
        }&page=${this.state.page + 1}`
      )
      .then((res) => {
        if (res?.status == 200 && res.data) {
          const { results, count } = res.data.data;
          this.setState({
            feedbackData: results,
            ratingValue: value,
            count: count,
          });
        }
      });
  };

  render() {
    const {
      startDate,
      endDate,
      rating,
      feedbackData,
      ratingValue,
      showCalendar,
      selectedDateRange,
      ratingTypeList,
      ratingType,
      count,
      page,      
      feedbackResolutionText,
    } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2vw' }}>
        <div style={{ fontSize: '1.5vw', fontWeight: 600 }}>Class Feedback</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              width: '60vw',
              gap: '1vw',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                width: '60vw',
              }}
            >
              {ratingTypeList?.map((ratingTypeListData) => (
                <div
                  onClick={() => {
                    if (ratingType != ratingTypeListData)
                      this.setState({ ratingType: ratingTypeListData }, () =>
                        this.fetchRatingData()
                      );
                  }}
                  style={{
                    textAlign: 'center',
                    fontSize: '1.25vw',
                    fontWeight: 600,
                    border: '1px solid #e0e0e0',
                    borderRadius: 10,
                    padding: '1vw 5vw',
                    backgroundColor:
                      ratingType == ratingTypeListData
                        ? '#e0e0e0'
                        : 'transparent',
                    height: 'fit-content',
                    cursor: 'pointer',
                  }}
                >
                  <div>
                    {ratingTypeListData.charAt(0).toUpperCase() +
                      ratingTypeListData.slice(1)}
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                width: '60vw',
              }}
            >
              {rating?.map((ratingData) => (
                <div
                  onClick={() => {
                    if (ratingValue != ratingData.value)
                      this.setState({ page: 0 }, () =>
                        this.fetchFeedbackData(ratingData.value)
                      );
                  }}
                  style={{
                    textAlign: 'center',
                    fontSize: '1vw',
                    fontWeight: 600,
                    border: '1px solid #e0e0e0',
                    borderRadius: 10,
                    padding: '1vw 2.5vw',
                    backgroundColor:
                      ratingValue == ratingData.value
                        ? '#e0e0e0'
                        : 'transparent',
                    height: 'fit-content',
                    cursor: 'pointer',
                  }}
                >
                  <div>{ratingData.name}</div>
                  <div>{ratingData.count}</div>
                </div>
              ))}
            </div>
          </div>

          <div style={{ width: '20vw' }}>
            {showCalendar ? (
              <Modal
                open={showCalendar}
                onClose={() => this.setState({ showCalendar: false })}
              >
                <div
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    zIndex: 1,
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    margin: 'auto',
                    background: 'white',
                    width: 'fit-content',
                    height: 'fit-content',
                    padding: '2vw',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '2vw',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {this.calendarData.map((calendarData) => (
                      <div
                        onClick={() =>
                          this.setState(
                            {
                              startDate: calendarData.startDate,
                              endDate: calendarData.endDate,
                              showCalendar: false,
                              selectedDateRange: calendarData.label,
                            },
                            () => this.fetchRatingData()
                          )
                        }
                        style={{
                          cursor: 'pointer',
                          padding: '0.25vw 1vw',
                          background:
                            selectedDateRange == calendarData.label
                              ? 'lightgray'
                              : 'transparent',
                        }}
                      >
                        {calendarData.label}
                      </div>
                    ))}
                  </div>
                  <DateRangePicker
                    value={moment.range(new Date(startDate), new Date(endDate))}
                    onSelect={(date) =>
                      this.setState(
                        {
                          startDate: moment(date.start).format('YYYY-MM-DD'),
                          endDate: moment(date.end).format('YYYY-MM-DD'),
                          showCalendar: false,
                        },
                        () => this.fetchRatingData()
                      )
                    }
                    singleDateRange={true}
                    numberOfCalendars={2}
                  />
                </div>
              </Modal>
            ) : null}
            <TextField
              label='Date Range'
              variant='standard'
              onClick={() => this.setState({ showCalendar: true })}
              value={
                startDate == endDate ? startDate : startDate + ' to ' + endDate
              }
            />
          </div>
        </div>
        {feedbackData?.length ? (
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(feedbackData[0]).map(
                  (header) =>
                    header != 'feedback_id' && (
                      <TableCell
                        style={{
                          textAlign: 'center',
                          fontSize: '1vw',
                          fontWeight: 600,
                          border: '1px solid #e0e0e0',
                          padding: '3px 12px 3px 8px',
                          height: '10vh',
                          backgroundColor: '#e0e0e0',
                        }}
                      >
                        {header.charAt(0).toUpperCase() +
                          header.replaceAll('_', ' ').slice(1)}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {feedbackData.map((feedback, index) => (
                <TableRow>
                  {Object.keys(feedbackData[0]).map(
                    (header) =>
                      header != 'feedback_id' && (
                        <TableCell
                          style={{
                            textAlign: 'left',
                            fontSize: '0.85vw',
                            fontWeight: 500,
                            border: '1px solid #e0e0e0',
                            padding: '3px 12px 3px 8px',
                            height: '7vh',
                            width:
                              header === 'remarks'
                                ? '11vw'
                                : header === 'ratings'
                                ? '8vw'
                                : '',
                          }}
                        >
                          {header.includes('date') ? (
                            new Date(feedback[header]).toLocaleString()
                          ) : Array.isArray(feedback[header]) ? (
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 5,
                              }}
                            >
                              {feedback[header].map((feedbackArrayData) => (
                                <Chip
                                  label={feedbackArrayData}
                                  variant='outlined'
                                  style={{ height: 'fit-content' }}
                                />
                              ))}
                            </div>
                          ) : header === 'resolution' ? (
                            <UpdateResolution
                              remark={feedback[header].resolution_remark}
                              isResolved={feedback[header].is_resolved}
                              onSuccess={() => this.fetchRatingData()}
                              apiUrl={`v1/admin/${this.props.adminId}/student-feedback/${feedbackData[index].feedback_id}/resolve/`}
                            />
                          ) : header === 'ratings' ? (
                            Object.entries(feedbackData[index].ratings).map(
                              ([key, value]) => {
                                return <div>{`${key} : ${value}`}</div>;
                              }
                            )
                          ) : (
                            feedback[header]
                          )}
                        </TableCell>
                      )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : feedbackData?.length == 0 ? (
          <div style={{ margin: 'auto' }}>No data</div>
        ) : ratingValue ? (
          <CircularProgress style={{ margin: 'auto' }} />
        ) : null}
        {count ? (
          <TablePagination
            className='teacherTrainerPagination'
            rowsPerPageOptions={[50]}
            component='div'
            count={count}
            rowsPerPage={50}
            page={page}
            onChangePage={(event, newPage) => {
              this.setState({ page: newPage }, () =>
                this.fetchFeedbackData(this.state.ratingValue)
              );
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminId: state.user?.user_id,
  };
};

export default withSnackbar(connect(mapStateToProps)(ClassFeedback));
