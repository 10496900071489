import React, { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, Modal, Button } from '@material-ui/core';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  list: {
    display: 'flex',
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },
  Main: {
    width: '95%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 10,
    top: 0,
    alignItems: 'center',
  },
  Modal: {
    background: '#fff',
    borderRadius: 5,
    width: '70vw',
    maxHeight: '60vh',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    height: 'fit-content',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    overflowY: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    position: 'sticky',
    left: 0,
  },

  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});

function SaveChangesModal(props) {
  const classes = userStyles();
  const {
    openModal,
    closeModal,
    text,
    style,
    displayData,
    onSave,
    disableConfirm,
  } = props;

  return openModal ? (
    <div className={classes.Main}>
      <Modal open={openModal ? true : false} onClose={closeModal}>
        <div className={classes.Modal} style={style}>
          <div className={classes.header}>
            <div style={{ margin: 'auto 0px', fontSize: 20, fontWeight: 500 }}>
              {text}
            </div>
            <div>
              <IconButton onClick={closeModal}>
                <Tooltip title='Close Modal'>
                  <Icon className='iconButton'>close</Icon>
                </Tooltip>
              </IconButton>
            </div>
          </div>
          {displayData && openModal ? displayData : null}
          {onSave ? (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Button variant='contained' color='primary' onClick={closeModal}>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={onSave}
                disabled={disableConfirm}
              >
                Confirm
              </Button>
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  ) : null;
}

export default SaveChangesModal;
