import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
  Icon,
} from '@material-ui/core';

class SessionSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packageList: null,
      selectedPackage: props.multiSelect?.includes('package') ? [] : '',
      gradeList: null,
      selectedGrade: props.multiSelect?.includes('grade') ? [] : '',
      sessionList: null,
      selectedSession: props.multiSelect?.includes('session') ? [] : '',
      selectedTeacher: '',
    };
    this.showPackage =
      this.props.filterList.includes('package') ||
      this.props.filterList.includes('session')
        ? true
        : false;
    this.showGrade =
      this.props.filterList.includes('grade') ||
      this.props.filterList.includes('session')
        ? true
        : false;
    this.showSession = this.props.filterList.includes('session') ? true : false;
    this.showTeacher = this.props.filterList.includes('teacher') ? true : false;
  }

  componentDidMount() {
    if (this.showPackage) this.getPackageList();
    if (this.showGrade) this.getGradeList();
    if (this.showTeacher) this.getAvailableTeacherList(this.props.sessionId);
  }

  getPackageList = () => {
    const packageData = this.props.packageData;
    const urlParams = packageData?.paid ? '?paid=1' : '';
    axios
      .get(`/v1/admin/${this.props.admin_id}/assign-package/${urlParams}`)
      .then((res) => {
        if (res && res.status == 200) {
          let data = res && res.data && res.data.data;
          if (packageData?.exclude)
            data = data.filter((item) => item.id !== packageData?.exclude);
          data.map(
            (ele, index) =>
              (data[index] = {
                label: ele.id + '. ' + ele.name,
                value: ele.id,
              })
          );
          this.setState({ packageList: data });
        }
      });
  };

  getAvailableTeacherList = (session_schedule_id) => {
    const adminId = this.props.admin_id;
    axios.get(`v1/admin/${adminId}/teacher/`).then((res) => {
      if (res.status == 200) {
        let data = res && res.data && res.data.data;
        data.map(
          (ele, index) => (data[index] = { label: ele.name, value: ele.id })
        );
        const selectedTeacher =
          data.find(
            (teacherDetails) =>
              teacherDetails.label == this.props.selectedTeacher
          )?.value || '';
        this.setState({ teacherList: data, selectedTeacher });
      }
    });
  };

  getGradeList = () => {
    axios.get('v1/classes/').then((res) => {
      if (res && res.status == 200) {
        let data = res && res.data && res.data.data;
        data.map(
          (ele, index) => (data[index] = { label: ele.name, value: ele.id })
        );
        this.setState({ gradeList: data });
      }
    });
  };
  getSessionList(class_id) {
    axios
      .get(
        `v1/admin/${this.props.admin_id}/content/${class_id}/sessions-list/?package_id=${this.state.selectedPackage}`
      )
      .then((res) => {
        if (res && res.status == 200) {
          let data = res && res.data && res.data.data;
          data.map(
            (ele, index) =>
              (data[index] = {
                label: ele.order + '. ' + ele.name,
                value: ele.id,
                order: ele.order,
              })
          );
          this.setState({ sessionList: data });
        }
      });
  }

  onPackageChange = (event) => {
    this.setState(
      {
        selectedPackage: event.target.value,
        selectedSession: false,
        sessionList: null,
      },
      () => this.props.onFilterChange({ package_id: event.target.value })
    );
    this.state.packageList &&
      this.state.packageList.filter((list) => {
        if (list.value == event.target.value)
          this.setState({ packageLabel: list.label });
      });
    this.state.selectedGrade && this.getSessionList(event.target.value);
  };
  onGradeChange = (event) => {
    this.setState(
      {
        selectedGrade: event.target.value,
        selectedSession: false,
        sessionList: null,
      },
      () => this.props.onFilterChange({ grade: event.target.value })
    );
    this.state.selectedPackage && this.getSessionList(event.target.value);
  };
  onSessionChange = (event) => {
    this.setState({ selectedSession: event.target.value });
    this.state.sessionList &&
      this.state.sessionList.filter((list) => {
        if (list.value == event.target.value)
          this.setState({ sessionLabel: list.label });
      });
  };

  onTeacherChange = (event) => {
    this.setState({ selectedTeacher: event.target.value });
  };

  filter(value, listData, label, onChangeFunction) {
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }} style={{ width: '20%' }}>
        <InputLabel
          id={label}
          style={{
            // fontSize: 22,
            fontWeight: 500,
            // marginTop: -10,
            color: '#000',
          }}
        >
          {label}
        </InputLabel>
        <Select
          labelId={label}
          id={label}
          value={value}
          label={label}
          onChange={onChangeFunction}
          disabled={!listData}
          defaultValue=''
          multiple={this.props.multiSelect?.includes(label.toLowerCase())}
        >
          {listData &&
            listData.map((list) => (
              <MenuItem value={list.value}>{list.label}</MenuItem>
            ))}
        </Select>
        <FormHelperText>Please select {label}</FormHelperText>
      </FormControl>
    );
  }

  isDisable() {
    if (
      (this.showPackage && !this.state.selectedPackage) ||
      (this.showGrade && !this.state.selectedGrade) ||
      (this.showSession && !this.state.selectedSession)
    )
      return true;
    else return false;
  }

  render() {
    const { selectedPackage, selectedGrade, selectedSession } = this.state;
    return (
      <div
        className='filters'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '3vw',
          ...this.props.style,
        }}
      >
        <div style={{ display: 'flex', gap: '3vw', width: '75vw' }}>
          {this.showPackage
            ? this.filter(
                this.state.selectedPackage,
                this.state.packageList,
                'Package',
                this.onPackageChange
              )
            : null}
          {this.showGrade
            ? this.filter(
                this.state.selectedGrade,
                this.state.gradeList,
                'Grade',
                this.onGradeChange
              )
            : null}
          {this.showSession
            ? this.filter(
                this.state.selectedSession,
                this.state.sessionList,
                'Session',
                this.onSessionChange
              )
            : null}
          {this.showTeacher
            ? this.filter(
                this.state.selectedTeacher,
                this.state.teacherList,
                'Teacher',
                this.onTeacherChange
              )
            : null}
        </div>
        {this.props.setFilterData ? (
          <Button
            variant='contained'
            color='primary'
            onClick={() =>
              this.props.setFilterData(
                this.state.selectedPackage,
                this.state.selectedGrade,
                this.state.selectedSession,
                this.state.selectedTeacher
              )
            }
            disabled={
              this.props.buttonDisabled
                ? this.props.buttonDisabled(
                    selectedPackage,
                    selectedGrade,
                    selectedSession
                  )
                : (this.showPackage && !this.state.selectedPackage) ||
                  (this.showGrade && !this.state.selectedGrade) ||
                  (this.showSession && !this.state.selectedSession)
            }
          >
            {this.props.buttonName || 'Show Result'}
            <Icon>play_arrow</Icon>
          </Button>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    admin_id: state.user && state.user.user_id,
  };
}

export default connect(mapStateToProps)(SessionSelector);
