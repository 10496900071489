import React, { Component } from 'react';
import DateRangePicker from 'react-daterange-picker';
import '../LQDataTables/dateRangeStyle.css';
import moment from 'moment';
import { Modal, TextField } from '@material-ui/core';

class DateRangePickerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate:
        props.defaultStartDate ||
        moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'),
      endDate: props.defaultEndDate || moment(new Date()).format('YYYY-MM-DD'),
      selectedDateRange: props.defaultDateRangeLabel || `Last 7 days`,
    };
    this.calendarData = [
      {
        label: 'Today',
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
      {
        label: 'Yesterday',
        startDate: moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'),
        endDate: moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD'),
      },
      {
        label: 'Last 7 days',
        startDate: moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
      {
        label: 'Upcoming 7 days',
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).add(7, 'd').format('YYYY-MM-DD'),
      },
      {
        label: 'Last 15 days',
        startDate: moment(new Date()).subtract(15, 'd').format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
      {
        label: 'This Month',
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
      {
        label: 'One Year',
        startDate: moment(new Date()).subtract(1, 'years').format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
    ];
  }
  componentDidMount() {
    this.props.fetchData(this.state.startDate, this.state.endDate);
  }

  render() {
    const { startDate, endDate, showCalendar, selectedDateRange } = this.state;
    const { hide } = this.props;
    return hide ? null : (
      <div>
        {showCalendar ? (
          <Modal
            open={showCalendar}
            onClose={() => this.setState({ showCalendar: false })}
          >
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                zIndex: 1,
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                margin: 'auto',
                background: 'white',
                width: 'fit-content',
                height: 'fit-content',
                padding: '2vw',
                borderRadius: 8,
                boxShadow:
                  '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.75vw',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {this.calendarData.map((calendarData) => (
                  <div
                    onClick={() =>
                      this.setState(
                        {
                          startDate: calendarData.startDate,
                          endDate: calendarData.endDate,
                          showCalendar: false,
                          selectedDateRange: calendarData.label,
                        },
                        () =>
                          this.props.fetchData(
                            this.state.startDate,
                            this.state.endDate
                          )
                      )
                    }
                    style={{
                      cursor: 'pointer',
                      padding: '0.5vw 1vw',
                      background:
                        selectedDateRange == calendarData.label
                          ? 'lightgray'
                          : 'transparent',
                      borderRadius: 6,
                    }}
                  >
                    {calendarData.label}
                  </div>
                ))}
              </div>
              <DateRangePicker
                value={moment.range(new Date(startDate), new Date(endDate))}
                onSelect={(date) =>
                  this.setState(
                    {
                      startDate: moment(date.start).format('YYYY-MM-DD'),
                      endDate: moment(date.end).format('YYYY-MM-DD'),
                      showCalendar: false,
                      selectedDateRange: null,
                    },
                    () =>
                      this.props.fetchData(
                        this.state.startDate,
                        this.state.endDate
                      )
                  )
                }
                singleDateRange={true}
                numberOfCalendars={2}
              />
            </div>
          </Modal>
        ) : null}
        <TextField
          label='Date Range'
          variant='standard'
          onClick={() => this.setState({ showCalendar: true })}
          value={
            startDate == endDate ? startDate : startDate + ' to ' + endDate
          }
        />
      </div>
    );
  }
}

export default DateRangePickerModal;
