const g2 = {
    COUNTRY: {
        '001': 'World',
        '002': 'Africa',
        '003': 'North America',
        '005': 'South America',
        '009': 'Oceania',
        '011': 'Western Africa',
        '013': 'Central America',
        '014': 'Eastern Africa',
        '015': 'Northern Africa',
        '017': 'Middle Africa',
        '018': 'Southern Africa',
        '019': 'Americas',
        '021': 'Northern America',
        '029': 'Caribbean',
        '030': 'Eastern Asia',
        '034': 'Southern Asia',
        '035': 'Southeast Asia',
        '039': 'Southern Europe',
        '053': 'Australasia',
        '054': 'Melanesia',
        '057': 'Micronesian Region',
        '061': 'Polynesia',
        142: 'Asia',
        143: 'Central Asia',
        145: 'Western Asia',
        150: 'Europe',
        151: 'Eastern Europe',
        154: 'Northern Europe',
        155: 'Western Europe',
        202: 'Sub-Saharan Africa',
        419: 'Latin America',
        AC: 'Ascension Island',
        AD: 'Andorra',
        AE: 'United Arab Emirates',
        AF: 'Afghanistan',
        AG: 'Antigua & Barbuda',
        AI: 'Anguilla',
        AL: 'Albania',
        AM: 'Armenia',
        AO: 'Angola',
        AQ: 'Antarctica',
        AR: 'Argentina',
        AS: 'American Samoa',
        AT: 'Austria',
        AU: 'Australia',
        AW: 'Aruba',
        AX: '\u00c5land Islands',
        AZ: 'Azerbaijan',
        BA: 'Bosnia & Herzegovina',
        BB: 'Barbados',
        BD: 'Bangladesh',
        BE: 'Belgium',
        BF: 'Burkina Faso',
        BG: 'Bulgaria',
        BH: 'Bahrain',
        BI: 'Burundi',
        BJ: 'Benin',
        BL: 'St Barth\u00e9lemy',
        BM: 'Bermuda',
        BN: 'Brunei',
        BO: 'Bolivia',
        BQ: 'Caribbean Netherlands',
        BR: 'Brazil',
        BS: 'Bahamas',
        BT: 'Bhutan',
        BV: 'Bouvet Island',
        BW: 'Botswana',
        BY: 'Belarus',
        BZ: 'Belize',
        CA: 'Canada',
        CC: 'Cocos (Keeling) Islands',
        CD: 'Congo - Kinshasa',
        CF: 'Central African Republic',
        CG: 'Congo - Brazzaville',
        CH: 'Switzerland',
        CI: 'C\u00f4te d\u2019Ivoire',
        CK: 'Cook Islands',
        CL: 'Chile',
        CM: 'Cameroon',
        CN: 'China',
        CO: 'Colombia',
        CP: 'Clipperton Island',
        CR: 'Costa Rica',
        CU: 'Cuba',
        CV: 'Cape Verde',
        CW: 'Cura\u00e7ao',
        CX: 'Christmas Island',
        CY: 'Cyprus',
        CZ: 'Czechia',
        DE: 'Germany',
        DG: 'Diego Garcia',
        DJ: 'Djibouti',
        DK: 'Denmark',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        DZ: 'Algeria',
        EA: 'Ceuta & Melilla',
        EC: 'Ecuador',
        EE: 'Estonia',
        EG: 'Egypt',
        EH: 'Western Sahara',
        ER: 'Eritrea',
        ES: 'Spain',
        ET: 'Ethiopia',
        EU: 'European Union',
        EZ: 'Eurozone',
        FI: 'Finland',
        FJ: 'Fiji',
        FK: 'Falkland Islands (Islas Malvinas)',
        FM: 'Micronesia',
        FO: 'Faroe Islands',
        FR: 'France',
        GA: 'Gabon',
        GB: 'United Kingdom',
        GD: 'Grenada',
        GE: 'Georgia',
        GF: 'French Guiana',
        GG: 'Guernsey',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GL: 'Greenland',
        GM: 'Gambia',
        GN: 'Guinea',
        GP: 'Guadeloupe',
        GQ: 'Equatorial Guinea',
        GR: 'Greece',
        GS: 'South Georgia & South Sandwich Islands',
        GT: 'Guatemala',
        GU: 'Guam',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HK: 'Hong Kong',
        HM: 'Heard & McDonald Islands',
        HN: 'Honduras',
        HR: 'Croatia',
        HT: 'Haiti',
        HU: 'Hungary',
        IC: 'Canary Islands',
        ID: 'Indonesia',
        IE: 'Ireland',
        IL: 'Israel',
        IM: 'Isle of Man',
        IN: 'India',
        IO: 'British Indian Ocean Territory',
        IQ: 'Iraq',
        IR: 'Iran',
        IS: 'Iceland',
        IT: 'Italy',
        JE: 'Jersey',
        JM: 'Jamaica',
        JO: 'Jordan',
        JP: 'Japan',
        KE: 'Kenya',
        KG: 'Kyrgyzstan',
        KH: 'Cambodia',
        KI: 'Kiribati',
        KM: 'Comoros',
        KN: 'St Kitts & Nevis',
        KP: 'North Korea',
        KR: 'South Korea',
        KW: 'Kuwait',
        KY: 'Cayman Islands',
        KZ: 'Kazakhstan',
        LA: 'Laos',
        LB: 'Lebanon',
        LC: 'St Lucia',
        LI: 'Liechtenstein',
        LK: 'Sri Lanka',
        LR: 'Liberia',
        LS: 'Lesotho',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        LV: 'Latvia',
        LY: 'Libya',
        MA: 'Morocco',
        MC: 'Monaco',
        MD: 'Moldova',
        ME: 'Montenegro',
        MF: 'St Martin',
        MG: 'Madagascar',
        MH: 'Marshall Islands',
        MK: 'North Macedonia',
        ML: 'Mali',
        MM: 'Myanmar (Burma)',
        MN: 'Mongolia',
        MO: 'Macao',
        MP: 'Northern Mariana Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MS: 'Montserrat',
        MT: 'Malta',
        MU: 'Mauritius',
        MV: 'Maldives',
        MW: 'Malawi',
        MX: 'Mexico',
        MY: 'Malaysia',
        MZ: 'Mozambique',
        NA: 'Namibia',
        NC: 'New Caledonia',
        NE: 'Niger',
        NF: 'Norfolk Island',
        NG: 'Nigeria',
        NI: 'Nicaragua',
        NL: 'Netherlands',
        NO: 'Norway',
        NP: 'Nepal',
        NR: 'Nauru',
        NU: 'Niue',
        NZ: 'New Zealand',
        OM: 'Oman',
        PA: 'Panama',
        PE: 'Peru',
        PF: 'French Polynesia',
        PG: 'Papua New Guinea',
        PH: 'Philippines',
        PK: 'Pakistan',
        PL: 'Poland',
        PM: 'St Pierre & Miquelon',
        PN: 'Pitcairn Islands',
        PR: 'Puerto Rico',
        PS: 'Palestine',
        PT: 'Portugal',
        PW: 'Palau',
        PY: 'Paraguay',
        QA: 'Qatar',
        QO: 'Outlying Oceania',
        RE: 'R\u00e9union',
        RO: 'Romania',
        RS: 'Serbia',
        RU: 'Russia',
        RW: 'Rwanda',
        SA: 'Saudi Arabia',
        SB: 'Solomon Islands',
        SC: 'Seychelles',
        SD: 'Sudan',
        SE: 'Sweden',
        SG: 'Singapore',
        SH: 'St Helena',
        SI: 'Slovenia',
        SJ: 'Svalbard & Jan Mayen',
        SK: 'Slovakia',
        SL: 'Sierra Leone',
        SM: 'San Marino',
        SN: 'Senegal',
        SO: 'Somalia',
        SR: 'Suriname',
        SS: 'South Sudan',
        ST: 'S\u00e3o Tom\u00e9 & Pr\u00edncipe',
        SV: 'El Salvador',
        SX: 'Sint Maarten',
        SY: 'Syria',
        SZ: 'Eswatini',
        TA: 'Tristan da Cunha',
        TC: 'Turks & Caicos Islands',
        TD: 'Chad',
        TF: 'French Southern Territories',
        TG: 'Togo',
        TH: 'Thailand',
        TJ: 'Tajikistan',
        TK: 'Tokelau',
        TL: 'Timor-Leste',
        TM: 'Turkmenistan',
        TN: 'Tunisia',
        TO: 'Tonga',
        TR: 'Turkey',
        TT: 'Trinidad & Tobago',
        TV: 'Tuvalu',
        TW: 'Taiwan',
        TZ: 'Tanzania',
        UA: 'Ukraine',
        UG: 'Uganda',
        UM: 'US Outlying Islands',
        UN: 'United Nations',
        US: 'United States',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VA: 'Vatican City',
        VC: 'St Vincent & Grenadines',
        VE: 'Venezuela',
        VG: 'British Virgin Islands',
        VI: 'US Virgin Islands',
        VN: 'Vietnam',
        VU: 'Vanuatu',
        WF: 'Wallis & Futuna',
        WS: 'Samoa',
        XK: 'Kosovo',
        YE: 'Yemen',
        YT: 'Mayotte',
        ZA: 'South Africa',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
        ZZ: 'Unknown Region',
    },
};

export const countryMapping = {
    Afghanistan: 'af',
    Albania: 'al',
    Algeria: 'dz',
    'American Samoa': 'as',
    Andorra: 'ad',
    Angola: 'ao',
    Anguilla: 'ai',
    Antarctica: 'aq',
    'Antigua & Barbuda': 'ag',
    Argentina: 'ar',
    Armenia: 'am',
    Aruba: 'aw',
    'Ascension Island': 'ac',
    Australia: 'au',
    Austria: 'at',
    Azerbaijan: 'az',
    Bahamas: 'bs',
    Bahrain: 'bh',
    Bangladesh: 'bd',
    Barbados: 'bb',
    Belarus: 'by',
    Belgium: 'be',
    Belize: 'bz',
    Benin: 'bj',
    Bermuda: 'bm',
    Bhutan: 'bt',
    Bolivia: 'bo',
    'Bosnia & Herzegovina': 'ba',
    Botswana: 'bw',
    'Bouvet Island': 'bv',
    Brazil: 'br',
    'British Indian Ocean Territory': 'io',
    'British Virgin Islands': 'vg',
    Brunei: 'bn',
    Bulgaria: 'bg',
    'Burkina Faso': 'bf',
    Burundi: 'bi',
    Cambodia: 'kh',
    Cameroon: 'cm',
    Canada: 'ca',
    'Canary Islands': 'ic',
    'Cape Verde': 'cv',
    'Caribbean Netherlands': 'bq',
    'Cayman Islands': 'ky',
    'Central African Republic': 'cf',
    'Ceuta & Melilla': 'ea',
    Chad: 'td',
    Chile: 'cl',
    China: 'cn',
    'Christmas Island': 'cx',
    'Clipperton Island': 'cp',
    'Cocos (Keeling) Islands': 'cc',
    Colombia: 'co',
    Comoros: 'km',
    'Congo - Brazzaville': 'cg',
    'Congo - Kinshasa': 'cd',
    'Cook Islands': 'ck',
    'Costa Rica': 'cr',
    Croatia: 'hr',
    Cuba: 'cu',
    Curaçao: 'cw',
    Cyprus: 'cy',
    Czechia: 'cz',
    'Côte d’Ivoire': 'ci',
    Denmark: 'dk',
    'Diego Garcia': 'dg',
    Djibouti: 'dj',
    Dominica: 'dm',
    'Dominican Republic': 'do',
    Ecuador: 'ec',
    Egypt: 'eg',
    'El Salvador': 'sv',
    'Equatorial Guinea': 'gq',
    Eritrea: 'er',
    Estonia: 'ee',
    Eswatini: 'sz',
    Ethiopia: 'et',
    'European Union': 'eu',
    Eurozone: 'ez',
    'Falkland Islands (Islas Malvinas)': 'fk',
    'Faroe Islands': 'fo',
    Fiji: 'fj',
    Finland: 'fi',
    France: 'fr',
    'French Guiana': 'gf',
    'French Polynesia': 'pf',
    'French Southern Territories': 'tf',
    Gabon: 'ga',
    Gambia: 'gm',
    Georgia: 'ge',
    Germany: 'de',
    Ghana: 'gh',
    Gibraltar: 'gi',
    Greece: 'gr',
    Greenland: 'gl',
    Grenada: 'gd',
    Guadeloupe: 'gp',
    Guam: 'gu',
    Guatemala: 'gt',
    Guernsey: 'gg',
    Guinea: 'gn',
    'Guinea-Bissau': 'gw',
    Guyana: 'gy',
    Haiti: 'ht',
    'Heard & McDonald Islands': 'hm',
    Honduras: 'hn',
    'Hong Kong': 'hk',
    Hungary: 'hu',
    Iceland: 'is',
    India: 'in',
    Indonesia: 'id',
    Iran: 'ir',
    Iraq: 'iq',
    Ireland: 'ie',
    'Isle of Man': 'im',
    Israel: 'il',
    Italy: 'it',
    Jamaica: 'jm',
    Japan: 'jp',
    Jersey: 'je',
    Jordan: 'jo',
    Kazakhstan: 'kz',
    Kenya: 'ke',
    Kiribati: 'ki',
    Kosovo: 'xk',
    Kuwait: 'kw',
    Kyrgyzstan: 'kg',
    Laos: 'la',
    Latvia: 'lv',
    Lebanon: 'lb',
    Lesotho: 'ls',
    Liberia: 'lr',
    Libya: 'ly',
    Liechtenstein: 'li',
    Lithuania: 'lt',
    Luxembourg: 'lu',
    Macao: 'mo',
    Madagascar: 'mg',
    Malawi: 'mw',
    Malaysia: 'my',
    Maldives: 'mv',
    Mali: 'ml',
    Malta: 'mt',
    'Marshall Islands': 'mh',
    Martinique: 'mq',
    Mauritania: 'mr',
    Mauritius: 'mu',
    Mayotte: 'yt',
    Mexico: 'mx',
    Micronesia: 'fm',
    Moldova: 'md',
    Monaco: 'mc',
    Mongolia: 'mn',
    Montenegro: 'me',
    Montserrat: 'ms',
    Morocco: 'ma',
    Mozambique: 'mz',
    'Myanmar (Burma)': 'mm',
    Namibia: 'na',
    Nauru: 'nr',
    Nepal: 'np',
    Netherlands: 'nl',
    'New Caledonia': 'nc',
    'New Zealand': 'nz',
    Nicaragua: 'ni',
    Niger: 'ne',
    Nigeria: 'ng',
    Niue: 'nu',
    'Norfolk Island': 'nf',
    'North Korea': 'kp',
    'North Macedonia': 'mk',
    'Northern Mariana Islands': 'mp',
    Norway: 'no',
    Oman: 'om',
    'Outlying Oceania': 'qo',
    Pakistan: 'pk',
    Palau: 'pw',
    Palestine: 'ps',
    Panama: 'pa',
    'Papua New Guinea': 'pg',
    Paraguay: 'py',
    Peru: 'pe',
    Philippines: 'ph',
    'Pitcairn Islands': 'pn',
    Poland: 'pl',
    Portugal: 'pt',
    'Puerto Rico': 'pr',
    Qatar: 'qa',
    Romania: 'ro',
    Russia: 'ru',
    Rwanda: 'rw',
    Réunion: 're',
    Samoa: 'ws',
    'San Marino': 'sm',
    'Saudi Arabia': 'sa',
    Senegal: 'sn',
    Serbia: 'rs',
    Seychelles: 'sc',
    'Sierra Leone': 'sl',
    Singapore: 'sg',
    'Sint Maarten': 'sx',
    Slovakia: 'sk',
    Slovenia: 'si',
    'Solomon Islands': 'sb',
    Somalia: 'so',
    'South Africa': 'za',
    'South Georgia & South Sandwich Islands': 'gs',
    'South Korea': 'kr',
    'South Sudan': 'ss',
    Spain: 'es',
    'Sri Lanka': 'lk',
    'St Barthélemy': 'bl',
    'St Helena': 'sh',
    'St Kitts & Nevis': 'kn',
    'St Lucia': 'lc',
    'St Martin': 'mf',
    'St Pierre & Miquelon': 'pm',
    'St Vincent & Grenadines': 'vc',
    Sudan: 'sd',
    Suriname: 'sr',
    'Svalbard & Jan Mayen': 'sj',
    Sweden: 'se',
    Switzerland: 'ch',
    Syria: 'sy',
    'São Tomé & Príncipe': 'st',
    Taiwan: 'tw',
    Tajikistan: 'tj',
    Tanzania: 'tz',
    Thailand: 'th',
    'Timor-Leste': 'tl',
    Togo: 'tg',
    Tokelau: 'tk',
    Tonga: 'to',
    'Trinidad & Tobago': 'tt',
    'Tristan da Cunha': 'ta',
    Tunisia: 'tn',
    Turkey: 'tr',
    Turkmenistan: 'tm',
    'Turks & Caicos Islands': 'tc',
    Tuvalu: 'tv',
    'US Outlying Islands': 'um',
    'US Virgin Islands': 'vi',
    Uganda: 'ug',
    Ukraine: 'ua',
    'United Arab Emirates': 'ae',
    'United Kingdom': 'gb',
    'United Nations': 'un',
    'United States': 'us',
    'Unknown Region': 'zz',
    Uruguay: 'uy',
    Uzbekistan: 'uz',
    Vanuatu: 'vu',
    'Vatican City': 'va',
    Venezuela: 've',
    Vietnam: 'vn',
    'Wallis & Futuna': 'wf',
    'Western Sahara': 'eh',
    Yemen: 'ye',
    Zambia: 'zm',
    Zimbabwe: 'zw',
    'Åland Islands': 'ax',
};

export const countryData = {
    in: { name: 'India', Va: '91', index: 94, imgIndex: 132 },
    sg: { name: 'Singapore', Va: '65', index: 180, imgIndex: 2 },
    ae: { name: 'United Arab Emirates', Va: '971', index: 219, imgIndex: 180 },
    gb: { name: 'United Kingdom', Va: '44', index: 220, imgIndex: 5 },
    us: { name: 'United States', Va: '1', index: 221, imgIndex: 4 },
};

export const countryDataBackup = {
    af: { name: 'Afghanistan', Va: '93', index: 0, imgIndex: 187 },
    al: { name: 'Albania', Va: '355', index: 1, imgIndex: 77 },
    dz: { name: 'Algeria', Va: '213', index: 2, imgIndex: 40 },
    as: { name: 'American Samoa', Va: '1', index: 3, imgIndex: 121 },
    ad: { name: 'Andorra', Va: '376', index: 4, imgIndex: 45 },
    ao: { name: 'Angola', Va: '244', index: 5, imgIndex: 155 },
    ai: { name: 'Anguilla', Va: '1', index: 6, imgIndex: 158 },
    ag: { name: 'Antigua & Barbuda', Va: '1', index: 7, imgIndex: 67 },
    ar: { name: 'Argentina', Va: '54', index: 8, imgIndex: 193 },
    am: { name: 'Armenia', Va: '374', index: 9, imgIndex: 12 },
    aw: { name: 'Aruba', Va: '297', index: 10, imgIndex: 60 },
    ac: { name: 'Ascension Island', Va: '247', index: 11, imgIndex: 5 },
    au: { name: 'Australia', Va: '61', index: 12, imgIndex: 134 },
    at: { name: 'Austria', Va: '43', index: 13, imgIndex: 102 },
    az: { name: 'Azerbaijan', Va: '994', index: 14, imgIndex: 94 },
    bs: { name: 'Bahamas', Va: '1', index: 15, imgIndex: 27 },
    bh: { name: 'Bahrain', Va: '973', index: 16, imgIndex: 115 },
    bd: { name: 'Bangladesh', Va: '880', index: 17, imgIndex: 139 },
    bb: { name: 'Barbados', Va: '1', index: 18, imgIndex: 122 },
    by: { name: 'Belarus', Va: '375', index: 19, imgIndex: 83 },
    be: { name: 'Belgium', Va: '32', index: 20, imgIndex: 0 },
    bz: { name: 'Belize', Va: '501', index: 21, imgIndex: 36 },
    bj: { name: 'Benin', Va: '229', index: 22, imgIndex: 99 },
    bm: { name: 'Bermuda', Va: '1', index: 23, imgIndex: 152 },
    bt: { name: 'Bhutan', Va: '975', index: 24, imgIndex: 146 },
    bo: { name: 'Bolivia', Va: '591', index: 25, imgIndex: 128 },
    ba: { name: 'Bosnia & Herzegovina', Va: '387', index: 26, imgIndex: 123 },
    bw: { name: 'Botswana', Va: '267', index: 27, imgIndex: 219 },
    br: { name: 'Brazil', Va: '55', index: 28, imgIndex: 59 },
    vg: { name: 'British Virgin Islands', Va: '1', index: 29, imgIndex: 109 },
    bn: { name: 'Brunei', Va: '673', index: 30, imgIndex: 131 },
    bg: { name: 'Bulgaria', Va: '359', index: 31, imgIndex: 208 },
    bf: { name: 'Burkina Faso', Va: '226', index: 32, imgIndex: 56 },
    bi: { name: 'Burundi', Va: '257', index: 33, imgIndex: 150 },
    kh: { name: 'Cambodia', Va: '855', index: 34, imgIndex: 17 },
    cm: { name: 'Cameroon', Va: '237', index: 35, imgIndex: 165 },
    ca: { name: 'Canada', Va: '1', index: 36, imgIndex: 106 },
    cv: { name: 'Cape Verde', Va: '238', index: 37, imgIndex: 214 },
    bq: { name: 'Caribbean Netherlands', Va: '599', index: 38, imgIndex: 220 },
    ky: { name: 'Cayman Islands', Va: '1', index: 39, imgIndex: 22 },
    cf: { name: 'Central African Republic', Va: '236', index: 40, imgIndex: 145 },
    td: { name: 'Chad', Va: '235', index: 41, imgIndex: 62 },
    cl: { name: 'Chile', Va: '56', index: 42, imgIndex: 103 },
    cn: { name: 'China', Va: '86', index: 43, imgIndex: 63 },
    co: { name: 'Colombia', Va: '57', index: 44, imgIndex: 24 },
    km: { name: 'Comoros', Va: '269', index: 45, imgIndex: 110 },
    cg: { name: 'Congo - Brazzaville', Va: '242', index: 46, imgIndex: 141 },
    cd: { name: 'Congo - Kinshasa', Va: '243', index: 47, imgIndex: 117 },
    ck: { name: 'Cook Islands', Va: '682', index: 48, imgIndex: 183 },
    cr: { name: 'Costa Rica', Va: '506', index: 49, imgIndex: 168 },
    ci: { name: 'Côte d’Ivoire', Va: '225', index: 50, imgIndex: 129 },
    hr: { name: 'Croatia', Va: '385', index: 51, imgIndex: 69 },
    cu: { name: 'Cuba', Va: '53', index: 52, imgIndex: 58 },
    cw: { name: 'Curaçao', Va: '599', index: 53, imgIndex: 221 },
    cy: { name: 'Cyprus', Va: '357', index: 54, imgIndex: 43 },
    cz: { name: 'Czechia', Va: '420', index: 55, imgIndex: 182 },
    dk: { name: 'Denmark', Va: '45', index: 56, imgIndex: 107 },
    dj: { name: 'Djibouti', Va: '253', index: 57, imgIndex: 169 },
    dm: { name: 'Dominica', Va: '1', index: 58, imgIndex: 197 },
    do: { name: 'Dominican Republic', Va: '1', index: 59, imgIndex: 118 },
    ec: { name: 'Ecuador', Va: '593', index: 60, imgIndex: 90 },
    eg: { name: 'Egypt', Va: '20', index: 61, imgIndex: 178 },
    sv: { name: 'El Salvador', Va: '503', index: 62, imgIndex: 127 },
    gq: { name: 'Equatorial Guinea', Va: '240', index: 63, imgIndex: 116 },
    er: { name: 'Eritrea', Va: '291', index: 64, imgIndex: 55 },
    ee: { name: 'Estonia', Va: '372', index: 65, imgIndex: 196 },
    sz: { name: 'Eswatini', Va: '268', index: 66, imgIndex: 184 },
    et: { name: 'Ethiopia', Va: '251', index: 67, imgIndex: 198 },
    fo: { name: 'Faroe Islands', Va: '298', index: 68, imgIndex: 84 },
    fj: { name: 'Fiji', Va: '679', index: 69, imgIndex: 147 },
    fi: { name: 'Finland', Va: '358', index: 70, imgIndex: 151 },
    fr: { name: 'France', Va: '33', index: 71, imgIndex: 19 },
    gf: { name: 'French Guiana', Va: '594', index: 72, imgIndex: 19 },
    pf: { name: 'French Polynesia', Va: '689', index: 73, imgIndex: 133 },
    ga: { name: 'Gabon', Va: '241', index: 74, imgIndex: 68 },
    gm: { name: 'Gambia', Va: '220', index: 75, imgIndex: 48 },
    ge: { name: 'Georgia', Va: '995', index: 76, imgIndex: 66 },
    de: { name: 'Germany', Va: '49', index: 77, imgIndex: 203 },
    gh: { name: 'Ghana', Va: '233', index: 78, imgIndex: 170 },
    gi: { name: 'Gibraltar', Va: '350', index: 79, imgIndex: 20 },
    gr: { name: 'Greece', Va: '30', index: 80, imgIndex: 11 },
    gl: { name: 'Greenland', Va: '299', index: 81, imgIndex: 138 },
    gd: { name: 'Grenada', Va: '1', index: 82, imgIndex: 195 },
    gp: { name: 'Guadeloupe', Va: '590', index: 83, imgIndex: 30 },
    gu: { name: 'Guam', Va: '1', index: 84, imgIndex: 192 },
    gt: { name: 'Guatemala', Va: '502', index: 85, imgIndex: 71 },
    gw: { name: 'Guinea-Bissau', Va: '245', index: 86, imgIndex: 153 },
    gn: { name: 'Guinea', Va: '224', index: 87, imgIndex: 207 },
    gy: { name: 'Guyana', Va: '592', index: 88, imgIndex: 61 },
    ht: { name: 'Haiti', Va: '509', index: 89, imgIndex: 23 },
    hn: { name: 'Honduras', Va: '504', index: 90, imgIndex: 174 },
    hk: { name: 'Hong Kong', Va: '852', index: 91, imgIndex: 218 },
    hu: { name: 'Hungary', Va: '36', index: 92, imgIndex: 53 },
    is: { name: 'Iceland', Va: '354', index: 93, imgIndex: 159 },
    in: { name: 'India', Va: '91', index: 94, imgIndex: 132 },
    id: { name: 'Indonesia', Va: '62', index: 95, imgIndex: 156 },
    ir: { name: 'Iran', Va: '98', index: 96, imgIndex: 161 },
    iq: { name: 'Iraq', Va: '964', index: 97, imgIndex: 50 },
    ie: { name: 'Ireland', Va: '353', index: 98, imgIndex: 157 },
    il: { name: 'Israel', Va: '972', index: 99, imgIndex: 25 },
    it: { name: 'Italy', Va: '39', index: 100, imgIndex: 9 },
    jm: { name: 'Jamaica', Va: '1', index: 101, imgIndex: 135 },
    jp: { name: 'Japan', Va: '81', index: 102, imgIndex: 31 },
    jo: { name: 'Jordan', Va: '962', index: 103, imgIndex: 112 },
    kz: { name: 'Kazakhstan', Va: '7', index: 104, imgIndex: 92 },
    ke: { name: 'Kenya', Va: '254', index: 105, imgIndex: 212 },
    ki: { name: 'Kiribati', Va: '686', index: 106, imgIndex: 28 },
    xk: { name: 'Kosovo', Va: '383', index: 107, imgIndex: 227 },
    kw: { name: 'Kuwait', Va: '965', index: 108, imgIndex: 202 },
    kg: { name: 'Kyrgyzstan', Va: '996', index: 109, imgIndex: 126 },
    la: { name: 'Laos', Va: '856', index: 110, imgIndex: 33 },
    lv: { name: 'Latvia', Va: '371', index: 111, imgIndex: 154 },
    lb: { name: 'Lebanon', Va: '961', index: 112, imgIndex: 95 },
    ls: { name: 'Lesotho', Va: '266', index: 113, imgIndex: 177 },
    lr: { name: 'Liberia', Va: '231', index: 114, imgIndex: 166 },
    ly: { name: 'Libya', Va: '218', index: 115, imgIndex: 8 },
    li: { name: 'Liechtenstein', Va: '423', index: 116, imgIndex: 75 },
    lt: { name: 'Lithuania', Va: '370', index: 117, imgIndex: 85 },
    lu: { name: 'Luxembourg', Va: '352', index: 118, imgIndex: 113 },
    mo: { name: 'Macao', Va: '853', index: 119, imgIndex: 209 },
    mg: { name: 'Madagascar', Va: '261', index: 120, imgIndex: 98 },
    mw: { name: 'Malawi', Va: '265', index: 121, imgIndex: 173 },
    my: { name: 'Malaysia', Va: '60', index: 122, imgIndex: 148 },
    mv: { name: 'Maldives', Va: '960', index: 123, imgIndex: 47 },
    ml: { name: 'Mali', Va: '223', index: 124, imgIndex: 204 },
    mt: { name: 'Malta', Va: '356', index: 125, imgIndex: 120 },
    mh: { name: 'Marshall Islands', Va: '692', index: 126, imgIndex: 86 },
    mq: { name: 'Martinique', Va: '596', index: 127, imgIndex: 14 },
    mr: { name: 'Mauritania', Va: '222', index: 128, imgIndex: 18 },
    mu: { name: 'Mauritius', Va: '230', index: 129, imgIndex: 176 },
    mx: { name: 'Mexico', Va: '52', index: 130, imgIndex: 162 },
    fm: { name: 'Micronesia', Va: '691', index: 131, imgIndex: 136 },
    md: { name: 'Moldova', Va: '373', index: 132, imgIndex: 217 },
    mc: { name: 'Monaco', Va: '377', index: 133, imgIndex: 70 },
    mn: { name: 'Mongolia', Va: '976', index: 134, imgIndex: 206 },
    me: { name: 'Montenegro', Va: '382', index: 135, imgIndex: 175 },
    ms: { name: 'Montserrat', Va: '1', index: 136, imgIndex: 44 },
    ma: { name: 'Morocco', Va: '212', index: 137, imgIndex: 189 },
    mz: { name: 'Mozambique', Va: '258', index: 138, imgIndex: 49 },
    mm: { name: 'Myanmar (Burma)', Va: '95', index: 139, imgIndex: 1 },
    na: { name: 'Namibia', Va: '264', index: 140, imgIndex: 149 },
    nr: { name: 'Nauru', Va: '674', index: 141, imgIndex: 137 },
    np: { name: 'Nepal', Va: '977', index: 142, imgIndex: 7 },
    nl: { name: 'Netherlands', Va: '31', index: 143, imgIndex: 111 },
    nc: { name: 'New Caledonia', Va: '687', index: 144, imgIndex: 97 },
    nz: { name: 'New Zealand', Va: '64', index: 145, imgIndex: 119 },
    ni: { name: 'Nicaragua', Va: '505', index: 146, imgIndex: 10 },
    ne: { name: 'Niger', Va: '227', index: 147, imgIndex: 42 },
    ng: { name: 'Nigeria', Va: '234', index: 148, imgIndex: 201 },
    nu: { name: 'Niue', Va: '683', index: 149, imgIndex: 167 },
    nf: { name: 'Norfolk Island', Va: '672', index: 150, imgIndex: 15 },
    kp: { name: 'North Korea', Va: '850', index: 151, imgIndex: 142 },
    mk: { name: 'North Macedonia', Va: '389', index: 152, imgIndex: 104 },
    mp: { name: 'Northern Mariana Islands', Va: '1', index: 153, imgIndex: 54 },
    no: { name: 'Norway', Va: '47', index: 154, imgIndex: 64 },
    om: { name: 'Oman', Va: '968', index: 155, imgIndex: 199 },
    pk: { name: 'Pakistan', Va: '92', index: 156, imgIndex: 163 },
    pw: { name: 'Palau', Va: '680', index: 157, imgIndex: 16 },
    ps: { name: 'Palestine', Va: '970', index: 158, imgIndex: 91 },
    pa: { name: 'Panama', Va: '507', index: 159, imgIndex: 65 },
    pg: { name: 'Papua New Guinea', Va: '675', index: 160, imgIndex: 114 },
    py: { name: 'Paraguay', Va: '595', index: 161, imgIndex: 190 },
    pe: { name: 'Peru', Va: '51', index: 162, imgIndex: 72 },
    ph: { name: 'Philippines', Va: '63', index: 163, imgIndex: 143 },
    pl: { name: 'Poland', Va: '48', index: 164, imgIndex: 89 },
    pt: { name: 'Portugal', Va: '351', index: 165, imgIndex: 39 },
    pr: { name: 'Puerto Rico', Va: '1', index: 166, imgIndex: 35 },
    qa: { name: 'Qatar', Va: '974', index: 167, imgIndex: 34 },
    re: { name: 'Réunion', Va: '262', index: 168, imgIndex: 19 },
    ro: { name: 'Romania', Va: '40', index: 169, imgIndex: 52 },
    ru: { name: 'Russia', Va: '7', index: 170, imgIndex: 51 },
    rw: { name: 'Rwanda', Va: '250', index: 171, imgIndex: 216 },
    ws: { name: 'Samoa', Va: '685', index: 172, imgIndex: 186 },
    sm: { name: 'San Marino', Va: '378', index: 173, imgIndex: 171 },
    st: { name: 'São Tomé & Príncipe', Va: '239', index: 174, imgIndex: 194 },
    sa: { name: 'Saudi Arabia', Va: '966', index: 175, imgIndex: 3 },
    sn: { name: 'Senegal', Va: '221', index: 176, imgIndex: 172 },
    rs: { name: 'Serbia', Va: '381', index: 177, imgIndex: 200 },
    sc: { name: 'Seychelles', Va: '248', index: 178, imgIndex: 78 },
    sl: { name: 'Sierra Leone', Va: '232', index: 179, imgIndex: 57 },
    sg: { name: 'Singapore', Va: '65', index: 180, imgIndex: 2 },
    sx: { name: 'Sint Maarten', Va: '1', index: 181, imgIndex: 225 },
    sk: { name: 'Slovakia', Va: '421', index: 182, imgIndex: 179 },
    si: { name: 'Slovenia', Va: '386', index: 183, imgIndex: 93 },
    sb: { name: 'Solomon Islands', Va: '677', index: 184, imgIndex: 80 },
    so: { name: 'Somalia', Va: '252', index: 185, imgIndex: 105 },
    za: { name: 'South Africa', Va: '27', index: 186, imgIndex: 191 },
    kr: { name: 'South Korea', Va: '82', index: 187, imgIndex: 181 },
    ss: { name: 'South Sudan', Va: '211', index: 188, imgIndex: 222 },
    es: { name: 'Spain', Va: '34', index: 189, imgIndex: 87 },
    lk: { name: 'Sri Lanka', Va: '94', index: 190, imgIndex: 213 },
    bl: { name: 'St Barthélemy', Va: '590', index: 191, imgIndex: 19 },
    sh: { name: 'St Helena', Va: '290', index: 192, imgIndex: 37 },
    kn: { name: 'St Kitts & Nevis', Va: '1', index: 193, imgIndex: 6 },
    lc: { name: 'St Lucia', Va: '1', index: 194, imgIndex: 108 },
    mf: { name: 'St Martin', Va: '590', index: 195, imgIndex: 5 },
    pm: { name: 'St Pierre & Miquelon', Va: '508', index: 196, imgIndex: 81 },
    vc: { name: 'St Vincent & Grenadines', Va: '1', index: 197, imgIndex: 211 },
    sd: { name: 'Sudan', Va: '249', index: 198, imgIndex: 26 },
    sr: { name: 'Suriname', Va: '597', index: 199, imgIndex: 215 },
    se: { name: 'Sweden', Va: '46', index: 200, imgIndex: 29 },
    ch: { name: 'Switzerland', Va: '41', index: 201, imgIndex: 101 },
    sy: { name: 'Syria', Va: '963', index: 202, imgIndex: 144 },
    tw: { name: 'Taiwan', Va: '886', index: 203, imgIndex: 38 },
    tj: { name: 'Tajikistan', Va: '992', index: 204, imgIndex: 13 },
    tz: { name: 'Tanzania', Va: '255', index: 205, imgIndex: 185 },
    th: { name: 'Thailand', Va: '66', index: 206, imgIndex: 73 },
    tl: { name: 'Timor-Leste', Va: '670', index: 207, imgIndex: 226 },
    tg: { name: 'Togo', Va: '228', index: 208, imgIndex: 46 },
    tk: { name: 'Tokelau', Va: '690', index: 209, imgIndex: 223 },
    to: { name: 'Tonga', Va: '676', index: 210, imgIndex: 82 },
    tt: { name: 'Trinidad & Tobago', Va: '1', index: 211, imgIndex: 32 },
    tn: { name: 'Tunisia', Va: '216', index: 212, imgIndex: 41 },
    tr: { name: 'Turkey', Va: '90', index: 213, imgIndex: 125 },
    tm: { name: 'Turkmenistan', Va: '993', index: 214, imgIndex: 205 },
    tc: { name: 'Turks & Caicos Islands', Va: '1', index: 215, imgIndex: 100 },
    tv: { name: 'Tuvalu', Va: '688', index: 216, imgIndex: 21 },
    ug: { name: 'Uganda', Va: '256', index: 217, imgIndex: 88 },
    ua: { name: 'Ukraine', Va: '380', index: 218, imgIndex: 160 },
    ae: { name: 'United Arab Emirates', Va: '971', index: 219, imgIndex: 180 },
    gb: { name: 'United Kingdom', Va: '44', index: 220, imgIndex: 5 },
    us: { name: 'United States', Va: '1', index: 221, imgIndex: 4 },
    uy: { name: 'Uruguay', Va: '598', index: 222, imgIndex: 210 },
    vi: { name: 'US Virgin Islands', Va: '1', index: 223, imgIndex: 140 },
    uz: { name: 'Uzbekistan', Va: '998', index: 224, imgIndex: 76 },
    vu: { name: 'Vanuatu', Va: '678', index: 225, imgIndex: 96 },
    va: { name: 'Vatican City', Va: '39', index: 226, imgIndex: 188 },
    ve: { name: 'Venezuela', Va: '58', index: 227, imgIndex: 79 },
    vn: { name: 'Vietnam', Va: '84', index: 228, imgIndex: 74 },
    wf: { name: 'Wallis & Futuna', Va: '681', index: 229, imgIndex: 19 },
    ye: { name: 'Yemen', Va: '967', index: 230, imgIndex: 130 },
    zm: { name: 'Zambia', Va: '260', index: 231, imgIndex: 124 },
    zw: { name: 'Zimbabwe', Va: '263', index: 232, imgIndex: 164 },
};
