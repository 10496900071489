import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  Icon,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';

import './style.scss';
import DateRangePickerModal from '../../components/DateRangePickerModal';

class TeacherTrainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teacherTrainerData: null,
      page: 0,
      no_of_rows: 50,
      count: null,
      startDate: null,
      endDate: null,
      pageHeader: null,
      cellData: null,
      activeSort: { id: '', asc: true },
      groupTypeState: 0,
    };
  }

  fetchTeacherTrainingData = (startDate, endDate) => {
    const { cellData, groupTypeState } = this.state;
    this.setState({ teacherTrainerData: null });
    var cellDataUrl = '';
    if (cellData) {
      if (cellData?.session_id)
        cellDataUrl = cellDataUrl + `&session_id=${cellData.session_id}`;
      if (cellData?.teacher_id)
        cellDataUrl = cellDataUrl + `&teacher_id=${cellData.teacher_id}`;
    }

    var groupType = groupTypeState ? `&group_type=${groupTypeState}` : '';

    const url =
      `v1/admin/${
        this.props.adminId
      }/teacher/performance/sessions/?from_date=${startDate}&to_date=${endDate}&page_size=${
        this.state.no_of_rows
      }&page=${this.state.page + 1}` +
      cellDataUrl +
      groupType;
    axios.get(url).then((response) => {
      if (response?.data?.data?.results) {
        const data = response.data.data;
        this.setState({
          teacherTrainerData: data.results,
          startDate: startDate,
          endDate: endDate,
          count: data.count,
        });
      }
    });
  };

  handleGroupType = (e) => {
    this.setState({ groupTypeState: e.target.value }, () => {
      this.fetchTeacherTrainingData(this.state.startDate, this.state.endDate);
    });
  };

  convertH2M = (timeInHour) => {
    const timeParts = timeInHour.split(':');
    return (
      Number(timeParts[0]) * 60 * 60 +
      Number(timeParts[1]) * 60 +
      Number(timeParts[2])
    );
  };

  conditionalColoringFunction = (teacherTrainer) =>
    teacherTrainer.below_par_students.classwork >= 50 ||
    teacherTrainer.below_par_students.homework >= 50 ||
    teacherTrainer.below_par_students.overall >= 50 ||
    (teacherTrainer.average_ratings.teaching ||
    teacherTrainer.average_ratings.teaching === 0
      ? teacherTrainer.average_ratings.teaching <= 3
      : false) ||
    !teacherTrainer.recording_link ||
    teacherTrainer.delay_time >= 180 ||
    (teacherTrainer.group_type === '2'
      ? this.convertH2M(teacherTrainer.duration) < 2700
      : teacherTrainer.group_type === '1'
      ? this.convertH2M(teacherTrainer.duration) < 3300
      : false);

  render() {
    const {
      teacherTrainerData,
      page,
      no_of_rows,
      count,
      startDate,
      endDate,
      pageHeader,
      cellData,
      activeSort,
      groupTypeState,
    } = this.state;
    return (
      <div className='teacherTrainerContainer'>
        <div className='teacherTrainerHeaderContainer'>
          <div className='teacherTrainerHeaderInnerContainer'>
            {pageHeader ? (
              <Icon
                onClick={() =>
                  this.setState(
                    { cellData: null, pageHeader: null, page: 0 },
                    () => this.fetchTeacherTrainingData(startDate, endDate)
                  )
                }
              >
                keyboard_backspace_icon
              </Icon>
            ) : null}
            <div className='teacherTrainerHeader'>
              Teacher Trainer{pageHeader ? ` - ${pageHeader}` : null}
            </div>
          </div>
          <DateRangePickerModal
            hide={cellData}
            fetchData={this.fetchTeacherTrainingData}
          />
        </div>
        <div className='selectPackageDropdown'>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>
              Select package
            </InputLabel>
            <Select value={groupTypeState} onChange={this.handleGroupType}>
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={1}>Free</MenuItem>
              <MenuItem value={2}>Paid</MenuItem>
            </Select>
          </FormControl>
        </div>
        {teacherTrainerData?.length ? (
          <>
            <div className='tableContainer'>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Object.keys(teacherTrainerData[0])?.map((header) =>
                      header == 'session_id' ||
                      header == 'teacher_id' ||
                      header === 'group_type' ? null : header ==
                        'below_par_students' ? (
                        <TableCell colSpan={3}>
                          % of kids below 70% score
                        </TableCell>
                      ) : header === 'average_score' ? (
                        <TableCell colSpan={2}>Average Score</TableCell>
                      ) : header === 'average_ratings' ? (
                        <TableCell colSpan={3}>Average Ratings</TableCell>
                      ) : (
                        <TableCell rowSpan={2}>
                          <div className='headers'>
                            {header == 'duration' ||
                            header == 'delay_time' ||
                            header == 'teacher' ? (
                              <Icon
                                className='sortIcon'
                                onClick={() =>
                                  this.setState({
                                    activeSort: {
                                      id: header,
                                      asc:
                                        activeSort.id == header
                                          ? !activeSort.asc
                                          : true,
                                    },
                                    teacherTrainerData: teacherTrainerData.sort(
                                      (a, b) =>
                                        typeof a[header] === 'string'
                                          ? this.state.activeSort.id == header
                                            ? this.state.activeSort.asc
                                              ? b[header] > a[header]
                                                ? 1
                                                : -1
                                              : a[header] > b[header]
                                              ? 1
                                              : -1
                                            : a[header] > b[header]
                                            ? 1
                                            : -1
                                          : activeSort.id == header
                                          ? activeSort.asc
                                            ? b[header] - a[header]
                                            : a[header] - b[header]
                                          : a[header] - b[header]
                                    ),
                                  })
                                }
                              >
                                {activeSort.id == header
                                  ? activeSort.asc
                                    ? 'arrow_downward_icon'
                                    : 'arrow_upward_icon'
                                  : 'sort_icon'}
                              </Icon>
                            ) : null}
                            {header.charAt(0).toUpperCase() +
                              header.replaceAll('_', ' ').slice(1)}
                          </div>
                        </TableCell>
                      )
                    )}
                  </TableRow>
                  <TableRow>
                    {Object.keys(
                      teacherTrainerData[0]?.below_par_students
                    )?.map((subHeader) => (
                      <TableCell>
                        <div className='headers'>
                          <Icon
                            className='sortIcon'
                            onClick={() =>
                              this.setState({
                                activeSort: {
                                  id: subHeader,
                                  asc:
                                    activeSort.id == subHeader
                                      ? !activeSort.asc
                                      : true,
                                },
                                teacherTrainerData: teacherTrainerData.sort(
                                  (a, b) =>
                                    activeSort.id == subHeader
                                      ? activeSort.asc
                                        ? b.below_par_students[subHeader] -
                                          a.below_par_students[subHeader]
                                        : a.below_par_students[subHeader] -
                                          b.below_par_students[subHeader]
                                      : a.below_par_students[subHeader] -
                                        b.below_par_students[subHeader]
                                ),
                              })
                            }
                          >
                            {activeSort.id == subHeader
                              ? activeSort.asc
                                ? 'arrow_downward_icon'
                                : 'arrow_upward_icon'
                              : 'sort_icon'}
                          </Icon>
                          {subHeader}
                        </div>
                      </TableCell>
                    ))}
                    {Object.keys(teacherTrainerData[0]?.average_ratings)?.map(
                      (subHeader) => (
                        <TableCell>{subHeader}</TableCell>
                      )
                    )}
                    {Object.keys(teacherTrainerData[0]?.average_score)?.map(
                      (subHeader) => (
                        <TableCell>{subHeader}</TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teacherTrainerData?.map((teacherTrainer) => (
                    <TableRow
                      style={{
                        backgroundColor: this.conditionalColoringFunction(
                          teacherTrainer
                        )
                          ? '#ff00001a'
                          : '',
                      }}
                    >
                      {Object.keys(teacherTrainerData[0])?.map((header) =>
                        header == 'session_id' ||
                        header == 'teacher_id' ||
                        header === 'group_type' ? null : header ==
                          'below_par_students' ? (
                          <>
                            {Object.keys(
                              teacherTrainerData[0]?.below_par_students
                            )?.map((subHeader) => (
                              <TableCell>
                                {teacherTrainer[header][subHeader] ||
                                teacherTrainer[header][subHeader] === 0
                                  ? teacherTrainer[header][subHeader]
                                  : '-'}
                              </TableCell>
                            ))}
                          </>
                        ) : header === 'average_score' ? (
                          <>
                            {Object.keys(
                              teacherTrainerData[0]?.average_score
                            )?.map((subHeader) => (
                              <TableCell>
                                {teacherTrainer[header][subHeader] ||
                                teacherTrainer[header][subHeader] === 0
                                  ? teacherTrainer[header][subHeader]
                                  : '-'}
                              </TableCell>
                            ))}
                          </>
                        ) : header === 'average_ratings' ? (
                          <>
                            {Object.keys(
                              teacherTrainerData[0]?.average_ratings
                            )?.map((subHeader) => (
                              <TableCell>
                                {teacherTrainer[header][subHeader] ||
                                teacherTrainer[header][subHeader] === 0
                                  ? teacherTrainer[header][subHeader]
                                  : '-'}
                              </TableCell>
                            ))}
                          </>
                        ) : (
                          <TableCell
                            className={
                              (header == 'topic' || header == 'teacher') &&
                              !cellData
                                ? 'clickableCell'
                                : ''
                            }
                            onClick={() => {
                              if (
                                (header == 'teacher' || header == 'topic') &&
                                !cellData
                              ) {
                                const cellData =
                                  header == 'teacher'
                                    ? {
                                        teacher_id: teacherTrainer.teacher_id,
                                        session_id: teacherTrainer.session_id,
                                      }
                                    : { session_id: teacherTrainer.session_id };
                                this.setState(
                                  {
                                    cellData: cellData,
                                    pageHeader: teacherTrainer[header],
                                    page: 0,
                                  },
                                  () =>
                                    this.fetchTeacherTrainingData(
                                      startDate,
                                      endDate
                                    )
                                );
                              }
                            }}
                          >
                            {typeof teacherTrainer[header] == 'boolean' ? (
                              teacherTrainer[header] ? (
                                'Yes'
                              ) : (
                                'No'
                              )
                            ) : teacherTrainer[header] ? (
                              header.includes('date') ||
                              header.includes('schedule') ? (
                                new Date(
                                  teacherTrainer[header]
                                ).toLocaleString()
                              ) : (header == 'topic' || header == 'teacher') &&
                                !cellData ? (
                                <div className='clickableDiv'>
                                  {teacherTrainer[header]}
                                  <Icon>chevron_right_icon</Icon>
                                </div>
                              ) : header === 'recording_link' ? (
                                <a
                                  href={teacherTrainer[header]}
                                  target='_blank'
                                >
                                  Link
                                </a>
                              ) : (
                                teacherTrainer[header]
                              )
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              className='teacherTrainerPagination'
              component='div'
              count={count}
              rowsPerPage={no_of_rows}
              page={page}
              onChangePage={(event, newPage) => {
                this.setState({ page: newPage }, () =>
                  this.fetchTeacherTrainingData(startDate, endDate)
                );
              }}
              onChangeRowsPerPage={(event) => {
                this.setState(
                  { no_of_rows: parseInt(event.target.value, 10) },
                  () => this.fetchTeacherTrainingData(startDate, endDate)
                );
              }}
            />
          </>
        ) : teacherTrainerData?.length == 0 ? (
          <div className='centerAligned'>No data</div>
        ) : (
          <CircularProgress className='centerAligned' />
        )}
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  return {
    adminId: states?.user?.user_id,
  };
};

export default connect(mapStateToProps)(TeacherTrainer);
