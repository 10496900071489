import React, { useState } from 'react';
import {
  ListItem,
  Typography,
  makeStyles,
  Modal,
  List,
  Grid,
  Button,
  Icon,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Input,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Table from '../../components/LQDataTables/demo';
import ReassignMissedSessionStudent from '../../components/ReassignMissedSessionStudent';
import axios from 'axios';
import { connect } from 'react-redux';

const userStyles = makeStyles({
  listItem: {
    fontSize: 12,
    marginBottom: 2,
  },
  iconButton: {
    fontSize: 12,
  },
  formControl: {
    width: '53%',
  },

  packageClassDetails: {
    '& a': {
      color: '#000',
    },
  },
});

let tableRef = null;

const ref = React.createRef();

function RescheduleTable(props) {
  const classes = userStyles();
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(null);
  const [rescheduleStatus, setRescheduleStatus] = useState(null);
  const [reAssignStudent, setReAssignStudent] = useState(null);
  function handleCallBack() {
    tableRef && tableRef.refreshTable();
  }

  function updateStatusModal() {
    const user_id = props && props.user && props.user.user_id;
    return (
      <Modal
        open={showUpdateStatusModal}
        onClose={() => {
          setShowUpdateStatusModal(null), setRescheduleStatus(null);
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            margin: 'auto',
            marginTop: '30vh',
            width: 600,
          }}
        >
          <div style={{ padding: 30 }}>
            <List>
              <ListItem>
                <Typography style={{ fontSize: 20 }}>Change Status</Typography>
              </ListItem>
              <ListItem>
                <Grid
                  container
                  spacing={8}
                  style={{ justifyContent: 'center' }}
                >
                  <Grid item>
                    <Select
                      value={rescheduleStatus}
                      onChange={(event) =>
                        setRescheduleStatus(event.target.value)
                      }
                      input={<Input />}
                      style={{ minWidth: 200 }}
                    >
                      <MenuItem value={1}>Active</MenuItem>
                      <MenuItem value={2}>Rescheduled</MenuItem>
                      <MenuItem value={3}>Cancelled</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid
                  container
                  spacing={8}
                  style={{ justifyContent: 'center' }}
                >
                  <Grid item>
                    <Button
                      color='primary'
                      variant='outlined'
                      onClick={() => {
                        setShowUpdateStatusModal(null),
                          setRescheduleStatus(null);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color='primary'
                      variant='outlined'
                      onClick={() => {
                        axios
                          .post(
                            `v1/admin/${user_id}/session/re-schedule/update-request/`,
                            {
                              request_id: showUpdateStatusModal,
                              status: rescheduleStatus,
                            }
                          )
                          .then((res) => {
                            if (res.status == 200) {
                              props.enqueueSnackbar(
                                res?.data?.data?.message ||
                                  'Status Updated Successfully',
                                { variant: 'success' }
                              );
                              setShowUpdateStatusModal(null);
                              setRescheduleStatus(null);
                              tableRef && tableRef.refreshTable();
                            }
                          })
                          .catch((err) => {
                            err?.response?.data?.data?.error?.map((error) =>
                              props.enqueueSnackbar(error || 'error', {
                                variant: 'warning',
                              })
                            );
                          });
                      }}
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </div>
        </div>
      </Modal>
    );
  }

  const ref = React.createRef();
  function formatTable(tableData, params) {
    const newData =
      tableData &&
      tableData.map((entry, index) => {
        const {
          id,
          scheduled_at,
          started_at,
          completed_at,
          class_type,
          status,
          participant_id,
        } = entry;

        const newEntry = { ...entry };

        const updatedEntry = {
          edit: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <IconButton
                onClick={() => {
                  setShowUpdateStatusModal(id),
                    setRescheduleStatus(params && params.status);
                }}
              >
                <Tooltip title='Edit'>
                  <Icon className='iconButton'>edit</Icon>
                </Tooltip>
              </IconButton>
              {status == 'Active' && (
                <IconButton onClick={() => setReAssignStudent(participant_id)}>
                  <Tooltip title='Reassign'>
                    <Icon className='iconButton'>person_add_icon</Icon>
                  </Tooltip>
                </IconButton>
              )}
            </div>
          ),
          ...newEntry,
        };
        return Object.keys(updatedEntry).map((subEntry) =>
          updatedEntry[subEntry]
            ? '' || updatedEntry[subEntry]
            : updatedEntry[subEntry] == 0
            ? '0'
            : '-'
        );
      });
    return newData;
  }

  return (
    <div>
      <Table
        onRef={(ref) => {
          tableRef = ref;
        }}
        firstCol='edit'
        headerFilterList={HEADER_FILTER_LIST}
        formatTable={formatTable}
        sortList={SORT_LIST}
        sortDate={[]}
        search={props.location && props.location.query}
        tableName='Reschedule Table'
        title='Reschedule Table'
        scrollType='stacked'
        rescheduleStatus={1}
        apiUrl={`session/re-schedule/requests/`}
      />
      {reAssignStudent ? (
        <ReassignMissedSessionStudent
          participant_id={reAssignStudent}
          closeModal={() => setReAssignStudent(null)}
          handleCallBack={() => setReAssignStudent(null)}
        />
      ) : showUpdateStatusModal ? (
        updateStatusModal()
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default withSnackbar(connect(mapStateToProps)(RescheduleTable));

const HEADER_FILTER_LIST = [];
const SORT_LIST = [];
