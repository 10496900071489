import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateRangePickerModal from '../../components/DateRangePickerModal';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  CircularProgress,
} from '@material-ui/core';
import SessionSelector from '../../components/SessionSelector';
import axios from 'axios';
import './style.scss';

class StudentMentorView1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentMentorData: null,
      startDate: null,
      endDate: null,
      sessionId: null,
      isLoading: false
    };
  }
  setSessionData = (packageId, gradeId, sessionId) => {
    this.setState({sessionId:sessionId},()=>this.fetchStudentMentorData(this.state.startDate, this.state.endDate)  )   
  }
  fetchStudentMentorData = (startDate = this.state.startDate, endDate = this.state.endDate ) => {
    this.setState({ studentMentorData: null, startDate:startDate, endDate:endDate });
    if(this.state.sessionId){
      this.setState({isLoading: true})
    axios
      .get(
        `v1/admin/${
          this.props.adminId
        }/student/topic/performance/?from_date=${startDate}&to_date=${endDate}&session_id=${this.state.sessionId}`
      )
      .then((response) => {
        if (response?.data?.data?.results) {
          const data = response.data.data;
          this.setState({
            studentMentorData: data.results,
            startDate: data.from_date,
            endDate: data.to_date,
            isLoading: false
          });
        }
      });
    }
  };

  render() {
    const { studentMentorData, isLoading } =
      this.state;
    return (
      <div className='studentMentorContainer'>
        <div className='studentMentorHeaderContainer'>
          <div className='studentMentorHeader'>Student Mentor View 1</div>          
          <DateRangePickerModal
            fetchData={this.fetchStudentMentorData}
          />
        </div>
        <SessionSelector
          setFilterData={this.setSessionData}
          filterList={['session']}
          onFilterChange={()=>this.setState({studentMentorData:null})}
        />
        {studentMentorData?.length ? (        
            <div className='tableContainer'>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Object.keys(studentMentorData[0]).map((header) =>
                      header == 'percentage_score' || header == 'stars' ? (
                        <TableCell colSpan={Object.keys(studentMentorData[0][header]).length}>                       
                          {header.charAt(0).toUpperCase() +
                            header.replaceAll('_', ' ').slice(1)}
                        </TableCell>
                      ) : (
                        <TableCell rowSpan={2}>
                          {header.charAt(0).toUpperCase() +
                            header.replaceAll('_', ' ').slice(1)}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                  <TableRow>
                  {Object.keys(studentMentorData[0]).map((header) =>
                      header == 'percentage_score' || header == 'stars' ? (
                  
                    Object.keys(studentMentorData[0]) && Object.keys(studentMentorData[0][header]).map(
                      (subHeader) => (
                        <TableCell>{subHeader}</TableCell>
                      )
                    )
                  ) : null)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentMentorData.map((studentMentor) => (
                    <TableRow>
                      {Object.keys(studentMentorData[0]).map((header) =>
                        header == 'percentage_score' || header == 'stars' ? (
                          <>
                            {Object.keys(
                              studentMentorData[0][header]
                            ).map((subHeader) => (
                              <TableCell>
                                {studentMentor[header][subHeader]}
                              </TableCell>
                            ))}
                          </>
                        ) : (
                          <TableCell>
                            {header.includes('date') ||
                            header.includes('schedule')
                              ? new Date(
                                  studentMentor[header]
                                ).toLocaleString()
                              : typeof studentMentor[header] == "boolean" ? studentMentor[header] ? 'Yes' : 'No' :                              
                              studentMentor[header] || '-'}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          
        ) : studentMentorData?.length == 0 ? (
          <div className='centerAligned'>No data</div>
        ) : isLoading ? (
          <CircularProgress className='centerAligned' />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (states) => {
  return {
    adminId: states?.user?.user_id,
  };
};

export default connect(mapStateToProps)(StudentMentorView1);
